import { Injectable } from '@angular/core';
import { CanActivate,} from '@angular/router';

import { UsuarioService } from './usuario.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class LoginAnalistaGuard implements CanActivate {
  constructor(
    public _usuarioService: UsuarioService
  ) { }
  canActivate(){

    if ( this._usuarioService.usuario.role === 'ANALISTA_PROVEEDOR' ) {
      return true;
    }else {
      console.log( 'Bloqueado por el ANALISTA GUARD');
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'No es rol Analista',
      
      });
      this._usuarioService.logOutOperador();
      return false;
    }

  }
  
}
