import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';
import { SidebarService } from '../../services/sidebar.service';
import { Usuario } from 'src/app/models/usuario.model';

declare function init_plugins();

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  usuario: Usuario;
  constructor(private _usuarioService: UsuarioService,  public _sidebar: SidebarService,
    ) { 
      init_plugins();
    }

  ngOnInit() {
    init_plugins();
    this.usuario = this._usuarioService.usuario;
    this._sidebar.cargarMenu();

  }
cerrarSesion(){
  this._usuarioService.logOut()
}
}