import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout3',
  templateUrl: './layout3.component.html',
  styleUrls: ['./layout3.component.css']
})
export class Layout3Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
