import { Component, OnInit } from '@angular/core';



declare function init_plugins();

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  
})
export class PagesComponent implements OnInit {

  constructor() { 
    init_plugins();

  }

  ngOnInit() {
    this.myFunction() 
  }
  myFunction() {
    // this.presionar = setTimeout(this.cagados, 10000);
   
    setTimeout(() => {
      init_plugins();
    }, 3000);
  }



}
