import { Usuario } from './usuario.model';

export class SolicitudCompra {

    _id: String;
 
    urlPDF:String;
    urlPDF2:String;
    progreso: Number;
    empresainforme:string;
    rucinforme:String;
    tipoinforme:String;
    informeExistente: String;
    razonSocialCalificacionProveedor: String;
    nombreComercialCalificacionProveedor: String;
 
    rucCalificacionProveedor: String;
    telefonoCalificacionProveedor: String;
    paginaWebCalificacionProveedor: String;
    correoCalificacionProveedor: String;
    referenciaCalificacionProveedor: String;
    cargoCalificacionProveedor: String;
    correoContactoCalificacionProveedor: String;
    fechaCalificacionProveedor:String= new Date().toISOString();
    tipoPago: String;

    bancoCalificacionProveedor: String="Banco Guayaquil";
    cuentacorrienteCalificacionProveedor: String="2897502-3";
    pagoanombredeCalificacionProveedor: String="Rating Services Ecuador Servirating.sa.";
    ndefacturaCalificacionProveedor: String ="llena Servirating";
    detalledepagoCalificacionProveedor: String="llena Servirating";
    estado: String;
    usuario: Usuario;
    img?: string
  }
  