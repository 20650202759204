import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormCalificacion } from '../../models/modelosProveedores/formularioCalificacion';
import { environment } from 'src/environments/environment';



const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class InformeFinalService {
  private informesUrl =  environment.URL_SERVICIOS +'/informesFinales';  // URL to web api
  constructor( 
    private http: HttpClient
  ) { }

  getInformes (): Observable<FormCalificacion[]> {
    return this.http.get<FormCalificacion[]>(this.informesUrl)
  }

  getInforme(id: string): Observable<FormCalificacion> {
    const url = `${this.informesUrl}/${id}`;
    return this.http.get<FormCalificacion>(url);
  }

  addInforme (informe: FormCalificacion): Observable<FormCalificacion> {
    return this.http.post<FormCalificacion>(this.informesUrl, informe, httpOptions);
  }

  deleteInforme (informe: FormCalificacion | string): Observable<FormCalificacion> {
    const id = typeof informe === 'string' ? informe : informe._id;
    const url = `${this.informesUrl}/${id}`;

    return this.http.delete<FormCalificacion>(url, httpOptions);
  }

  updateInforme (informe: FormCalificacion): Observable<any> {
    return this.http.put(this.informesUrl, informe, httpOptions);
  }


  getAdUnits() {
    return this
           .http
           .get(`${this.informesUrl}`);
    }


}