import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UsuarioComercial } from 'src/app/models/usuarioComercial';
import { UsuarioComercialService } from '../../services/usuario-comercial.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AlertaService } from 'src/app/services/alerta.service';

declare function init_plugins();
@Component({
  selector: 'app-registrarse',
  templateUrl: './registrarse.component.html',
  styleUrls: ['./registrarse.component.css']
})
export class RegistrarseComponent implements OnInit {

  forma: FormGroup;

  constructor(
    public _usuarioService: UsuarioComercialService,
    public router: Router,
    private _alertaService: AlertaService
  ) { }

  sonIguales( campo1: string, campo2: string ) {

    return ( group: FormGroup ) => {

      let pass1 = group.controls[campo1].value;
      let pass2 = group.controls[campo2].value;

      if ( pass1 === pass2 ) {
        return null;
      }

      return {
        sonIguales: true
      };

    };

  }


  ngOnInit() {
      init_plugins();

      this.forma = new FormGroup({
        nombre: new FormControl( null , Validators.required ),
        correo: new FormControl( null , [Validators.required, Validators.email] ),
        password: new FormControl( null , Validators.required ),
        password2: new FormControl( null , Validators.required ),
       
      }, { validators: this.sonIguales( 'password', 'password2' )  } );


      this.forma.setValue({
        nombre: '',
        correo: '',
        password: '',
        password2: '',
      
      });

  }


  



  registrarUsuario() {
    if ( !this.forma.valid ) {
      this._alertaService.mostrarAlerta('warning', 'Hay errores en los campos');
      return;
    }
  let usuario = new UsuarioComercial(
      this.forma.value.nombre,
      this.forma.value.correo,
      this.forma.value.password
    );

    this._usuarioService.crearUsuario( usuario ).subscribe((resp) => {
      console.log(resp);
      Swal.fire('Usuario', `creado exitosamente`, 'success');
      this._alertaService.mostrarAlerta('success', 'Usuario creado!', resp.email, 2000);
     this.resetUsuario();
    },

    err => {
      if (err.status = (409)) {
        Swal.fire('Usuario', 'Ya existe!', 'error');
      }
      console.log(err)
    },
    
    
    
    );
  }

  resetUsuario(){
    this.forma.reset()
  }


}
