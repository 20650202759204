export class Solicitud {

  _id: String;
  comprobantePago: Text;
  urlPDF:String;
  razonSocialCalificacionProveedor: String;
  nombreComercialCalificacionProveedor: String;
  direccionCalificacionProveedor: String;
  listaProvincias: String;
  ciudades: String;
  provinciasEcuadorCalificacionProveedor: String;
  ciudadesEcuadorCalificacionProveedor: string;
  rucCalificacionProveedor: String;
  telefonoCalificacionProveedor: String;
  paginaWebCalificacionProveedor: String;
  correoCalificacionProveedor: String;
  referenciaCalificacionProveedor: String;
  cargoCalificacionProveedor: String;
  correoContactoCalificacionProveedor: String;
  fechaCalificacionProveedor:String= new Date().toISOString();
  tipoPago: String;
 // depositoCalificacionProveedor: String;
//  chequeCalificacionProveedor: String;
 // transfereciaCalificacionProveedor: String;
  bancoCalificacionProveedor: String="Banco Guayaquil";
  cuentacorrienteCalificacionProveedor: String="2897502-3";
  pagoanombredeCalificacionProveedor: String="Rating Services Ecuador Servirating.sa.";
  ndefacturaCalificacionProveedor: String ="llena Servirating";
  detalledepagoCalificacionProveedor: String="llena Servirating";
  usuario: string;
  
}
