import { parse } from 'url';

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { FormProveedoresServiceService } from '../../../services/form-proveedores-service.service';
import { FormExpress } from '../../../models/formularioExpress';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
//import * as pluginDataLabels from 'chartjs-plugin-datalabels';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { UsuarioService } from 'src/app/services/usuario.service';
import { TareaService } from 'src/app/services/tareas.service';
import { ListainformesService } from 'src/app/services/listainformes.service';

import { Usuario } from 'src/app/models/usuario.model';

import * as ClassicEditorBuild from '@ckeditor/ckeditor5-build-classic';
import { Tarea } from 'src/app/models/tarea.model';
import * as Chart from 'chart.js';

declare function init_plugins()
declare function mostrarDescrip()
declare function  checksPersonales() 
declare function ValidacionCheckscomer()


@Component({
  selector: 'app-detalle-express',
  templateUrl: './detalle-express.component.html',
  styleUrls: ['./detalle-express.component.css']
})
export class DetalleExpressComponent implements OnInit {
  @ViewChild('reporteExpress', { static: true })
  public Editor = ClassicEditorBuild;

//empezando los graficos nuevos
//grafico de años y empresas
chart:any=[];
date1=[];
capital1=[];
//grafico de empleados
chart1:any=[];
capital2=[];
date2=[];
//grafico de scores

chart3:any=[];
capital3=[];
date3=[];

//grafico de pasivos
chart4:any=[];
capital4=[];
date4=[];

//grafico de pasivos 2
chart5:any=[];
capital5=[];
capital6=[];
date5=[];




form:FormExpress [];

  radioSelected: string;

  //tareaActual
  tareas: Tarea[];
  tareaActual:Tarea;
  //usuario logeado
  usuario: Usuario;
  idFormulario;
  //para mongo
  formularioExpress = new FormExpress();
  submitted = false;
  //empieza formulario
  reporteExpress: FormGroup;
  message: String;
  messageClient = this.formularioExpress.checksCalificaciones;
  myGroup: FormGroup;



  
  constructor(public fb: FormBuilder,
    private proveedoresService: FormProveedoresServiceService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    public _usuarioService: UsuarioService,
    private _tareaService: TareaService,
    private listainforme: ListainformesService) {
    this.reporteExpress = this.fb.group({
      razonSocialExpress: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(this.textPattern)]],
      nombreComercialExpress: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(this.textPattern)]],
      rucExpress: ['', [Validators.required, Validators.minLength(13), Validators.maxLength(13), Validators.pattern(this.numberPattern)]],
      formaLegalExpress: ['', [Validators.required]],
      inicioActividadesExpress: ['', [Validators.required]],
      reinicioActividadesExpress: ['', [Validators.required]],
      actividadPrincipalExpress: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(this.textPattern)]],
      provinciaExpress: ['', [Validators.required]],
      direccionExpress: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(200)]],
      sucursalesExpress: ['', [Validators.required]],
      telefonoExpress: ['', ],
      paginaExpress: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(100)]],
      correoExpress:['', [Validators.required, , Validators.pattern(this.emailPattern)]],
      representanteLegalExpress: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(150), Validators.pattern(this.textPattern)]],
      personaContactoExpress: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(150), Validators.pattern(this.textPattern)]],
      cargoExpress: ['', [Validators.required]],
      empresasRelacionadasExpress: ['',],
      numeroEmpleadosExpress: ['', [Validators.required]],
      pagoRentaUnoExpress: ['',],
      checksListaClinton: [''],
      anioPagoImpuesto: ['',],
      editorrelevante: [''],
      anioPagoImpuestoDos: ['', [Validators.required]],
      fechaInfoFinanciera: ['', [Validators.required]],
      fechaInfoFinanciera1: ['', [Validators.required]],
      fechaInfoFinanciera2: ['', [Validators.required]],
      pagoRentaDosExpress: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(this.textPattern)]],
      impuestoDivisasExpressUno: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
      anioImpuestoDivisas: ['', [Validators.required]],
      anioImpuestoDivisasDos: ['', [Validators.required]],
      puntualidadPagosExpress: ['', [Validators.required]],
      impuestoDivisasExpressDos: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(this.textPattern)]],
      //calificacion
      requeridoClienteExpress: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      requeridoServiratingExpress: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      nombreReferenciaExpress: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(this.textPattern)]],
      direccionReferenciaExpress: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      telefonoReferenciaExpress: ['', ],
      telefonoReferenciaExpress2: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(100), Validators.pattern(this.numberPattern)]],
      contactoReferenciaExpress: ['', ],
      lineaCreditoExpress: ['', [Validators.required]],
      pagosReferenciaExpress: ['', [Validators.required]],
      contactoReferenciaExpress2:[''],
      solicitanteExpress: [],
      productoServicioReferenciaExpress: ['', ],
      clienteDesdeExpress: ['', [Validators.required]],
      compraMensualExpress: ['', [Validators.required] ],
      //selects
      checks: ['', [Validators.required]],
      checks1: ['', [Validators.required]],
      checks2: ['', [Validators.required]],
      ciudadExpress: ['', [Validators.required]],
      estadoExpress: ['', [Validators.required]],
      checksEmpresas: ['', [Validators.required]],
      checksCalificaciones: ['', [Validators.required]],
      montosCreditoExpressDos:['',[Validators.required]],
      checksCalificaciones10:['',],
      //checks
      procesosJudicialesExpress: [''],
      procesosJudicialesExpress2: [''],
      informacionRelevanteExpress: [''],
      informacionBancariaExpress: [''],
      ventas2014Express: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      ventas2015Express: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      ventas2016Express: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      activos2014Express: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      activos2015Express: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      activos2016Express: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      pasivo2014Express: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      pasivo2015Express: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      pasivo2016Express: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      patrimonio2014Express: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      patrimonio2015Express: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      patrimonio2016Express: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      utilidad2014Express: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      utilidad2015Express: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      utilidad2016Express: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      terrenoAreaExpress: [],
      terrenoAvaluoExpress: [],
      construccionAreaExpress: [],
      construccionAvaluoExpress: [],
      avaluoTotalAreaExpress: [],
      avaluoTotalAvaluoExpress: [],
      marcaExpress: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      marcaExpress1: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      marcaExpress2: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      marcaExpress3: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      marcaExpress4: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      tipoExpress: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      tipoExpress1: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      tipoExpress2: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      tipoExpress3: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      tipoExpress4: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      fechavahiculosExpress: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      fechavahiculosExpress1: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      fechavahiculosExpress2: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      fechavahiculosExpress3: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      fechavahiculosExpress4: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      avaluoExpress: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      avaluoExpress1: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      avaluoExpress2: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      avaluoExpress3: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      avaluoExpress4: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      observacionesExpress: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(500)]],
      riseExpress: [''],
      telefonoReferenciaExpress3:[''],
      //nuevos
      edadInvetigadoExpress:['', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
      capitalExpress:['', ],
      actividadExpress:['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      yearExpress:['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      productosExpress:['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      PaisesExpress:['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      importeExpress:['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      yearEvolucionRating:['', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern(this.numberPattern)]],
      valorEvolucionRating:[''],
      escoreEvolucionRating:[''],
      yearUnoEvolucionRating:['', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern(this.numberPattern)]],
      valorUnoEvolucionRating:['',],
      escoreUnoEvolucionRating:['',],
      yearDosEvolucionRating:['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      valorDosEvolucionRating:[''],
      escoreDosEvolucionRating:['', ],
      yearTresEvolucionRating:['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      valortresEvolucionRating:[''],
      escoreTresEvolucionRating:['',],
      yearCuatroEvolucionRating:['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      valorCuatroEvolucionRating:[''],
      escoreCuatroEvolucionRating:[''],
      obligadocontabilidad:[''],
      yearEvolucionEmpleados:['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      NumberEvolucionEmpleados:['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      yearDosEvolucionEmpleados:['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      NumberDosEvolucionEmpleados:['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      yearTresEvolucionEmpleados:['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      NumberTresEvolucionEmpleados:['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      yearCuatroEvolucionEmpleados:['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      NumberCuatroEvolucionEmpleados:['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      relacionEmpresasUno:['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      relacionEmpresasDos:['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      relacionEmpresasTres:['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      yearEvolucionCapitalSocial:['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      EvolucionCapitalSocial:['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      yearDosCapitalSocial:['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      dosevolucioncp:['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      yearTresEvolucionCapitalSocial:['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      tresevolucioncp:['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
    
      yearCuatroEvolucionCapitalSocial:['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      cuartrovolucioncp:['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],

      // referencias
      nombreDosReferenciaExpress: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      direccionDosReferenciaExpress: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      telefonoDosReferenciaExpress: ['', ],
      telefonoDosReferenciaExpress4: ['', ],
      contactoDosReferenciaExpress: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      lineaCreditoDosExpress: ['', [Validators.required]],
      pagosDosReferenciaExpress: ['', [Validators.required]],
      productoDosServicioReferenciaExpress: ['', ],
      clienteDesdeDosExpress:['', [Validators.required]],
      compraMensualDosExpress: ['', ],
      puntualidadPagosDosExpress: ['', [Validators.required]],
      checksSucursales:['', [Validators.required]],
      descripcionSucursales: ['',],
      montosCreditoExpress:['', [Validators.required]],
      FormaPagoexpress:['', [Validators.required]],
      FormaPagoexpressDos:['',],
      descriptionObligacionesLegalesExpress:['',],
      descriptionObligacionesIESExpress: ['',],
      descriptionCompaniasExpress: ['',],
      nombreReferenciaExpressTres:['',],
      direccionReferenciaExpressTres  : ['',], 
      telefonoReferenciaExpressTres: ['',],
      contactoReferenciaExpressTres: ['',],
      lineaCreditoExpressTres: ['',],
      montosCreditoExpressTres: ['',],
      pagosReferenciaExpressTres: ['',],
      FormaPagoexpressTres: ['',],
      productoServicioReferenciaExpressTres: ['',],
      compraMensualExpressTres: ['',],
      puntualidadPagosExpressTres: ['',],
      clienteDesdeExpressTress: ['',],
      checksReferenciaPersonales: ['',],
      nombreReferenciaPersonal:['',],
      direccionReferenciaPersonal: ['',],
      TelefonoReferenciaPersonal:['',],
      contactoReferenciaPersonal: ['',],
      desdeTeimpoReferenciaPersonal:['',],
      relacionReferenciaPersonal: ['',],
      recomendacionesReferenciaPersonal:['',],
      nombreReferenciaPersonalDos:['',],
      direccionReferenciaPersonalDos: ['',],
      TelefonoReferenciaPersonalDos: ['',],
      contactoReferenciaPersonalDos: ['',],
      desdeTeimpoReferenciaPersonalDos: ['',],
      relacionReferenciaPersonalDos: ['',],
      recomendacionesReferenciaPersonalDos: ['',],
      nombreReferenciaPersonalTres: ['',],
      direccionReferenciaPersonalTres: ['',],
      TelefonoReferenciaPersonalTres: ['',],
      contactoReferenciaPersonalTres: ['',],
      desdeTeimpoReferenciaPersonalTres: ['',],
      relacionReferenciaPersonalTres: ['',],
      recomendacionesReferenciaPersonalTres: ['',],
      califica10:['',],
      califica9: ['',],
      califica8: ['',],
      califica67: ['',],
      califica5: ['',],
      califica4: ['',],
      califica123: ['',],
      califica0: ['',],
      checksOblogacionTribuSRI:['',],




    });

  }
  ngOnInit() {
    init_plugins();
    mostrarDescrip();
    this.myFunction2();
    this.myFunction3()
    this.myFunction();
  




    this.myGroup = new FormGroup({
      state: new FormControl()
    });
    this.usuario = this._usuarioService.usuario;
    this.idFormulario = this.route.snapshot.paramMap.get('id');
    this.getInformeExpress(this.idFormulario);
    this.getTareas();




}



  riseExpress: String;
  estadoFormulario: String;
  razonSocialExpress: string;
  fecha: string;
  solicitanteExpress: string;
  nombreComercialExpress: string;
  rucExpress: number;
  formaLegalExpress: string;
  fechaInfoFinanciera: string;
  fechaInfoFinanciera1: string;
  fechaInfoFinanciera2: string;
  estadoExpress: string;
  inicioActividadesExpress: string;
  reinicioActividadesExpress: string;
  actividadPrincipalExpress: string;
  provinciaExpress: string;
  ciudadExpress: string;
  direccionExpress: string;
  sucursalesExpress: string;
  telefonoExpress: number;
  correoExpress: string;
  paginaExpress: string;
  obligadocontabilidad: string;
  representanteLegalExpress: string;
  personaContactoExpress: string;
  cargoExpress: string;
  empresasRelacionadasExpress: string;
  numeroEmpleadosExpress: number;
  pagoRentaUnoExpress: Text;
  anioPagoImpuesto: Text;
  editorrelevante:Text;
  anioPagoImpuestoDos: string;
  pagoRentaDosExpress: string;
  impuestoDivisasExpressUno: string;
  anioImpuestoDivisas: string;
  anioImpuestoDivisasDos: string;
  impuestoDivisasExpressDos: string;
  puntualidadPagosExpress: string;
  //calificacion
  requeridoClienteExpress: string;
  requeridoServiratingExpress: string;
  nombreReferenciaExpress: string;
  direccionReferenciaExpress: string;
  telefonoReferenciaExpress: string;
  telefonoReferenciaExpress2: string;
  contactoReferenciaExpress: string;
  lineaCreditoExpress: string;
  pagosReferenciaExpress: string;
  productoServicioReferenciaExpress: string;
  clienteDesdeExpress: string;
  compraMensualExpress: string;
  //selects
  checks: string;
  checks1: string;
  checks2: string;
  checksEmpresas: string;
  checksCalificaciones: string;
  checksCalificaciones10: String;
  //checks
  procesosJudicialesExpress: Text;
  procesosJudicialesExpress2: Text;
  informacionRelevanteExpress: Text;
  informacionBancariaExpress: Text;
  ventas2014Express: string;
  ventas2015Express: string;
  ventas2016Express: string;
  activos2014Express: string;
  activos2015Express: string;
  activos2016Express: string;
  pasivo2014Express: string;
  pasivo2015Express: string;
  pasivo2016Express: string;
  patrimonio2014Express: string;
  patrimonio2015Express: string;
  patrimonio2016Express: string;
  utilidad2014Express: string;
  utilidad2015Express: string;
  utilidad2016Express: string;
  terrenoAreaExpress: string;
  terrenoAvaluoExpress:string;
  construccionAreaExpress: string;
  construccionAvaluoExpress:string;
  avaluoTotalAreaExpress: string;
  avaluoTotalAvaluoExpress;
  marcaExpress: string;
  marcaExpress1: string;
  marcaExpress2: string;
  marcaExpress3: string;
  marcaExpress4: string;
  tipoExpress: string;
  tipoExpress1: string;
  tipoExpress2: string;
  tipoExpress3: string;
  tipoExpress4: string;
  fechavahiculosExpress: string;
  fechavahiculosExpress1: string;
  fechavahiculosExpress2: string;
  fechavahiculosExpress3: string;
  fechavahiculosExpress4: string;
  avaluoExpress: string;
  avaluoExpress1: string;
  avaluoExpress2: string;
  avaluoExpress3: string;
  avaluoExpress4: string;
  observacionesExpress: string;
  telefonoReferenciaExpress3:String;
  // nuevos
  contactoReferenciaExpress2:String;
  edadInvetigadoExpress: string;
  capitalExpress: string;
  actividadExpress: string;
  yearExpress: string;
  productosExpress: string;
  PaisesExpress: string;
  importeExpress: string;
  yearEvolucionRating: number;
  valorEvolucionRating: number;
  escoreEvolucionRating: string;
  yearUnoEvolucionRating: number;
  valorUnoEvolucionRating: number;
  escoreUnoEvolucionRating: string;
  yearDosEvolucionRating: number;
  valorDosEvolucionRating: number;
  escoreDosEvolucionRating: string;
  yearTresEvolucionRating: number;
  valortresEvolucionRating: number;
  escoreTresEvolucionRating: string;
  yearCuatroEvolucionRating: number;
  valorCuatroEvolucionRating: number;
  escoreCuatroEvolucionRating: string;
  yearEvolucionEmpleados: number;
  NumberEvolucionEmpleados: number;
  yearDosEvolucionEmpleados: number;
  NumberDosEvolucionEmpleados: number;
  yearTresEvolucionEmpleados: number;
  NumberTresEvolucionEmpleados: number;
  yearCuatroEvolucionEmpleados: number;
  NumberCuatroEvolucionEmpleados: number;
  relacionEmpresasUno: string;
  relacionEmpresasDos: string;
  relacionEmpresasTres: string;
  yearEvolucionCapitalSocial: string;
  EvolucionCapitalSocial: number;
  yearDosCapitalSocial: string;
  dosevolucioncp: number;
  yearTresEvolucionCapitalSocial: string;
  tresevolucioncp: number;
  yearCuatroEvolucionCapitalSocial: string;
  cuartrovolucioncp: number;
  nombreDosReferenciaExpress: string;
  direccionDosReferenciaExpress: string;
  telefonoDosReferenciaExpress: string;
  telefonoDosReferenciaExpress4:string
  contactoDosReferenciaExpress: string;
  lineaCreditoDosExpress: string;
  pagosDosReferenciaExpress: String;
  productoDosServicioReferenciaExpress: string;
  clienteDesdeDosExpress: string;
  compraMensualDosExpress: string;
  puntualidadPagosDosExpress: string;

  checksSucursales: String;
  checksListaClinton:String;
  descripcionSucursales: String;
  montosCreditoExpress: String;
  montosCreditoExpressDos: String;
  FormaPagoexpress: String;
  FormaPagoexpressDos: String;
  descriptionObligacionesLegalesExpress: String;
  descriptionObligacionesIESExpress: String;
  descriptionCompaniasExpress: String;
  // campos referencias 3
  nombreReferenciaExpressTres: String;
  direccionReferenciaExpressTres: String;
  telefonoReferenciaExpressTres: String;
  lineaCreditoExpressTres:String;
  montosCreditoExpressTres: String;
  pagosReferenciaExpressTres: String;
  FormaPagoexpressTres: String;
  productoServicioReferenciaExpressTres: String;
  clienteDesdeExpressTress: String;
  compraMensualExpressTres: String;
  puntualidadPagosExpressTres: String;
  checksReferenciaPersonales: String;
  nombreReferenciaPersonal: String;
  direccionReferenciaPersonal: String;
  TelefonoReferenciaPersonal: String;
  contactoReferenciaPersonal: String;
  desdeTeimpoReferenciaPersonal: String;
  relacionReferenciaPersonal: String;
  recomendacionesReferenciaPersonal: String;
  nombreReferenciaPersonalDos: String;
  TelefonoReferenciaPersonalDos: String;
  contactoReferenciaPersonalDos: String;
  desdeTeimpoReferenciaPersonalDos: String;
  relacionReferenciaPersonalDos: String;
  recomendacionesReferenciaPersonalDos: String;
  nombreReferenciaPersonalTres: String;
  direccionReferenciaPersonalTres: String;
  TelefonoReferenciaPersonalTres: String;
  contactoReferenciaPersonalTres: String;
  desdeTeimpoReferenciaPersonalTres: String;
  relacionReferenciaPersonalTres: String;
  recomendacionesReferenciaPersonalTres: String;
  califica10: String;
  califica9: String;
  califica8: String;
  califica67: String;
  califica5: String;
  califica4: String;
  califica123: String;
  califica0: String;
  checksOblogacionTribuSRI:String;




  listaProvincias: Array<any> = [
    {
      name: "AZUAY", ciudades: ["CUENCA", "GIRÓN", "GUALACEO", "NABÓN", "PAUTE", "PUCARA", "SAN FERNANDO", "SANTA ISABEL", "SIGSIG", "OÑA", "CHORDELEG", "EL PAN", "SEVILLA DE ORO", "GUACHAPALA", "CAMILO PONCE ENRÍQUEZ"
      ]
    }, {
      name: "BOLIVAR", ciudades: ["GUARANDA", "CHILLANES", "CHIMBO", "ECHEANDÍA", "SAN MIGUEL", "CALUMA", "LAS NAVES"
      ]
    }, {
      name: "CAÑAR", ciudades: ["AZOGUES", "BIBLIÁN", "CAÑAR", "LA TRONCAL", "EL TAMBO", "DÉLEG", "SUSCAL"
      ]
    }, {
      name: "CARCHI", ciudades: ["TULCÁN", "BOLÍVAR", "ESPEJO", "MIRA", "MONTÚFAR", "SAN PEDRO DE HUACA"
      ]
    }, {
      name: "COTOPAXI", ciudades: ["LATACUNGA", "LA MANÁ", "PANGUA", "PUJILI", "SALCEDO", "SAQUISILÍ", "SIGCHOS"
      ]
    }, {
      name: "CHIMBORAZO", ciudades: ["RIOBAMBA", "ALAUSI", "COLTA", "CHAMBO", "CHUNCHI", "GUAMOTE", "GUANO", "PALLATANGA", "PENIPE", "CUMANDÁ"
      ]
    }, {
      name: "EL ORO", ciudades: ["MACHALA", "ARENILLAS", "ATAHUALPA", "BALSAS", "CHILLA", "EL GUABO", "HUAQUILLAS", "MARCABELÍ", "PASAJE", "PIÑAS", "PORTOVELO", "SANTA ROSA", "ZARUMA", "LAS LAJAS"
      ]
    }, {
      name: "ESMERALDAS", ciudades: ["ESMERALDAS", "ELOY ALFARO", "MUISNE", "QUININDÉ", "SAN LORENZO", "ATACAMES", "RIOVERDE", "LA CONCORDIA"
      ]
    }, {
      name: "GUAYAS", ciudades: ["GUAYAQUIL", "ALFREDO BAQUERIZO MORENO (JUJÁN)", "BALAO", "BALZAR", "COLIMES", "DAULE", "DURÁN", "EL EMPALME", "EL TRIUNFO", "MILAGRO", "NARANJAL", "NARANJITO", "PALESTINA", "PEDRO CARBO", "SAMBORONDÓN", "SANTA LUCÍA", "SALITRE (URBINA JADO)", "SAN JACINTO DE YAGUACHI", "PLAYAS", "SIMÓN BOLÍVAR", "CORONEL MARCELINO MARIDUEÑA", "LOMAS DE SARGENTILLO", "NOBOL", "GENERAL ANTONIO ELIZALDE", "ISIDRO AYORA"
      ]
    }, {
      name: "IMBABURA", ciudades: ["IBARRA", "ANTONIO ANTE", "COTACACHI", "OTAVALO", "PIMAMPIRO", "SAN MIGUEL DE URCUQUÍ"
      ]
    }, {
      name: "LOJA", ciudades: ["LOJA", "CALVAS", "CATAMAYO", "CELICA", "CHAGUARPAMBA", "ESPÍNDOLA", "GONZANAMÁ", "MACARÁ", "PALTAS", "PUYANGO", "SARAGURO", "SOZORANGA", "ZAPOTILLO", "PINDAL", "QUILANGA", "OLMEDO"
      ]
    }, {
      name: "LOS RIOS", ciudades: ["BABAHOYO", "BABA", "MONTALVO", "PUEBLOVIEJO", "QUEVEDO", "URDANETA", "VENTANAS", "VÍNCES", "PALENQUE", "BUENA FÉ", "VALENCIA", "MOCACHE", "QUINSALOMA"
      ]
    }, {
      name: "MANABI", ciudades: ["PORTOVIEJO", "BOLÍVAR", "CHONE", "EL CARMEN", "FLAVIO ALFARO", "JIPIJAPA", "JUNÍN", "MANTA", "MONTECRISTI", "PAJÁN", "PICHINCHA", "ROCAFUERTE", "SANTA ANA", "SUCRE", "TOSAGUA", "24 DE MAYO", "PEDERNALES", "OLMEDO", "PUERTO LÓPEZ", "JAMA", "JARAMIJÓ", "SAN VICENTE"
      ]
    }, {
      name: "MORONA SANTIAGO", ciudades: ["MORONA", "GUALAQUIZA", "LIMÓN INDANZA", "PALORA", "SANTIAGO", "SUCÚA", "HUAMBOYA", "SAN JUAN BOSCO", "TAISHA", "LOGROÑO", "PABLO SEXTO", "TIWINTZA"
      ]
    }, {
      name: "NAPO", ciudades: ["TENA", "ARCHIDONA", "EL CHACO", "QUIJOS", "CARLOS JULIO AROSEMENA TOLA"
      ]
    }, {
      name: "PASTAZA", ciudades: ["PASTAZA", "MERA", "SANTA CLARA", "ARAJUNO"
      ]
    }, {
      name: "PICHINCHA", ciudades: ["QUITO", "CAYAMBE", "MEJIA", "PEDRO MONCAYO", "RUMIÑAHUI", "SAN MIGUEL DE LOS BANCOS", "PEDRO VICENTE MALDONADO", "PUERTO QUITO"
      ]
    }, {
      name: "TUNGURAHUA", ciudades: ["AMBATO", "BAÑOS DE AGUA SANTA", "CEVALLOS", "MOCHA", "PATATE", "QUERO", "SAN PEDRO DE PELILEO", "SANTIAGO DE PÍLLARO", "TISALEO"
      ]
    }, {
      name: "ZAMORA CHINCHIPE", ciudades: ["ZAMORA", "CHINCHIPE", "NANGARITZA", "YACUAMBI", "YANTZAZA (YANZATZA)", "EL PANGUI", "CENTINELA DEL CÓNDOR", "PALANDA", "PAQUISHA"
      ]
    }, {
      name: "GALAPAGOS", ciudades: ["SAN CRISTÓBAL", "ISABELA", "SANTA CRUZ"
      ]
    }, {
      name: "SUCUMBIOS", ciudades: ["LAGO AGRIO", "GONZALO PIZARRO", "PUTUMAYO", "SHUSHUFINDI", "SUCUMBÍOS", "CASCALES", "CUYABENO"
      ]
    }, {
      name: "ORELLANA", ciudades: ["ORELLANA", "AGUARICO", "LA JOYA DE LOS SACHAS", "LORETO"
      ]
    }, {
      name: "SANTO DOMINGO DE LOS TSACHILAS", ciudades: ["SANTO DOMINGO"
      ]
    }, { name: "SANTA ELENA", ciudades: ["SANTA ELENA", "LA LIBERTAD", "SALINAS"] },
  ];
  ciudades: Array<any>;
  cambioProvincia(count) {
    this.ciudades = this.listaProvincias.find(con => con.name == count).ciudades;
  }



  emailPattern = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  textPattern = new RegExp(/^[ a-zA-ZñÑáéíóúÁÉÍÓÚ&-.\s ]+$/);
  numberPattern = new RegExp(/^(\d{1,3}(\,\d{3})*|(\d+))(\.\d{2})?$/);
  paginaWebPattern = new RegExp(/^((https?):\/\/)?([w|W]{3}\.)+[a-zA-Z0-9\-\.]{3,}\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/);
  dineroPattern = new RegExp(/^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9][0-9])?$/);
  saveData() {
 
  }



  onResetForm() {
    this.reporteExpress.reset();

  }


  //funciones anandidas para mongoDB

  newProveedor(): void {
    this.submitted = false;
    this.formularioExpress = new FormExpress();
  }

  addReporteExpress() {
    this.submitted = true;
    this.save();
  }

  /*goBack(): void {
    this.location.back();
  }*/

  private save(): void {
    console.log(this.formularioExpress);
    if(this.formularioExpress.relacionEmpresasTres !=null || this.formularioExpress.relacionEmpresasTres == undefined || this.formularioExpress.relacionEmpresasTres == ""){
      this.update("25%");
    }
    this.proveedoresService.addInforme(this.formularioExpress).subscribe(function (e) {
      console.log(e);
    });

    Swal.fire('Muy Bien', 'Datos Guardados Éxitosamente', 'success');
  }



  quitarColorCritico() {
    document.getElementById("critico").style.backgroundColor = "white";

  }

  quitarColorRiesgoso() {
    document.getElementById("riesgoso").style.backgroundColor = "white";

  }

  quitarColorConfiable() {
    document.getElementById("confiable").style.backgroundColor = "white";

  }

  quitarColorNotable() {
    document.getElementById("notable").style.backgroundColor = "white";

  }


  cambiarColorCritico0() {
    document.getElementById("critico").style.backgroundColor = "#0A9F25";
  }
  cambiarColorRiesgoso() {
    document.getElementById("riesgoso").style.backgroundColor = "#66CDAA";

  }
  cambiarColorConfiable() {

    document.getElementById("confiable").style.backgroundColor = "#0A9F25";

  }
  cambiarColorNotable() {
    document.getElementById("notable").style.backgroundColor = "#0A9F25";

  }

  textoRojo0() {
    document.getElementById("0").style.color = "#D80D0D"
  }
  quitarColor0() {
    document.getElementById("0").style.color = "black"

  }
  textoRojo1() {
    document.getElementById("1").style.color = "#D80D0D"
  }
  quitarColor1() {
    document.getElementById("1").style.color = "black"

  }
  textoRojo2() {
    document.getElementById("2").style.color = "#D80D0D"
  }
  quitarColor2() {
    document.getElementById("2").style.color = "black"

  }
  textoRojo3() {
    document.getElementById("3").style.color = "#D80D0D"
  }
  quitarColor3() {
    document.getElementById("3").style.color = "black"
  }
  textoRojo4() {
    document.getElementById("4").style.color = "#D80D0D"
  }
  quitarColor4() {
    document.getElementById("4").style.color = "black"

  }
  textoRojo5() {
    document.getElementById("5").style.color = "#D80D0D"
  }
  quitarColor5() {
    document.getElementById("5").style.color = "black"

  }
  textoRojo6() {
    document.getElementById("6").style.color = "#66CDAA"
  }
  quitarColor6() {
    document.getElementById("6").style.color = "black"

  }
  textoRojo7() {
    document.getElementById("7").style.color = "#66CDAA"
  }
  quitarColor7() {
    document.getElementById("7").style.color = "black"

  }
  textoRojo8() {
    document.getElementById("8").style.color = "#66CDAA"
  }
  quitarColor8() {
    document.getElementById("8").style.color = "black"

  }
  textoRojo9() {
    document.getElementById("9").style.color = "#D80D0D"
  }
  quitarColor9() {
    document.getElementById("9").style.color = "black"

  }
  textoRojo10() {
    document.getElementById("10").style.color = "#D80D0D"
  }
  quitarColor10() {
    document.getElementById("10").style.color = "black"

  }


  getInformeExpress(id) {
    this.proveedoresService.getInforme(id).subscribe(
      informesExpress => {
        console.log(informesExpress);
        this.formularioExpress = informesExpress;
      }
    );
  }

  getInformeExpress1(id) {
    this.proveedoresService.getInforme(id).subscribe(
      informesExpress => {
        console.log(informesExpress);
        this.formularioExpress = informesExpress;
      }
    );
  }

  back() {
    setTimeout(() => {
      this.location.back();
    }, 1400);
  }
  getTareas() {
    this._tareaService.getTareas1().subscribe(tasks => {
      this.tareas = tasks
    });
  }
  update(estadoForm: String): void {
    for(var e in this.tareas){
      if(this.tareas[e]._idFormulario == this.idFormulario && this.tareas[e]._idResponsable==this.usuario._id){
        this.tareaActual = this.tareas[e];
      }
    }
    this.tareaActual["id"]=this.tareaActual._id
    this.tareaActual.estado = estadoForm;
    this._tareaService.updateTarea100(this.tareaActual).subscribe(result =>{
      console.log(result)
    });
    this.submitted = true;
    this.formularioExpress["id"] = this.formularioExpress._id;
    this.formularioExpress.estadoFormExpress = estadoForm;
    this.proveedoresService.updateInforme(this.formularioExpress).subscribe(function (result) {
   
      console.log(result)
      
      Swal.fire('Muy Bien', 'Se ha guardado con éxito', 'success')
     
  

     // window.location.reload();
      if (!result) {
        Swal.fire("Algo salió mal", "", "error");

      }
    });
  }
  delete(): void {

    this.submitted = true;

    this.proveedoresService.deleteInforme(this.formularioExpress._id).subscribe(
      result => this.message = "Se eliminó con éxito");
    Swal.fire('Eliminado','Datos Eliminados Correctamente','success');
    this.router.navigateByUrl("/inicioOperador", { skipLocationChange: true });
    this.back();



  }



  updateValue(value: string) {
    let val = parseInt(value, 10);
    if (Number.isNaN(val)) {
      val = 0;
    }
    this.ventas2014Express = formatCurrency(val, 'en-US', getCurrencySymbol('USD', 'wide'));
  }

  recargarPagina() {
    setTimeout("location.reload()", 2100)
  };


 

 

  imprimir() {
    window.print();
}

atras(): void {
  this.location.back();
}



listascores: Array<any> = [
  {
    name: "", ciudades1: ["NO HAY INFORMACIÓN", ]
  },
  {
    name: "0", ciudades1: ["Score: Cliente no apto para realizar gestiones comerciales 0", ]
  },
  {
    name: "1", ciudades1: [" Score: Recomendamos no otorgar ningún tipo de crédito a este cliente debido a su mala experiencia crediticia bancaria y comercial. 1", ]
  },
  {
    name: "2", ciudades1: [" Score: Recomendamos no otorgar ningún tipo de crédito a este cliente debido a su mala experiencia crediticia y bancaria. 2", ]
  },
  {
    name: "3", ciudades1: ["Score: Recomendamos trabajar solo al contado con este cliente. 3", ]
  },
  {
    name: "4", ciudades1: [" Score: Por falta de información no podemos recomendar ningún tipo de sugerencia de monto de crédito. 4", ]
  },
  {
    name: "5", ciudades1: [" Score: Recomendamos solicitar algún tipo de garantía real para trabajar con este cliente 5", ]
  },
  {
    name: "6", ciudades1: ["   Score: Recomendamos mantener relaciones comerciales con este cliente. 6 " ]
  },
  {
    name: "7", ciudades1: ["  Score: Recomendamos mantener relaciones comerciales con este cliente y aumentar su capacidad de endeudamiento. 7", ]
  },
  {
    name: "8", ciudades1: ["  Score: Recomendamos mantener relaciones comerciales a largo plazo con este cliente. 8", ]
  },
  {
    name: "9", ciudades1: [" Score: Recomendamos otorgar créditos de mediana y alta cuantía sujeto de crédito con notable desempeño crediticio y comercial. 9", ]
  },
  {
    name: "10", ciudades1: ["   Score: Recomendamos otorgar créditos de mediana y alta cuantía sujeto de crédito con notable desempeño crediticio comercial y financieros. 10", ]
  },
];

ciudades1: Array<any>;
cambioProvincia1(count) {
  this.ciudades1 = this.listascores.find(con => con.name == count).ciudades1;
}

listascores2: Array<any> = [
  {
    name: "", ciudades2: ["NO HAY INFORMACIÓN", ]
  },
  {
    name: "0", ciudades2: ["Score: Cliente no apto para realizar gestiones comerciales 0", ]
  },
  {
    name: "1", ciudades2: [" Score: Recomendamos no otorgar ningún tipo de crédito a este cliente debido a su mala experiencia crediticia bancaria y comercial. 1", ]
  },
  {
    name: "2", ciudades2: [" Score: Recomendamos no otorgar ningún tipo de crédito a este cliente debido a su mala experiencia crediticia y bancaria. 2", ]
  },
  {
    name: "3", ciudades2: ["Score: Recomendamos trabajar solo al contado con este cliente. 3", ]
  },
  {
    name: "4", ciudades2: [" Score: Por falta de información no podemos recomendar ningún tipo de sugerencia de monto de crédito. 4", ]
  },
  {
    name: "5", ciudades2: ["  Score: Recomendamos solicitar algún tipo de garantía real para trabajar con este cliente 5", ]
  },
  {
    name: "6", ciudades2: ["  Score: Recomendamos mantener relaciones comerciales con este cliente. 6", ]
  },
  {
    name: "7", ciudades2: ["  Score: Recomendamos mantener relaciones comerciales con este cliente y aumentar su capacidad de endeudamiento. 7", ]
  },
  {
    name: "8", ciudades2: ["  Score: Recomendamos mantener relaciones comerciales a largo plazo con este cliente. 8", ]
  },
  {
    name: "9", ciudades2: [" Score: Recomendamos otorgar créditos de mediana y alta cuantía sujeto de crédito con notable desempeño crediticio y comercial. 9", ]
  },
  {
    name: "10", ciudades2: ["  Score: Recomendamos otorgar créditos de mediana y alta cuantía sujeto de crédito con notable desempeño crediticio comercial y financieros. 10", ]
  },
];

ciudades2: Array<any>;
cambioProvincia2(count) {
  this.ciudades2 = this.listascores2.find(con => con.name == count).ciudades2;
}

listascores3: Array<any> = [
  {
    name: "", ciudades3: ["NO HAY INFORMACIÓN", ]
  },
  {
    name: "0", ciudades3: ["Score: Cliente no apto para realizar gestiones comerciales 0", ]
  },
  {
    name: "1", ciudades3: [" Score: Recomendamos no otorgar ningún tipo de crédito a este cliente debido a su mala experiencia crediticia bancaria y comercial. 1", ]
  },
  {
    name: "2", ciudades3: [   "Score: Recomendamos no otorgar ningún tipo de crédito a este cliente debido a su mala experiencia crediticia y bancaria. 2 ", ]
  },
  {
    name: "3", ciudades3: [" Score: Recomendamos trabajar solo al contado con este cliente. 3", ]
  },
  {
    name: "4", ciudades3: [" Score: Por falta de información no podemos recomendar ningún tipo de sugerencia de monto de crédito. 4 ", ]
  },
  {
    name: "5", ciudades3: ["  Score: Recomendamos solicitar algún tipo de garantía real para trabajar con este cliente 5  ", ]
  },
  {
    name: "6", ciudades3: ["  Score: Recomendamos mantener relaciones comerciales con este cliente. 6  ", ]
  },
  {
    name: "7", ciudades3: ["  Score: Recomendamos mantener relaciones comerciales con este cliente y aumentar su capacidad de endeudamiento. 7 ", ]
  },
  {
    name: "8", ciudades3: [" Score: Recomendamos mantener relaciones comerciales a largo plazo con este cliente 8  ", ]
  },
  {
    name: "9", ciudades3: ["  Score: Recomendamos otorgar créditos de mediana y alta cuantía sujeto de crédito con notable desempeño crediticio y comercial. 9", ]
  },
  {
    name: "10", ciudades3: [" Score: Recomendamos otorgar créditos de mediana y alta cuantía sujeto de crédito con notable desempeño crediticio comercial y financieros. 10   ", ]
  },
];

ciudades3: Array<any>;
cambioProvincia3(count) {
  this.ciudades3 = this.listascores3.find(con => con.name == count).ciudades3;
}
listascores4: Array<any> = [
  {
    name: "", ciudades4: ["NO HAY INFORMACIÓN",]
  },
  {
    name: "0", ciudades4: ["Score: Cliente no apto para realizar gestiones comerciales 0", ]
  },
  {
    name: "1", ciudades4: [" Score: Recomendamos no otorgar ningún tipo de crédito a este cliente debido a su mala experiencia crediticia bancaria y comercial. 1", ]
  },
  {
    name: "2", ciudades4: ["Score: Recomendamos no otorgar ningún tipo de crédito a este cliente debido a su mala experiencia crediticia y bancaria. 2", ]
  },
  {
    name: "3", ciudades4: ["Score: Recomendamos trabajar solo al contado con este cliente. 3", ]
  },
  {
    name: "4", ciudades4: ["  Score: Por falta de información no podemos recomendar ningún tipo de sugerencia de monto de crédito. 4", ]
  },
  {
    name: "5", ciudades4: ["  Score: Recomendamos solicitar algún tipo de garantía real para trabajar con este cliente 5", ]
  },
  {
    name: "6", ciudades4: ["  Score: Recomendamos mantener relaciones comerciales con este cliente. 6", ]
  },
  {
    name: "7", ciudades4: ["  Score: Recomendamos mantener relaciones comerciales con este cliente y aumentar su capacidad de endeudamiento. 7", ]
  },
  {
    name: "8", ciudades4: ["  Score: Recomendamos mantener relaciones comerciales a largo plazo con este cliente. 8", ]
  },
  {
    name: "9", ciudades4: [" Score: Recomendamos otorgar créditos de mediana y alta cuantía sujeto de crédito con notable desempeño crediticio y comercial. 9", ]
  },
  {
    name: "10", ciudades4: [" Score: Recomendamos otorgar créditos de mediana y alta cuantía sujeto de crédito con notable desempeño crediticio comercial y financieros. 10", ]
  },
];

ciudades4: Array<any>;
cambioProvincia4(count) {
  this.ciudades4 = this.listascores4.find(con => con.name == count).ciudades4;
}

listascores5: Array<any> = [
  {
    name: "", ciudades5: ["NO HAY INFORMACIÓN", ]
  },
  {
    name: "0", ciudades5: ["Score: Cliente no apto para realizar gestiones comerciales 0", ]
  },
  {
    name: "1", ciudades5: [" Score: Recomendamos no otorgar ningún tipo de crédito a este cliente debido a su mala experiencia crediticia bancaria y comercial. 1", ]
  },
  {
    name: "2", ciudades5: ["Score: Recomendamos no otorgar ningún tipo de crédito a este cliente debido a su mala experiencia crediticia y bancaria. 2", ]
  },
  {
    name: "3", ciudades5: ["Score: Recomendamos trabajar solo al contado con este cliente. 3", ]
  },
  {
    name: "4", ciudades5: ["  Score: Por falta de información no podemos recomendar ningún tipo de sugerencia de monto de crédito. 4", ]
  },
  {
    name: "5", ciudades5: [" Score: Recomendamos solicitar algún tipo de garantía real para trabajar con este cliente 5", ]
  },
  {
    name: "6", ciudades5: ["  Score: Recomendamos mantener relaciones comerciales con este cliente. 6", ]
  },
  {
    name: "7", ciudades5: ["  Score: Recomendamos mantener relaciones comerciales con este cliente y aumentar su capacidad de endeudamiento. 7", ]
  },
  {
    name: "8", ciudades5: ["  Score: Recomendamos mantener relaciones comerciales a largo plazo con este cliente. 8", ]
  },
  {
    name: "9", ciudades5: [" Score: Recomendamos otorgar créditos de mediana y alta cuantía sujeto de crédito con notable desempeño crediticio y comercial. 9", ]
  },
  {
    name: "10", ciudades5: ["  Score: Recomendamos otorgar créditos de mediana y alta cuantía sujeto de crédito con notable desempeño crediticio comercial y financieros. 10", ]
  },
];

ciudades5: Array<any>;
cambioProvincia5(count) {
  this.ciudades5 = this.listascores5.find(con => con.name == count).ciudades5;
}

myFunction() {
  // this.presionar = setTimeout(this.cagados, 10000);
 
  setTimeout(() => {

    if(this.formularioExpress.provinciaExpress != null){
      this.cambioProvincia(this.formularioExpress.provinciaExpress);
    }
    if(this.formularioExpress.valorEvolucionRating != null){
      this.cambioProvincia1(this.formularioExpress.valorEvolucionRating);
    }
    if(this.formularioExpress.valorUnoEvolucionRating != null){
      this.cambioProvincia2(this.formularioExpress.valorUnoEvolucionRating);
    }
    if(this.formularioExpress.valorDosEvolucionRating != null){
      this.cambioProvincia3(this.formularioExpress.valorDosEvolucionRating);
    }
    if(this.formularioExpress.valortresEvolucionRating != null){
     
    this.cambioProvincia4(this.formularioExpress.valortresEvolucionRating);
    }
    if(this.formularioExpress.valorCuatroEvolucionRating != null){
     
      this.cambioProvincia5(this.formularioExpress.valorCuatroEvolucionRating);
      }
  
   
  
  
  
    this.informacionRelevant();
    this.sumar2()
  }, 3000);
 
   }

   infoRelevanteSi
   infoRelevateNo
   
   informacionRelevant(){
     this.formularioExpress.checksListaClinton ==="SiLista"? console.log(this.infoRelevanteSi="* El nombre de referencia SI se encuentra incluido en el programa SDNT de la OFAC (Lista Clinton).",document.getElementById("infoRelevanteColor").style.color="red"):
     console.log(this.infoRelevanteSi="* El nombre de referencia NO se encuentra incluido en el programa SDNT de la OFAC (Lista Clinton).",document.getElementById("infoRelevanteColor").style.color="blue");
   
   }


   inicializarGraficos(){
 
    // Grafico de capital
 // this.proveedoresService.getChart().subscribe((res :FormExpress[]) =>{
  //  console.log(res,'holaaa');
    this.capital1 = []
    if(this.formularioExpress.EvolucionCapitalSocial != null && this.formularioExpress.dosevolucioncp != null && this.formularioExpress.tresevolucioncp != null && this.formularioExpress.cuartrovolucioncp != null) { 
  
      this.capital1.push(parseFloat(this.formularioExpress.EvolucionCapitalSocial.replace(/,/g,"")));
      this.capital1.push(parseFloat(this.formularioExpress.dosevolucioncp.replace(/,/g,"")));
      this.capital1.push(parseFloat(this.formularioExpress.tresevolucioncp.replace(/,/g,"")));
      this.capital1.push(parseFloat(this.formularioExpress.cuartrovolucioncp.replace(/,/g,"")));
      
    
  
  
  }
    this.date1.push(this.formularioExpress.yearEvolucionCapitalSocial ,this.formularioExpress.yearDosCapitalSocial,
      this.formularioExpress.yearTresEvolucionCapitalSocial,this.formularioExpress.yearCuatroEvolucionCapitalSocial);
    this.chart=new Chart('canvas',{
      type:'bar',
     data:{
       labels: this.date1,
      datasets:[
          {
  data:this.capital1,
         backgroundColor:[
          'rgba(128, 128, 128)',
          'rgba(95, 158, 160)',
          'rgba(70, 130, 180)',
          'rgba(176, 196, 222)'
      ],
     fill:true
        }
       ],
     },
      options:{
        legend:{
          display:false
        },
        scales:{
          xAxes:[{
            barPercentage: 1,
            categoryPercentage: 0.6,
            display:true
          }],
          yAxes:[{
            ticks: {
          
            beginAtZero:true
          },
            display:true
          }]
        }
      }
    });
  //  });
  
  //grafico de empleados
 // this.proveedoresService.getChart().subscribe((res :FormExpress[]) =>{
   
      this.capital2.push(this.formularioExpress.NumberEvolucionEmpleados);
      this.capital2.push(this.formularioExpress.NumberDosEvolucionEmpleados);
      this.capital2.push(this.formularioExpress.NumberTresEvolucionEmpleados);
      this.capital2.push(this.formularioExpress.NumberCuatroEvolucionEmpleados);

    this.date2.push(this.formularioExpress.yearEvolucionEmpleados ,this.formularioExpress.yearDosEvolucionEmpleados,this.formularioExpress.yearTresEvolucionEmpleados,this.formularioExpress.yearCuatroEvolucionEmpleados);
    this.chart1=new Chart('canvas1',{
      type:'bar',
     data:{
       labels: this.date2,
      datasets:[
          {
  data:this.capital2,
  label: 'Evolución de empleados',
         backgroundColor:[
          'rgba(128, 128, 128)',
          'rgba(95, 158, 160)',
          'rgba(70, 130, 180)',
          'rgba(176, 196, 222)'
      ],
     fill:true
        }
       ],
     },
      options:{
        legend:{
          display:false
        },
        scales:{
          xAxes:[{
            barPercentage: 1,
            categoryPercentage: 0.6,
            display:true
          }],
          yAxes:[{
            ticks: {
          
            beginAtZero:true
          },
            display:true
          }]
        }
      }
    });
   // });
  //graficos de scores
 // this.proveedoresService.getChart().subscribe((res :FormExpress[]) =>{
 
      this.capital3.push(this.formularioExpress.valorEvolucionRating);
      this.capital3.push(this.formularioExpress.valorUnoEvolucionRating);
      this.capital3.push(this.formularioExpress.valorDosEvolucionRating);
      this.capital3.push(this.formularioExpress.valortresEvolucionRating);
      this.capital3.push(this.formularioExpress.valorCuatroEvolucionRating);
    
    
    this.date3.push(this.formularioExpress.yearEvolucionRating ,this.formularioExpress.yearUnoEvolucionRating,this.formularioExpress.yearDosEvolucionRating,
      this.formularioExpress.yearTresEvolucionRating,this.formularioExpress.yearCuatroEvolucionRating);
    this.chart3=new Chart('canvas3',{
      type:'bar',
     data:{
       labels: this.date3,
      datasets:[
          {
  data:this.capital3,
         backgroundColor:[
          'rgba(128, 128, 128)',
          'rgba(95, 158, 160)',
          'rgba(70, 130, 180)',
          'rgba(176, 196, 222)',
          'rgba(176, 224, 230)'
      ],
     fill:true
        }
       ],
     },
      options:{
        legend:{
          display:false
        },
        scales:{
          xAxes:[{
            barPercentage: 1,
            categoryPercentage: 0.6,
            display:true
          }],
          yAxes:[{
            ticks: {
          
            beginAtZero:true
          },
            display:true
          }]
        }
      }
    });
  //});
  //grafico de pasivo y patrimonio
  
  
   // this.proveedoresService.getChart().subscribe((res :FormExpress[]) =>{
      if(this.formularioExpress.activos2014Express != null){
  
        this.capital4.push(parseFloat(this.formularioExpress.activos2014Express.replace(/,/g,"")) );
    
      console.log(this.capital4)
    
    }
      this.chart4=new Chart('canvas4',{
        type:'bar',
       data:{
         labels: ['Total Activo'],
        datasets:[
          {data:this.capital4, backgroundColor:
            'rgba(95, 158, 160)',
           },
     ],
         
         
       },
       
        options:{
          legend:{
            display:false
          },
          scales:{
            xAxes:[{
              stacked: false,
              barPercentage: 1,
              categoryPercentage: 0.6,
              display:true
            }],
            yAxes:[{
              stacked: false,
              ticks: {
            
              beginAtZero:true
            },
            
              display:true
            }]
          }
        }
      });
  //  });
    //siguiente grafico
  //  this.proveedoresService.getChart().subscribe((res :FormExpress[]) =>{
      if(this.formularioExpress.pasivo2014Express != null && this.formularioExpress.patrimonio2014Express != null){
      
        
        this.capital5.push(parseFloat(this.formularioExpress.pasivo2014Express.replace(/,/g,""))),
        this.capital6.push(parseFloat(this.formularioExpress.patrimonio2014Express.replace(/,/g,"")));
      console.log( this.capital5)
  
    }
      //this.date4.push(this.formularioExpress.yearEvolucionRating ,this.formularioExpress.yearUnoEvolucionRating,this.formularioExpress.yearDosEvolucionRating,
     //   this.formularioExpress.yearTresEvolucionRating,this.formularioExpress.yearCuatroEvolucionRating);
      this.chart5=new Chart('canvas5',{
        type:'bar',
       data:{
         labels: [ 'Total pasivo y patrimonio'],
        datasets:[
          {data:this.capital5, backgroundColor:
            'rgba(70, 130, 180)',
          
           },
         {
         data:this.capital6,backgroundColor:
         'rgba(176, 196, 222)',
    
         },
       
          
         ],
         
         
       },
       
        options:{
          legend:{
            display:false
          },
          scales:{
            xAxes:[{
              stacked: true,
              barPercentage: 1,
              categoryPercentage: 0.6,
              display:true
            }],
            yAxes:[{
              stacked: true,
              ticks: {
            
              beginAtZero:true
            },
            
              display:true
            }]
          }
        }
      });
   // });
  
  
  
  
  
  } 
totalesAvaluos
valorA
valorB


sumar2(){
 
//this.valorA=parseFloat(this.formularioExpress.terrenoAvaluoExpress.toString().replace(/,/g,"")).toFixed(2)
//this.valorB=
if(this.formularioExpress.terrenoAvaluoExpress != null && this.formularioExpress.construccionAvaluoExpress != null){
  this.totalesAvaluos=(parseFloat(this.formularioExpress.terrenoAvaluoExpress.toString().replace(/,/g,"")) + parseFloat(this.formularioExpress.construccionAvaluoExpress.toString().replace(/,/g,""))).toFixed(2)
}

//this.totalesAvaluos= formatterDolar.format(this.totalesAvaluos)
//this.totalesAvaluos = parseFloat(this.formularioExpress.avaluoTotalAvaluoExpress.toString().replace(/,/g,""))


console.log("suma de los terrenos"+" "+this.totalesAvaluos )
}

/// funcion de suma de activo pasivos para sacar patrimonio
totalPatrimonio
totalPatrimonio1
sumaTotalPatrimonio(){
  const formatterDolar = new Intl.NumberFormat('en-US', {
    currency: 'USD'
  })
if(this.formularioExpress.activos2014Express != null && this.formularioExpress.pasivo2014Express != null){
  this.totalPatrimonio=(parseFloat(this.formularioExpress.activos2014Express.replace(/,/g,"")) - parseFloat(this.formularioExpress.pasivo2014Express.replace(/,/g,""))).toFixed(2)
  this.formularioExpress.patrimonio2014Express = formatterDolar.format(this.totalPatrimonio)

};

if(this.formularioExpress.activos2015Express != null && this.formularioExpress.pasivo2015Express != null ){

    //este valor es de patriomnio que debe graficar
 
    this.totalPatrimonio1=(parseFloat(this.formularioExpress.activos2015Express.replace(/,/g,"")) - parseFloat(this.formularioExpress.pasivo2015Express.replace(/,/g,""))).toFixed(2)
    this.formularioExpress.patrimonio2015Express = formatterDolar.format(this.totalPatrimonio1)

}

 
  
}



///

  //carga met automaticamente
  myFunction2() {
    // this.presionar = setTimeout(this.cagados, 10000);
   
    setTimeout(() => {
      this.usuario = this._usuarioService.usuario;
      this.idFormulario = this.route.snapshot.paramMap.get('id');
      this.getInformeExpress(this.idFormulario);
      this.getTareas();
      mostrarDescrip();
      checksPersonales();
      ValidacionCheckscomer();
      this.inicializarGraficos();
      this.sumaTotalPatrimonio();  
      this.sumar2();
      this.myFunction3;
     
    }, 3000);
    Swal.fire('Muy Bien', 'Datos cargados Éxitosamente', 'success');
    
  }

  myFunction3() {
    // this.presionar = setTimeout(this.cagados, 10000);
   
    setTimeout(() => {
 
    checksPersonales();
  
    
    
     
    
    }, 5000);
  
    
  }
  
   
}


