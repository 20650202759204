import { Component, OnInit, ɵConsole } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { SolicitudService } from '../../../services/solicitud.service';
import { Solicitud } from '../../../models/solicitud.model';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { OpcionesGenerales2 } from '../../../models/modelosProveedores/opcinesGenerales2';
import { OpcionesGenerales2Service } from '../../../services/serviciosProveedores/opcines-generales2.service';


//subir archivos importaciones drog
import { DragdropService } from "../../../services/dragdrop.service";
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

declare function init_plugins()

@Component({
  selector: 'app-solicitud-prov',
  templateUrl: './solicitud-prov.component.html',
  styleUrls: ['./solicitud-prov.component.css']
})
export class SolicitudProvComponent implements OnInit {

  urlPDF:string;
//variables drop
fileArr = [];
imgArr = [];
fileObj = [];
//form: FormGroup;
msg: string;
progress: number = 0;



  solicitudModel = new Solicitud();
  opcionesGenerales: OpcionesGenerales2[];//opciones que traigo de la  base


  formularioSolicitud: FormGroup;
  razonSocialCalificacionProveedor: String;
  nombreComercialCalificacionProveedor: String;
  direccionCalificacionProveedor: String;
  listaProvincias: Array<any> = [
    {
      name: "AZUAY", ciudades: ["CUENCA", "GIRÓN", "GUALACEO", "NABÓN", "PAUTE", "PUCARA", "SAN FERNANDO", "SANTA ISABEL", "SIGSIG", "OÑA", "CHORDELEG", "EL PAN", "SEVILLA DE ORO", "GUACHAPALA", "CAMILO PONCE ENRÍQUEZ"
      ]
    }, {
      name: "BOLIVAR", ciudades: ["GUARANDA", "CHILLANES", "CHIMBO", "ECHEANDÍA", "SAN MIGUEL", "CALUMA", "LAS NAVES"
      ]
    }, {
      name: "CAÑAR", ciudades: ["AZOGUES", "BIBLIÁN", "CAÑAR", "LA TRONCAL", "EL TAMBO", "DÉLEG", "SUSCAL"
      ]
    }, {
      name: "CARCHI", ciudades: ["TULCÁN", "BOLÍVAR", "ESPEJO", "MIRA", "MONTÚFAR", "SAN PEDRO DE HUACA"
      ]
    }, {
      name: "COTOPAXI", ciudades: ["LATACUNGA", "LA MANÁ", "PANGUA", "PUJILI", "SALCEDO", "SAQUISILÍ", "SIGCHOS"
      ]
    }, {
      name: "CHIMBORAZO", ciudades: ["RIOBAMBA", "ALAUSI", "COLTA", "CHAMBO", "CHUNCHI", "GUAMOTE", "GUANO", "PALLATANGA", "PENIPE", "CUMANDÁ"
      ]
    }, {
      name: "EL ORO", ciudades: ["MACHALA", "ARENILLAS", "ATAHUALPA", "BALSAS", "CHILLA", "EL GUABO", "HUAQUILLAS", "MARCABELÍ", "PASAJE", "PIÑAS", "PORTOVELO", "SANTA ROSA", "ZARUMA", "LAS LAJAS"
      ]
    }, {
      name: "ESMERALDAS", ciudades: ["ESMERALDAS", "ELOY ALFARO", "MUISNE", "QUININDÉ", "SAN LORENZO", "ATACAMES", "RIOVERDE", "LA CONCORDIA"
      ]
    }, {
      name: "GUAYAS", ciudades: ["GUAYAQUIL", "ALFREDO BAQUERIZO MORENO (JUJÁN)", "BALAO", "BALZAR", "COLIMES", "DAULE", "DURÁN", "EL EMPALME", "EL TRIUNFO", "MILAGRO", "NARANJAL", "NARANJITO", "PALESTINA", "PEDRO CARBO", "SAMBORONDÓN", "SANTA LUCÍA", "SALITRE (URBINA JADO)", "SAN JACINTO DE YAGUACHI", "PLAYAS", "SIMÓN BOLÍVAR", "CORONEL MARCELINO MARIDUEÑA", "LOMAS DE SARGENTILLO", "NOBOL", "GENERAL ANTONIO ELIZALDE", "ISIDRO AYORA"
      ]
    }, {
      name: "IMBABURA", ciudades: ["IBARRA", "ANTONIO ANTE", "COTACACHI", "OTAVALO", "PIMAMPIRO", "SAN MIGUEL DE URCUQUÍ"
      ]
    }, {
      name: "LOJA", ciudades: ["LOJA", "CALVAS", "CATAMAYO", "CELICA", "CHAGUARPAMBA", "ESPÍNDOLA", "GONZANAMÁ", "MACARÁ", "PALTAS", "PUYANGO", "SARAGURO", "SOZORANGA", "ZAPOTILLO", "PINDAL", "QUILANGA", "OLMEDO"
      ]
    }, {
      name: "LOS RIOS", ciudades: ["BABAHOYO", "BABA", "MONTALVO", "PUEBLOVIEJO", "QUEVEDO", "URDANETA", "VENTANAS", "VÍNCES", "PALENQUE", "BUENA FÉ", "VALENCIA", "MOCACHE", "QUINSALOMA"
      ]
    }, {
      name: "MANABI", ciudades: ["PORTOVIEJO", "BOLÍVAR", "CHONE", "EL CARMEN", "FLAVIO ALFARO", "JIPIJAPA", "JUNÍN", "MANTA", "MONTECRISTI", "PAJÁN", "PICHINCHA", "ROCAFUERTE", "SANTA ANA", "SUCRE", "TOSAGUA", "24 DE MAYO", "PEDERNALES", "OLMEDO", "PUERTO LÓPEZ", "JAMA", "JARAMIJÓ", "SAN VICENTE"
      ]
    }, {
      name: "MORONA SANTIAGO", ciudades: ["MORONA", "GUALAQUIZA", "LIMÓN INDANZA", "PALORA", "SANTIAGO", "SUCÚA", "HUAMBOYA", "SAN JUAN BOSCO", "TAISHA", "LOGROÑO", "PABLO SEXTO", "TIWINTZA"
      ]
    }, {
      name: "NAPO", ciudades: ["TENA", "ARCHIDONA", "EL CHACO", "QUIJOS", "CARLOS JULIO AROSEMENA TOLA"
      ]
    }, {
      name: "PASTAZA", ciudades: ["PASTAZA", "MERA", "SANTA CLARA", "ARAJUNO"
      ]
    }, {
      name: "PICHINCHA", ciudades: ["QUITO", "CAYAMBE", "MEJIA", "PEDRO MONCAYO", "RUMIÑAHUI", "SAN MIGUEL DE LOS BANCOS", "PEDRO VICENTE MALDONADO", "PUERTO QUITO"
      ]
    }, {
      name: "TUNGURAHUA", ciudades: ["AMBATO", "BAÑOS DE AGUA SANTA", "CEVALLOS", "MOCHA", "PATATE", "QUERO", "SAN PEDRO DE PELILEO", "SANTIAGO DE PÍLLARO", "TISALEO"
      ]
    }, {
      name: "ZAMORA CHINCHIPE", ciudades: ["ZAMORA", "CHINCHIPE", "NANGARITZA", "YACUAMBI", "YANTZAZA (YANZATZA)", "EL PANGUI", "CENTINELA DEL CÓNDOR", "PALANDA", "PAQUISHA"
      ]
    }, {
      name: "GALAPAGOS", ciudades: ["SAN CRISTÓBAL", "ISABELA", "SANTA CRUZ"
      ]
    }, {
      name: "SUCUMBIOS", ciudades: ["LAGO AGRIO", "GONZALO PIZARRO", "PUTUMAYO", "SHUSHUFINDI", "SUCUMBÍOS", "CASCALES", "CUYABENO"
      ]
    }, {
      name: "ORELLANA", ciudades: ["ORELLANA", "AGUARICO", "LA JOYA DE LOS SACHAS", "LORETO"
      ]
    }, {
      name: "SANTO DOMINGO DE LOS TSACHILAS", ciudades: ["SANTO DOMINGO"
      ]
    }, { name: "SANTA ELENA", ciudades: ["SANTA ELENA", "LA LIBERTAD", "SALINAS"] },
  ];
  ciudades: Array<any>;
  cambioProvincia(count) {
    this.ciudades = this.listaProvincias.find(con => con.name == count).ciudades;
  }

  
  provinciasEcuadorCalificacionProveedor: String;
  ciudadesEcuadorCalificacionProveedor: string;

  rucCalificacionProveedor: String;


  telefonoCalificacionProveedor: String;
  paginaWebCalificacionProveedor: String;
  correoCalificacionProveedor: String;


  referenciaCalificacionProveedor: String;
  cargoCalificacionProveedor: String;
  correoContactoCalificacionProveedor: String;
  fechaCalificacionProveedor: String;
  tipoPago: String;

 // depositoCalificacionProveedor: String
 // chequeCalificacionProveedor: String
 // transfereciaCalificacionProveedor: String;

  bancoCalificacionProveedor: String;
  cuentacorrienteCalificacionProveedor: String;
  pagoanombredeCalificacionProveedor: String;

  ndefacturaCalificacionProveedor: String;
  detalledepagoCalificacionProveedor: String;

  emailPattern = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9\.ec]+\.)+[a-zA-Z.ec.A-Z-a-z]{2,}))$/);
  //textPattern= new RegExp(/^[a-zA-Z ]+$/);
  textPattern = new RegExp(/^[A-Z]{1}[a-zA-ZñÑáéíóúÁÉÍÓÚ&.\s]+$/);
  numberPattern = new RegExp(/^[0-9/-]+$/);
  //pageWebPattern= new RegExp(/^[w]+([\.]+[\w]+)+$/);
  pageWebPattern = new RegExp(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/);



  constructor(public fb: FormBuilder, private solicitudService: SolicitudService,private location:Location, private router: Router,private opcionesServices: OpcionesGenerales2Service,
    private sanitizer: DomSanitizer,  public dragdropService: DragdropService) {
    this.formularioSolicitud = this.fb.group({
      razonSocialCalificacionProveedor: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      nombreComercialCalificacionProveedor: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      direccionCalificacionProveedor: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(200)]],

      rucCalificacionProveedor: ['', [Validators.required, Validators.minLength(13), Validators.maxLength(13), Validators.pattern(this.numberPattern)]],

      // en ves del express
      provinciasEcuadorCalificacionProveedor: ['', [Validators.required]],
      ciudadesEcuadorCalificacionProveedor: ['', [Validators.required]],
      avatar: [null],

      telefonoCalificacionProveedor: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]],
      correoCalificacionProveedor: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      paginaWebCalificacionProveedor: ['', [Validators.required, Validators.pattern(this.pageWebPattern)]],


      referenciaCalificacionProveedor: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      cargoCalificacionProveedor: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      correoContactoCalificacionProveedor: ['', [Validators.required, Validators.pattern(this.emailPattern)]],


      fechaCalificacionProveedor: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10),/*Validators.pattern(this.numberPattern)*/]],
      ndefacturaCalificacionProveedor: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      detalledepagoCalificacionProveedor: ['', [Validators.required, Validators.pattern(this.textPattern)]],

      bancoCalificacionProveedor: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(13)]],
      cuentacorrienteCalificacionProveedor: ['', [Validators.required, Validators.minLength(3)]],
      pagoanombredeCalificacionProveedor: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      tipoPago: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],

    //  depositoCalificacionProveedor: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
    //  chequeCalificacionProveedor: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
    //  transferenciaCalificacionProveedor: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],

    });
  }
  ngOnInit() { 
   init_plugins();
   this.getOpciones1();
  }





  saveData() {
    this.razonSocialCalificacionProveedor = this.formularioSolicitud.value.razonSocialCalificacionProveedor;
    this.direccionCalificacionProveedor = this.formularioSolicitud.value.direccionCalificacionProveedor;
    this.nombreComercialCalificacionProveedor = this.formularioSolicitud.value.nombreComercialCalificacionProveedor;


    // en ves del express
    this.ciudadesEcuadorCalificacionProveedor = this.formularioSolicitud.value.ciudadesEcuadorCalificacionProveedor;
    this.provinciasEcuadorCalificacionProveedor = this.formularioSolicitud.value.provinciasEcuadorCalificacionProveedor;



    this.rucCalificacionProveedor = this.formularioSolicitud.value.rucCalificacionProveedor;
    this.telefonoCalificacionProveedor = this.formularioSolicitud.value.telefonoCalificacionProveedor;
    this.correoCalificacionProveedor = this.formularioSolicitud.value.correoCalificacionProveedor;
    this.paginaWebCalificacionProveedor = this.formularioSolicitud.value.paginaWebCalificacionProveedor;

    this.referenciaCalificacionProveedor = this.formularioSolicitud.value.referenciaCalificacionProveedor;
    this.cargoCalificacionProveedor = this.formularioSolicitud.value.cargoCalificacionProveedor;

    this.correoContactoCalificacionProveedor = this.formularioSolicitud.value.correoContactoCalificacionProveedor;
    this.fechaCalificacionProveedor = this.formularioSolicitud.value.fechaCalificacionProveedor;

   // this.depositoCalificacionProveedor = this.formularioSolicitud.value.depositoCalificacionProveedor;
   // this.chequeCalificacionProveedor = this.formularioSolicitud.value.chequeCalificacionProveedor;
   // this.transfereciaCalificacionProveedor = this.formularioSolicitud.value.transfereciaCalificacionProveedor;
   this.tipoPago  = this.formularioSolicitud.value.tipoPago;
    this.bancoCalificacionProveedor = this.formularioSolicitud.value.bancoCalificacionProveedor;
    this.cuentacorrienteCalificacionProveedor = this.formularioSolicitud.value.cuentacorrienteCalificacionProveedor;
    this.pagoanombredeCalificacionProveedor = this.formularioSolicitud.value.pagoanombredeCalificacionProveedor;
    this.detalledepagoCalificacionProveedor = this.formularioSolicitud.value.detalledepagoCalificacionProveedor;
    this.ndefacturaCalificacionProveedor = this.formularioSolicitud.value.ndefacturaCalificacionProveedor;

    alert("Se Han ingresado exitosamente los datos");
    console.log(this.formularioSolicitud.value);
    this.onResetForm();
  }
 /* back() {
    setTimeout(() => {
      this.location.back();
    }, 1400);
  }  */

  addSolicitud() {

    this.save();
   // this.back();

  }




  getOpciones1() {
    return this.opcionesServices.getOpciones()
      .subscribe(
        opcionesGenerales => {
        //  this.toastr.success('Seleccione una opcion para validar si manda el documento solicitado o no!', 'Hola!');
          console.log(opcionesGenerales);
          this.opcionesGenerales = opcionesGenerales
        }
      );
  }
  
  /*goBack(): void {
    this.location.back();
  }*/

  private save(): void {
   // this.solicitudModel.urlPDF = this.urlPDF;
    console.log(this.solicitudModel.razonSocialCalificacionProveedor)
    if (this.solicitudModel.razonSocialCalificacionProveedor ===undefined ||this.solicitudModel.nombreComercialCalificacionProveedor ===undefined
  ||  this.solicitudModel.direccionCalificacionProveedor===undefined ||  this.solicitudModel.rucCalificacionProveedor===undefined  
  ||  this.solicitudModel.telefonoCalificacionProveedor===undefined ||  this.solicitudModel.referenciaCalificacionProveedor===undefined /*|| !this.solicitudModel*/) {
   // Swal.fire(`Por favor todos los campos son obligatorios.`, 'Verifique');
    Swal.fire(
      'Solicitud no enviada',
      'Por favor, todos los campos son obligatorios.',
      'error');
      
    } else {
      console.log(this.solicitudModel);
      this.solicitudService.addInformeCompleto(this.solicitudModel)
        .subscribe();
        Swal.fire('Correcto', `La información fue guardada con éxito`, 'success');
    }
    

  }


  onResetForm() {
    this.formularioSolicitud.reset();

  }


  requisito(){
    this.router.navigateByUrl('/requisito');
  }

 //nueva importacion de archivos drop
 /*upload(e) {
  const fileListAsArray = Array.from(e);
  fileListAsArray.forEach((item, i) => {
    const file = (e as HTMLInputElement);
    const url = URL.createObjectURL(file[i]);
    this.imgArr.push(url);
    this.fileArr.push({ item, url: url });
  })

  this.fileArr.forEach((item) => {
    this.fileObj.push(item.item)
  })

  // Set files form control
  this.formularioSolicitud.patchValue({
    avatar: this.fileObj
  })

  this.formularioSolicitud.get('avatar').updateValueAndValidity()

  // Upload to server
  this.dragdropService.addFiles(this.formularioSolicitud.value.avatar)
    .subscribe((event: HttpEvent<any>) => {
   //   console.log(event.body.userCreate);
      switch (event.type) {
        case HttpEventType.Sent:
          console.log('Request has been made!');
          break;
        case HttpEventType.ResponseHeader:
          console.log('Response header has been received!');
          break;
        case HttpEventType.UploadProgress:
          this.progress = Math.round(event.loaded / event.total * 100);
          console.log(`Uploaded! ${this.progress}%`);
          break;
        case HttpEventType.Response:
          console.log('File uploaded successfully!', event.body);
           this.urlPDF= event.body.userCreated.avatar[0];
          setTimeout(() => {
            this.progress = 0;
            this.fileArr = [];
            this.fileObj = [];
            this.msg = "Archivo subido corretamente!"
            
          }, 3000);
      }
    })
}

// Clean Url for showing image preview
sanitize(url: string) {
  return this.sanitizer.bypassSecurityTrustUrl(url);
}

 */



}

