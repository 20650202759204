import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import Swal from 'sweetalert2';


import { NgForm } from '@angular/forms';

import { Usuario } from '../../models/usuario.model';
import { UsuarioService } from '../../services/usuario.service';
import { AlertaService } from '../../services/alerta.service';
import { NgxSpinnerService } from "ngx-spinner";

declare function init_plugins();

@Component({
  selector: 'app-login-cliente',
  templateUrl: './login-cliente.component.html',
  styleUrls: ['./login-cliente.component.css']
})
export class LoginClienteComponent implements OnInit {
  recuerdame: boolean = false;
  email: string = '';
  constructor( private router: Router,
    private _usuarioService: UsuarioService,
    private _alertaService: AlertaService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    init_plugins();
    this.spinner.show();
 
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 2000);
    
  }

/* onLogin(form): void {
    this.authService.login(form.value).subscribe(res => {
      console.log(res);
    this.router.navigateByUrl('/inicioComerciales');
    Swal.fire('Login', `Hola  has iniciado sesión con éxito!`, 'success');
  
      },  err => {
        if (err.status = (409)) {
          Swal.fire('Error Login', 'Usuario o clave incorrectas!', 'error');
        }
        console.log(err)
      },
    
      
      );
  
} */
ingresar( form: NgForm ) {
  if ( !form.valid ) {
    this._alertaService.mostrarAlerta('warning', 'Hay errores en los campos');

    return;
  }

  let usuario = new Usuario(
    null,
    form.value.email,
    form.value.password
  );

  this._usuarioService.login( usuario, form.value.recuerdame ).subscribe(resp =>  {this.router.navigateByUrl('/ListaProveedores')
  Swal.fire('Login', `Hola  has iniciado sesión con éxito!`, 'success');
  },
  err => {
    if (err.status = (409 )) {
      Swal.fire('Error Login', 'Usuario o clave incorrectas!', 'error');
    }
 
    console.log(err)
  },
);
}
}
