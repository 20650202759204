import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { UsuarioService } from './usuario.service';
import Swal from 'sweetalert2';

@Injectable()
export class AdminGuard implements CanActivate {

  constructor(
    public _usuarioService: UsuarioService
  ) { }

  canActivate() {
  
    if ( this._usuarioService.usuario.role === 'ADMIN_ROLE' ) {
      return true;
    }else {
      console.log( 'Bloqueado por el ADMIN GUARD');
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'No es usuario administrador',
      
      });
      this._usuarioService.logOut();
      return false;
    }

  }

}
