import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';


@Pipe({
  name: 'pago'
})
export class PagoPipe implements PipeTransform {

  transform(img3: any,  tipo: string = 'usuario'): any {

    let url = environment.URL_SERVICIOS + '/img';

    if (!img3) {

      return url + '/usuarios/xxx';
    }

   

    switch (tipo) {

      case 'usuario':
        url += '/proveedor/' + img3;

     

        default:
            console.log('tipo de imagen no existe');
            url + '/usuarios/xxx';

    }



    return url;
  }

}