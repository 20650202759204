import { Contact2 } from 'src/app/models/contact2.models';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Usuario } from 'src/app/models/usuario.model';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ListaproveedorService } from 'src/app/services/listaproveedor.service';

@Component({
  selector: 'app-solicitud-calificacion',
  templateUrl: './solicitud-calificacion.component.html',
  styleUrls: ['./solicitud-calificacion.component.css']
})
export class SolicitudCalificacionComponent implements OnInit {
  formularioUsuario: FormGroup;

  razonSocial : String;
  ruc : String;
  personaContacto: String;
  telefono: String;
  email: String;
  ciudad: String;
  nacionalInternacional :String;
  estado: String;
 observaciones: String;
 

lista = new Contact2();
submitted = false;


constructor(
  
  private fb: FormBuilder, private listainforme:ListaproveedorService, private route: ActivatedRoute, ) {
    this.buildForm();
  }
ngOnInit() {

 /*  const id = this.route.snapshot.paramMap.get('id');
  this.listainforme.getCustomer(id)
    .subscribe(lista => this.lista = lista); */


}


buildForm() {
  /**
   * @description Asignamos a la propiedad "formularioUsuario" los campos que se van a controlar de la vista
   */
  this.formularioUsuario = this.fb.group({
    razonSocial: ['express', [Validators.required, Validators.minLength(3), Validators.maxLength(45)]],
    ruc: ['', [Validators.required, Validators.maxLength(30)]],
    personaContacto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(450)]],
    telefono: ['', [ Validators.minLength(5), Validators.maxLength(45)]],
    email: ['', [ Validators.minLength(10), Validators.maxLength(10)]],
    ciudad: [''],
    nacionalInternacional : ['', [ Validators.minLength(3), Validators.maxLength(45)]],
    estado:[''],
    plazoPagoCredito: ['', [ Validators.minLength(3), Validators.maxLength(45)]],
    observaciones: [''],

  
});
}

newLista(): void {
  this.submitted = false;
  this.lista = new Contact2();
}

addLista() {
  this.submitted = true;
  this.save();

}
 
private save(): void {
  console.log(this.lista);
  this.lista.usuario = JSON.parse(localStorage.getItem('usuario')) as Usuario;
  this.listainforme.addCustomer(this.lista)
      .subscribe();
      Swal.fire(
        'Muy Bien',
         'Datos Guardados Éxitosamente',
         'success'
       );
        this.onResetForm();
}


onResetForm() {
  this.formularioUsuario.reset();

}
}