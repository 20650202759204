import { Contact2 } from './../models/contact2.models';
import { Injectable, EventEmitter } from '@angular/core';
import { Usuario } from '../models/usuario.model';

import { UsuarioService } from './usuario.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', },)
};

@Injectable({
  providedIn: 'root'
})

export class ListaproveedorService {
  usuario: Usuario;
  token: string;
  usuarioLogueado: Usuario;
  
  private listaUrl = environment.URL_SERVICIOS +'/listaproveedores';  // URL to web api
  private listaUrl1 = environment.URL_SERVICIOS +'/listaproveedores/listaProv';
  private listaUrl2 = environment.URL_SERVICIOS +'/listaproveedores/todas';
// notificacion para escuchar evento
public notificacion = new EventEmitter<any>();
 

  constructor( private http: HttpClient, private _usuarioService: UsuarioService) {

    this.usuarioLogueado = JSON.parse(localStorage.getItem('usuario')) as Usuario; 
   }

  getCustomers (): Observable<Contact2[]> {
      return this.http.get<Contact2[]>(this.listaUrl)
  }


  getProveedores3 (usuario_id:string): Observable< Contact2[]> {
    return this.http.get< Contact2[]>(this.listaUrl1+'?usuario_id='+usuario_id)
     }

  getCustomer(id: string): Observable<Contact2> {
    const parametros = '&id=' + this.usuarioLogueado._id;
    const url = `${this.listaUrl}/${id}/${parametros}`;
    return this.http.get<Contact2>(url);
  }

/*  addCustomer (contacto: Contact): Observable<Contact> {
    this.listaUrl += '?token=' + this._usuarioService.token;
    return this.http.post<Contact>(this.listaUrl, contacto, httpOptions);
  }  */

  addCustomer (contacto: Contact2): Observable<Contact2> {
    this.listaUrl = environment.URL_SERVICIOS +'/listaproveedores/listaP'+'?token=' + this._usuarioService.token;
      return this.http.post<Contact2>(this.listaUrl, contacto, httpOptions);
    }


 
  

  deleteCustomer (contacto: Contact2 | string): Observable<Contact2> {
    const id = typeof contacto === 'string' ? contacto : contacto._id;
    const url = `${this.listaUrl}/${id}`;

    return this.http.delete<Contact2>(url, httpOptions);
  }

  updateCustomer (contacto: Contact2): Observable<any> {
    this.listaUrl = environment.URL_SERVICIOS +'/listaproveedores'+'?token=' + this._usuarioService.token;
    return this.http.put(this.listaUrl, contacto, httpOptions);
  }


cargarInformes() {
    let url = `${ environment.URL_SERVICIOS }/listaproveedores `;

    return this.http.get( url ).pipe(map((resp: any) => resp));
  }
  
  cargarInformes2(): Observable<Contact2[]> {
    return this.http.get<Contact2[]>(this.listaUrl2)
  }


  buscarinforme( termino: string ) {
    let url = `${ environment.URL_SERVICIOS }/busqueda/coleccion/listaproveedores/${ termino }`;

    return this.http.get( url ).pipe(map((resp: any) => resp.lista));
  }
  cargarinformes1( desde: number = 0 ) {

    let url = environment.URL_SERVICIOS + '/listaproveedores?desde=' + desde;
    return this.http.get( url );

  }
}
