import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Usuario } from 'src/app/models/usuario.model';
import Swal from 'sweetalert2';
import { ModalUploadService } from 'src/app/modal-upload/modal-upload.service';

declare function init_plugins()
@Component({
  selector: 'app-comprobantes',
  templateUrl: './comprobantes.component.html',
  styleUrls: ['./comprobantes.component.css']
})
export class ComprobantesComponent implements OnInit {
  usuarios: Usuario[] = [];
  desde: number = 0;

  totalRegistros: number = 0;
  cargando: boolean = true;

  constructor(public _usuarioService: UsuarioService,
    public _modalUploadService: ModalUploadService) {

      init_plugins()
 
     }

  ngOnInit() {
    init_plugins()
this.cargarUsuarios();

  this._modalUploadService.notificacion
.subscribe( resp => this.cargarUsuarios() );

  }


  mostrarModal( id: string ) {

    this._modalUploadService.mostrarModal( 'proveedor', id );
  }

  mostrarModal1( id: string ) {

    this._modalUploadService.mostrarModal( 'informes', id );
  }
  cargarUsuarios() {

    this.cargando = true;

    this._usuarioService.cargarUsuarios1( this.desde )
    .subscribe((resp: any) => {
      for (var user in resp.usuarios) {
        //Habilitar en caso de querer cargar todos los roles de usuario en la asignación de tareas.
        if (resp.usuarios[user].role == "PROVEEDOR_PROSPECTO" || resp.usuarios[user].role == "PROVEEDOR_CONFIRMADO") {
          this.usuarios.push(resp.usuarios[user]);
        }
     
      }
      this.totalRegistros = resp.total;
   
      this.cargando = false;
    });
            

  }


 /* cambiarDesde( valor: number ) {

    let desde = this.desde + valor;

    if ( desde >= this.totalRegistros ) {
      return;
    }

    if ( desde < 0 ) {
      return;
    }

    this.desde += valor;
    this.cargarUsuarios();

  }

*/

  buscarUsuario( termino: string ) {

    if ( termino.length <= 0 ) {
      this.cargarUsuarios();
      return;
    }

    this.cargando = true;

    this._usuarioService.buscarUsuarios( termino )
            .subscribe( (usuarios: Usuario[]) => {

              this.usuarios = usuarios;
              this.cargando = false;
            });

  }

  

  borrarUsuario( usuario: Usuario ) {

    if ( usuario._id === this._usuarioService.usuario._id ) {
      Swal.fire('No puede borrar usuario', 'No se puede borrar a si mismo', 'error');
      return;
    }

    Swal.fire(
     '¿Esta seguro?',
      'Esta a punto de borrar a ' + usuario.nombre,
       'warning',

    )
    .then( borrar => {

      if (borrar) {

        this._usuarioService.borrarUsuario( usuario._id )
                  .subscribe( borrado => {
                      this.cargarUsuarios();
                  });

      }

    });

  }

  guardarUsuario( usuario: Usuario ) {

    this._usuarioService.actualizarUsuario( usuario )
            .subscribe();

  }

  nohay(){
    Swal.fire(
  
      'NO HAY',
      'ADJUNTOS EN ESTE INFORME',
      'warning'
    );
  
  }
  
  
}


