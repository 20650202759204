import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CompraInforme } from '../models/compraInforme';

import { map } from 'rxjs/operators';
import { UsuarioService } from './usuario.service';
import { Usuario } from '../models/usuario.model';
import { environment } from 'src/environments/environment';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  usuario: Usuario;
  token: string;
  private customersUrl =   environment.URL_SERVICIOS +'/comprar';  // URL to web api
  constructor( 
    private http: HttpClient,private _usuarioService: UsuarioService
  ) { }

  getCustomers (): Observable<CompraInforme[]> {
    return this.http.get<CompraInforme[]>(this.customersUrl)
  }

  getCustomer(id: string): Observable<CompraInforme> {
    const url = `${this.customersUrl}/${id}`;
    return this.http.get<CompraInforme>(url);
  }

  addCustomer (customer: CompraInforme): Observable<CompraInforme> {
    return this.http.post<CompraInforme>(this.customersUrl, customer, httpOptions);
  }

 /* deleteCustomer (customer: CompraInforme | string): Observable<CompraInforme> {
    const id = typeof customer === 'string' ? customer : customer._id;
    const url = `${this.customersUrl}/${id}`;

    return this.http.delete<CompraInforme>(url, httpOptions);
  } */

  updateCustomer (customer: CompraInforme): Observable<any> {
    return this.http.put(this.customersUrl, customer, httpOptions);
  }

  crearUsuario( usuario: CompraInforme ) {
    let url = environment.URL_SERVICIOS +'/comprar'+'?token=' + this._usuarioService.token;
    return this.http.post( url, usuario ).pipe(map((resp: any) => resp.usuario));
  }

  buscarUsuarios( termino: string ) {
    let url = `${ environment.URL_SERVICIOS }/busqueda/coleccion/comprar/${ termino }`;

    return this.http.get( url ).pipe(map((resp: any) => resp.comprar));
  }
}