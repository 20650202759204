import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import Swal from 'sweetalert2';
import { UsuarioService } from './usuario.service';


@Injectable({
  providedIn: 'root'
})
export class LoginProveedorGuard implements CanActivate {
  constructor( public _usuarioService:UsuarioService ,  public router: Router,){}

  canActivate()
  {
    if ( this._usuarioService.usuario.role === 'PROVEEDOR_PROSPECTO' ) {
      return true;
    }
    if ( this._usuarioService.usuario.role === 'PROVEEDOR_CONFIRMADO') {
      return true;
    }
     else {
      console.log( 'Bloqueado por el PROVEEDOR GUARD');
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'No es usuario Proveedor',
      
      });
      this._usuarioService.logOutProveedor();
      return false;
    }

   
  }
}

