export class formCompleto {
    
    _id: string;
    fechaElaboracionCompleta: string;
    solicitanteCompleto: string;
    razonSocialCompleto: string;
    nombreComercialCompleto: string;
    rucCompleto: string;
    riseCompleto:string;
    reinicioactividades:String;
    obligadocontabilidad:string;
    formaLegalCompleto: string;
    estadoCompleto: string;
    capitalSubscritoCompleto: string;
    inicioActividadesCompleto: string;
    actividadPrincipalCompleto: string;
    objetoSocialCompleto: string;
    provinciaCompleto: string;
    ciudadCompleto: string;
    direccionCompleto: string;
    telefonoCompleto: string;
    paginaWebCompleto: string;
    correoWebCompleto:string;
    representanteLegalCompleto: string;
    cedulaCompleto: string;
    personaContactoCompleto: string;
    cargoCompleto: string;
    correoCompleto: string;
    checksEmpresasCompleto: string;
    empresasRelacionadasCompleto: string;
    grupoEconomicoCompleto: string;
    codigoActividadCompleto: string;
    anioPagoImpuestoCompleto: string;
    pagoRentaUnoCompleto: string;
    anioPagoImpuestoDosCompleto: string;
    pagoRentaDosCompleto: string;
    anioSalidaDivisasCompleto: string;
    anioSalidaDivisasDosCompleto:string;
    salidaDivisasCompletoUno: string;
    salidaDivisasCompletoDos: string;
    checksCalificaciones: string;
    requeridoClienteCompleto: string;
    requeridoServiratingCompleto: string;
    checksInfraestructura: string;
    checksSucursalesCompleto: string;
    direccionSucursalCompleto: string;
    numeroEmpleadosCompleto: string;
    checksMaquinaria: string;
    directivoUnoCompleto: string;
    cargoDirectivoUnoCompleto: string;
    directivoDosCompleto: string;
    cargoDirectivoDosCompleto: string;
    numeroAccionistaCompleto: string;
    identificacionAccionistaCompleto: string;
    nombreAccionistaCompleto: string;
    nacionalidadAccionistaCompleto: string;
    tipoInversionAccionistaCompleto
    capitalAccionistaCompleto: string;
    restriccionAccionistaCompleto: string;
    numeroDosAccionistaCompleto: string;
    identificacionDosAccionistaCompleto: string;
    nombreDosAccionistaCompleto: string;
    nacionalidadDosAccionistaCompleto: string;
    tipoInversionDosAccionistaCompleto: string;
    capitalDosAccionistaCompleto: string;
    restriccionDosAccionistaCompleto: string;
    nombreRefCompleto: string;
    nombreRefDosCompleto: string;
    direccionRefCompleto: string;
    direccionRefDosCompleto: string;
    telefonoRefCompleto: string;
    telefonoRefDosCompleto: string;
    contactoRefCompleto: string;
    contactoRefDosCompleto: string;
    lineaCreditoRefCompleto: string;
    lineaCreditoRefDosCompleto: string;
    pagosRefCompleto: string;
    pagosRefDosCompleto: string;
    productoRefCompleto: string;
    productoRefDosCompleto: string;
    clienteDesdeRefCompleto: string;
    clienteDesdeRefDosCompleto: string;
    compraMensualRefCompleta: string;
    compraMensualDosRefCompleta: string;
    checksPuntualidadPagos: string;
    checksPuntualidadPagosDos: string;
    checksObligacionesLegales: string;
    checksSuperintendencia: string;
    procesosJudicialesCompleto: Text;
    procesosJudicialesCompleto2: Text;
    //accionesDelitosCompleto: string;
    informacionRelevanteCompleto: Text;
    informacionBancariaCompleto: Text;
    institucionRefCompleta: string;
    fechaInstCompleto: string;
    mensajeRefCompleta: string;
    fechaCorteCompleta: string;
    institucionCompleta: string;
    tipoRiesgoCompleta: string;
    tipoCreditoCompleta: string;
    vincCompleta: string;
    totalVencerCompleta: string;
    noDevInteresesCompleta: string;
    totalVencidoCompleta: string;
    demJudCompleta: string;
    cartCastCompleta: string;
    saldoDeudaCompleta: string;
    acConcCompleta: string;
    fechaCorteCompleta1: string;
    institucionCompleta1: string;
    tipoRiesgoCompleta1: string;
    tipoCreditoCompleta1: string;
    vincCompleta1: string;
    totalVencerCompleta1: string;
    noDevInteresesCompleta1: string;
    totalVencidoCompleta1: string;
    demJudCompleta1: string;
    cartCastCompleta1: string;
    saldoDeudaCompleta1: string;
    acConcCompleta1: string;
    fechaCorteCompleta2: string;
    institucionCompleta2: string;
    tipoRiesgoCompleta2: string;
    tipoCreditoCompleta2: string;
    vincCompleta2: string;
    totalVencerCompleta2: string;
    noDevInteresesCompleta2: string;
    totalVencidoCompleta2: string;
    demJudCompleta2: string;
    cartCastCompleta2: string;
    saldoDeudaCompleta2: string;
    acConcCompleta2: string;
    fechaCorteCompleta3: string;
    institucionCompleta3: string;
    tipoRiesgoCompleta3: string;
    tipoCreditoCompleta3: string;
    vincCompleta3: string;
    totalVencerCompleta3: string;
    noDevInteresesCompleta3: string;
    totalVencidoCompleta3: string;
    demJudCompleta3: string;
    cartCastCompleta3: string;
    saldoDeudaCompleta3: string;
    acConcCompleta3: string;
    fechaCorteCompleta4: string;
    institucionCompleta4: string;
    tipoRiesgoCompleta4: string;
    tipoCreditoCompleta4: string;
    vincCompleta4: string;
    totalVencerCompleta4: string;
    noDevInteresesCompleta4: string;
    totalVencidoCompleta4: string;
    demJudCompleta4: string;
    cartCastCompleta4: string;
    saldoDeudaCompleta4: string;
    acConcCompleta4: string;
    fechaCorteCompleta5: string;
    institucionCompleta5: string;
    tipoRiesgoCompleta5: string;
    tipoCreditoCompleta5: string;
    vincCompleta5: string;
    totalVencerCompleta5: string;
    noDevInteresesCompleta5: string;
    totalVencidoCompleta5: string;
    demJudCompleta5: string;
    cartCastCompleta5: string;
    saldoDeudaCompleta5: string;
    acConcCompleta5: string;
    institucionCompleta6: string;
    //tipoCreditoCompleta6: string;
    //vincCompleta6: string;
    totalVencerCompleta6: string;
    noDevInteresesCompleta6: string;
    totalVencidoCompleta6: string;
    demJudCompleta6: string;
    cartCastCompleta6: string;
    saldoDeudaCompleta6: string;
    //acConcCompleta6: string;
    acreedorCompleto: string;
    porVencerCompleto: string;
    noDevengaIntCompleto: string;
    vencidoCompleto: string;
    saldDeudaCompleto: string;
    demandaJudicialCompleto: string;
    carteraCastigadaCompleto: string;
    porVencerTotal: string;
    noDevInteresesTotal: string;
    vencidoTotal: string;
    saldoDeudaTotal: string;
    demandaJudicialTotal: string;
    carteraCastigadaTotal: string;
    historialCrediticioCompleto: string;
    mayPlazoVencidoCompleto: string;
    fechaMayPlazoVencido: string;
    mayValorVencidoCompleto: string;
    fechaMayValorVencido: string;
    endeudamientoPromCompleto: string;
    fechaEndeudamiento: string;
    mayPlazoVencidoCompleto1: string;
    fechaMayPlazoVencido1: string;
    mayValorVencidoCompleto1: string;
    fechaMayValorVencido1: string;
    endeudamientoPromCompleto1: string;
    fechaEndeudamiento1: string;
    detallOperacionesVencidasCompleto: string;
//-----------------------------------------------------
    balanceGeneralFecha: string;
    balanceGeneralFechaDos: string;
    balanceGeneralFechaTres: string;
    cajaBancosCompleto: string;
    cajaBancosCompletoDos: string;
    cajaBancosCompletoTres: string;
    inversionesCorrientes: string;
    inversionesCorrientesDos: string;
    inversionesCorrientesTres: string;
    cuentasRelacionadas: string;
    cuentasRelacionadasDos: string;
    cuentasRelacionadasTres: string;
    cuentasNoRelacionadas: string;
    cuentasNoRelacionadasDos: string;
    cuentasNoRelacionadasTres: string;
    otrasCuentasRelacionadas: string;
    otrasCuentasRelacionadasDos: string;
    otrasCuentasRelacionadasTres: string;
    otrasCuentasNoRelacionadas: string;
    otrasCuentasNoRelacionadasDos: string;
    otrasCuentasNoRelacionadasTres: string;
    provisionCuentas: string;
    provisionCuentasDos: string;
    provisionCuentasTres: string;
    creditoTributario: string;
    creditoTributarioDos: string;
    creditoTributarioTres: string;
    inventariosCompleto: string;
    inventariosCompletoDos: string;
    inventariosCompletoTres: string;
    activosPagados: string;
    activosPagadosDos: string;
    activosPagadosTres: string;
    otrosActivosCorrientes: string;
    otrosActivosCorrientesDos: string;
    otrosActivosCorrientesTres: string;
    totalActivoCorriente: string;
    totalActivoCorrienteDos: string;
    totalActivoCorrienteTres: string;
    totalActivoFijo: string;
    totalActivoFijoDos: string;
    totalActivoFijoTres: string;
    totalActivoDiferido: string;
    totalActivoDiferidoDos: string;
    totalActivoDiferidoTres: string;
    inversionesParticipaciones: string;
    inversionesParticipacionesDos: string;
    inversionesParticipacionesTres: string;
    otrasInversionesLargoPlazo: string;
    otrasInversionesLargoPlazoDos: string;
    otrasInversionesLargoPlazoTres: string;
    cuentasPorCobrarRelacionadas: string;
    cuentasPorCobrarRelacionadasDos: string;
    cuentasPorCobrarRelacionadasTres: string;
    cuentasPorCobrarNoRelacionadas: string;
    cuentasPorCobrarNoRelacionadasDos: string;
    cuentasPorCobrarNoRelacionadasTres: string;
    otrasCuentasPorCobrarRelacionadas: string;
    otrasCuentasPorCobrarRelacionadasDos: string;
    otrasCuentasPorCobrarRelacionadasTres: string;
    otrasCuentasPorCobrarNoRelacionadas: string;
    otrasCuentasPorCobrarNoRelacionadasDos: string;
    otrasCuentasPorCobrarNoRelacionadasTres: string;
    provisionCuentasIncobrables: string;
    provisionCuentasIncobrablesDos: string;
    provisionCuentasIncobrablesTres: string;
    otrosActivosLargoPlazo: string;
    otrosActivosLargoPlazoDos: string;
    otrosActivosLargoPlazoTres: string;
    totalActivosLargoPlazo: string;
    totalActivosLargoPlazoDos: string;
    totalActivosLargoPlazoTres: string;
    totalDelActivo: string;
    totalDelActivoDos: string;
    totalDelActivoTres: string;
    pasivoCompleto: string;
    pasivoCompletoDos: string;
    pasivoCompletoTres: string;
    cuentasProveedoresRelacionado: string;
    cuentasProveedoresRelacionadoDos: string;
    cuentasProveedoresRelacionadoTres: string;
    cuentasProveedoresNoRelacionado: string;
    cuentasProveedoresNoRelacionadoDos: string;
    cuentasProveedoresNoRelacionadoTres: string;
    obligacionesFinancieras: string;
    obligacionesFinancierasDos: string;
    obligacionesFinancierasTres: string;
    prestamosAccionistas: string;
    prestamosAccionistasDos: string;
    prestamosAccionistasTres: string;
    otrasCuentasPagarRelacionadas: string;
    otrasCuentasPagarRelacionadasDos: string;
    otrasCuentasPagarRelacionadasTres: string;
    otrasCuentasPagarNoRelacionadas: string;
    otrasCuentasPagarNoRelacionadasDos: string;
    otrasCuentasPagarNoRelacionadasTres: string;
    impuestoRentaEjercito: string;
    impuestoRentaEjercitoDos: string;
    impuestoRentaEjercitoTres: string;
    participacionEjercito: string;
    participacionEjercitoDos: string;
    participacionEjercitoTres: string;
    otrosPasivosCorrientes: string;
    otrosPasivosCorrientesDos: string;
    otrosPasivosCorrientesTres: string;
    totalPasivoCorriente: string;
    totalPasivoCorrienteDos: string;
    totalPasivoCorrienteTres: string;
    cuentasPorPagar: string;
    cuentasPorPagarDos: string;
    cuentasPorPagarTres: string;
    cuentasPorPagarNoRela: string;
    cuentasPorPagarNoRelaDos: string;
    cuentasPorPagarNoRelaTres: string;
    obligacionesInstituFinan: string;
    obligacionesInstituFinanDos: string;
    obligacionesInstituFinanTres: string;
    prestamosLargoPlazo: string;
    prestamosLargoPlazoDos: string;
    prestamosLargoPlazoTres: string;
    otrasCuentasLargoPlazo: string;
    otrasCuentasLargoPlazoDos: string;
    otrasCuentasLargoPlazoTres: string;
    otrasCuentasPorPagar: string;
    otrasCuentasPorPagarDos: string;
    otrasCuentasPorPagarTres: string;
    otrosPasivosLargoPlazo: string;
    otrosPasivosLargoPlazoDos: string;
    otrosPasivosLargoPlazoTres: string;
    totPasivoLargoPlazo: string;
    totPasivoLargoPlazoDos: string;
    totPasivoLargoPlazoTres: string;
    otrosPasivos: string;
    otrosPasivosDos: string;
    otrosPasivosTres: string;
    totalDelPasivo: string;
    totalDelPasivoDos: string;
    totalDelPasivoTres: string;
    patrimonioNeto: string;
    patrimonioNetoDos: string;
    patrimonioNetoTres: string;
    capitalSuscrito: string;
    capitalSuscritoDos: string;
    capitalSuscritoTres: string;
    capitalSuscritoNoPaga: string;
    capitalSuscritoNoPagaDos: string;
    capitalSuscritoNoPagaTres: string;
    aportesSocios: string;
    aportesSociosDos: string;
    aportesSociosTres: string;
    reservaLegal: string;
    reservaLegalDos: string;
    reservaLegalTres: string;
    otrasReservas: string;
    otrasReservasDos: string;
    otrasReservasTres: string;
    utilidadNoDistribuida: string;
    utilidadNoDistribuidaDos: string;
    utilidadNoDistribuidaTres: string;
    perdidaAcumulada: string;
    perdidaAcumuladaDos: string;
    perdidaAcumuladaTres: string;
    utilidadEjercito: string;
    utilidadEjercitoDos: string;
    utilidadEjercitoTres: string;
    totalPatrimonio: string;
    totalPatrimonioDos: string;
    totalPatrimonioTres: string;
    totalPasivoPatrimonio: string;
    totalPasivoPatrimonioDos: string;
    totalPasivoPatrimonioTres: string;

//-------------------------------------------------------
    estadoResultadosFecha: string;
    estadoResultadosFechaDos: string;
    ventasTotales: string;
    ventasTotalesDos: string;
    costoVentas: string;
    costoVentasDos: string;
    utilidadBruta: string;
    utilidadBrutaDos: string;
    gastosAdministracion: string;
    gastosAdministracionDos: string;
    utilidadOperacional: string;
    utilidadOperacionalDos: string;
    ingresosNoOperacionales: string;
    ingresosNoOperacionalesDos: string;
    gastosNoOperacio: string;
    gastosNoOperacioDos: string;
    utilidadAntesImpuestos: string;
    utilidadAntesImpuestosDos: string;
    participacionTrabajadores: string;
    participacionTrabajadoresDos: string;
    utilidadTributable: string;
    utilidadTributableDos: string;
    impuestoARenta: string;
    impuestoARentaDos: string;
    utilidadRepartible: string;
    utilidadRepartibleDos: string;
    indiceFinancieroFecha: string;
    indiceFinancieroFechaDos: string;
    razonCorriente: string;
    razonCorrienteDos: string;
    razonCorrienteTres: string;
    pruebaAcida: string;
    pruebaAcidaDos: string;
    pruebaAcidaTres: string;
    razonEfectivo: string;
    razonEfectivoDos: string;
    razonEfectivoTres: string;
    indiceApalanca: string;
    indiceApalancaDos: string;
    indiceApalancaTres: string;
    razonDeudaTotal: string;
    razonDeudaTotalDos: string;
    razonDeudaTotalTres: string;
    indicesActividad: string;
    indicesActividadDos: string;
    indicesActividadTres: string;
    rotacionInventario: string;
    rotacionInventarioDos: string;
    rotacionInventarioTres: string;
    diasVenta: string;
    diasVentaDos: string;
    diasVentaTres: string;
    rotacionCuentas: string;
    rotacionCuentasDos: string;
    rotacionCuentasTres: string;
    diasPromedio: string;
    diasPromedioDos: string;
    diasPromedioTres: string;
    rotacionCuentasPagar: string;
    rotacionCuentasPagarDos: string;
    rotacionCuentasPagarTres: string;
    diasCuentasPagar: string;
    diasCuentasPagarDos: string;
    diasCuentasPagarTres: string;
    rotacionActivosTotales: string;
    rotacionActivosTotalesDos: string;
    rotacionActivosTotalesTres: string;
    indicesRentabilidad: string;
    indicesRentabilidadDos: string;
    indicesRentabilidadTres: string;
    margenUtilidad: string;
    margenUtilidadDos: string;
    margenUtilidadTres: string;
    rendimientoActivos: string;
    rendimientoActivosDos: string;
    rendimientoActivosTres: string;
    rendimientoCapital: string;
    rendimientoCapitalDos: string;
    rendimientoCapitalTres: string;
    cuentaFecha: string;
    cuentaFechaDos: string;
    activoCorriente: string;
    activoCorrienteDos: string;
    activoNoCorriente: string;
    activoNoCorrienteDos: string;
    totalActivos: string;
    totalActivosDos: string;
    pasivoCorriente: string;
    pasivoCorrienteDos: string;
    pasivoNoCorriente: string;
    pasivoNoCorrienteDos: string;
    totalPasivos: string;
    totalPasivosDos: string;
    patrimonio: string;
    patrimonioDos: string;
    ventasCompleto: string;
    ventasCompletoDos: string;
    utilidadPerdida: string;
    utilidadPerdidaDos: string;
    capitalTrabajo: string;
    capitalTrabajoDos: string;
    valor1: string;
    valor2: string;
    valor3: string;
    valor4: string;
    valor5: string;
    valor6: string;
    valor7: string;
    valor8: string;
    valor9: string;
    valor10: string;
    porcentaje1: string;
    porcentaje2: string;
    porcentaje3: string;
    porcentaje4: string;
    porcentaje5: string;
    porcentaje6: string;
    porcentaje7: string;
    porcentaje8: string;
    porcentaje9: string;
    porcentaje10: string;
    fortaleza: string;
    fortalezacamp1:String;
  fortalezacamp2:String;
  fortalezacamp3:String;
  fortalezacamp4:String;
  fortalezacamp5:String;
  fortalezacamp6:String;
  fortalezacamp7:String;
  fortalezacamp8:String;
  fortalezacamp9:String;
  fortalezacamp10:String;
  fortalezacamp11:String;
  fortalezacamp12:String;
  fortalezacamp13:String;
  fortalezacamp14:String;
  fortalezacamp15:String;

    debilidad: string;
    debilidad1: string;
    debilidad2: string;
    debilidad3: string;
    debilidad4: string;
    debilidad5: string;
    debilidad6: string;
    debilidad7: string;
    debilidad8: string;
    debilidad9: string;
    debilidad10: string;
    debilidad11: string;
    debilidad12: string;
    debilidad13: string;
    debilidad14: string;
    debilidad15: string;
    observacionesCompleto: string;
    checksListaClinton:string;
    // GRAFICOS
    valorTresEvolucionRatingComple :string;
    yearEvolucionCapitalSocialComple: String;
    EvolucionCapitalSocialComple: String;
    yearDosCapitalSocialComple: String;
    dosevolucioncpComple: String;
    yearTresEvolucionCapitalSocialComple: String;
    tresevolucioncpComple: String;
    yearCuatroEvolucionCapitalSocialComple: String;
    cuartrovolucioncpComple: String;
    yearEvolucionRatingComple: String;
    yearDosEvolucionRatingComple: string;
    yearCuatroEvolucionRatingComple: string;
    valorDosEvolucionRatingComple : string;
    valorEvolucionRatingComple: String;
    escoreEvolucionRatingComple: String;
    yearUnoEvolucionRatingComple: String;
    escoreDosEvolucionRatingComple: String;
    escoreTresEvolucionRatingComple: String;
    valorUnoEvolucionRatingComple: String;
    escoreUnoEvolucionRatingComple: String;
    yearTresEvolucionRatingComple: String;
    valorCuatroEvolucionRatingComple: String;
    escoreCuatroEvolucionRatingComple: String;
    yearEvolucionEmpleadosComple: String;
    NumberEvolucionEmpleadosComple: String;
    yearDosEvolucionEmpleadosComple: String;
    NumberDosEvolucionEmpleadosComple: String;
    yearTresEvolucionEmpleadosComple: String;
    NumberTresEvolucionEmpleadosComple: String;
    yearCuatroEvolucionEmpleadosComple: String;
    NumberCuatroEvolucionEmpleadosComple: String;
    // Nuevos campo referencias comerciales
    nombreRefCompletoDos: String;
    direccionRefCompletoDos: String;
    telefonoRefCompletoDos: String;
    contactoRefCompletoDos: String;
    lineaCreditoRefCompletoDos: String;
    pagosRefCompletoDos: String;
    productoRefCompletoDos: String;
    clienteDesdeRefCompletoDos: String;
    compraMensualRefCompletaDos: String;
    checksPuntualidadPagosDosU: String;
    descriptionEmpresasCimpleto: String;
    checksGrupoEconomicoCompleto: String;

    descriptionObligacionesLegales:String;
    descriptionSuperintendencia: String;
    montosCredito: String;  
    calificaUnoComple: String;
    calificaDosComple: String;
    calificaTresComple: String;
    calificaCuatroComple: String;
    calificaCincoComple: String;
    calificaSeisComple: String;
    calificaSieteComple: String;
    calificaOchoComple: String;

    // debil-forta
    checksRazonCorrienteEs: String;
    checksRazonDeudaEs: String;
    checksDiaspromedioCobrar: String;
    checksDiaspromedioPagar: String;
    checksRendimientoActivo: String;
    checksVentasMayor10mil: String;
    checksVentas5: String;
    checksUtilidad5: String;
    checksOperacion10: String;
    checksRegistraJuicios: String;
    checksPagaAtiempo: String;
    IniciodeActividadesOtros: String;
    descriptionInfraCompleto: String;


    //estado del informe para ver correo
    estadoFormCompleto: String;
    emailCompleto: String;
    tipoInformeCompleto: String;



}