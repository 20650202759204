
import { Component, OnInit, ViewChild, EventEmitter, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompletoService } from '../../../services/completo.service'
import { formCompleto } from '../../../models/formularioCompleto';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Router } from '@angular/router';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import Swal from 'sweetalert2';
import * as jsPDF from 'jspdf';
import * as ClassicEditorBuild from '@ckeditor/ckeditor5-build-classic';
import { Tarea } from 'src/app/models/tarea.model';
import { TareaService } from 'src/app/services/tareas.service';
import { Usuario } from 'src/app/models/usuario.model';
import { UsuarioService } from 'src/app/services/usuario.service';
import * as Chart from 'chart.js';
import { parse } from 'url';
import { MessageService } from 'src/app/services/message.service';
import { Contact } from 'src/app/models/contact.model';
import { environment } from 'src/environments/environment';
// usar funciones del index js
declare function init_plugins()
declare  function checksEMpresas()
declare function checksInformeCompleto()


@Component({
  selector: 'app-detalle-completo2',
  templateUrl: './detalle-completo2.component.html',
  styleUrls: ['./detalle-completo2.component.css']
})
export class DetalleCompleto2Component implements OnInit {
  @ViewChild('reporteExpress', { static: true })

  public Editor = ClassicEditorBuild;
 

  //para el correo
  loading = false;
  buttionText = "Submit";
  //empezando los graficos nuevos
  //grafico de años y empresas
  chart: any = [];
  date1 = [];
  capital1 = [];
  //grafico de empleados
  chart1: any = [];
  capital2 = [];
  date2 = [];
  //grafico de scores

  chart3: any = [];
  capital3 = [];
  date3 = [];

  //grafico de pasivos
  chart4: any = [];
  capital4 = [];
  date4 = [];
  completo3: formCompleto[];
lista: Contact;
  public mask = function (rawValue) {
    console.log(rawValue)
    return [/[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  }

  formularioCompleto = new formCompleto();
  messageClient = this.formularioCompleto.checksCalificaciones;
  //tareaActual
  tareas: Tarea[];
  tareaActual: Tarea;
  idFormulario;
  usuario: Usuario;

  submitted = false;
  //empieza formulario
  reporteCompleto: FormGroup;
  message: String;

  reinicioactividades: String;
  obligadocontabilidad: String;
  yearCuatroEvolucionRatingComple: string;
  valorDosEvolucionRatingComple: number;
  fechaElaboracionCompleta: string;
  solicitanteCompleto: string;
  razonSocialCompleto: string;
  nombreComercialCompleto: string;
  rucCompleto: string;
  formaLegalCompleto: string;
  estadoCompleto: string;
  capitalSubscritoCompleto: string;
  inicioActividadesCompleto: string;
  actividadPrincipalCompleto: string;
  objetoSocialCompleto: string;
  provinciaCompleto: string;
  ciudadCompleto: string;
  direccionCompleto: string;
  telefonoCompleto: string;
  paginaWebCompleto: string;
  correoWebCompleto: string;
  representanteLegalCompleto: string;
  cedulaCompleto: string;
  personaContactoCompleto: string;
  cargoCompleto: string;
  correoCompleto: string;
  checksEmpresasCompleto: string;
  empresasRelacionadasCompleto: string;
  grupoEconomicoCompleto: string;
  codigoActividadCompleto: string;
  anioPagoImpuestoCompleto: string;
  pagoRentaUnoCompleto: string;
  anioPagoImpuestoDosCompleto: string;
  pagoRentaDosCompleto: string;
  anioSalidaDivisasCompleto: string;
  anioSalidaDivisasDosCompleto: string;
  salidaDivisasCompletoUno: string;
  salidaDivisasCompletoDos: string;
  checksCalificaciones: string;
  requeridoClienteCompleto: string;
  requeridoServiratingCompleto: string;
  checksInfraestructura: string;
  checksSucursalesCompleto: string;
  direccionSucursalCompleto: string;
  numeroEmpleadosCompleto: string;
  checksMaquinaria: string;
  directivoUnoCompleto: string;
  cargoDirectivoUnoCompleto: string;
  directivoDosCompleto: string;
  cargoDirectivoDosCompleto: string;
  numeroAccionistaCompleto: string;
  identificacionAccionistaCompleto: string;
  nombreAccionistaCompleto: string;
  nacionalidadAccionistaCompleto: string;
  tipoInversionAccionistaCompleto
  capitalAccionistaCompleto: string;
  restriccionAccionistaCompleto: string;
  numeroDosAccionistaCompleto: string;
  identificacionDosAccionistaCompleto: string;
  nombreDosAccionistaCompleto: string;
  nacionalidadDosAccionistaCompleto: string;
  tipoInversionDosAccionistaCompleto: string;
  capitalDosAccionistaCompleto: string;
  restriccionDosAccionistaCompleto: string;
  nombreRefCompleto: string;
  nombreRefDosCompleto: string;
  direccionRefCompleto: string;
  direccionRefDosCompleto: string;
  telefonoRefCompleto: string;
  telefonoRefDosCompleto: string;
  contactoRefCompleto: string;
  contactoRefDosCompleto: string;
  lineaCreditoRefCompleto: string;
  lineaCreditoRefDosCompleto: string;
  pagosRefCompleto: string;
  pagosRefDosCompleto: string;
  productoRefCompleto: string;
  productoRefDosCompleto: string;
  clienteDesdeRefCompleto: string;
  clienteDesdeRefDosCompleto: string;
  compraMensualRefCompleta: string;
  compraMensualDosRefCompleta: string;
  checksPuntualidadPagos: string;
  checksPuntualidadPagosDos: string;
  checksObligacionesLegales: string;
  checksSuperintendencia: string;
  procesosJudicialesCompleto: Text;
  procesosJudicialesCompleto2: Text;
  //accionesDelitosCompleto: string;
  informacionRelevanteCompleto: Text;
  informacionBancariaCompleto: Text;
  checksListaClinton: string;
  institucionRefCompleta: string;
  fechaInstCompleto: string;
  mensajeRefCompleta: string;
  fechaCorteCompleta: string;
  institucionCompleta: string;
  tipoRiesgoCompleta: string;
  tipoCreditoCompleta: string;
  vincCompleta: string;
  totalVencerCompleta: string;
  noDevInteresesCompleta: string;
  totalVencidoCompleta: string;
  demJudCompleta: string;
  cartCastCompleta: string;
  saldoDeudaCompleta: string;
  acConcCompleta: string;
  fechaCorteCompleta1: string;
  institucionCompleta1: string;
  tipoRiesgoCompleta1: string;
  tipoCreditoCompleta1: string;
  vincCompleta1: string;
  totalVencerCompleta1: string;
  noDevInteresesCompleta1: string;
  totalVencidoCompleta1: string;
  demJudCompleta1: string;
  cartCastCompleta1: string;
  saldoDeudaCompleta1: string;
  acConcCompleta1: string;
  fechaCorteCompleta2: string;
  institucionCompleta2: string;
  tipoRiesgoCompleta2: string;
  tipoCreditoCompleta2: string;
  vincCompleta2: string;
  totalVencerCompleta2: string;
  noDevInteresesCompleta2: string;
  totalVencidoCompleta2: string;
  demJudCompleta2: string;
  cartCastCompleta2: string;
  saldoDeudaCompleta2: string;
  acConcCompleta2: string;
  fechaCorteCompleta3: string;
  institucionCompleta3: string;
  tipoRiesgoCompleta3: string;
  tipoCreditoCompleta3: string;
  vincCompleta3: string;
  totalVencerCompleta3: string;
  noDevInteresesCompleta3: string;
  totalVencidoCompleta3: string;
  demJudCompleta3: string;
  cartCastCompleta3: string;
  saldoDeudaCompleta3: string;
  acConcCompleta3: string;
  fechaCorteCompleta4: string;
  institucionCompleta4: string;
  tipoRiesgoCompleta4: string;
  tipoCreditoCompleta4: string;
  vincCompleta4: string;
  totalVencerCompleta4: string;
  noDevInteresesCompleta4: string;
  totalVencidoCompleta4: string;
  demJudCompleta4: string;
  cartCastCompleta4: string;
  saldoDeudaCompleta4: string;
  acConcCompleta4: string;
  fechaCorteCompleta5: string;
  institucionCompleta5: string;
  tipoRiesgoCompleta5: string;
  tipoCreditoCompleta5: string;
  vincCompleta5: string;
  totalVencerCompleta5: string;
  noDevInteresesCompleta5: string;
  totalVencidoCompleta5: string;
  demJudCompleta5: string;
  cartCastCompleta5: string;
  saldoDeudaCompleta5: string;
  acConcCompleta5: string;

  institucionCompleta6: string;
  tipoRiesgoCompleta6: string;
  tipoCreditoCompleta6: string;
  vincCompleta6: string;
  totalVencerCompleta6: string;
  noDevInteresesCompleta6: string;
  totalVencidoCompleta6: string;
  demJudCompleta6: string;
  cartCastCompleta6: string;
  saldoDeudaCompleta6: string;
  acConcCompleta6: string;
  acreedorCompleto: string;
  porVencerCompleto: string;
  noDevengaIntCompleto: string;
  vencidoCompleto: string;
  saldDeudaCompleto: string;
  demandaJudicialCompleto: string;
  carteraCastigadaCompleto: string;
  porVencerTotal: string;
  noDevInteresesTotal: string;
  vencidoTotal: string;
  saldoDeudaTotal: string;
  demandaJudicialTotal: string;
  carteraCastigadaTotal: string;
  historialCrediticioCompleto: string;
  mayPlazoVencidoCompleto: string;
  fechaMayPlazoVencido: string;
  mayValorVencidoCompleto: string;
  fechaMayValorVencido: string;
  endeudamientoPromCompleto: string;
  fechaEndeudamiento: string;
  mayPlazoVencidoCompleto1: string;
  fechaMayPlazoVencido1: string;
  mayValorVencidoCompleto1: string;
  fechaMayValorVencido1: string;
  endeudamientoPromCompleto1: string;
  fechaEndeudamiento1: string;
  detallOperacionesVencidasCompleto: string;
  valorTresEvolucionRatingComple: number;

  //aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa


  balanceGeneralFecha: string;
  balanceGeneralFechaDos: string;
  balanceGeneralFechaTres: string;
  cajaBancosCompleto: string;
  cajaBancosCompletoDos: string;
  cajaBancosCompletoTres: string;
  inversionesCorrientes: string;
  inversionesCorrientesDos: string;
  inversionesCorrientesTres: string;
  cuentasRelacionadas: string;
  cuentasRelacionadasDos: string;
  cuentasRelacionadasTres: string;
  cuentasNoRelacionadas: string;
  cuentasNoRelacionadasDos: string;
  cuentasNoRelacionadasTres: string;
  otrasCuentasRelacionadas: string;
  otrasCuentasRelacionadasDos: string;
  otrasCuentasRelacionadasTres: string;
  otrasCuentasNoRelacionadas: string;
  otrasCuentasNoRelacionadasDos: string;
  otrasCuentasNoRelacionadasTres: string;
  provisionCuentas: string;
  provisionCuentasDos: string;
  provisionCuentasTres: string;
  creditoTributario: string;
  creditoTributarioDos: string;
  creditoTributarioTres: string;
  inventariosCompleto: string;
  inventariosCompletoDos: string;
  inventariosCompletoTres: string;
  activosPagados: string;
  activosPagadosDos: string;
  activosPagadosTres: string;
  otrosActivosCorrientes: string;
  otrosActivosCorrientesDos: string;
  otrosActivosCorrientesTres: string;
  totalActivoCorriente: string;
  totalActivoCorrienteDos: string;
  totalActivoCorrienteTres: string;
  totalActivoFijo: string;
  totalActivoFijoDos: string;
  totalActivoFijoTres: string;
  totalActivoDiferido: string;
  totalActivoDiferidoDos: string;
  totalActivoDiferidoTres: string;
  inversionesParticipaciones: string;
  inversionesParticipacionesDos: string;
  inversionesParticipacionesTres: string;
  otrasInversionesLargoPlazo: string;
  otrasInversionesLargoPlazoDos: string;
  otrasInversionesLargoPlazoTres: string;
  cuentasPorCobrarRelacionadas: string;
  cuentasPorCobrarRelacionadasDos: string;
  cuentasPorCobrarRelacionadasTres: string;
  cuentasPorCobrarNoRelacionadas: string;
  cuentasPorCobrarNoRelacionadasDos: string;
  cuentasPorCobrarNoRelacionadasTres: string;
  otrasCuentasPorCobrarRelacionadas: string;
  otrasCuentasPorCobrarRelacionadasDos: string;
  otrasCuentasPorCobrarRelacionadasTres: string;
  otrasCuentasPorCobrarNoRelacionadas: string;
  otrasCuentasPorCobrarNoRelacionadasDos: string;
  otrasCuentasPorCobrarNoRelacionadasTres: string;
  provisionCuentasIncobrables: string;
  provisionCuentasIncobrablesDos: string;
  provisionCuentasIncobrablesTres: string;
  otrosActivosLargoPlazo: string;
  otrosActivosLargoPlazoDos: string;
  otrosActivosLargoPlazoTres: string;
  totalActivosLargoPlazo: string;
  totalActivosLargoPlazoDos: string;
  totalActivosLargoPlazoTres: string;
  totalDelActivo: string;
  totalDelActivoDos: string;
  totalDelActivoTres: string;
  cuentasProveedoresRelacionado: string;
  cuentasProveedoresRelacionadoDos: string;
  cuentasProveedoresRelacionadoTres: string;
  cuentasProveedoresNoRelacionado: string;
  cuentasProveedoresNoRelacionadoDos: string;
  cuentasProveedoresNoRelacionadoTres: string;
  obligacionesFinancieras: string;
  obligacionesFinancierasDos: string;
  obligacionesFinancierasTres: string;
  prestamosAccionistas: string;
  prestamosAccionistasDos: string;
  prestamosAccionistasTres: string;
  otrasCuentasPagarRelacionadas: string;
  otrasCuentasPagarRelacionadasDos: string;
  otrasCuentasPagarRelacionadasTres: string;
  otrasCuentasPagarNoRelacionadas: string;
  otrasCuentasPagarNoRelacionadasDos: string;
  otrasCuentasPagarNoRelacionadasTres: string;
  impuestoRentaEjercito: string;
  impuestoRentaEjercitoDos: string;
  impuestoRentaEjercitoTres: string;
  participacionEjercito: string;
  participacionEjercitoDos: string;
  participacionEjercitoTres: string;
  otrosPasivosCorrientes: string;
  otrosPasivosCorrientesDos: string;
  otrosPasivosCorrientesTres: string;
  totalPasivoCorriente: string;
  totalPasivoCorrienteDos: string;
  totalPasivoCorrienteTres: string;
  cuentasPorPagar: string;
  cuentasPorPagarDos: string;
  cuentasPorPagarTres: string;
  cuentasPorPagarNoRela: string;
  cuentasPorPagarNoRelaDos: string;
  cuentasPorPagarNoRelaTres: string;
  obligacionesInstituFinan: string;
  obligacionesInstituFinanDos: string;
  obligacionesInstituFinanTres: string;
  prestamosLargoPlazo: string;
  prestamosLargoPlazoDos: string;
  prestamosLargoPlazoTres: string;
  otrasCuentasLargoPlazo: string;
  otrasCuentasLargoPlazoDos: string;
  otrasCuentasLargoPlazoTres: string;
  otrasCuentasPorPagar: string;
  otrasCuentasPorPagarDos: string;
  otrasCuentasPorPagarTres: string;
  otrosPasivosLargoPlazo: string;
  otrosPasivosLargoPlazoDos: string;
  otrosPasivosLargoPlazoTres: string;
  totPasivoLargoPlazo: string;
  totPasivoLargoPlazoDos: string;
  totPasivoLargoPlazoTres: string;
  otrosPasivos: string;
  otrosPasivosDos: string;
  otrosPasivosTres: string;
  totalDelPasivo: string;
  totalDelPasivoDos: string;
  totalDelPasivoTres: string;
  capitalSuscrito: string;
  capitalSuscritoDos: string;
  capitalSuscritoTres: string;
  capitalSuscritoNoPaga: string;
  capitalSuscritoNoPagaDos: string;
  capitalSuscritoNoPagaTres: string;
  aportesSocios: string;
  aportesSociosDos: string;
  aportesSociosTres: string;
  reservaLegal: string;
  reservaLegalDos: string;
  reservaLegalTres: string;
  otrasReservas: string;
  otrasReservasDos: string;
  otrasReservasTres: string;
  utilidadNoDistribuida: string;
  utilidadNoDistribuidaDos: string;
  utilidadNoDistribuidaTres: string;
  perdidaAcumulada: string;
  perdidaAcumuladaDos: string;
  perdidaAcumuladaTres: string;
  utilidadEjercito: string;
  utilidadEjercitoDos: string;
  utilidadEjercitoTres: string;
  totalPatrimonio: string;
  totalPatrimonioDos: string;
  totalPatrimonioTres: string;
  totalPasivoPatrimonio: string;
  totalPasivoPatrimonioDos: string;
  totalPasivoPatrimonioTres: string;
  estadoResultadosFecha: string;
  estadoResultadosFechaDos: string;
  ventasTotales: string;
  ventasTotalesDos: string;
  costoVentas: string;
  costoVentasDos: string;
  utilidadBruta: string;
  utilidadBrutaDos: string;
  gastosAdministracion: string;
  gastosAdministracionDos: string;
  utilidadOperacional: string;
  utilidadOperacionalDos: string;
  ingresosNoOperacionales: string;
  ingresosNoOperacionalesDos: string;
  gastosNoOperacio: string;
  gastosNoOperacioDos: string;
  utilidadAntesImpuestos: string;
  utilidadAntesImpuestosDos: string;
  participacionTrabajadores: string;
  participacionTrabajadoresDos: string;
  utilidadTributable: string;
  utilidadTributableDos: string;
  impuestoARenta: string;
  impuestoARentaDos: string;
  utilidadRepartible: string;
  utilidadRepartibleDos: string;
  indiceFinancieroFecha: string;
  indiceFinancieroFechaDos: string;
  razonCorriente: string;
  razonCorrienteDos: string;
  razonCorrienteTres: string;
  pruebaAcida: string;
  pruebaAcidaDos: string;
  pruebaAcidaTres: string;
  razonEfectivo: string;
  razonEfectivoDos: string;
  razonEfectivoTres: string;
  indiceApalanca: string;
  indiceApalancaDos: string;
  indiceApalancaTres: string;
  razonDeudaTotal: string;
  razonDeudaTotalDos: string;
  razonDeudaTotalTres: string;
  indicesActividad: string;
  indicesActividadDos: string;
  indicesActividadTres: string;
  rotacionInventario: string;
  rotacionInventarioDos: string;
  rotacionInventarioTres: string;
  diasVenta: string;
  diasVentaDos: string;
  diasVentaTres: string;
  rotacionCuentas: string;
  rotacionCuentasDos: string;
  rotacionCuentasTres: string;
  diasPromedio: string;
  diasPromedioDos: string;
  diasPromedioTres: string;
  rotacionCuentasPagar: string;
  rotacionCuentasPagarDos: string;
  rotacionCuentasPagarTres: string;
  diasCuentasPagar: string;
  diasCuentasPagarDos: string;
  diasCuentasPagarTres: string;
  rotacionActivosTotales: string;
  rotacionActivosTotalesDos: string;
  rotacionActivosTotalesTres: string;
  indicesRentabilidad: string;
  indicesRentabilidadDos: string;
  indicesRentabilidadTres: string;
  margenUtilidad: string;
  margenUtilidadDos: string;
  margenUtilidadTres: string;
  rendimientoActivos: string;
  rendimientoActivosDos: string;
  rendimientoActivosTres: string;
  rendimientoCapital: string;
  rendimientoCapitalDos: string;
  rendimientoCapitalTres: string;
  cuentaFecha: string;
  cuentaFechaDos: string;
  activoCorriente: string;
  activoCorrienteDos: string;
  activoNoCorriente: string;
  activoNoCorrienteDos: string;
  totalActivos: string;
  totalActivosDos: string;
  pasivoCorriente: string;
  pasivoCorrienteDos: string;
  pasivoNoCorriente: string;
  pasivoNoCorrienteDos: string;
  totalPasivos: string;
  totalPasivosDos: string;
  patrimonio: string;
  patrimonioDos: string;
  ventasCompleto: string;
  ventasCompletoDos: string;
  utilidadPerdida: string;
  utilidadPerdidaDos: string;
  capitalTrabajo: string;
  capitalTrabajoDos: string;
  valor1: string;
  valor2: string;
  valor3: string;
  valor4: string;
  valor5: string;
  valor6: string;
  valor7: string;
  valor8: string;
  valor9: string;
  valor10: string;
  porcentaje1: string;
  porcentaje2: string;
  porcentaje3: string;
  porcentaje4: string;
  porcentaje5: string;
  porcentaje6: string;
  porcentaje7: string;
  porcentaje8: string;
  porcentaje9: string;
  porcentaje10: string;
  fortaleza: string;
  fortalezacamp1: String;
  fortalezacamp2: String;
  fortalezacamp3: String;
  fortalezacamp4: String;
  fortalezacamp5: String;
  fortalezacamp6: String;
  fortalezacamp7: String;
  fortalezacamp8: String;
  fortalezacamp9: String;
  fortalezacamp10: String;
  fortalezacamp11: String;
  fortalezacamp12: String;
  fortalezacamp13: String;
  fortalezacamp14: String;
  fortalezacamp15: String;
  

  debilidad: string;
  debilidad1: string;
  debilidad2: string;
  debilidad3: string;
  debilidad4: string;
  debilidad5: string;
  debilidad6: string;
  debilidad7: string;
  debilidad8: string;
  debilidad9: string;
  debilidad10: string;
  debilidad11: String;
  debilidad12: String;
  debilidad13: String;
  debilidad14: String;
  debilidad15: String;
  

  observacionesCompleto: string;
  riseCompleto: string;

  //Graficos Completos
  yearEvolucionCapitalSocialComple: string;
  EvolucionCapitalSocialComple: String;
  yearDosCapitalSocialComple: String;
  dosevolucioncpComple: String;
  yearTresEvolucionCapitalSocialComple: String;
  tresevolucioncpComple: String;
  yearCuatroEvolucionCapitalSocialComple: String;
  cuartrovolucioncpComple: String;
  yearDosEvolucionRatingComple: string;
  yearEvolucionRatingComple: string;
  valorEvolucionRatingComple: number;
  escoreEvolucionRatingComple: String;
  yearUnoEvolucionRatingComple: String;
  valorUnoEvolucionRatingComple: number;
  escoreUnoEvolucionRatingComple: String;
  escoreDosEvolucionRatingComple: String;
  escoreTresEvolucionRatingComple: String;
  yearTresEvolucionRatingComple: String;
  valorCuatroEvolucionRatingComple: number;
  escoreCuatroEvolucionRatingComple: String;
  yearEvolucionEmpleadosComple: String;
  NumberEvolucionEmpleadosComple: String;
  yearDosEvolucionEmpleadosComple: String;
  NumberDosEvolucionEmpleadosComple: String;
  yearTresEvolucionEmpleadosComple: String;
  NumberTresEvolucionEmpleadosComple: String;
  yearCuatroEvolucionEmpleadosComple: String;
  NumberCuatroEvolucionEmpleadosComple: String;
  // Nuevos campos referencias
  nombreRefCompletoDos: String;
  direccionRefCompletoDos: String;
  telefonoRefCompletoDos: String;
  contactoRefCompletoDos: String;
  lineaCreditoRefCompletoDos: String;
  pagosRefCompletoDos: String;
  productoRefCompletoDos: String;
  clienteDesdeRefCompletoDos: String;
  compraMensualRefCompletaDos: String;
  checksPuntualidadPagosDosU: String;
  descriptionEmpresasCimpleto: String;
  checksGrupoEconomicoCompleto: String;
  descriptionGrupoEconomicoCompleto: String;
  descriptionObligacionesLegales: String;
  descriptionSuperintendencia: String;
  montosCredito: String;
  montosCreditoDos: String;

  // tabla final informativa
  calificaUnoComple: String;
  calificaDosComple: String;
  calificaTresComple: String;
  calificaCuatroComple: String;
  calificaCincoComple: String;
  calificaSeisComple: String;
  calificaSieteComple: String;
  calificaOchoComple: String;

  //campos Forta-Debil
  checksRazonCorrienteEs: String;
  checksRazonDeudaEs: String;
  checksDiaspromedioCobrar: String;
  checksDiaspromedioPagar: String;
  checksRendimientoActivo: String;
  checksVentasMayor10mil: String;
  checksVentas5: String;
  checksUtilidad5: String;
  checksOperacion10: String;
  checksRegistraJuicios: String;
  checksPagaAtiempo: String;
  //cambios nuevos
  IniciodeActividadesOtros: String;
  descriptionInfraCompleto: String;
  porcentajeTotalPasivoLargoP1prueba
  porcentajeTotalPasivoC1prueba
  porcentajeTotalPatrimonioN1prueba
  porcentajeTotalPasivoLargoP2prueba
  porcentajeTotalPasivoC2prueba
  porcentajeTotalPatrimonioN2prueba




  emailPattern = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  textPattern = new RegExp(/^[ a-zA-ZñÑáéíóúÁÉÍÓÚ;&-/'.\s ]+$/);
  numberPattern = new RegExp(/^[0-9-%$.,() ]+$/);
  paginaWebPattern = new RegExp(/^((https?):\/\/)?([w|W]{3}\.)+[a-zA-Z0-9\-\.]{3,}\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/);
  dineroPattern = new RegExp(/^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9][0-9])?$/);

  public notificacion = new EventEmitter<formCompleto[]>();



  constructor( public _MessageService: MessageService, public fb: FormBuilder, private _usuarioService: UsuarioService, private _tareaService: TareaService, private completoService: CompletoService, private route: ActivatedRoute,
    private location: Location, private router: Router) {

    this.reporteCompleto = this.fb.group({
      riseCompleto: [''],
      fechaElaboracionCompleta: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(200), Validators.pattern(this.textPattern)]],
      solicitanteCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      razonSocialCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(80), Validators.pattern(this.textPattern)]],
      nombreComercialCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      rucCompleto: ['', [Validators.required, Validators.minLength(13), Validators.maxLength(13), Validators.pattern(this.numberPattern)]],
      formaLegalCompleto: ['', [Validators.required]],
      estadoCompleto: ['', [Validators.required]],
      capitalSubscritoCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      inicioActividadesCompleto: ['', [Validators.required]],
      actividadPrincipalCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      objetoSocialCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(500), Validators.pattern(this.textPattern)]],
      provinciaCompleto: ['',],
      ciudadCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      direccionCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      telefonoCompleto: ['', ],
      paginaWebCompleto: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(30), Validators.pattern(this.paginaWebPattern)]],
      correoWebCompleto: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(30), Validators.pattern(this.emailPattern)]],
      representanteLegalCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      cedulaCompleto: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(this.numberPattern)]],
      personaContactoCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      cargoCompleto: ['', [Validators.required]],
      correoCompleto: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(30), Validators.pattern(this.emailPattern)]],
      checksEmpresasCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      empresasRelacionadasCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      grupoEconomicoCompleto: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(100), Validators.pattern(this.textPattern)]],
      codigoActividadCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      anioPagoImpuestoCompleto: ['', [Validators.required]],
      pagoRentaUnoCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      anioPagoImpuestoDosCompleto: ['', [Validators.required]],
      pagoRentaDosCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      anioSalidaDivisasCompleto: ['', [Validators.required]],
      salidaDivisasCompletoUno: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      salidaDivisasCompletoDos: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      anioSalidaDivisasDosCompleto: ['', [Validators.required]],
      checksCalificaciones: ['', [Validators.required]],
      requeridoClienteCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      requeridoServiratingCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      checksInfraestructura: ['', [Validators.required]],
      checksSucursalesCompleto: ['', [Validators.required]],
      direccionSucursalCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      numeroEmpleadosCompleto: ['', [Validators.required]],
      checksMaquinaria: ['', [Validators.required]],
      directivoUnoCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      cargoDirectivoUnoCompleto: ['', [Validators.required]],
      directivoDosCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), , Validators.pattern(this.textPattern)]],
      cargoDirectivoDosCompleto: ['', [Validators.required]],
      numeroAccionistaCompleto: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(30)]],
      identificacionAccionistaCompleto: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      nombreAccionistaCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      nacionalidadAccionistaCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      tipoInversionAccionistaCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      capitalAccionistaCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      restriccionAccionistaCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      numeroDosAccionistaCompleto: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(30)]],
      identificacionDosAccionistaCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      nombreDosAccionistaCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      nacionalidadDosAccionistaCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      tipoInversionDosAccionistaCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      capitalDosAccionistaCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      restriccionDosAccionistaCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      nombreRefCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      nombreRefDosCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      direccionRefCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      direccionRefDosCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      telefonoRefCompleto: ['', ],
      telefonoRefDosCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      contactoRefCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      contactoRefDosCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      lineaCreditoRefCompleto: ['', [Validators.required]],
      lineaCreditoRefDosCompleto: ['', [Validators.required]],
      pagosRefCompleto: ['', [Validators.required]],
      pagosRefDosCompleto: ['', [Validators.required]],
      productoRefCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      productoRefDosCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      clienteDesdeRefCompleto: ['', [Validators.required]],
      clienteDesdeRefDosCompleto: ['', [Validators.required]],
      compraMensualRefCompleta: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      compraMensualDosRefCompleta: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      checksPuntualidadPagos: ['', [Validators.required]],
      checksPuntualidadPagosDos: ['', [Validators.required]],
      checksObligacionesLegales: ['', [Validators.required]],
      checksSuperintendencia: ['', [Validators.required]],
      procesosJudicialesCompleto: ['', ],
      procesosJudicialesCompleto2: ['', ],
      // accionesDelitosCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      informacionRelevanteCompleto: [''],
      informacionBancariaCompleto: [''],
      institucionRefCompleta: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      fechaInstCompleto: ['', [Validators.required]],
      mensajeRefCompleta: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      fechaCorteCompleta: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      institucionCompleta: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      tipoRiesgoCompleta: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      tipoCreditoCompleta: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      vincCompleta: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      totalVencerCompleta: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      noDevInteresesCompleta: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      totalVencidoCompleta: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      demJudCompleta: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      cartCastCompleta: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      saldoDeudaCompleta: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      acConcCompleta: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      fechaCorteCompleta1: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      institucionCompleta1: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      tipoRiesgoCompleta1: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      tipoCreditoCompleta1: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      vincCompleta1: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      totalVencerCompleta1: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      noDevInteresesCompleta1: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      totalVencidoCompleta1: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      demJudCompleta1: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      cartCastCompleta1: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      saldoDeudaCompleta1: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      acConcCompleta1: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      fechaCorteCompleta2: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      institucionCompleta2: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      tipoRiesgoCompleta2: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      tipoCreditoCompleta2: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      vincCompleta2: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      totalVencerCompleta2: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      noDevInteresesCompleta2: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      totalVencidoCompleta2: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      demJudCompleta2: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      cartCastCompleta2: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      saldoDeudaCompleta2: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      acConcCompleta2: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      fechaCorteCompleta3: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      institucionCompleta3: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      tipoRiesgoCompleta3: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      tipoCreditoCompleta3: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      vincCompleta3: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      totalVencerCompleta3: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      noDevInteresesCompleta3: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      totalVencidoCompleta3: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      demJudCompleta3: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      cartCastCompleta3: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      saldoDeudaCompleta3: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      acConcCompleta3: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      fechaCorteCompleta4: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      institucionCompleta4: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      tipoRiesgoCompleta4: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      tipoCreditoCompleta4: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      vincCompleta4: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      totalVencerCompleta4: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      noDevInteresesCompleta4: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      totalVencidoCompleta4: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      demJudCompleta4: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      cartCastCompleta4: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      saldoDeudaCompleta4: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      acConcCompleta4: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      fechaCorteCompleta5: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      institucionCompleta5: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      tipoRiesgoCompleta5: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      tipoCreditoCompleta5: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      vincCompleta5: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      totalVencerCompleta5: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      noDevInteresesCompleta5: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      totalVencidoCompleta5: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      demJudCompleta5: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      cartCastCompleta5: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      saldoDeudaCompleta5: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      acConcCompleta5: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      // fechaCorteCompleta6: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      institucionCompleta6: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      //tipoRiesgoCompleta6: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      //tipoCreditoCompleta6: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      //vincCompleta6: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      totalVencerCompleta6: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      noDevInteresesCompleta6: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      totalVencidoCompleta6: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      demJudCompleta6: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      cartCastCompleta6: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      saldoDeudaCompleta6: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      //acConcCompleta6: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      acreedorCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      porVencerCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      noDevengaIntCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      vencidoCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      saldDeudaCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      demandaJudicialCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      carteraCastigadaCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      porVencerTotal: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      noDevInteresesTotal: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      vencidoTotal: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      saldoDeudaTotal: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      demandaJudicialTotal: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      carteraCastigadaTotal: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      historialCrediticioCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      mayPlazoVencidoCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      fechaMayPlazoVencido: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      mayValorVencidoCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      fechaMayValorVencido: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      endeudamientoPromCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      fechaEndeudamiento: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      mayPlazoVencidoCompleto1: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      fechaMayPlazoVencido1: ['', [Validators.required]],
      mayValorVencidoCompleto1: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      fechaMayValorVencido1: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      endeudamientoPromCompleto1: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      fechaEndeudamiento1: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      detallOperacionesVencidasCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],



      //aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa

      reinicioactividades: ['', [Validators.required]],
      obligadocontabilidad: ['', [Validators.required]],
      balanceGeneralFecha: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      balanceGeneralFechaDos: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      balanceGeneralFechaTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      cajaBancosCompleto: ['',],
      cajaBancosCompletoDos: ['',],
      cajaBancosCompletoTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      inversionesCorrientes: ['', ],
      inversionesCorrientesDos: ['', ],
      inversionesCorrientesTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      cuentasRelacionadas: ['', ],
      cuentasRelacionadasDos: ['', ],
      cuentasRelacionadasTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      cuentasNoRelacionadas: ['', ],
      cuentasNoRelacionadasDos: ['', ],
      cuentasNoRelacionadasTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      otrasCuentasRelacionadas: ['', ],
      otrasCuentasRelacionadasDos: ['', ],
      otrasCuentasRelacionadasTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      otrasCuentasNoRelacionadas: ['', ],
      otrasCuentasNoRelacionadasDos: ['', ],
      otrasCuentasNoRelacionadasTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      provisionCuentas: ['', ],
      provisionCuentasDos: ['',],
      provisionCuentasTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      creditoTributario: ['', ],
      creditoTributarioDos: ['', ],
      creditoTributarioTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      inventariosCompleto: ['', ],
      inventariosCompletoDos: ['', ],
      inventariosCompletoTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      activosPagados: ['', ],
      activosPagadosDos: ['', ],
      activosPagadosTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      otrosActivosCorrientes: ['', ],
      otrosActivosCorrientesDos: ['',],
      otrosActivosCorrientesTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      totalActivoCorriente: ['', ],
      totalActivoCorrienteDos: ['',],
      totalActivoCorrienteTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      totalActivoFijo: ['', ],
      totalActivoFijoDos: ['',],
      totalActivoFijoTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      totalActivoDiferido: ['', ],
      totalActivoDiferidoDos: ['',],
      totalActivoDiferidoTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      inversionesParticipaciones: ['', ],
      inversionesParticipacionesDos: ['', ],
      inversionesParticipacionesTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      otrasInversionesLargoPlazo: ['', ],
      otrasInversionesLargoPlazoDos: ['', ],
      otrasInversionesLargoPlazoTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      cuentasPorCobrarRelacionadas: ['', ],
      cuentasPorCobrarRelacionadasDos: ['', ],
      cuentasPorCobrarRelacionadasTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      cuentasPorCobrarNoRelacionadas: ['', ],
      cuentasPorCobrarNoRelacionadasDos: ['', ],
      cuentasPorCobrarNoRelacionadasTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      otrasCuentasPorCobrarRelacionadas: ['', ],
      otrasCuentasPorCobrarRelacionadasDos: ['', ],
      otrasCuentasPorCobrarRelacionadasTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      otrasCuentasPorCobrarNoRelacionadas: ['', ],
      otrasCuentasPorCobrarNoRelacionadasDos: ['', ],
      otrasCuentasPorCobrarNoRelacionadasTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      provisionCuentasIncobrables: ['', ],
      provisionCuentasIncobrablesDos: ['', ],
      provisionCuentasIncobrablesTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      otrosActivosLargoPlazo: ['', ],
      otrosActivosLargoPlazoDos: ['', ],
      otrosActivosLargoPlazoTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      totalActivosLargoPlazo: ['', ],
      totalActivosLargoPlazoDos: ['',],
      totalActivosLargoPlazoTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      totalDelActivo: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      totalDelActivoDos: ['', ],
      totalDelActivoTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      cuentasProveedoresRelacionado: ['', ],
      cuentasProveedoresRelacionadoDos: ['', ],
      cuentasProveedoresRelacionadoTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      cuentasProveedoresNoRelacionado: ['', ],
      cuentasProveedoresNoRelacionadoDos: ['', ],
      cuentasProveedoresNoRelacionadoTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      obligacionesFinancieras: ['', ],
      obligacionesFinancierasDos: ['', ],
      obligacionesFinancierasTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      prestamosAccionistas: ['', ],
      prestamosAccionistasDos: ['', ],
      prestamosAccionistasTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      otrasCuentasPagarRelacionadas: ['',],
      otrasCuentasPagarRelacionadasDos: ['',],
      otrasCuentasPagarRelacionadasTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      otrasCuentasPagarNoRelacionadas: ['', ],
      otrasCuentasPagarNoRelacionadasDos: ['',],
      otrasCuentasPagarNoRelacionadasTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      impuestoRentaEjercito: ['',],
      impuestoRentaEjercitoDos: ['', ],
      impuestoRentaEjercitoTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      participacionEjercito: ['', ],
      participacionEjercitoDos: ['',],
      participacionEjercitoTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      otrosPasivosCorrientes: ['', ],
      otrosPasivosCorrientesDos: ['',],
      otrosPasivosCorrientesTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      totalPasivoCorriente: ['', ],
      totalPasivoCorrienteDos: ['', ],
      totalPasivoCorrienteTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      cuentasPorPagar: ['',],
      cuentasPorPagarDos: ['', ],
      cuentasPorPagarTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      cuentasPorPagarNoRela: ['', ],
      cuentasPorPagarNoRelaDos: ['', ],
      cuentasPorPagarNoRelaTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      obligacionesInstituFinan: ['', ],
      obligacionesInstituFinanDos: ['', ],
      obligacionesInstituFinanTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      prestamosLargoPlazo: ['', ],
      prestamosLargoPlazoDos: ['', ],
      prestamosLargoPlazoTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      otrasCuentasLargoPlazo: ['',],
      otrasCuentasLargoPlazoDos: ['', ],
      otrasCuentasLargoPlazoTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      otrasCuentasPorPagar: ['', ],
      otrasCuentasPorPagarDos: ['', ],
      otrasCuentasPorPagarTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      otrosPasivosLargoPlazo: ['', ],
      otrosPasivosLargoPlazoDos: ['',],
      otrosPasivosLargoPlazoTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      totPasivoLargoPlazo: ['',],
      totPasivoLargoPlazoDos: ['', ],
      totPasivoLargoPlazoTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      otrosPasivos: ['', ],
      otrosPasivosDos: ['', ],
      otrosPasivosTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      totalDelPasivo: ['', ],
      totalDelPasivoDos: ['', ],
      totalDelPasivoTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      capitalSuscrito: ['', ],
      capitalSuscritoDos: ['', ],
      capitalSuscritoTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      capitalSuscritoNoPaga: ['', ],
      capitalSuscritoNoPagaDos: ['', ],
      capitalSuscritoNoPagaTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      aportesSocios: ['', ],
      aportesSociosDos: ['', ],
      aportesSociosTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      reservaLegal: ['', ],
      reservaLegalDos: ['', ],
      reservaLegalTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      otrasReservas: ['', ],
      otrasReservasDos: ['', ],
      otrasReservasTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      utilidadNoDistribuida: ['', ],
      utilidadNoDistribuidaDos: ['', ],
      utilidadNoDistribuidaTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      perdidaAcumulada: ['', ],
      perdidaAcumuladaDos: ['', ],
      perdidaAcumuladaTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      utilidadEjercito: ['', ],
      utilidadEjercitoDos: ['', ],
      utilidadEjercitoTres: ['', ],
      totalPatrimonio: ['', ],
      totalPatrimonioDos: ['', ],
      totalPatrimonioTres: ['', ],
      totalPasivoPatrimonio: ['', ],
      totalPasivoPatrimonioDos: ['', ],
      totalPasivoPatrimonioTres: ['',],
      estadoResultadosFecha: ['', ],
      estadoResultadosFechaDos: ['', ],
      ventasTotales: ['', ],
      ventasTotalesDos: ['',],
      costoVentas: ['',],
      costoVentasDos: ['', ],
      utilidadBruta: ['', ],
      utilidadBrutaDos: ['', ],
      gastosAdministracion: ['', ],
      gastosAdministracionDos: ['', ],
      utilidadOperacional: ['',],
      utilidadOperacionalDos: ['',],
      ingresosNoOperacionales: ['',],
      ingresosNoOperacionalesDos: ['', ],
      gastosNoOperacio: ['',],
      gastosNoOperacioDos: ['', ],
      utilidadAntesImpuestos: ['', ],
      utilidadAntesImpuestosDos: ['', ],
      participacionTrabajadores: ['', ],
      participacionTrabajadoresDos: ['',],
      utilidadTributable: ['', ],
      utilidadTributableDos: ['',],
      impuestoARenta: ['', ],
      impuestoARentaDos: ['',],
      utilidadRepartible: ['',],
      utilidadRepartibleDos: ['', ],
      indiceFinancieroFecha: ['', ],
      indiceFinancieroFechaDos:['', ],
      razonCorriente: ['',],
      razonCorrienteDos: [''],
      razonCorrienteTres: ['', ],
      pruebaAcida: ['', ],
      pruebaAcidaDos: ['', ],
      pruebaAcidaTres: ['', ],
      razonEfectivo: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      razonEfectivoDos: ['', ],
      razonEfectivoTres: ['', ],
      indiceApalanca: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      indiceApalancaDos: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      indiceApalancaTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      razonDeudaTotal: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      razonDeudaTotalDos: ['', ],
      razonDeudaTotalTres: ['', ],
      indicesActividad: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      indicesActividadDos: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      indicesActividadTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      rotacionInventario: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      rotacionInventarioDos: ['', ],
      rotacionInventarioTres: ['', ],
      diasVenta: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      diasVentaDos: ['', ],
      diasVentaTres: ['', ],
      rotacionCuentas: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      rotacionCuentasDos: ['',],
      rotacionCuentasTres: ['', ],
      diasPromedio: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      diasPromedioDos: ['', ],
      diasPromedioTres: ['',],
      rotacionCuentasPagar: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      rotacionCuentasPagarDos: ['', ],
      rotacionCuentasPagarTres: ['', ],
      diasCuentasPagar: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      diasCuentasPagarDos: ['', ],
      diasCuentasPagarTres: ['', ],
      rotacionActivosTotales: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      rotacionActivosTotalesDos: ['', ],
      rotacionActivosTotalesTres: ['', ],
      indicesRentabilidad: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      indicesRentabilidadDos: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      indicesRentabilidadTres: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      margenUtilidad: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      margenUtilidadDos: ['', ],
      margenUtilidadTres: ['', ],
      rendimientoActivos: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      rendimientoActivosDos: ['', ],
      rendimientoActivosTres: ['', ],
      rendimientoCapital: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      rendimientoCapitalDos: ['', ],
      rendimientoCapitalTres: ['', ],
      cuentaFecha: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      cuentaFechaDos: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      activoCorriente: ['', ],
      activoCorrienteDos: ['', ],
      activoNoCorriente: ['', ],
      activoNoCorrienteDos: ['', ],
      totalActivos: ['', ],
      totalActivosDos: ['', ],
      pasivoCorriente: ['', ],
      pasivoCorrienteDos: ['', ],
      pasivoNoCorriente: ['', ],
      pasivoNoCorrienteDos: ['', ],
      totalPasivos: ['', ],
      totalPasivosDos: ['',],
      patrimonio: ['', ],
      patrimonioDos: ['', ],
      ventasCompleto: ['', ],
      ventasCompletoDos: ['', ],
      utilidadPerdida: ['', ],
      utilidadPerdidaDos: ['', ],
      capitalTrabajo: ['', ],
      capitalTrabajoDos: ['', ],
      valor1: ['', ],
      valor2: ['', ],
      valor3: ['', ],
      valor4: ['', ],
      valor5: ['', ],
      valor6: ['', ],
      valor7: ['', ],
      valor8: ['', ],
      valor9: ['', ],
      valor10: ['',],
      porcentaje1: ['',],
      porcentaje2: ['',],
      porcentaje3: ['',],
      porcentaje4: ['',],
      porcentaje5: ['',],
      porcentaje6: ['',],
      porcentaje7: ['',],
      porcentaje8: ['',],
      porcentaje9: ['',],
      porcentaje10: ['',],
      // Campos referencias comerciales

      nombreRefCompletoDos: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      direccionRefCompletoDos: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      telefonoRefCompletoDos: ['',],
      contactoRefCompletoDos: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      lineaCreditoRefCompletoDos: ['', [Validators.required]],
      pagosRefCompletoDos: ['', [Validators.required]],
      productoRefCompletoDos: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      clienteDesdeRefCompletoDos: ['', [Validators.required]],
      compraMensualRefCompletaDos: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      checksPuntualidadPagosDosU: ['', [Validators.required]],
      montosCredito: ['', [Validators.required]],
      montosCreditoDos: ['', [Validators.required]],

      // Graficos
      yearEvolucionCapitalSocialComple: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      EvolucionCapitalSocialComple: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      yearDosCapitalSocialComple: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern(this.numberPattern)]],
      dosevolucioncpComple: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      yearTresEvolucionCapitalSocialComple: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern(this.numberPattern)]],
      tresevolucioncpComple: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      yearCuatroEvolucionCapitalSocialComple: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern(this.numberPattern)]],
      cuartrovolucioncpComple: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      yearEvolucionRatingComple: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      yearDosEvolucionRatingComple: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      valorEvolucionRatingComple: [''],
      yearCuatroEvolucionRatingComple: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      valorDosEvolucionRatingComple: [''],
      valorTresEvolucionRatingComple: [''],
      escoreDosEvolucionRatingComple: [''],
      escoreTresEvolucionRatingComple: [''],
      escoreEvolucionRatingComple: [''],
      yearUnoEvolucionRatingComple: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      valorUnoEvolucionRatingComple: ['',],
      escoreUnoEvolucionRatingComple: ['',],
      yearTresEvolucionRatingComple: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      valorCuatroEvolucionRatingComple: [''],
      escoreCuatroEvolucionRatingComple: [''],
      yearEvolucionEmpleadosComple: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern(this.numberPattern)]],
      NumberEvolucionEmpleadosComple: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      yearDosEvolucionEmpleadosComple: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      NumberDosEvolucionEmpleadosComple: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      yearTresEvolucionEmpleadosComple: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      NumberTresEvolucionEmpleadosComple: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      yearCuatroEvolucionEmpleadosComple: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      NumberCuatroEvolucionEmpleadosComple: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      descriptionEmpresasCimpleto: ['', [Validators.required]],
      checksGrupoEconomicoCompleto: ['', [Validators.required]],
      descriptionGrupoEconomicoCompleto: ['', [Validators.required]],
      descriptionObligacionesLegales: ['', [Validators.required]],
      descriptionSuperintendencia: ['', [Validators.required]],


      fortaleza: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      fortaleza1: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      fortaleza2: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      fortaleza3: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      fortaleza4: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      fortaleza5: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      fortaleza6: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      fortaleza7: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      fortaleza8: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      fortaleza9: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      fortaleza10: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      fortalezacamp1: ['',],
      fortalezacamp2: ['',],
      fortalezacamp3: ['',],
      fortalezacamp4: ['',],
      fortalezacamp5: ['',],
      fortalezacamp6: ['',],
      fortalezacamp7: ['',],
      fortalezacamp8: ['',],
      fortalezacamp9: ['',],
      fortalezacamp10: ['',],
      fortalezacamp11: ['',],
      fortalezacamp12: ['',],
      fortalezacamp13: ['',],
      fortalezacamp14: ['',],
      fortalezacamp15: ['',],
     

      debilidad: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      debilidad1: ['', ],
      debilidad2: ['', ],
      debilidad3: ['', ],
      debilidad4: ['', ],
      debilidad5: ['', ],
      debilidad6: ['', ],
      debilidad7: ['', ],
      debilidad8: ['', ],
      debilidad9: ['', ],
      debilidad10: ['', ],
      debilidad11: ['', ],
      debilidad12: ['', ],
      debilidad13: ['', ],
      debilidad14: ['', ],
      debilidad15: ['', ],
    
      observacionesCompleto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(200), Validators.pattern(this.textPattern)]],
      checksListaClinton: ['',],
      calificaUnoComple: ['',],
      calificaDosComple: ['',],
      calificaTresComple: ['',],
      calificaCuatroComple:['',],
      calificaCincoComple: ['',],
      calificaSeisComple: ['',],
      calificaSieteComple: ['',],
      calificaOchoComple: ['',],

      // campos Forta-Debil
      checksRazonCorrienteEs:['',],
      checksRazonDeudaEs: ['',],
      checksDiaspromedioCobrar: ['',],
      checksDiaspromedioPagar:['',],
      checksRendimientoActivo:['',],
      checksVentasMayor10mil: ['',],
      checksVentas5: ['',],
      checksUtilidad5:['',],
      checksOperacion10:['',],
      checksRegistraJuicios:['',],
      checksPagaAtiempo: ['',],
      //campos nuevos
      IniciodeActividadesOtros:[''],
      descriptionInfraCompleto:[''],

    });
  }
  listaProvincias: Array<any> = [
    {
      name: "AZUAY", ciudades: ["CUENCA", "GIRÓN", "GUALACEO", "NABÓN", "PAUTE", "PUCARA", "SAN FERNANDO", "SANTA ISABEL", "SIGSIG", "OÑA", "CHORDELEG", "EL PAN", "SEVILLA DE ORO", "GUACHAPALA", "CAMILO PONCE ENRÍQUEZ"
      ]
    }, {
      name: "BOLIVAR", ciudades: ["GUARANDA", "CHILLANES", "CHIMBO", "ECHEANDÍA", "SAN MIGUEL", "CALUMA", "LAS NAVES"
      ]
    }, {
      name: "CAÑAR", ciudades: ["AZOGUES", "BIBLIÁN", "CAÑAR", "LA TRONCAL", "EL TAMBO", "DÉLEG", "SUSCAL"
      ]
    }, {
      name: "CARCHI", ciudades: ["TULCÁN", "BOLÍVAR", "ESPEJO", "MIRA", "MONTÚFAR", "SAN PEDRO DE HUACA"
      ]
    }, {
      name: "COTOPAXI", ciudades: ["LATACUNGA", "LA MANÁ", "PANGUA", "PUJILI", "SALCEDO", "SAQUISILÍ", "SIGCHOS"
      ]
    }, {
      name: "CHIMBORAZO", ciudades: ["RIOBAMBA", "ALAUSI", "COLTA", "CHAMBO", "CHUNCHI", "GUAMOTE", "GUANO", "PALLATANGA", "PENIPE", "CUMANDÁ"
      ]
    }, {
      name: "EL ORO", ciudades: ["MACHALA", "ARENILLAS", "ATAHUALPA", "BALSAS", "CHILLA", "EL GUABO", "HUAQUILLAS", "MARCABELÍ", "PASAJE", "PIÑAS", "PORTOVELO", "SANTA ROSA", "ZARUMA", "LAS LAJAS"
      ]
    }, {
      name: "ESMERALDAS", ciudades: ["ESMERALDAS", "ELOY ALFARO", "MUISNE", "QUININDÉ", "SAN LORENZO", "ATACAMES", "RIOVERDE", "LA CONCORDIA"
      ]
    }, {
      name: "GUAYAS", ciudades: ["GUAYAQUIL", "ALFREDO BAQUERIZO MORENO (JUJÁN)", "BALAO", "BALZAR", "COLIMES", "DAULE", "DURÁN", "EL EMPALME", "EL TRIUNFO", "MILAGRO", "NARANJAL", "NARANJITO", "PALESTINA", "PEDRO CARBO", "SAMBORONDÓN", "SANTA LUCÍA", "SALITRE (URBINA JADO)", "SAN JACINTO DE YAGUACHI", "PLAYAS", "SIMÓN BOLÍVAR", "CORONEL MARCELINO MARIDUEÑA", "LOMAS DE SARGENTILLO", "NOBOL", "GENERAL ANTONIO ELIZALDE", "ISIDRO AYORA"
      ]
    }, {
      name: "IMBABURA", ciudades: ["IBARRA", "ANTONIO ANTE", "COTACACHI", "OTAVALO", "PIMAMPIRO", "SAN MIGUEL DE URCUQUÍ"
      ]
    }, {
      name: "LOJA", ciudades: ["LOJA", "CALVAS", "CATAMAYO", "CELICA", "CHAGUARPAMBA", "ESPÍNDOLA", "GONZANAMÁ", "MACARÁ", "PALTAS", "PUYANGO", "SARAGURO", "SOZORANGA", "ZAPOTILLO", "PINDAL", "QUILANGA", "OLMEDO"
      ]
    }, {
      name: "LOS RIOS", ciudades: ["BABAHOYO", "BABA", "MONTALVO", "PUEBLOVIEJO", "QUEVEDO", "URDANETA", "VENTANAS", "VÍNCES", "PALENQUE", "BUENA FÉ", "VALENCIA", "MOCACHE", "QUINSALOMA"
      ]
    }, {
      name: "MANABI", ciudades: ["PORTOVIEJO", "BOLÍVAR", "CHONE", "EL CARMEN", "FLAVIO ALFARO", "JIPIJAPA", "JUNÍN", "MANTA", "MONTECRISTI", "PAJÁN", "PICHINCHA", "ROCAFUERTE", "SANTA ANA", "SUCRE", "TOSAGUA", "24 DE MAYO", "PEDERNALES", "OLMEDO", "PUERTO LÓPEZ", "JAMA", "JARAMIJÓ", "SAN VICENTE"
      ]
    }, {
      name: "MORONA SANTIAGO", ciudades: ["MORONA", "GUALAQUIZA", "LIMÓN INDANZA", "PALORA", "SANTIAGO", "SUCÚA", "HUAMBOYA", "SAN JUAN BOSCO", "TAISHA", "LOGROÑO", "PABLO SEXTO", "TIWINTZA"
      ]
    }, {
      name: "NAPO", ciudades: ["TENA", "ARCHIDONA", "EL CHACO", "QUIJOS", "CARLOS JULIO AROSEMENA TOLA"
      ]
    }, {
      name: "PASTAZA", ciudades: ["PASTAZA", "MERA", "SANTA CLARA", "ARAJUNO"
      ]
    }, {
      name: "PICHINCHA", ciudades: ["QUITO", "CAYAMBE", "MEJIA", "PEDRO MONCAYO", "RUMIÑAHUI", "SAN MIGUEL DE LOS BANCOS", "PEDRO VICENTE MALDONADO", "PUERTO QUITO"
      ]
    }, {
      name: "TUNGURAHUA", ciudades: ["AMBATO", "BAÑOS DE AGUA SANTA", "CEVALLOS", "MOCHA", "PATATE", "QUERO", "SAN PEDRO DE PELILEO", "SANTIAGO DE PÍLLARO", "TISALEO"
      ]
    }, {
      name: "ZAMORA CHINCHIPE", ciudades: ["ZAMORA", "CHINCHIPE", "NANGARITZA", "YACUAMBI", "YANTZAZA (YANZATZA)", "EL PANGUI", "CENTINELA DEL CÓNDOR", "PALANDA", "PAQUISHA"
      ]
    }, {
      name: "GALAPAGOS", ciudades: ["SAN CRISTÓBAL", "ISABELA", "SANTA CRUZ"
      ]
    }, {
      name: "SUCUMBIOS", ciudades: ["LAGO AGRIO", "GONZALO PIZARRO", "PUTUMAYO", "SHUSHUFINDI", "SUCUMBÍOS", "CASCALES", "CUYABENO"
      ]
    }, {
      name: "ORELLANA", ciudades: ["ORELLANA", "AGUARICO", "LA JOYA DE LOS SACHAS", "LORETO"
      ]
    }, {
      name: "SANTO DOMINGO DE LOS TSACHILAS", ciudades: ["SANTO DOMINGO"
      ]
    }, { name: "SANTA ELENA", ciudades: ["SANTA ELENA", "LA LIBERTAD", "SALINAS"] },
  ];
  ciudades: Array<any>;
  cambioProvincia(count) {
    this.ciudades = this.listaProvincias.find(con => con.name == count).ciudades;
  }

  addInformeCompleto() {
    this.submitted = true;
    this.save();
  }
  private save(): void {
    console.log(this.formularioCompleto);
    this.completoService.addInformeCompleto(this.formularioCompleto)
      .subscribe();
    Swal.fire(
      'Muy Bien',
      'Datos Guardados Éxitosamente',
      'success'
    );
  }

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    plugins: {
      datalabels:{
        color: 'transparent',},

      labels: {
        display: false,
        
    }
      }
      
  };



  public barChartLabels = ['Activo Corriente', 'Activo no corriente', 'Total de activos', 'Pasivo Corriente', 'Pasivo no corriente', 'Total pasivos', 'Patrimonio', 'Ventas', 'Utilidad/Perdida', 'Capital de trabajo'];
  public barChartType = 'bar';
  public barChartLegend = true;
  public barChartData = [
    { data: [0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Año 1' },
    { data: [0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Año 2' }
  ];

  //Pasteles
  public pieChartType = 'pie';

  public barChartPlugins = [pluginDataLabels];
  public pieChartOptions = {
    responsive: true,
    plugins: {
      datalabels: {
        formatter: function (value, context) {
          return Math.round(value) + '%';
        }
      }
    }
  }
  //Activo Corriente
  public pieChartLabelsUno = ['CAJA,BANCOS', 'INV. CORRIENTES', 'CXC RELAC','CXC NO RELAC', 'OTRAS CXC RELAC','OTRAS CXC NO RELAC', 'PROV C.INCOBRABLES', 'CREDITO TRIBUTARIO','INVENTARIOS', 'ACT PAGADO POR ANTIC', 'OTROS ACT.CORR'];
  public pieChartDataUno = [0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0]
  //Activo Largo Plazo
  public pieChartLabelsDos = ['T ACT. FIJO ','T ACT. DIFERIDO ','INV NO CORRIENTES','INV NO CORR OTRAS ','CXC CLIENTES RELAC','CXC CLIEN NO CORR NO RELAC','OTRAS CXC CLIEN NO CORR RELAC','OTRAS CXC CLIEN NO CORR NO RELAC','(-) PROV CTS INCOBRABLES', 'OTROS ACT NO CORR'];
  public pieChartDataDos = [0, 0, 0, 0, 0,0, 0, 0, 0, 0];
  //Activo del pasivo corriente
  public pieChartLabelsTres =['CXP PROVEE CORR RELAC','CXP PROVEE CORR NO RELAC','OBLIGACIONES INST FINANCIERAS','PRESTAMOS ACCIONISTAS ', 'OTRAS CXP CORR RELAC','OTRAS CXP CORRNO RELAC','IMPUESTO A LA RENTA POR PAGAR', 'PART TRABAJADORES X PAGAR','OTROS PASIVOS CORRIENTES'];
  public pieChartDataTres = [0, 0, 0, 0, 0,0, 0, 0, 0];
  //Pasivo Largo plazo
  public pieChartLabelsCuatro = ['CXP PROVEE NO CORR RELAC','CXP PROVEE NO CORR NO RELAC ','OBLIGACIONES INST FINANCIERAS', 'PRESTAMOS ACCIONISTAS','OTRAS CXP NO CORR RELAC', 'OTRAS CXP NO CORR NO RELAC','OTROS PASIVOS NO CORRIENTES'
];
  public pieChartDataCuatro = [0, 0, 0, 0, 0,0,0];
  //Patrimonio
  public pieChartLabelsCinco = ['CAPITAL SUSC Y/O ASIG','OTRS RESULT  INTEGRALES ','APORTES DE SOCIOS O ACCIONISTAS','RESERVA LEGAL-FACULTATIVA','RESULT ACUM DE  NIIF','UTILIDAD NO DISTRI EJER ANT','(-) PERDIDA ACUM EJER ANT','UTILIDAD DEL EJERCICIO',
];
  public pieChartDataCinco = [0, 0, 0, 0, 0,0, 0, 0];


  ngOnInit() {
 
    this.myFunction1();
    this.cargarInformes();
    init_plugins();
   
    
    //  this.leyendas();
    const id = this.route.snapshot.paramMap.get('id');
    console.log(id);
    this.getInformeCompleto(id);
    this.idFormulario = this.route.snapshot.paramMap.get('id');
    this.usuario = this._usuarioService.usuario;
    console.log(this.formularioCompleto.checksCalificaciones)
    this.getTareas();
    //  this.myFunction()



    // Grafico de capital
   
      if(this.formularioCompleto.EvolucionCapitalSocialComple != null && this.formularioCompleto.dosevolucioncpComple != null && this.formularioCompleto.tresevolucioncpComple != null && this.formularioCompleto.cuartrovolucioncpComple != null){ 
      
          this.capital1.push(parseFloat(this.formularioCompleto.EvolucionCapitalSocialComple.replace(/,/g,"")));
          this.capital1.push(parseFloat(this.formularioCompleto.dosevolucioncpComple.replace(/,/g,"")));
          this.capital1.push(parseFloat(this.formularioCompleto.tresevolucioncpComple.replace(/,/g,"")));
          this.capital1.push(parseFloat(this.formularioCompleto.cuartrovolucioncpComple.replace(/,/g,"")));
     
      };
      this.date1.push(this.formularioCompleto.yearEvolucionCapitalSocialComple, this.formularioCompleto.yearDosCapitalSocialComple,
        this.formularioCompleto.yearTresEvolucionCapitalSocialComple, this.formularioCompleto.yearCuatroEvolucionCapitalSocialComple);
      
      this.chart = new Chart('canvas', {
        type: 'bar',
        data: {
          labels: this.date1,
          datasets: [
            {
              data: this.capital1,
              backgroundColor: [
                'rgba(128, 128, 128)',
                'rgba(95, 158, 160)',
                'rgba(70, 130, 180)',
                'rgba(176, 196, 222)'
              ],
              fill: true
            }
          ],
        },
        options: {
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              barPercentage: 1,
              categoryPercentage: 0.6,
              display: true
            }],
            yAxes: [{
              ticks: {

                beginAtZero: true
              },
              display: true
            }]
          }
        }
      });
    


    //grafico de empleados
 
        this.capital2.push(this.formularioCompleto.NumberEvolucionEmpleadosComple);
        this.capital2.push(this.formularioCompleto.NumberDosEvolucionEmpleadosComple);
        this.capital2.push(this.formularioCompleto.NumberTresEvolucionEmpleadosComple);
        this.capital2.push(this.formularioCompleto.NumberCuatroEvolucionEmpleadosComple);
 
      this.date2.push(this.formularioCompleto.yearEvolucionEmpleadosComple, this.formularioCompleto.yearDosEvolucionEmpleadosComple, this.formularioCompleto.yearTresEvolucionEmpleadosComple, this.formularioCompleto.yearCuatroEvolucionEmpleadosComple);
     
      this.chart1 = new Chart('canvas1', {
        type: 'bar',
        data: {
          labels: this.date2,
          datasets: [
            {
              data: this.capital2,
              label: 'Evolución de empleados',
              backgroundColor: [
                'rgba(128, 128, 128)',
          'rgba(95, 158, 160)',
          'rgba(70, 130, 180)',
          'rgba(176, 196, 222)'
              ],
              fill: true
            }
          ],
        },
        options: {
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              barPercentage: 1,
              categoryPercentage: 0.6,
              display: true
            }],
            yAxes: [{
              ticks: {

                beginAtZero: true
              },
              display: true
            }]
          }
        }
      });
    


    //graficos de scores

        this.capital3.push(this.formularioCompleto.valorEvolucionRatingComple);
        this.capital3.push(this.formularioCompleto.valorUnoEvolucionRatingComple);
        this.capital3.push(this.formularioCompleto.valorDosEvolucionRatingComple);
        this.capital3.push(this.formularioCompleto.valorTresEvolucionRatingComple);
        this.capital3.push(this.formularioCompleto.valorCuatroEvolucionRatingComple);
    

      this.date3.push(this.formularioCompleto.yearEvolucionRatingComple, this.formularioCompleto.yearUnoEvolucionRatingComple, this.formularioCompleto.yearDosEvolucionRatingComple,
        this.formularioCompleto.yearTresEvolucionRatingComple, this.formularioCompleto.yearCuatroEvolucionRatingComple);
      
      this.chart3 = new Chart('canvas3', {
        type: 'bar',
        data: {
          labels: this.date3,
          datasets: [
            {
              data: this.capital3,
              backgroundColor: [
                'rgba(128, 128, 128)',
                'rgba(95, 158, 160)',
                'rgba(70, 130, 180)',
                'rgba(176, 196, 222)',
                'rgba(176, 224, 230)'
              ],
              fill: true
            }
          ],
        },
        options: {
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              barPercentage: 1,
              categoryPercentage: 0.6,
              display: true
            }],
            yAxes: [{
              ticks: {

                beginAtZero: true
              },
              display: true
            }]
          }
        }
      });
   
   

  }
  getInformeCompleto(id) {

    this.completoService.getInformeCompleto(id).subscribe(
      informesCompletos => {
    
        console.log(informesCompletos);
        this.formularioCompleto = informesCompletos;
       
      }
    );
  }

  back() {
    setTimeout(() => {
      this.location.back();
    }, 1400);
  }

  getTareas() {
    this._tareaService.getTareas().subscribe(tasks => {
      this.tareas = tasks
    });
  }
  update(estadoForm: String): void {
    for (var e in this.tareas) {
      if (this.tareas[e]._idFormulario == this.idFormulario && this.tareas[e]._idResponsable == this.usuario._id) {
        this.tareaActual = this.tareas[e];
      }
    }
    this.tareaActual["id"] = this.tareaActual._id
    this.tareaActual.estado = estadoForm;
 
    this._tareaService.updateTarea(this.tareaActual).subscribe();
    this.submitted = true;
    this.completoService.updateInformeCompleto(this.formularioCompleto).subscribe(result =>
      {  this.message = "Se Actualizo el dato"
    
    
    });
    Swal.fire(
      'Muy Bien',
      'Datos Actualizados Correctamente',
      'success'
    );
   
    //this.router.navigateByUrl("/admin"); 
    //   this.back();

  }

  update2(){
    this.completoService.updateInformeCompleto(this.formularioCompleto).subscribe(result =>{ 
      console.log(result,'GERMAN ESTA EDITANDO')
      this.message = "Se Actualizo el dato"
    
    
    });
    Swal.fire(
      'Muy Bien',
      'Datos Actualizados Correctamente',
      'success'
    );


  }


  delete(): void {

    this.submitted = true;

    this.completoService.deleteInformeCompleto(this.formularioCompleto._id).subscribe
    (result => {this.message = "Se elimino"
    console.log(result)
  });

    Swal.fire(

      'Eliminado',
      'Datos Eliminados Correctamente',
      'success'
    );
    this.router.navigateByUrl("/inicioOperador", { skipLocationChange: true });
     this.back();



  }



  delete1(): void {

    this.submitted = true;

    this.completoService.deleteInformeCompleto(this.formularioCompleto._id).subscribe(
     
      result => this.message = "Se eliminó con éxito");
     
    Swal.fire('Eliminado','Datos Eliminados Correctamente','success');
   // this.router.navigateByUrl("/inicioOperador", { skipLocationChange: true });
    this.back();



  }

  onResetForm() {
    this.reporteCompleto.reset();
  }
  //funciones anandidas para mongoDB
  newProveedor(): void {
    this.submitted = false;
    this.formularioCompleto = new formCompleto();
  }
  /**
       * 0 Caja, banco
       * 1 Inversiones corrientes
       * 2 Cuetas por cobrar
       * 3 Inventario
       * 4 Activos pagados por anticipado
       * 5 Otros activos corrientes
       */
  public pieChartData = {
    uno: [],
    dos: [],
    tres: [],
    cuatro: [],
    cinco: []
  };
  public porCobrar = []
  /**
   * 
   * @param value Valor del input para agregar al array
   * @param position Indica la posición que ocupará dentro del array
   */
  public temoralSum = 0;
 

  /*getInputValues(pieChartName, value, position: number, needSum, numOrder: number) {
    if (!(Array.isArray(this.pieChartData[pieChartName]))) {
      this.pieChartData[pieChartName] = []
    }
    //Se comprueba si se necesitan sumar los número para el gráfico
    if (!(Array.isArray(this.pieChartData[pieChartName]['numbers']))) {
      this.pieChartData[pieChartName]['numbers'] = []
    }
    if (typeof needSum !== 'undefined') {
      if (!(Array.isArray(this.pieChartData[pieChartName][needSum]))) {
        this.pieChartData[pieChartName][needSum] = []
      }
      this.pieChartData[pieChartName][needSum][numOrder] = parseFloat(value);
      var suma = 0;
      for (var e in this.pieChartData[pieChartName][needSum]) {
        if (isNaN(this.pieChartData[pieChartName][needSum][e])) {
          this.pieChartData[pieChartName][needSum][e] = 0;
        }
        suma += this.pieChartData[pieChartName][needSum][e];
      }
      this.pieChartData[pieChartName]['numbers'][position] = suma;
    }
    else {
      this.pieChartData[pieChartName]['numbers'][position] = parseFloat(value);
    }
    this.pieChartData[pieChartName]['sumaTotal'] = 0
    this.pieChartData[pieChartName]['porcentajes'] = []
    for (var j = 0; j < this.pieChartData[pieChartName]['numbers'].length; j++) {
      if (this.pieChartData[pieChartName]['numbers'][j] == null) {
        this.pieChartData[pieChartName]['numbers'][j] = 0;
      }
      this.pieChartData[pieChartName]['sumaTotal'] += this.pieChartData[pieChartName]['numbers'][j];
      //console.log(sumaTotal);
    }
    for (e in this.pieChartData[pieChartName]['numbers']) {
      this.pieChartData[pieChartName]['porcentajes'][e] = ((this.pieChartData[pieChartName]['numbers'][e] / this.pieChartData[pieChartName]['sumaTotal']));
    }
    if (pieChartName == 'uno') {
      this.pieChartDataUno = this.pieChartData[pieChartName]['porcentajes'];
      console.log(this.pieChartDataUno);
    } else if (pieChartName == 'dos') {
      this.pieChartDataDos = this.pieChartData[pieChartName]['porcentajes'];
      console.log(this.pieChartDataDos);
    } else if (pieChartName == 'tres') {
      this.pieChartDataTres = this.pieChartData[pieChartName]['porcentajes'];
      console.log(this.pieChartDataTres);
    } else if (pieChartName == 'cuatro') {
      this.pieChartDataCuatro = this.pieChartData[pieChartName]['porcentajes'];
      console.log(this.pieChartDataCuatro);
    } else if (pieChartName == 'cinco') {
      this.pieChartDataCinco = this.pieChartData[pieChartName]['porcentajes'];
      console.log(this.pieChartDataCinco);
    }
    console.log(this.pieChartData[pieChartName]);
  }*/
  
  public barChart = {
    uno: [],
    dos: []
  }
  quitarCero() {
    var prop = Object.keys(this.formularioCompleto);
    for (var i = 192; i <= 325; i++) {
      if (this.formularioCompleto[prop[i]] == 0) {
        this.formularioCompleto[prop[i]] = null;
      }
    }
  }
  // porcentaje activo corriente
   porcentaje1A
   porcentaje2A
   porcentaje3A
   porcentaje4A
   porcentaje5A
   porcentaje6A
   porcentaje7A
   porcentaje8A
   porcentaje9A
   porcentaje10A
   porcentaje11A
   //porcentaje activo corriente 2
   porcentaje1A2
   porcentaje2A2
   porcentaje3A2
   porcentaje4A2
   porcentaje5A2
   porcentaje6A2
   porcentaje7A2
   porcentaje8A2
   porcentaje9A2
   porcentaje10A2
   porcentaje11A2
   //porcentaje activo largo plazo
   porcentaje1Alp
   porcentaje2Alp
   porcentaje3Alp
   porcentaje4Alp
   porcentaje5Alp
   porcentaje6Alp
   porcentaje7Alp
   porcentaje8Alp
   //porcentaje activo largo plazo 2
   porcentaje1Alp2
   porcentaje2Alp2
   porcentaje3Alp2
   porcentaje4Alp2
   porcentaje5Alp2
   porcentaje6Alp2
   porcentaje7Alp2
   porcentaje8Alp2
   //porcentaje pasivo corriente 1
   porcentaje1Pc1
   porcentaje2Pc1
   porcentaje3Pc1
   porcentaje4Pc1
   porcentaje5Pc1
   porcentaje6Pc1
   porcentaje7Pc1
   porcentaje8Pc1
   porcentaje9PC1
   //porcentahe pasivo corriente 2
   porcentaje1Pc2
   porcentaje2Pc2
   porcentaje3Pc2
   porcentaje4Pc2
   porcentaje5Pc2
   porcentaje6Pc2
   porcentaje7Pc2
   porcentaje8Pc2
   porcentaje9PC2
   //porcentaje pasivo largo plazo 1
   porcentaje1Plp1
   porcentaje2Plp1
   porcentaje3Plp1
   porcentaje4Plp1
   porcentaje5Plp1
   porcentaje6Plp1
   porcentaje7Plp1
   //porcentaje pasivo largo plazo 2
   porcentaje1Plp2
   porcentaje2Plp2
   porcentaje3Plp2
   porcentaje4Plp2
   porcentaje5Plp2
   porcentaje6Plp2
   porcentaje7Plp2
  
   sumaCuentas 
   sumaCuentas1
   sumaRotacionCuentas1
   sumaRotacionCuentas2
  // Inicializar

 // Inicializar porcentaje patrimonio neto
 porcentaje1Pn1
 porcentaje2Pn1
 porcentaje3Pn1
 porcentaje4Pn1
 porcentaje5Pn1
 porcentaje6Pn1
 porcentaje7Pn1
 porcentaje8Pn1
 // patrimonio2
 porcentaje1Pn2
 porcentaje2Pn2
 porcentaje3Pn2
 porcentaje4Pn2
 porcentaje5Pn2
 porcentaje6Pn2
 porcentaje7Pn2
 porcentaje8Pn2

 //porcentajes Finales 1
 porcentajeAcorriente1
 porcentajeTotalAFijo1
 porcentajeTotalADiferido1
 porcentajeTotalAlargoPlazo1
 porcentajeTotalActivo1
 porcentajeTotalPasivoC1
 porcentajeTotalPasivoLargoP1
 porcentajeOtrosPasivos1
 porcentajeTotalPasivo1
 porcentajeTotalPatrimonioN1
 porcentajePatrimonioPasivo1
 
 //porcentajes Finales 2
 porcentajeAcorriente2
 porcentajeTotalAFijo2
 porcentajeTotalADiferido2
 porcentajeTotalAlargoPlazo2
 porcentajeTotalActivo2
 porcentajeTotalPasivoC2
 porcentajeTotalPasivoLargoP2
 porcentajeOtrosPasivos2
 porcentajeTotalPasivo2
 porcentajeTotalPatrimonioN2
 porcentajePatrimonioPasivo2

 porcentaje1Tn1
 porcentaje2Tpn1
 porcentaje1Tpn2
 porcentaje2Tpn2
 //porcentajporcentaje1Er1e estado resultados 1
 porcentaje1Er1
 porcentaje2Er1
 porcentaje3Er1
 porcentaje4Er1
 porcentaje5Er1
 porcentaje6Er1
 porcentaje7Er1
 porcentaje8Er1
 porcentaje9Er1
 porcentaje10Er1
 porcentaje11Er1
 //porcentaje estado de resultado 2
 porcentaje1Er2
 porcentaje2Er2
 porcentaje3Er2
 porcentaje4Er2
 porcentaje5Er2
 porcentaje6Er2
 porcentaje7Er2
 porcentaje8Er2
 porcentaje9Er2
 porcentaje10Er2
 porcentaje11Er2


   
   sumarTotal() {
    const formatterDolar = new Intl.NumberFormat('en-US', {
      currency: 'USD'
    })
    if (isNaN(parseFloat(this.formularioCompleto.cajaBancosCompleto))) {this.formularioCompleto.cajaBancosCompleto = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.inversionesCorrientes))) {this.formularioCompleto.inversionesCorrientes = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.cuentasRelacionadas))) {this.formularioCompleto.cuentasRelacionadas = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.cuentasNoRelacionadas))) {this.formularioCompleto.cuentasNoRelacionadas = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.otrasCuentasRelacionadas))) {this.formularioCompleto.otrasCuentasRelacionadas = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.otrasCuentasNoRelacionadas))) {this.formularioCompleto.otrasCuentasNoRelacionadas = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.provisionCuentas))) {this.formularioCompleto.provisionCuentas = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.creditoTributario))) {this.formularioCompleto.creditoTributario = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.inventariosCompleto))) {this.formularioCompleto.inventariosCompleto = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.activosPagados))) {this.formularioCompleto.activosPagados = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.otrosActivosCorrientes))) {this.formularioCompleto.otrosActivosCorrientes = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.totalActivoFijo))) {this.formularioCompleto.totalActivoFijo = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.totalActivoDiferido))) {this.formularioCompleto.totalActivoDiferido = "0";}
    

    if (isNaN(parseFloat(this.formularioCompleto.cajaBancosCompletoDos))) {this.formularioCompleto.cajaBancosCompletoDos = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.inversionesCorrientesDos))) {this.formularioCompleto.inversionesCorrientesDos = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.cuentasRelacionadasDos))) {this.formularioCompleto.cuentasRelacionadasDos = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.cuentasNoRelacionadasDos))) {this.formularioCompleto.cuentasNoRelacionadasDos = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.otrasCuentasRelacionadasDos))) {this.formularioCompleto.otrasCuentasRelacionadasDos = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.otrasCuentasNoRelacionadasDos))) {this.formularioCompleto.otrasCuentasNoRelacionadasDos = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.provisionCuentasDos))) {this.formularioCompleto.provisionCuentasDos = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.creditoTributarioDos))) {this.formularioCompleto.creditoTributarioDos = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.inventariosCompletoDos))) {this.formularioCompleto.inventariosCompletoDos = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.activosPagadosDos))) {this.formularioCompleto.activosPagadosDos = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.otrosActivosCorrientesDos))) {this.formularioCompleto.otrosActivosCorrientesDos = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.totalActivoFijoDos))) {this.formularioCompleto.totalActivoFijoDos = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.totalActivoDiferidoDos))) {this.formularioCompleto.totalActivoDiferidoDos = "0";}

    
    if (isNaN(parseFloat(this.formularioCompleto.inversionesParticipaciones))) {this.formularioCompleto.inversionesParticipaciones = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.otrasInversionesLargoPlazo))) {this.formularioCompleto.otrasInversionesLargoPlazo = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.cuentasPorCobrarRelacionadas))) {this.formularioCompleto.cuentasPorCobrarRelacionadas = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.cuentasPorCobrarNoRelacionadas))) {this.formularioCompleto.cuentasPorCobrarNoRelacionadas = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.otrasCuentasPorCobrarRelacionadas))) {this.formularioCompleto.otrasCuentasPorCobrarRelacionadas = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.otrasCuentasPorCobrarNoRelacionadas))) {this.formularioCompleto.otrasCuentasPorCobrarNoRelacionadas = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.provisionCuentasIncobrables))) {this.formularioCompleto.provisionCuentasIncobrables = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.otrosActivosLargoPlazo))) {this.formularioCompleto.otrosActivosLargoPlazo = "0";}

    if (isNaN(parseFloat(this.formularioCompleto.inversionesParticipacionesDos))) {this.formularioCompleto.inversionesParticipacionesDos = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.otrasInversionesLargoPlazoDos))) {this.formularioCompleto.otrasInversionesLargoPlazoDos = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.cuentasPorCobrarRelacionadasDos))) {this.formularioCompleto.cuentasPorCobrarRelacionadasDos = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.cuentasPorCobrarNoRelacionadasDos))) {this.formularioCompleto.cuentasPorCobrarNoRelacionadasDos = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.otrasCuentasPorCobrarRelacionadasDos))) {this.formularioCompleto.otrasCuentasPorCobrarRelacionadasDos = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.otrasCuentasPorCobrarNoRelacionadasDos))) {this.formularioCompleto.otrasCuentasPorCobrarNoRelacionadasDos = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.provisionCuentasIncobrablesDos))) {this.formularioCompleto.provisionCuentasIncobrablesDos = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.otrosActivosLargoPlazoDos))) {this.formularioCompleto.otrosActivosLargoPlazoDos = "0";}
    //valores a probar primer Año
    if (isNaN(parseFloat(this.formularioCompleto.cuentasProveedoresRelacionado))) {this.formularioCompleto.cuentasProveedoresRelacionado = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.cuentasProveedoresNoRelacionado))) {this.formularioCompleto.cuentasProveedoresNoRelacionado = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.obligacionesFinancieras))) {this.formularioCompleto.obligacionesFinancieras = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.prestamosAccionistas))) {this.formularioCompleto.prestamosAccionistas = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.otrasCuentasPagarRelacionadas))) {this.formularioCompleto.otrasCuentasPagarRelacionadas = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.otrasCuentasPagarNoRelacionadas))) {this.formularioCompleto.otrasCuentasPagarNoRelacionadas = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.impuestoRentaEjercito))) {this.formularioCompleto.impuestoRentaEjercito = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.participacionEjercito))) {this.formularioCompleto.participacionEjercito = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.otrosPasivosCorrientes))) {this.formularioCompleto.otrosPasivosCorrientes = "0";}
    //otros valores primer año
    if (isNaN(parseFloat(this.formularioCompleto.cuentasPorPagar))) {this.formularioCompleto.cuentasPorPagar = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.cuentasPorPagarNoRela))) {this.formularioCompleto.cuentasPorPagarNoRela = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.obligacionesInstituFinan))) {this.formularioCompleto.obligacionesInstituFinan = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.prestamosLargoPlazo))) {this.formularioCompleto.prestamosLargoPlazo = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.otrasCuentasLargoPlazo))) {this.formularioCompleto.otrasCuentasLargoPlazo = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.otrasCuentasPorPagar))) {this.formularioCompleto.otrasCuentasPorPagar = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.otrosPasivosLargoPlazo))) {this.formularioCompleto.otrosPasivosLargoPlazo = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.otrosPasivos))) {this.formularioCompleto.otrosPasivos = "0";}
   
    //patrimonio neto primer año
    if (isNaN(parseFloat(this.formularioCompleto.capitalSuscrito))) {this.formularioCompleto.capitalSuscrito = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.capitalSuscritoNoPaga))) {this.formularioCompleto.capitalSuscritoNoPaga = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.aportesSocios))) {this.formularioCompleto.aportesSocios = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.reservaLegal))) {this.formularioCompleto.reservaLegal = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.otrasReservas))) {this.formularioCompleto.otrasReservas = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.utilidadNoDistribuida))) {this.formularioCompleto.utilidadNoDistribuida = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.perdidaAcumulada))) {this.formularioCompleto.perdidaAcumulada = "0";}
    if (isNaN(parseFloat(this.formularioCompleto.utilidadEjercito))) {this.formularioCompleto.utilidadEjercito = "0";}
         //estado de resultados
         if (isNaN(parseFloat(this.formularioCompleto.ventasTotales))) {this.formularioCompleto.ventasTotales = "0";}
         if (isNaN(parseFloat(this.formularioCompleto.costoVentas))) {this.formularioCompleto.costoVentas = "0";}
         if (isNaN(parseFloat(this.formularioCompleto.gastosAdministracion))) {this.formularioCompleto.gastosAdministracion = "0";}
         if (isNaN(parseFloat(this.formularioCompleto.ingresosNoOperacionales))) {this.formularioCompleto.ingresosNoOperacionales = "0";}
         if (isNaN(parseFloat(this.formularioCompleto.gastosNoOperacio))) {this.formularioCompleto.gastosNoOperacio = "0";}
         if (isNaN(parseFloat(this.formularioCompleto.participacionTrabajadores))) {this.formularioCompleto.participacionTrabajadores = "0";}
         if (isNaN(parseFloat(this.formularioCompleto.impuestoARenta))) {this.formularioCompleto.impuestoARenta = "0";}

    //fin de valores a probar

      //valores a probar segundo Año
      if (isNaN(parseFloat(this.formularioCompleto.cuentasProveedoresRelacionadoDos))) {this.formularioCompleto.cuentasProveedoresRelacionadoDos = "0";}
      if (isNaN(parseFloat(this.formularioCompleto.cuentasProveedoresNoRelacionadoDos))) {this.formularioCompleto.cuentasProveedoresNoRelacionadoDos = "0";}
      if (isNaN(parseFloat(this.formularioCompleto.obligacionesFinancierasDos))) {this.formularioCompleto.obligacionesFinancierasDos = "0";}
      if (isNaN(parseFloat(this.formularioCompleto.prestamosAccionistasDos))) {this.formularioCompleto.prestamosAccionistasDos = "0";}
      if (isNaN(parseFloat(this.formularioCompleto.otrasCuentasPagarRelacionadasDos))) {this.formularioCompleto.otrasCuentasPagarRelacionadasDos = "0";}
      if (isNaN(parseFloat(this.formularioCompleto.otrasCuentasPagarNoRelacionadasDos))) {this.formularioCompleto.otrasCuentasPagarNoRelacionadasDos = "0";}
      if (isNaN(parseFloat(this.formularioCompleto.impuestoRentaEjercitoDos))) {this.formularioCompleto.impuestoRentaEjercitoDos = "0";}
      if (isNaN(parseFloat(this.formularioCompleto.participacionEjercitoDos))) {this.formularioCompleto.participacionEjercitoDos = "0";}
      if (isNaN(parseFloat(this.formularioCompleto.otrosPasivosCorrientesDos))) {this.formularioCompleto.otrosPasivosCorrientesDos = "0";}
      ///otros valores segundo año
      if (isNaN(parseFloat(this.formularioCompleto.cuentasPorPagarDos))) {this.formularioCompleto.cuentasPorPagarDos = "0";}
      if (isNaN(parseFloat(this.formularioCompleto.cuentasPorPagarNoRelaDos))) {this.formularioCompleto.cuentasPorPagarNoRelaDos = "0";}
      if (isNaN(parseFloat(this.formularioCompleto.obligacionesInstituFinanDos))) {this.formularioCompleto.obligacionesInstituFinanDos = "0";}
      if (isNaN(parseFloat(this.formularioCompleto.prestamosLargoPlazoDos))) {this.formularioCompleto.prestamosLargoPlazoDos = "0";}
      if (isNaN(parseFloat(this.formularioCompleto.otrasCuentasLargoPlazoDos))) {this.formularioCompleto.otrasCuentasLargoPlazoDos = "0";}
      if (isNaN(parseFloat(this.formularioCompleto.otrasCuentasPorPagarDos))) {this.formularioCompleto.otrasCuentasPorPagarDos = "0";}
      if (isNaN(parseFloat(this.formularioCompleto.otrosPasivosLargoPlazoDos))) {this.formularioCompleto.otrosPasivosLargoPlazoDos = "0";}
      if (isNaN(parseFloat(this.formularioCompleto.otrosPasivosDos))) {this.formularioCompleto.otrosPasivosDos = "0";}
       //patrimonio neto segundo año
      if (isNaN(parseFloat(this.formularioCompleto.capitalSuscritoDos))) {this.formularioCompleto.capitalSuscritoDos = "0";}
      if (isNaN(parseFloat(this.formularioCompleto.capitalSuscritoNoPagaDos))) {this.formularioCompleto.capitalSuscritoNoPagaDos = "0";}
      if (isNaN(parseFloat(this.formularioCompleto.aportesSociosDos))) {this.formularioCompleto.aportesSociosDos = "0";}
      if (isNaN(parseFloat(this.formularioCompleto.reservaLegalDos))) {this.formularioCompleto.reservaLegalDos = "0";}
      if (isNaN(parseFloat(this.formularioCompleto.otrasReservasDos))) {this.formularioCompleto.otrasReservasDos = "0";}
      if (isNaN(parseFloat(this.formularioCompleto.utilidadNoDistribuidaDos))) {this.formularioCompleto.utilidadNoDistribuidaDos = "0";}
      if (isNaN(parseFloat(this.formularioCompleto.perdidaAcumuladaDos))) {this.formularioCompleto.perdidaAcumuladaDos = "0";}
      if (isNaN(parseFloat(this.formularioCompleto.utilidadEjercitoDos))) {this.formularioCompleto.utilidadEjercitoDos = "0";}
      //estado de resultados
      if (isNaN(parseFloat(this.formularioCompleto.ventasTotalesDos))) {this.formularioCompleto.ventasTotalesDos = "0";}
      if (isNaN(parseFloat(this.formularioCompleto.costoVentasDos))) {this.formularioCompleto.costoVentasDos = "0";}
      if (isNaN(parseFloat(this.formularioCompleto.gastosAdministracionDos))) {this.formularioCompleto.gastosAdministracionDos = "0";}
      if (isNaN(parseFloat(this.formularioCompleto.ingresosNoOperacionalesDos))) {this.formularioCompleto.ingresosNoOperacionalesDos = "0";}
      if (isNaN(parseFloat(this.formularioCompleto.gastosNoOperacioDos))) {this.formularioCompleto.gastosNoOperacioDos = "0";}
      if (isNaN(parseFloat(this.formularioCompleto.participacionTrabajadoresDos))) {this.formularioCompleto.participacionTrabajadoresDos = "0";}
      if (isNaN(parseFloat(this.formularioCompleto.impuestoARentaDos))) {this.formularioCompleto.impuestoARentaDos = "0";}
    
      //fin de valores a probar
    

    var prop = Object.keys(this.formularioCompleto);

    for(var i = 216 ; i<=219;i++){
        if(this.formularioCompleto[prop[i]]=='undefined' || this.formularioCompleto[prop[i]]==null){
            this.formularioCompleto[prop[i]] = 0;
        }
    }
    for(var i = 240 ; i<=257;i++){
        if(this.formularioCompleto[prop[i]]=='undefined' || this.formularioCompleto[prop[i]]==null){
            this.formularioCompleto[prop[i]] = 0;
        }
    }
    for(var i = 240 ; i<=257;i++){
        if(this.formularioCompleto[prop[i]]=='undefined' || this.formularioCompleto[prop[i]]==null){
            this.formularioCompleto[prop[i]] = 0;
        }
    }
    for(var i = 260 ; i<=273;i++){
        if(this.formularioCompleto[prop[i]]=='undefined' || this.formularioCompleto[prop[i]]==null){
            this.formularioCompleto[prop[i]] = 0;
        }
    }
    for(var i = 280 ; i<=295;i++){
        if(this.formularioCompleto[prop[i]]=='undefined' || this.formularioCompleto[prop[i]]==null){
            this.formularioCompleto[prop[i]] = 0;
        }
    }

    for(var i = 302 ; i<=325;i++){
        if(this.formularioCompleto[prop[i]]=='undefined' || this.formularioCompleto[prop[i]]==null){
            this.formularioCompleto[prop[i]] = 0;
        }
    }
    this.formularioCompleto.totalActivoCorriente = (
        parseFloat(this.formularioCompleto.cajaBancosCompleto.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.inversionesCorrientes.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.cuentasRelacionadas.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.cuentasNoRelacionadas.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.otrasCuentasRelacionadas.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.otrasCuentasNoRelacionadas.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.provisionCuentas.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.creditoTributario.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.inventariosCompleto.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.activosPagados.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.otrosActivosCorrientes.replace(/,/g,"")  )
    ).toFixed(2).toString();

        this.porcentaje1A =((parseFloat(this.formularioCompleto.cajaBancosCompleto.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivoCorriente))*100).toFixed(0)
        this.porcentaje2A =((parseFloat(this.formularioCompleto.inversionesCorrientes.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivoCorriente))*100).toFixed(0)
        this.porcentaje3A =((parseFloat(this.formularioCompleto.cuentasRelacionadas.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivoCorriente))*100).toFixed(0)
        this.porcentaje4A =((parseFloat(this.formularioCompleto.cuentasNoRelacionadas.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivoCorriente))*100).toFixed(0)
        this.porcentaje5A =((parseFloat(this.formularioCompleto.otrasCuentasRelacionadas.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivoCorriente))*100).toFixed(0)
        this.porcentaje6A =((parseFloat(this.formularioCompleto.otrasCuentasNoRelacionadas.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivoCorriente))*100).toFixed(0)
        this.porcentaje7A =((parseFloat(this.formularioCompleto.provisionCuentas.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivoCorriente))*100).toFixed(0)
        this.porcentaje8A =((parseFloat(this.formularioCompleto.creditoTributario.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivoCorriente))*100).toFixed(0)
        this.porcentaje9A =((parseFloat(this.formularioCompleto.inventariosCompleto.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivoCorriente))*100).toFixed(0)
        this.porcentaje10A =((parseFloat(this.formularioCompleto.activosPagados.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivoCorriente))*100).toFixed(0)
        this.porcentaje11A =((parseFloat(this.formularioCompleto.otrosActivosCorrientes.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivoCorriente))*100).toFixed(0)
        console.log("ACTIVO CORRIENTE"+" "+this.porcentaje1A);
        console.log("ACTIVO CORRIENTE"+" "+this.porcentaje2A);
        console.log("ACTIVO CORRIENTE"+" "+this.porcentaje3A);
        console.log("ACTIVO CORRIENTE"+" "+this.porcentaje4A);
        console.log("ACTIVO CORRIENTE"+" "+this.porcentaje5A);
        console.log("ACTIVO CORRIENTE"+" "+this.porcentaje6A);
        console.log("ACTIVO CORRIENTE"+" "+this.porcentaje7A);
        console.log("ACTIVO CORRIENTE"+" "+this.porcentaje8A);
        console.log("ACTIVO CORRIENTE"+" "+this.porcentaje9A);
        console.log("ACTIVO CORRIENTE"+" "+this.porcentaje10A);
        console.log("ACTIVO CORRIENTE"+" "+this.porcentaje11A);

        this.pieChartDataUno = [this.porcentaje1A,this.porcentaje2A,this.porcentaje3A,this.porcentaje4A,this.porcentaje5A,
          this.porcentaje6A,this.porcentaje7A,this.porcentaje8A,this.porcentaje9A,this.porcentaje10A,this.porcentaje11A]
        console.log("Array de primer pie " + this.pieChartDataUno )
    
        this.formularioCompleto.totalActivoCorrienteDos = (
          parseFloat(this.formularioCompleto.cajaBancosCompletoDos.replace(/,/g,"")) +
          parseFloat(this.formularioCompleto.inversionesCorrientesDos.replace(/,/g,"")) +
          parseFloat(this.formularioCompleto.cuentasRelacionadasDos.replace(/,/g,"")) +
          parseFloat(this.formularioCompleto.cuentasNoRelacionadasDos.replace(/,/g,"")) +
          parseFloat(this.formularioCompleto.otrasCuentasRelacionadasDos.replace(/,/g,"")) +
          parseFloat(this.formularioCompleto.otrasCuentasNoRelacionadasDos.replace(/,/g,"")) +
          parseFloat(this.formularioCompleto.provisionCuentasDos.replace(/,/g,"")) +
          parseFloat(this.formularioCompleto.creditoTributarioDos.replace(/,/g,"")) +
          parseFloat(this.formularioCompleto.inventariosCompletoDos.replace(/,/g,"")) +
          parseFloat(this.formularioCompleto.activosPagadosDos.replace(/,/g,"")) +
          parseFloat(this.formularioCompleto.otrosActivosCorrientesDos.replace(/,/g,""))
      ).toFixed(2).toString();
          this.porcentaje1A2 =(parseFloat(this.formularioCompleto.cajaBancosCompletoDos.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivoCorrienteDos)*100).toFixed(0)
          this.porcentaje2A2  =(parseFloat(this.formularioCompleto.inversionesCorrientesDos.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivoCorrienteDos)*100).toFixed(0)
          this.porcentaje3A2 =(parseFloat(this.formularioCompleto.cuentasRelacionadasDos.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivoCorrienteDos)*100).toFixed(0)
          this.porcentaje4A2 =(parseFloat(this.formularioCompleto.cuentasNoRelacionadasDos.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivoCorrienteDos)*100).toFixed(0)
          this.porcentaje5A2 =(parseFloat(this.formularioCompleto.otrasCuentasRelacionadasDos.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivoCorrienteDos)*100).toFixed(0)
          this.porcentaje6A2 =(parseFloat(this.formularioCompleto.otrasCuentasNoRelacionadasDos.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivoCorrienteDos)*100).toFixed(0)
          this.porcentaje7A2 =(parseFloat(this.formularioCompleto.provisionCuentasDos.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivoCorrienteDos)*100).toFixed(0)
          this.porcentaje8A2 =(parseFloat(this.formularioCompleto.creditoTributarioDos.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivoCorrienteDos)*100).toFixed(0)
          this.porcentaje9A2 =(parseFloat(this.formularioCompleto.inventariosCompletoDos.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivoCorrienteDos)*100).toFixed(0)
          this.porcentaje10A2 =(parseFloat(this.formularioCompleto.activosPagadosDos.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivoCorrienteDos)*100).toFixed(0)
          this.porcentaje11A2 =(parseFloat(this.formularioCompleto.otrosActivosCorrientesDos.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivoCorrienteDos)*100).toFixed(0)
          console.log("ACTIVO CORRIENTE 2"+" "+this.porcentaje1A2);
          console.log("ACTIVO CORRIENTE 2"+" "+this.porcentaje2A2);
          console.log("ACTIVO CORRIENTE 2"+" "+this.porcentaje3A2);
          console.log("ACTIVO CORRIENTE 2"+" "+this.porcentaje4A2);
          console.log("ACTIVO CORRIENTE 2"+" "+this.porcentaje5A2);
          console.log("ACTIVO CORRIENTE 2"+" "+this.porcentaje6A2);
          console.log("ACTIVO CORRIENTE 2"+" "+this.porcentaje7A2);
          console.log("ACTIVO CORRIENTE 2"+" "+this.porcentaje8A2);
          console.log("ACTIVO CORRIENTE 2"+" "+this.porcentaje9A2);
          console.log("ACTIVO CORRIENTE 2"+" "+this.porcentaje10A2);
          console.log("ACTIVO CORRIENTE 2"+" "+this.porcentaje11A2); 

              
    this.formularioCompleto.totalActivosLargoPlazo = (

      parseFloat(this.formularioCompleto.totalActivoFijo.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.totalActivoDiferido.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.inversionesParticipaciones.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.otrasInversionesLargoPlazo.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.cuentasPorCobrarRelacionadas.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.cuentasPorCobrarNoRelacionadas.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.otrasCuentasPorCobrarRelacionadas.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.otrasCuentasPorCobrarNoRelacionadas.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.provisionCuentasIncobrables.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.otrosActivosLargoPlazo.replace(/,/g,""))
  ).toFixed(2).toString();
      this.porcentajeTotalAFijo1=(parseFloat(this.formularioCompleto.totalActivoFijo.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalActivosLargoPlazo)*100).toFixed(0)
      this.porcentajeTotalADiferido1=(parseFloat(this.formularioCompleto.totalActivoDiferido.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalActivosLargoPlazo)*100).toFixed(0)
      this.porcentaje1Alp=((parseFloat(this.formularioCompleto.inversionesParticipaciones.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivosLargoPlazo))*100).toFixed(0)
      this.porcentaje2Alp=((parseFloat(this.formularioCompleto.otrasInversionesLargoPlazo.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivosLargoPlazo))*100).toFixed(0)
      this.porcentaje3Alp=((parseFloat(this.formularioCompleto.cuentasPorCobrarRelacionadas.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivosLargoPlazo))*100).toFixed(0)
      this.porcentaje4Alp=((parseFloat(this.formularioCompleto.cuentasPorCobrarNoRelacionadas.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivosLargoPlazo))*100).toFixed(0)
      this.porcentaje5Alp=((parseFloat(this.formularioCompleto.otrasCuentasPorCobrarRelacionadas.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivosLargoPlazo))*100).toFixed(0)
      this.porcentaje6Alp=((parseFloat(this.formularioCompleto.otrasCuentasPorCobrarNoRelacionadas.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivosLargoPlazo))*100).toFixed(0)
      this.porcentaje7Alp=((parseFloat(this.formularioCompleto.provisionCuentasIncobrables.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivosLargoPlazo))*100).toFixed(0)
      this.porcentaje8Alp=((parseFloat(this.formularioCompleto.otrosActivosLargoPlazo.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivosLargoPlazo))*100).toFixed(0)
      console.log("ACTIVO NO CORRIENTE"+" "+this.porcentajeTotalAFijo1)
      console.log("ACTIVO NO CORRIENTE"+" "+this.porcentajeTotalADiferido1)
      console.log("ACTIVO NO CORRIENTE"+" "+this.porcentaje1Alp);
      console.log("ACTIVO NO CORRIENTE"+" "+this.porcentaje2Alp);
      console.log("ACTIVO NO CORRIENTE"+" "+this.porcentaje3Alp);
      console.log("ACTIVO NO CORRIENTE"+" "+this.porcentaje4Alp);
      console.log("ACTIVO NO CORRIENTE"+" "+this.porcentaje5Alp);
      console.log("ACTIVO NO CORRIENTE"+" "+this.porcentaje6Alp);
      console.log("ACTIVO NO CORRIENTE"+" "+this.porcentaje7Alp);
      console.log("ACTIVO NO CORRIENTE"+" "+this.porcentaje8Alp);

      this.pieChartDataDos= [this.porcentajeTotalAFijo1,this.porcentajeTotalADiferido1,this.porcentaje1Alp,this.porcentaje2Alp,this.porcentaje3Alp,
        this.porcentaje4Alp,this.porcentaje5Alp,this.porcentaje6Alp,this.porcentaje7Alp,this.porcentaje8Alp]
      console.log("Array de segundo pie " + this.pieChartDataDos )

     

  this.formularioCompleto.totalActivosLargoPlazoDos = (

      parseFloat(this.formularioCompleto.totalActivoFijoDos.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.totalActivoDiferidoDos.replace(/,/g,"")) +      
      parseFloat(this.formularioCompleto.inversionesParticipacionesDos.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.otrasInversionesLargoPlazoDos.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.cuentasPorCobrarRelacionadasDos.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.cuentasPorCobrarNoRelacionadasDos.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.otrasCuentasPorCobrarRelacionadasDos.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.otrasCuentasPorCobrarNoRelacionadasDos.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.provisionCuentasIncobrablesDos.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.otrosActivosLargoPlazoDos.replace(/,/g,""))
  ).toFixed(2).toString();
       this.porcentajeTotalAFijo2=(parseFloat(this.formularioCompleto.totalActivoFijoDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalActivosLargoPlazoDos)*100).toFixed(0)
      this.porcentajeTotalADiferido2=(parseFloat(this.formularioCompleto.totalActivoDiferidoDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalActivosLargoPlazoDos)*100).toFixed(0)
      this.porcentaje1Alp2=((parseFloat(this.formularioCompleto.inversionesParticipacionesDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalActivosLargoPlazoDos))*100).toFixed(0)
      this.porcentaje2Alp2=((parseFloat(this.formularioCompleto.otrasInversionesLargoPlazoDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalActivosLargoPlazoDos))*100).toFixed(0)
      this.porcentaje3Alp2=((parseFloat(this.formularioCompleto.cuentasPorCobrarRelacionadasDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalActivosLargoPlazoDos))*100).toFixed(0)
      this.porcentaje4Alp2=((parseFloat(this.formularioCompleto.cuentasPorCobrarNoRelacionadasDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalActivosLargoPlazoDos))*100).toFixed(0)
      this.porcentaje5Alp2=((parseFloat(this.formularioCompleto.otrasCuentasPorCobrarRelacionadasDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalActivosLargoPlazoDos))*100).toFixed(0)
      this.porcentaje6Alp2=((parseFloat(this.formularioCompleto.otrasCuentasPorCobrarNoRelacionadasDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalActivosLargoPlazoDos))*100).toFixed(0)
      this.porcentaje7Alp2=((parseFloat(this.formularioCompleto.provisionCuentasIncobrablesDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalActivosLargoPlazoDos))).toFixed(0)
      this.porcentaje8Alp2=((parseFloat(this.formularioCompleto.otrosActivosLargoPlazoDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalActivosLargoPlazoDos))*100).toFixed(0)
      
      console.log("ACTIVO NO CORRIENTE 2"+" "+this.porcentajeTotalAFijo2)
      console.log("ACTIVO NO CORRIENTE 2"+" "+this.porcentajeTotalADiferido2)
      console.log("ACTIVO NO CORRIENTE 2"+" "+this.porcentaje1Alp2)
      console.log("ACTIVO NO CORRIENTE 2"+" "+this.porcentaje2Alp2)
      console.log("ACTIVO NO CORRIENTE 2"+" "+this.porcentaje3Alp2)
      console.log("ACTIVO NO CORRIENTE 2"+" "+this.porcentaje4Alp2)
      console.log("ACTIVO NO CORRIENTE 2"+" "+this.porcentaje5Alp2)
      console.log("ACTIVO NO CORRIENTE 2"+" "+this.porcentaje6Alp2)
      console.log("ACTIVO NO CORRIENTE 2"+" "+this.porcentaje7Alp2)
      console.log("ACTIVO NO CORRIENTE 2"+" "+this.porcentaje8Alp2)

      this.formularioCompleto.totalDelActivo = (
        parseFloat(this.formularioCompleto.totalActivoCorriente.replace(/,/g,"")) +
       // parseFloat(this.formularioCompleto.totalActivoFijo.replace(/,/g,"")) +
       // parseFloat(this.formularioCompleto.totalActivoDiferido.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.totalActivosLargoPlazo.replace(/,/g,""))
        
    ).toFixed(2).toString();

    this.formularioCompleto.totalDelActivoDos = (
        parseFloat(this.formularioCompleto.totalActivoCorrienteDos.replace(/,/g,"")) +
        //parseFloat(this.formularioCompleto.totalActivoFijoDos.replace(/,/g,"")) +
        //parseFloat(this.formularioCompleto.totalActivoDiferidoDos.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.totalActivosLargoPlazoDos.replace(/,/g,""))
        
    ).toFixed(2).toString();


         

    this.formularioCompleto.totalPasivoCorriente = (
      parseFloat(this.formularioCompleto.cuentasProveedoresRelacionado.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.cuentasProveedoresNoRelacionado.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.obligacionesFinancieras.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.prestamosAccionistas.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.otrasCuentasPagarRelacionadas.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.otrasCuentasPagarNoRelacionadas.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.impuestoRentaEjercito.replace(/,/g,"")) +        
      parseFloat(this.formularioCompleto.participacionEjercito.replace(/,/g,""))  +          
      parseFloat(this.formularioCompleto.otrosPasivosCorrientes.replace(/,/g,""))            

  ).toFixed(2).toString();

    this.porcentaje1Pc1=((parseFloat(this.formularioCompleto.cuentasProveedoresRelacionado.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorriente))*100).toFixed(0)
    this.porcentaje2Pc1=((parseFloat(this.formularioCompleto.cuentasProveedoresNoRelacionado.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorriente))*100).toFixed(0)
    this.porcentaje3Pc1=((parseFloat(this.formularioCompleto.obligacionesFinancieras.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorriente))*100).toFixed(0)
    this.porcentaje4Pc1=((parseFloat(this.formularioCompleto.prestamosAccionistas.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorriente))*100).toFixed(0)
    this.porcentaje5Pc1=((parseFloat(this.formularioCompleto.otrasCuentasPagarRelacionadas.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorriente))*100).toFixed(0)
    this.porcentaje6Pc1=((parseFloat(this.formularioCompleto.otrasCuentasPagarNoRelacionadas.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorriente))*100).toFixed(0)
    this.porcentaje7Pc1=((parseFloat(this.formularioCompleto.impuestoRentaEjercito.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorriente))*100).toFixed(0)
    this.porcentaje8Pc1=((parseFloat(this.formularioCompleto.participacionEjercito.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorriente))*100).toFixed(0)
    this.porcentaje9PC1=((parseFloat(this.formularioCompleto.otrosPasivosCorrientes.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorriente))*100).toFixed(0)

    console.log("PASIVO CORRIENTE"+" "+this.porcentaje1Pc1)
    console.log("PASIVO CORRIENTE"+" "+this.porcentaje2Pc1)
    console.log("PASIVO CORRIENTE"+" "+this.porcentaje3Pc1)
    console.log("PASIVO CORRIENTE"+" "+this.porcentaje4Pc1)
    console.log("PASIVO CORRIENTE"+" "+this.porcentaje5Pc1)
    console.log("PASIVO CORRIENTE"+" "+this.porcentaje6Pc1)
    console.log("PASIVO CORRIENTE"+" "+this.porcentaje7Pc1)
    console.log("PASIVO CORRIENTE"+" "+this.porcentaje8Pc1)
    console.log("PASIVO CORRIENTE"+" "+this.porcentaje9PC1)

    this.pieChartDataTres= [this.porcentaje1Pc1,this.porcentaje2Pc1,this.porcentaje3Pc1,this.porcentaje4Pc1,this.porcentaje5Pc1,
      this.porcentaje6Pc1,this.porcentaje7Pc1,this.porcentaje8Pc1,this.porcentaje9PC1]
    console.log("Array de tercer pie " + this.pieChartDataTres )

  

  this.formularioCompleto.totalPasivoCorrienteDos = (
      parseFloat(this.formularioCompleto.cuentasProveedoresRelacionadoDos.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.cuentasProveedoresNoRelacionadoDos.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.obligacionesFinancierasDos.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.prestamosAccionistasDos.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.otrasCuentasPagarRelacionadasDos.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.otrasCuentasPagarNoRelacionadasDos.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.impuestoRentaEjercitoDos.replace(/,/g,"")) +        
      parseFloat(this.formularioCompleto.participacionEjercitoDos.replace(/,/g,""))  +          
      parseFloat(this.formularioCompleto.otrosPasivosCorrientesDos.replace(/,/g,""))            

  ).toFixed(2).toString();

  this.porcentaje1Pc2=((parseFloat(this.formularioCompleto.cuentasProveedoresRelacionadoDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorrienteDos))*100).toFixed(0)
  this.porcentaje2Pc2=((parseFloat(this.formularioCompleto.cuentasProveedoresNoRelacionadoDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorrienteDos))*100).toFixed(0)
  this.porcentaje3Pc2=((parseFloat(this.formularioCompleto.obligacionesFinancierasDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorrienteDos))*100).toFixed(0)
  this.porcentaje4Pc2=((parseFloat(this.formularioCompleto.prestamosAccionistasDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorrienteDos))*100).toFixed(0)
  this.porcentaje5Pc2=((parseFloat(this.formularioCompleto.otrasCuentasPagarRelacionadasDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorrienteDos))*100).toFixed(0)
  this.porcentaje6Pc2=((parseFloat(this.formularioCompleto.otrasCuentasPagarNoRelacionadasDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorrienteDos))*100).toFixed(0)
  this.porcentaje7Pc2=((parseFloat(this.formularioCompleto.impuestoRentaEjercitoDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorrienteDos))*100).toFixed(0)
  this.porcentaje8Pc2=((parseFloat(this.formularioCompleto.participacionEjercitoDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorrienteDos))*100).toFixed(0)
  this.porcentaje9PC2=((parseFloat(this.formularioCompleto.otrosPasivosCorrientesDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorrienteDos))*100).toFixed(0)

  console.log("PASIVO CORRIENTE 2"+" "+this.porcentaje1Pc2)
  console.log("PASIVO CORRIENTE 2"+" "+this.porcentaje2Pc2)
  console.log("PASIVO CORRIENTE 2"+" "+this.porcentaje3Pc2)
  console.log("PASIVO CORRIENTE 2"+" "+this.porcentaje4Pc2)
  console.log("PASIVO CORRIENTE 2"+" "+this.porcentaje5Pc2)
  console.log("PASIVO CORRIENTE 2"+" "+this.porcentaje6Pc2)
  console.log("PASIVO CORRIENTE 2"+" "+this.porcentaje7Pc2)
  console.log("PASIVO CORRIENTE 2"+" "+this.porcentaje8Pc2)
  console.log("PASIVO CORRIENTE 2"+" "+this.porcentaje9PC2)

   this.formularioCompleto.totalPasivoCorriente = (
        parseFloat(this.formularioCompleto.cuentasProveedoresRelacionado.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.cuentasProveedoresNoRelacionado.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.obligacionesFinancieras.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.prestamosAccionistas.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.otrasCuentasPagarRelacionadas.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.otrasCuentasPagarNoRelacionadas.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.impuestoRentaEjercito.replace(/,/g,"")) +        
        parseFloat(this.formularioCompleto.participacionEjercito.replace(/,/g,""))  +          
        parseFloat(this.formularioCompleto.otrosPasivosCorrientes.replace(/,/g,""))            

    ).toFixed(2).toString();

      this.porcentaje1Pc1=((parseFloat(this.formularioCompleto.cuentasProveedoresRelacionado.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorriente))*100).toFixed(0)
      this.porcentaje2Pc1=((parseFloat(this.formularioCompleto.cuentasProveedoresNoRelacionado.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorriente))*100).toFixed(0)
      this.porcentaje3Pc1=((parseFloat(this.formularioCompleto.obligacionesFinancieras.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorriente))*100).toFixed(0)
      this.porcentaje4Pc1=((parseFloat(this.formularioCompleto.prestamosAccionistas.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorriente))*100).toFixed(0)
      this.porcentaje5Pc1=((parseFloat(this.formularioCompleto.otrasCuentasPagarRelacionadas.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorriente))*100).toFixed(0)
      this.porcentaje6Pc1=((parseFloat(this.formularioCompleto.otrasCuentasPagarNoRelacionadas.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorriente))*100).toFixed(0)
      this.porcentaje7Pc1=((parseFloat(this.formularioCompleto.impuestoRentaEjercito.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorriente))*100).toFixed(0)
      this.porcentaje8Pc1=((parseFloat(this.formularioCompleto.participacionEjercito.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorriente))*100).toFixed(0)
      this.porcentaje9PC1=((parseFloat(this.formularioCompleto.otrosPasivosCorrientes.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorriente))*100).toFixed(0)

      console.log("PASIVO CORRIENTE"+" "+this.porcentaje1Pc1)
      console.log("PASIVO CORRIENTE"+" "+this.porcentaje2Pc1)
      console.log("PASIVO CORRIENTE"+" "+this.porcentaje3Pc1)
      console.log("PASIVO CORRIENTE"+" "+this.porcentaje4Pc1)
      console.log("PASIVO CORRIENTE"+" "+this.porcentaje5Pc1)
      console.log("PASIVO CORRIENTE"+" "+this.porcentaje6Pc1)
      console.log("PASIVO CORRIENTE"+" "+this.porcentaje7Pc1)
      console.log("PASIVO CORRIENTE"+" "+this.porcentaje8Pc1)
      console.log("PASIVO CORRIENTE"+" "+this.porcentaje9PC1)

    

    

    this.formularioCompleto.totalPasivoCorrienteDos = (
        parseFloat(this.formularioCompleto.cuentasProveedoresRelacionadoDos.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.cuentasProveedoresNoRelacionadoDos.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.obligacionesFinancierasDos.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.prestamosAccionistasDos.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.otrasCuentasPagarRelacionadasDos.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.otrasCuentasPagarNoRelacionadasDos.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.impuestoRentaEjercitoDos.replace(/,/g,"")) +        
        parseFloat(this.formularioCompleto.participacionEjercitoDos.replace(/,/g,""))  +          
        parseFloat(this.formularioCompleto.otrosPasivosCorrientesDos.replace(/,/g,""))            

    ).toFixed(2).toString();

    this.porcentaje1Pc2=((parseFloat(this.formularioCompleto.cuentasProveedoresRelacionadoDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorrienteDos))*100).toFixed(0)
    this.porcentaje2Pc2=((parseFloat(this.formularioCompleto.cuentasProveedoresNoRelacionadoDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorrienteDos))*100).toFixed(0)
    this.porcentaje3Pc2=((parseFloat(this.formularioCompleto.obligacionesFinancierasDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorrienteDos))*100).toFixed(0)
    this.porcentaje4Pc2=((parseFloat(this.formularioCompleto.prestamosAccionistasDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorrienteDos))*100).toFixed(0)
    this.porcentaje5Pc2=((parseFloat(this.formularioCompleto.otrasCuentasPagarRelacionadasDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorrienteDos))*100).toFixed(0)
    this.porcentaje6Pc2=((parseFloat(this.formularioCompleto.otrasCuentasPagarNoRelacionadasDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorrienteDos))*100).toFixed(0)
    this.porcentaje7Pc2=((parseFloat(this.formularioCompleto.impuestoRentaEjercitoDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorrienteDos))*100).toFixed(0)
    this.porcentaje8Pc2=((parseFloat(this.formularioCompleto.participacionEjercitoDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorrienteDos))*100).toFixed(0)
    this.porcentaje9PC2=((parseFloat(this.formularioCompleto.otrosPasivosCorrientesDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoCorrienteDos))*100).toFixed(0)

    console.log("PASIVO CORRIENTE 2"+" "+this.porcentaje1Pc2)
    console.log("PASIVO CORRIENTE 2"+" "+this.porcentaje2Pc2)
    console.log("PASIVO CORRIENTE 2"+" "+this.porcentaje3Pc2)
    console.log("PASIVO CORRIENTE 2"+" "+this.porcentaje4Pc2)
    console.log("PASIVO CORRIENTE 2"+" "+this.porcentaje5Pc2)
    console.log("PASIVO CORRIENTE 2"+" "+this.porcentaje6Pc2)
    console.log("PASIVO CORRIENTE 2"+" "+this.porcentaje7Pc2)
    console.log("PASIVO CORRIENTE 2"+" "+this.porcentaje8Pc2)
    console.log("PASIVO CORRIENTE 2"+" "+this.porcentaje9PC2)
    this.formularioCompleto.totPasivoLargoPlazo = (
      parseFloat(this.formularioCompleto.cuentasPorPagar.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.cuentasPorPagarNoRela.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.obligacionesInstituFinan.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.prestamosLargoPlazo.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.otrasCuentasLargoPlazo.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.otrasCuentasPorPagar.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.otrosPasivosLargoPlazo.replace(/,/g,"")) 
      

  ).toFixed(2).toString();

  this.porcentaje1Plp1=((parseFloat(this.formularioCompleto.cuentasPorPagar.replace(/,/g,""))/parseFloat(this.formularioCompleto.totPasivoLargoPlazo))*100).toFixed(0)
  this.porcentaje2Plp1=((parseFloat(this.formularioCompleto.cuentasPorPagarNoRela.replace(/,/g,""))/parseFloat(this.formularioCompleto.totPasivoLargoPlazo))*100).toFixed(0)
  this.porcentaje3Plp1=((parseFloat(this.formularioCompleto.obligacionesInstituFinan.replace(/,/g,""))/parseFloat(this.formularioCompleto.totPasivoLargoPlazo))*100).toFixed(0)
  this.porcentaje4Plp1=((parseFloat(this.formularioCompleto.prestamosLargoPlazo.replace(/,/g,""))/parseFloat(this.formularioCompleto.totPasivoLargoPlazo))*100).toFixed(0)
  this.porcentaje5Plp1=((parseFloat(this.formularioCompleto.otrasCuentasLargoPlazo.replace(/,/g,""))/parseFloat(this.formularioCompleto.totPasivoLargoPlazo))*100).toFixed(0)
  this.porcentaje6Plp1=((parseFloat(this.formularioCompleto.otrasCuentasPorPagar.replace(/,/g,""))/parseFloat(this.formularioCompleto.totPasivoLargoPlazo))*100).toFixed(0)
  this.porcentaje7Plp1=((parseFloat(this.formularioCompleto.otrosPasivosLargoPlazo.replace(/,/g,"")) /parseFloat(this.formularioCompleto.totPasivoLargoPlazo))*100).toFixed(0)
  
  console.log("PASIVO NO CORRIENTE "+" "+this.porcentaje1Plp1)
  console.log("PASIVO NO CORRIENTE "+" "+this.porcentaje2Plp1)
  console.log("PASIVO NO CORRIENTE "+" "+this.porcentaje3Plp1)
  console.log("PASIVO NO CORRIENTE "+" "+this.porcentaje4Plp1)
  console.log("PASIVO NO CORRIENTE "+" "+this.porcentaje5Plp1)
  console.log("PASIVO NO CORRIENTE "+" "+this.porcentaje6Plp1)
  console.log("PASIVO NO CORRIENTE "+" "+this.porcentaje7Plp1)

  this.pieChartDataCuatro= [this.porcentaje1Plp1,this.porcentaje2Plp1,this.porcentaje3Plp1,this.porcentaje4Plp1,this.porcentaje5Plp1,
    this.porcentaje6Plp1,this.porcentaje7Plp1]
  console.log("Array de tercer pie " + this.pieChartDataCuatro )

  


  this.formularioCompleto.totPasivoLargoPlazoDos = (
      parseFloat(this.formularioCompleto.cuentasPorPagarDos.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.cuentasPorPagarNoRelaDos.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.obligacionesInstituFinanDos.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.prestamosLargoPlazoDos.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.otrasCuentasLargoPlazoDos.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.otrasCuentasPorPagarDos.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.otrosPasivosLargoPlazoDos.replace(/,/g,""))            

  ).toFixed(2).toString();

  

  
  this.porcentaje1Plp2=((parseFloat(this.formularioCompleto.cuentasPorPagarDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totPasivoLargoPlazoDos))*100).toFixed(0)
  this.porcentaje2Plp2=((parseFloat(this.formularioCompleto.cuentasPorPagarNoRelaDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totPasivoLargoPlazoDos))*100).toFixed(0)
  this.porcentaje3Plp2=((parseFloat(this.formularioCompleto.obligacionesInstituFinanDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totPasivoLargoPlazoDos))*100).toFixed(0)
  this.porcentaje4Plp2=((parseFloat(this.formularioCompleto.prestamosLargoPlazoDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totPasivoLargoPlazoDos))*100).toFixed(0)
  this.porcentaje5Plp2=((parseFloat(this.formularioCompleto.otrasCuentasLargoPlazoDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totPasivoLargoPlazoDos))*100).toFixed(0)
  this.porcentaje6Plp2=((parseFloat(this.formularioCompleto.otrasCuentasPorPagarDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totPasivoLargoPlazoDos))*100).toFixed(0)
  this.porcentaje7Plp2=((parseFloat(this.formularioCompleto.otrosPasivosLargoPlazoDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totPasivoLargoPlazoDos))*100).toFixed(0)

  console.log("PASIVO NO CORRIENTE 2"+" "+this.porcentaje1Plp2)
  console.log("PASIVO NO CORRIENTE 2"+" "+this.porcentaje2Plp2)
  console.log("PASIVO NO CORRIENTE 2"+" "+this.porcentaje3Plp2)
  console.log("PASIVO NO CORRIENTE 2"+" "+this.porcentaje4Plp2)
  console.log("PASIVO NO CORRIENTE 2"+" "+this.porcentaje5Plp2)
  console.log("PASIVO NO CORRIENTE 2"+" "+this.porcentaje6Plp2)
  console.log("PASIVO NO CORRIENTE 2"+" "+this.porcentaje7Plp2)

  

  this.formularioCompleto.totalDelPasivo = (
      parseFloat(this.formularioCompleto.totalPasivoCorriente.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.totPasivoLargoPlazo.replace(/,/g,"")) 
               
  ).toFixed(2).toString();



  this.formularioCompleto.totalDelPasivoDos = (
      parseFloat(this.formularioCompleto.totalPasivoCorrienteDos) +
      parseFloat(this.formularioCompleto.totPasivoLargoPlazoDos) 
               
  ).toFixed(2).toString();

  

  this.formularioCompleto.totalPatrimonio = (
      parseFloat(this.formularioCompleto.capitalSuscrito.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.capitalSuscritoNoPaga.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.aportesSocios.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.reservaLegal.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.otrasReservas.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.utilidadNoDistribuida.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.perdidaAcumulada.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.utilidadEjercito.replace(/,/g,"")) 
              
  ).toFixed(2).toString();

  // Nuevos 

  this.porcentaje1Pn1=((parseFloat(this.formularioCompleto.capitalSuscrito.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonio))*100).toFixed(0)
  this.porcentaje2Pn1=((parseFloat(this.formularioCompleto.capitalSuscritoNoPaga.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonio))*100).toFixed(0)
  this.porcentaje3Pn1=((parseFloat(this.formularioCompleto.aportesSocios.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonio))*100).toFixed(0)
  this.porcentaje4Pn1=((parseFloat(this.formularioCompleto.reservaLegal.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonio))*100).toFixed(0)
  this.porcentaje5Pn1=((parseFloat(this.formularioCompleto.otrasReservas.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonio))*100).toFixed(0)
  this.porcentaje6Pn1=((parseFloat(this.formularioCompleto.utilidadNoDistribuida.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonio))*100).toFixed(0)
  this.porcentaje7Pn1=((parseFloat(this.formularioCompleto.perdidaAcumulada.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonio))*100).toFixed(0)
  this.porcentaje8Pn1=((parseFloat(this.formularioCompleto.utilidadEjercito.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonio))*100).toFixed(0)
  
  console.log("NETO PORCENTAJE"+" "+this.porcentaje1Pn1)
  console.log("NETO PORCENTAJE"+" "+this.porcentaje2Pn1)
  console.log("NETO PORCENTAJE"+" "+this.porcentaje3Pn1)
  console.log("NETO PORCENTAJE"+" "+this.porcentaje4Pn1)
  console.log("NETO PORCENTAJE"+" "+this.porcentaje5Pn1)
  console.log("NETO PORCENTAJE"+" "+this.porcentaje7Pn1)
  console.log("NETO PORCENTAJE"+" "+this.porcentaje8Pn1)

  this.pieChartDataCinco= [this.porcentaje1Pn1,this.porcentaje2Pn1,this.porcentaje3Pn1,this.porcentaje4Pn1,this.porcentaje5Pn1,
    this.porcentaje6Pn1,this.porcentaje7Pn1,this.porcentaje8Pn1]
  console.log("Array de tercer pie " + this.pieChartDataCinco )
  // fin nuevos



  this.formularioCompleto.totalPatrimonioDos = (
      parseFloat(this.formularioCompleto.capitalSuscritoDos.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.capitalSuscritoNoPagaDos.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.aportesSociosDos.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.reservaLegalDos.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.otrasReservasDos.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.utilidadNoDistribuidaDos.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.perdidaAcumuladaDos.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.utilidadEjercitoDos.replace(/,/g,"")) 
              
  ).toFixed(2).toString();
  


  // nuevos dos
  this.porcentaje1Pn2=((parseFloat(this.formularioCompleto.capitalSuscritoDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonioDos))*100).toFixed(0)
  this.porcentaje2Pn2=((parseFloat(this.formularioCompleto.capitalSuscritoNoPagaDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonioDos))*100).toFixed(0)
  this.porcentaje3Pn2=((parseFloat(this.formularioCompleto.aportesSociosDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonioDos))*100).toFixed(0)
  this.porcentaje4Pn2=((parseFloat(this.formularioCompleto.reservaLegalDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonioDos))*100).toFixed(0)
  this.porcentaje5Pn2=((parseFloat(this.formularioCompleto.otrasReservasDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonioDos))*100).toFixed(0)
  this.porcentaje6Pn2=((parseFloat(this.formularioCompleto.utilidadNoDistribuidaDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonioDos))*100).toFixed(0)
  this.porcentaje7Pn2=((parseFloat(this.formularioCompleto.perdidaAcumuladaDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonioDos))*100).toFixed(0)
  this.porcentaje8Pn2=((parseFloat(this.formularioCompleto.utilidadEjercitoDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonioDos))*100).toFixed(0)
  
  console.log("NETO PORCENTAJE Dos"+" "+this.porcentaje1Pn2)
  console.log("NETO PORCENTAJE Dos"+" "+this.porcentaje2Pn2)
  console.log("NETO PORCENTAJE Dos"+" "+this.porcentaje3Pn2)
  console.log("NETO PORCENTAJE Dos"+" "+this.porcentaje4Pn2)
  console.log("NETO PORCENTAJE Dos"+" "+this.porcentaje5Pn2)
  console.log("NETO PORCENTAJE Dos"+" "+this.porcentaje6Pn2)
  console.log("NETO PORCENTAJE Dos"+" "+this.porcentaje7Pn2)
  console.log("NETO PORCENTAJE Dos"+" "+this.porcentaje8Pn2)

  // fin campos
  this.formularioCompleto.totalPasivoPatrimonio = (
      parseFloat(this.formularioCompleto.totalDelPasivo.replace(/,/g,"")) +
      parseFloat(this.formularioCompleto.totalPatrimonio.replace(/,/g,""))   
      
      
  ).toFixed(2).toString();

  

        

    this.formularioCompleto.activoCorriente = formatterDolar.format(parseFloat(this.formularioCompleto.totalActivoCorriente));
    this.formularioCompleto.activoCorrienteDos = formatterDolar.format(parseFloat(this.formularioCompleto.totalActivoCorrienteDos));
    
    this.formularioCompleto.totalActivos = formatterDolar.format(parseFloat(this.formularioCompleto.totalPasivoPatrimonio));
    this.formularioCompleto.totalActivosDos = formatterDolar.format(parseFloat(this.formularioCompleto.totalPasivoPatrimonioDos));

    this.formularioCompleto.activoNoCorriente = (parseFloat(this.formularioCompleto.totalActivos.replace(/,/g,"")) - parseFloat(this.formularioCompleto.activoCorriente.replace(/,/g,""))).toString()
    this.formularioCompleto.activoNoCorriente = formatterDolar.format(parseFloat(this.formularioCompleto.activoNoCorriente));
    this.formularioCompleto.activoNoCorrienteDos = (parseFloat(this.formularioCompleto.totalActivosDos.replace(/,/g,"")) - parseFloat(this.formularioCompleto.activoCorrienteDos.replace(/,/g,""))).toString()
    this.formularioCompleto.activoNoCorrienteDos = formatterDolar.format(parseFloat(this.formularioCompleto.activoNoCorrienteDos));

    this.formularioCompleto.pasivoCorriente = formatterDolar.format(parseFloat(this.formularioCompleto.totalPasivoCorriente));
    this.formularioCompleto.pasivoCorrienteDos = formatterDolar.format(parseFloat(this.formularioCompleto.totalPasivoCorrienteDos));   

    this.formularioCompleto.totalPasivos = formatterDolar.format(parseFloat(this.formularioCompleto.totalDelPasivo));
    this.formularioCompleto.totalPasivosDos = formatterDolar.format(parseFloat(this.formularioCompleto.totalDelPasivoDos));

    this.formularioCompleto.pasivoNoCorriente = (parseFloat(this.formularioCompleto.totalPasivos.replace(/,/g,"")) - parseFloat(this.formularioCompleto.pasivoCorriente.replace(/,/g,""))).toString();
    this.formularioCompleto.pasivoNoCorriente = formatterDolar.format(parseFloat( this.formularioCompleto.pasivoNoCorriente))
    this.formularioCompleto.pasivoNoCorrienteDos = (parseFloat(this.formularioCompleto.totalPasivosDos.replace(/,/g,"")) - parseFloat(this.formularioCompleto.pasivoCorrienteDos.replace(/,/g,""))).toString();
    this.formularioCompleto.pasivoNoCorrienteDos = formatterDolar.format(parseFloat( this.formularioCompleto.pasivoNoCorrienteDos));

    this.formularioCompleto.patrimonio = formatterDolar.format(parseFloat(this.formularioCompleto.totalPatrimonio));
    this.formularioCompleto.patrimonioDos = formatterDolar.format(parseFloat(this.formularioCompleto.totalPatrimonioDos));

    this.formularioCompleto.ventasCompleto = formatterDolar.format(parseFloat(this.formularioCompleto.ventasTotales.replace(/,/g,"")));
    this.formularioCompleto.ventasCompletoDos = formatterDolar.format(parseFloat(this.formularioCompleto.ventasTotalesDos.replace(/,/g,"")));

    this.formularioCompleto.capitalTrabajo =  (parseFloat(this.formularioCompleto.totalActivoCorriente) - parseFloat(this.formularioCompleto.totalPasivoCorriente)).toString();
    this.formularioCompleto.capitalTrabajo =  formatterDolar.format(parseFloat(this.formularioCompleto.capitalTrabajo));
    this.formularioCompleto.capitalTrabajoDos =  (parseFloat(this.formularioCompleto.totalActivoCorrienteDos) - parseFloat(this.formularioCompleto.totalPasivoCorrienteDos)).toString();
    this.formularioCompleto.capitalTrabajoDos = formatterDolar.format(parseFloat(this.formularioCompleto.capitalTrabajoDos));

    this.formularioCompleto.utilidadPerdida = formatterDolar.format(parseFloat(this.formularioCompleto.utilidadRepartible));
    this.formularioCompleto.utilidadPerdidaDos = formatterDolar.format(parseFloat(this.formularioCompleto.utilidadRepartibleDos));
    //this.formularioCompleto.utilidadPerdida =  (parseFloat(this.formularioCompleto.totalActivoCorriente) - parseFloat(this.formularioCompleto.totalPasivoCorriente)).toString();
    //this.formularioCompleto.utilidadPerdida = formatterDolar.format(parseFloat(this.formularioCompleto.utilidadPerdida));
    //this.formularioCompleto.utilidadPerdidaDos =  (parseFloat(this.formularioCompleto.totalActivoCorrienteDos) - parseFloat(this.formularioCompleto.totalPasivoCorrienteDos)).toString();
    //this.formularioCompleto.utilidadPerdidaDos = formatterDolar.format(parseFloat(this.formularioCompleto.utilidadPerdidaDos));

    


    // porcetajes  r
    this.formularioCompleto.utilidadBruta =  (parseFloat(this.formularioCompleto.ventasTotales.replace(/,/g,"")) - parseFloat(this.formularioCompleto.costoVentas.replace(/,/g,""))).toString();
    
    //porcentajes costo,utilidad
    console.log("valor 1" +parseFloat(this.formularioCompleto.costoVentasDos.replace(/,/g,"")))
    console.log("valor 2" +parseFloat(this.formularioCompleto.ventasTotales.replace(/,/g,"")))
    this.porcentaje1Er1= (parseFloat(this.formularioCompleto.costoVentas.replace(/,/g,""))/parseFloat(this.formularioCompleto.ventasTotales.replace(/,/g,""))*100).toFixed(2)
    this.porcentaje2Er1= (parseFloat(this.formularioCompleto.costoVentasDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.ventasTotalesDos.replace(/,/g,""))*100).toFixed(2)
    //fin
    
    this.formularioCompleto.utilidadBrutaDos =  (parseFloat(this.formularioCompleto.ventasTotalesDos.replace(/,/g,"")) - parseFloat(this.formularioCompleto.costoVentasDos.replace(/,/g,""))).toString();
    console.log("utilidad bruta dos"+this.formularioCompleto.utilidadBrutaDos)
    console.log("ventas totales dos"+this.formularioCompleto.utilidadBrutaDos)

    //porcentajes costo,utilidad 2
    this.porcentaje1Er2= (parseFloat(this.formularioCompleto.utilidadBruta.replace(/,/g,""))/parseFloat(this.formularioCompleto.ventasTotales.replace(/,/g,""))*100).toFixed(2)
    this.porcentaje2Er2= (parseFloat(this.formularioCompleto.utilidadBrutaDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.ventasTotalesDos.replace(/,/g,""))*100).toFixed(2)
    //fin
/*
    this.porcentaje1Pn1=((parseFloat(this.formularioCompleto.capitalSuscrito.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonio))*100).toFixed(2)
    this.porcentaje2Pn1=((parseFloat(this.formularioCompleto.capitalSuscritoNoPaga.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonio))*100).toFixed(2)
    this.porcentaje3Pn1=((parseFloat(this.formularioCompleto.aportesSocios.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonio))*100).toFixed(2)
    this.porcentaje4Pn1=((parseFloat(this.formularioCompleto.reservaLegal.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonio))*100).toFixed(2)
    this.porcentaje5Pn1=((parseFloat(this.formularioCompleto.otrasReservas.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonio))*100).toFixed(2)
    this.porcentaje6Pn1=((parseFloat(this.formularioCompleto.utilidadNoDistribuida.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonio))*100).toFixed(2)
    this.porcentaje7Pn1=((parseFloat(this.formularioCompleto.perdidaAcumulada.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonio))*100).toFixed(2)
    this.porcentaje8Pn1=((parseFloat(this.formularioCompleto.utilidadEjercito.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonio))*100).toFixed(2)
    
    console.log("NETO PORCENTAJE"+" "+this.porcentaje1Pn1)
    console.log("NETO PORCENTAJE"+" "+this.porcentaje2Pn1)
    console.log("NETO PORCENTAJE"+" "+this.porcentaje3Pn1)
    console.log("NETO PORCENTAJE"+" "+this.porcentaje4Pn1)
    console.log("NETO PORCENTAJE"+" "+this.porcentaje5Pn1)
    console.log("NETO PORCENTAJE"+" "+this.porcentaje6Pn1)
    console.log("NETO PORCENTAJE"+" "+this.porcentaje7Pn1)
    console.log("NETO PORCENTAJE"+" "+this.porcentaje8Pn1)

    this.porcentaje1Pn2=((parseFloat(this.formularioCompleto.capitalSuscritoDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonioDos))*100).toFixed(2)
    this.porcentaje2Pn2=((parseFloat(this.formularioCompleto.capitalSuscritoNoPagaDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonioDos))*100).toFixed(2)
    this.porcentaje3Pn2=((parseFloat(this.formularioCompleto.aportesSociosDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonioDos))*100).toFixed(2)
    this.porcentaje4Pn2=((parseFloat(this.formularioCompleto.reservaLegalDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonioDos))*100).toFixed(2)
    this.porcentaje5Pn2=((parseFloat(this.formularioCompleto.otrasReservasDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonioDos))*100).toFixed(2)
    this.porcentaje6Pn2=((parseFloat(this.formularioCompleto.utilidadNoDistribuidaDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonioDos))*100).toFixed(2)
    this.porcentaje7Pn2=((parseFloat(this.formularioCompleto.perdidaAcumuladaDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonioDos))*100).toFixed(2)
    this.porcentaje8Pn2=((parseFloat(this.formularioCompleto.utilidadEjercitoDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonioDos))*100).toFixed(2)
    
    console.log("NETO PORCENTAJE Dos"+" "+this.porcentaje1Pn2)
    console.log("NETO PORCENTAJE Dos"+" "+this.porcentaje2Pn2)
    console.log("NETO PORCENTAJE Dos"+" "+this.porcentaje3Pn2)
    console.log("NETO PORCENTAJE Dos"+" "+this.porcentaje4Pn2)
    console.log("NETO PORCENTAJE Dos"+" "+this.porcentaje5Pn2)
    console.log("NETO PORCENTAJE Dos"+" "+this.porcentaje6Pn2)
    console.log("NETO PORCENTAJE Dos"+" "+this.porcentaje7Pn2)
    console.log("NETO PORCENTAJE Dos"+" "+this.porcentaje8Pn2)
  */
    

    this.formularioCompleto.utilidadOperacional =  (parseFloat(this.formularioCompleto.utilidadBruta.replace(/,/g,"")) - parseFloat(this.formularioCompleto.gastosAdministracion.replace(/,/g,""))).toString();
    
    //porcentajes gastos,operacional
    this.porcentaje3Er1= (parseFloat(this.formularioCompleto.gastosAdministracion.replace(/,/g,""))/parseFloat(this.formularioCompleto.ventasTotales.replace(/,/g,""))*100).toFixed(2)
    this.porcentaje4Er1= (parseFloat(this.formularioCompleto.utilidadOperacional.replace(/,/g,""))/parseFloat(this.formularioCompleto.ventasTotales.replace(/,/g,""))*100).toFixed(2)
    //fin
    
    this.formularioCompleto.utilidadOperacionalDos =  (parseFloat(this.formularioCompleto.utilidadBrutaDos.replace(/,/g,"")) - parseFloat(this.formularioCompleto.gastosAdministracionDos.replace(/,/g,""))).toString();
    //porcentajes gastos,operacional2
    this.porcentaje3Er2= (parseFloat(this.formularioCompleto.gastosAdministracionDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.ventasTotalesDos.replace(/,/g,""))*100).toFixed(2)
    this.porcentaje4Er2= (parseFloat(this.formularioCompleto.utilidadOperacionalDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.ventasTotalesDos.replace(/,/g,""))*100).toFixed(2)
    //fin

    
    this.formularioCompleto.utilidadAntesImpuestos =  (parseFloat(this.formularioCompleto.utilidadOperacional.replace(/,/g,"")) + parseFloat(this.formularioCompleto.ingresosNoOperacionales.replace(/,/g,"")) - parseFloat(this.formularioCompleto.gastosNoOperacio.replace(/,/g,""))).toString();
    
    //porcentajes no operacionales,operacionales1
    this.porcentaje5Er1= (parseFloat(this.formularioCompleto.ingresosNoOperacionales.replace(/,/g,""))/parseFloat(this.formularioCompleto.ventasTotales.replace(/,/g,""))*100).toFixed(2)
    this.porcentaje6Er1= (parseFloat(this.formularioCompleto.gastosNoOperacio.replace(/,/g,""))/parseFloat(this.formularioCompleto.ventasTotales.replace(/,/g,""))*100).toFixed(2)
    this.porcentaje7Er1= (parseFloat(this.formularioCompleto.utilidadAntesImpuestos.replace(/,/g,""))/parseFloat(this.formularioCompleto.ventasTotales.replace(/,/g,""))*100).toFixed(2)
    //fin
    this.formularioCompleto.utilidadAntesImpuestosDos =  (parseFloat(this.formularioCompleto.utilidadOperacionalDos.replace(/,/g,"")) + parseFloat(this.formularioCompleto.ingresosNoOperacionalesDos.replace(/,/g,"")) - parseFloat(this.formularioCompleto.gastosNoOperacioDos.replace(/,/g,""))).toString();
    //porcentajes no operaciones,operacionesl2
    this.porcentaje5Er2= (parseFloat(this.formularioCompleto.ingresosNoOperacionalesDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.ventasTotalesDos.replace(/,/g,""))*100).toFixed(2)
    this.porcentaje6Er2= (parseFloat(this.formularioCompleto.gastosNoOperacioDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.ventasTotalesDos.replace(/,/g,""))*100).toFixed(2)
    this.porcentaje7Er2= (parseFloat(this.formularioCompleto.utilidadAntesImpuestosDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.ventasTotalesDos.replace(/,/g,""))*100).toFixed(2)
    //fin  

    this.formularioCompleto.utilidadTributable =  (parseFloat(this.formularioCompleto.utilidadAntesImpuestos.replace(/,/g,"")) - parseFloat(this.formularioCompleto.participacionTrabajadores.replace(/,/g,""))).toString();
    
    //porcentajes trabajadores,utilidad tributable
    this.porcentaje8Er1= (parseFloat(this.formularioCompleto.participacionTrabajadores.replace(/,/g,""))/parseFloat(this.formularioCompleto.ventasTotales.replace(/,/g,""))*100).toFixed(2)
    this.porcentaje9Er1= (parseFloat(this.formularioCompleto.utilidadTributable.replace(/,/g,""))/parseFloat(this.formularioCompleto.ventasTotales.replace(/,/g,""))*100).toFixed(2)
    //fin
    
    this.formularioCompleto.utilidadTributableDos =  (parseFloat(this.formularioCompleto.utilidadAntesImpuestosDos.replace(/,/g,"")) - parseFloat(this.formularioCompleto.participacionTrabajadoresDos.replace(/,/g,""))).toString();
    
    //porcentajes trabajadores, utilidad tributanle 2
    this.porcentaje8Er2= (parseFloat(this.formularioCompleto.participacionTrabajadoresDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.ventasTotalesDos.replace(/,/g,""))*100).toFixed(2)
    this.porcentaje9Er2= (parseFloat(this.formularioCompleto.utilidadTributableDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.ventasTotalesDos.replace(/,/g,""))*100).toFixed(2)
    //fin

    this.formularioCompleto.utilidadRepartible =  (parseFloat(this.formularioCompleto.utilidadTributable.replace(/,/g,"")) - parseFloat(this.formularioCompleto.impuestoARenta.replace(/,/g,""))).toString();
    
    //porcentaje renta,utilidad
    this.porcentaje10Er1= (parseFloat(this.formularioCompleto.impuestoARenta.replace(/,/g,""))/parseFloat(this.formularioCompleto.utilidadBruta.replace(/,/g,""))*100).toFixed(2)
    this.porcentaje11Er1= (parseFloat(this.formularioCompleto.utilidadRepartible.replace(/,/g,""))/parseFloat(this.formularioCompleto.ventasTotales.replace(/,/g,""))*100).toFixed(2)

    //fin
    
    this.formularioCompleto.utilidadRepartibleDos =  (parseFloat(this.formularioCompleto.utilidadTributableDos.replace(/,/g,"")) - parseFloat(this.formularioCompleto.impuestoARentaDos.replace(/,/g,""))).toString();
      
    //porcentaje renta,utilidad2
    this.porcentaje10Er2= (parseFloat(this.formularioCompleto.impuestoARentaDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.utilidadBrutaDos.replace(/,/g,""))*100).toFixed(2)
    this.porcentaje11Er2= (parseFloat(this.formularioCompleto.utilidadRepartibleDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.ventasTotalesDos.replace(/,/g,""))*100).toFixed(2)
    //fin
    //VARIACION
    this.formularioCompleto.valor1 =  (parseFloat(this.formularioCompleto.activoCorriente.replace(/,/g,"")) - parseFloat(this.formularioCompleto.activoCorrienteDos.replace(/,/g,""))).toString();
    this.formularioCompleto.valor1 = formatterDolar.format(parseFloat(this.formularioCompleto.valor1))
    this.formularioCompleto.porcentaje1 =  (parseFloat(this.formularioCompleto.valor1.replace(/,/g,"")) / parseFloat(this.formularioCompleto.activoCorrienteDos.replace(/,/g,""))*100).toFixed(0).toString();

    this.formularioCompleto.valor2 =  (parseFloat(this.formularioCompleto.activoNoCorriente.replace(/,/g,"")) - parseFloat(this.formularioCompleto.activoNoCorrienteDos.replace(/,/g,""))).toString();
    this.formularioCompleto.valor2 = formatterDolar.format(parseFloat(this.formularioCompleto.valor2))
    this.formularioCompleto.porcentaje2 =  (parseFloat(this.formularioCompleto.valor2.replace(/,/g,"")) / parseFloat(this.formularioCompleto.activoNoCorrienteDos.replace(/,/g,""))*100).toFixed(0).toString();

    this.formularioCompleto.valor3 =  (parseFloat(this.formularioCompleto.totalActivos.replace(/,/g,"")) - parseFloat(this.formularioCompleto.totalActivosDos.replace(/,/g,""))).toString();
    this.formularioCompleto.valor3 = formatterDolar.format(parseFloat(this.formularioCompleto.valor3))
    this.formularioCompleto.porcentaje3 =  (parseFloat(this.formularioCompleto.valor3.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalActivosDos.replace(/,/g,""))*100).toFixed(0).toString();

    this.formularioCompleto.valor4 =  (parseFloat(this.formularioCompleto.pasivoCorriente.replace(/,/g,"")) - parseFloat(this.formularioCompleto.pasivoCorrienteDos.replace(/,/g,""))).toString();
    this.formularioCompleto.valor4 = formatterDolar.format(parseFloat(this.formularioCompleto.valor4))
    this.formularioCompleto.porcentaje4 =  (parseFloat(this.formularioCompleto.valor4.replace(/,/g,"")) / parseFloat(this.formularioCompleto.pasivoCorrienteDos.replace(/,/g,""))*100).toFixed(0).toString();
     
    this.formularioCompleto.valor5 =  (parseFloat(this.formularioCompleto.pasivoNoCorriente.replace(/,/g,"")) - parseFloat(this.formularioCompleto.pasivoNoCorrienteDos.replace(/,/g,""))).toString();
    this.formularioCompleto.valor5 = formatterDolar.format(parseFloat(this.formularioCompleto.valor5))
    this.formularioCompleto.porcentaje5 =  (parseFloat(this.formularioCompleto.valor5.replace(/,/g,"")) / parseFloat(this.formularioCompleto.pasivoNoCorrienteDos.replace(/,/g,""))*100).toFixed(0).toString();

    this.formularioCompleto.valor6 =  (parseFloat(this.formularioCompleto.totalPasivos.replace(/,/g,"")) - parseFloat(this.formularioCompleto.totalPasivosDos.replace(/,/g,""))).toString();
    this.formularioCompleto.valor6= formatterDolar.format(parseFloat(this.formularioCompleto.valor6))
    
    
    this.formularioCompleto.porcentaje6 =  (parseFloat(this.formularioCompleto.valor6.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalPasivosDos.replace(/,/g,""))*100).toFixed(0).toString();
    console.log("pasivo totales"+parseFloat(this.formularioCompleto.valor6.replace(/,/g,"")))
    console.log("pasivo totales"+parseFloat(this.formularioCompleto.totalPasivosDos.replace(/,/g,"")))
    console.log("pasivo totales"+parseFloat(this.formularioCompleto.porcentaje6))

    this.formularioCompleto.valor7 =  (parseFloat(this.formularioCompleto.patrimonio.replace(/,/g,"")) - parseFloat(this.formularioCompleto.patrimonioDos.replace(/,/g,""))).toString();
    this.formularioCompleto.valor7= formatterDolar.format(parseFloat(this.formularioCompleto.valor7))
    this.formularioCompleto.porcentaje7 =  (parseFloat(this.formularioCompleto.valor7.replace(/,/g,"")) / parseFloat(this.formularioCompleto.patrimonioDos.replace(/,/g,""))*100).toFixed(0).toString();

    this.formularioCompleto.valor8 =  (parseFloat(this.formularioCompleto.ventasCompleto.replace(/,/g,"")) - parseFloat(this.formularioCompleto.ventasCompletoDos.replace(/,/g,""))).toString();
    this.formularioCompleto.valor8= formatterDolar.format(parseFloat(this.formularioCompleto.valor8))
    this.formularioCompleto.porcentaje8 =  (parseFloat(this.formularioCompleto.valor8.replace(/,/g,"")) / parseFloat(this.formularioCompleto.ventasCompletoDos.replace(/,/g,""))*100).toFixed(0).toString();

    this.formularioCompleto.valor9 =  (parseFloat(this.formularioCompleto.utilidadPerdida.replace(/,/g,"")) - parseFloat(this.formularioCompleto.utilidadPerdidaDos.replace(/,/g,""))).toString();
    this.formularioCompleto.valor9= formatterDolar.format(parseFloat(this.formularioCompleto.valor9))
    this.formularioCompleto.porcentaje9 =  (parseFloat(this.formularioCompleto.valor9.replace(/,/g,"")) / parseFloat(this.formularioCompleto.utilidadPerdidaDos.replace(/,/g,""))*100).toFixed(0).toString();

    this.formularioCompleto.valor10 =  (parseFloat(this.formularioCompleto.capitalTrabajo.replace(/,/g,"")) - parseFloat(this.formularioCompleto.capitalTrabajoDos.replace(/,/g,""))).toString();
    this.formularioCompleto.valor10= formatterDolar.format(parseFloat(this.formularioCompleto.valor10))
    this.formularioCompleto.porcentaje10 =  (parseFloat(this.formularioCompleto.valor10.replace(/,/g,"")) / parseFloat(this.formularioCompleto.capitalTrabajoDos.replace(/,/g,""))*100).toFixed(0).toString();


    // INDICES FINANCIEROS
    
    this.formularioCompleto.razonCorrienteDos =  (parseFloat(this.formularioCompleto.totalActivoCorriente) / parseFloat(this.formularioCompleto.totalPasivoCorriente)).toFixed(2).toString();
    this.formularioCompleto.razonCorrienteTres =  (parseFloat(this.formularioCompleto.totalActivoCorrienteDos) / parseFloat(this.formularioCompleto.totalPasivoCorrienteDos)).toFixed(2).toString();
   
    this.formularioCompleto.razonCorrienteDos=formatterDolar.format(parseFloat(this.formularioCompleto.razonCorrienteDos))
    this.formularioCompleto.razonCorrienteTres=formatterDolar.format(parseFloat(this.formularioCompleto.razonCorrienteTres))
    
    this.formularioCompleto.pruebaAcidaDos =  ((parseFloat(this.formularioCompleto.totalActivoCorriente) - parseFloat(this.formularioCompleto.inventariosCompleto.replace(/,/g,"")))/ parseFloat(this.formularioCompleto.totalPasivoCorriente)).toFixed(2).toString();
    this.formularioCompleto.pruebaAcidaTres =  ((parseFloat(this.formularioCompleto.totalActivoCorrienteDos) - parseFloat(this.formularioCompleto.inventariosCompletoDos.replace(/,/g,""))) / parseFloat(this.formularioCompleto.totalPasivoCorrienteDos)).toFixed(2).toString();
    
    this.formularioCompleto.pruebaAcidaDos=formatterDolar.format(parseFloat(this.formularioCompleto.pruebaAcidaDos))
    this.formularioCompleto.pruebaAcidaTres=formatterDolar.format(parseFloat(this.formularioCompleto.pruebaAcidaTres))
    
    this.formularioCompleto.razonEfectivoDos =  (parseFloat(this.formularioCompleto.cajaBancosCompleto.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalPasivoCorriente)).toFixed(2).toString();
    this.formularioCompleto.razonEfectivoTres =  (parseFloat(this.formularioCompleto.cajaBancosCompletoDos.replace(/,/g,"")) / parseFloat(this.formularioCompleto.totalPasivoCorrienteDos) ).toFixed(2).toString();

    this.formularioCompleto.razonEfectivoDos=formatterDolar.format(parseFloat(this.formularioCompleto.razonEfectivoDos))
    this.formularioCompleto.razonEfectivoTres=formatterDolar.format(parseFloat(this.formularioCompleto.razonEfectivoTres))    
    
    this.formularioCompleto.razonDeudaTotalDos =  ((parseFloat(this.formularioCompleto.totalDelActivo) - parseFloat(this.formularioCompleto.totalPatrimonio)) / parseFloat(this.formularioCompleto.totalDelActivo)).toFixed(2).toString();
    this.formularioCompleto.razonDeudaTotalTres =  ((parseFloat(this.formularioCompleto.totalDelActivoDos) - parseFloat(this.formularioCompleto.totalPatrimonioDos)) / parseFloat(this.formularioCompleto.totalDelActivoDos)).toFixed(2).toString();
    
    this.formularioCompleto.razonDeudaTotalDos=formatterDolar.format(parseFloat(this.formularioCompleto.razonDeudaTotalDos))
    this.formularioCompleto.razonDeudaTotalTres=formatterDolar.format(parseFloat(this.formularioCompleto.razonDeudaTotalTres))  

    this.formularioCompleto.rotacionInventarioDos =  (parseFloat(this.formularioCompleto.costoVentas.replace(/,/g,"")) / parseFloat(this.formularioCompleto.inventariosCompleto.replace(/,/g,""))).toFixed(2).toString();
    this.formularioCompleto.rotacionInventarioTres =  (parseFloat(this.formularioCompleto.costoVentasDos.replace(/,/g,"")) / parseFloat(this.formularioCompleto.inventariosCompletoDos.replace(/,/g,""))).toFixed(2).toString();
    
    this.formularioCompleto.rotacionInventarioDos=formatterDolar.format(parseFloat(this.formularioCompleto.rotacionInventarioDos))
    this.formularioCompleto.rotacionInventarioTres=formatterDolar.format(parseFloat(this.formularioCompleto.rotacionInventarioTres))  
    
    //this.formularioCompleto.diasVentaDos =  ( 365 / parseFloat(this.formularioCompleto.rotacionInventarioDos)).toFixed(0).toString();
    //this.formularioCompleto.diasVentaTres =  ( 365 / parseFloat(this.formularioCompleto.rotacionInventarioTres)).toFixed(0).toString();
    
    if (this.formularioCompleto.rotacionInventarioDos != "---" || this.formularioCompleto.rotacionInventarioDos != undefined || parseFloat(this.formularioCompleto.rotacionInventarioDos) != Infinity || parseFloat(this.formularioCompleto.rotacionInventarioDos) != 0) {
      this.formularioCompleto.diasVentaDos =  ( 365 / parseFloat(this.formularioCompleto.rotacionInventarioDos)).toFixed(0).toString();
    } else {
      this.formularioCompleto.diasVentaDos = "---"
    }  

    if (this.formularioCompleto.rotacionInventarioTres != "---" || this.formularioCompleto.rotacionInventarioTres != undefined || parseFloat(this.formularioCompleto.rotacionInventarioTres) != Infinity || parseFloat(this.formularioCompleto.rotacionInventarioTres) != 0) {
      this.formularioCompleto.diasVentaTres =  ( 365 / parseFloat(this.formularioCompleto.rotacionInventarioTres)).toFixed(0).toString();

    } else {
      this.formularioCompleto.diasVentaTres = "---"
    }  


    //this.formularioCompleto.rotacionCuentasDos =  ( parseFloat(this.formularioCompleto.ventasTotales) / (parseFloat(this.formularioCompleto.cuentasRelacionadas) + parseFloat(this.formularioCompleto.cuentasNoRelacionadas))).toFixed(2).toString();
    //this.formularioCompleto.rotacionCuentasTres =  ( parseFloat(this.formularioCompleto.ventasTotalesDos) / (parseFloat(this.formularioCompleto.cuentasRelacionadasDos) + parseFloat(this.formularioCompleto.cuentasNoRelacionadasDos))).toFixed(2).toString();
    this.sumaCuentas = (parseFloat(this.formularioCompleto.cuentasRelacionadas) + parseFloat(this.formularioCompleto.cuentasNoRelacionadas));
    this.sumaCuentas1 = (parseFloat(this.formularioCompleto.cuentasRelacionadasDos) + parseFloat(this.formularioCompleto.cuentasNoRelacionadasDos));
    if( this.sumaCuentas ===0 || this.sumaCuentas === undefined || this.sumaCuentas === Infinity || this.sumaCuentas === NaN ){ 
    
      this.formularioCompleto.rotacionCuentasDos = "---";
    }else{
    
      this.formularioCompleto.rotacionCuentasDos =  ( parseFloat(this.formularioCompleto.ventasTotales.replace(/,/g,"")) / ((parseFloat(this.formularioCompleto.cuentasRelacionadas.replace(/,/g,"")) + parseFloat(this.formularioCompleto.cuentasNoRelacionadas.replace(/,/g,""))))).toFixed(2).toString();
      this.formularioCompleto.rotacionCuentasDos=formatterDolar.format(parseFloat(this.formularioCompleto.rotacionCuentasDos))  

    }


    if(this.sumaCuentas1 ===0 || this.sumaCuentas1 === undefined || this.sumaCuentas1 === Infinity || this.sumaCuentas1 === NaN){
      this.formularioCompleto.rotacionCuentasTres = "---";

    }else{
      this.formularioCompleto.rotacionCuentasTres =  ( parseFloat(this.formularioCompleto.ventasTotalesDos.replace(/,/g,"")) / (parseFloat(this.formularioCompleto.cuentasRelacionadasDos.replace(/,/g,"")) + parseFloat(this.formularioCompleto.cuentasNoRelacionadasDos.replace(/,/g,"")))).toFixed(2).toString();
      this.formularioCompleto.rotacionCuentasTres=formatterDolar.format(parseFloat(this.formularioCompleto.rotacionCuentasTres))  

    }
    
   //----------------------------------------------DIAS---------------------------------------------
    //this.formularioCompleto.diasPromedioDos =  ( 365 / (parseFloat(this.formularioCompleto.rotacionCuentasDos))).toFixed(0).toString();
    //this.formularioCompleto.diasPromedioTres =  ( 365 / (parseFloat(this.formularioCompleto.rotacionCuentasTres))).toFixed(0).toString();
    
    if (this.formularioCompleto.rotacionCuentasDos === "---" || this.formularioCompleto.rotacionCuentasDos === undefined || parseFloat(this.formularioCompleto.rotacionCuentasDos) === Infinity || parseFloat(this.formularioCompleto.rotacionCuentasDos) === 0) {
      this.formularioCompleto.diasPromedioDos = "---"

    } else {
      this.formularioCompleto.diasPromedioDos =  ( 365 / (parseFloat(this.formularioCompleto.rotacionCuentasDos))).toFixed(0).toString();
    } 
  

    if (this.formularioCompleto.rotacionCuentasTres === "---" || this.formularioCompleto.rotacionCuentasTres === undefined || parseFloat(this.formularioCompleto.rotacionCuentasTres) === Infinity || parseFloat(this.formularioCompleto.rotacionCuentasTres) === 0) {
      this.formularioCompleto.diasPromedioTres = "---"
    } else {
      this.formularioCompleto.diasPromedioTres =  ( 365 / (parseFloat(this.formularioCompleto.rotacionCuentasTres))).toFixed(0).toString();

    } 
    //----------------------------------------------FIN DIAS---------------------------------------------


    //this.formularioCompleto.rotacionCuentasPagarDos =   (parseFloat(this.formularioCompleto.costoVentas) / (parseFloat(this.formularioCompleto.cuentasProveedoresRelacionado) + (parseFloat(this.formularioCompleto.cuentasProveedoresNoRelacionado)))).toFixed(2).toString();
    //this.formularioCompleto.rotacionCuentasPagarTres =   (parseFloat(this.formularioCompleto.costoVentasDos) / (parseFloat(this.formularioCompleto.cuentasProveedoresRelacionadoDos) + (parseFloat(this.formularioCompleto.cuentasProveedoresNoRelacionadoDos)))).toFixed(2).toString();
    this.sumaRotacionCuentas1= parseFloat(this.formularioCompleto.cuentasProveedoresRelacionado) + parseFloat(this.formularioCompleto.cuentasProveedoresNoRelacionado)
    this.sumaRotacionCuentas2= parseFloat(this.formularioCompleto.cuentasProveedoresRelacionadoDos) + parseFloat(this.formularioCompleto.cuentasProveedoresNoRelacionadoDos)
    if(this.sumaRotacionCuentas1 ===0 || this.sumaRotacionCuentas1 === undefined || this.sumaRotacionCuentas1 === Infinity || this.sumaRotacionCuentas1 === NaN){
      this.formularioCompleto.rotacionCuentasPagarDos = "---";

    }else{
      this.formularioCompleto.rotacionCuentasPagarDos =   (parseFloat(this.formularioCompleto.costoVentas.replace(/,/g,"")) / (parseFloat(this.formularioCompleto.cuentasProveedoresRelacionado.replace(/,/g,"")) + (parseFloat(this.formularioCompleto.cuentasProveedoresNoRelacionado.replace(/,/g,""))))).toFixed(2).toString();
      this.formularioCompleto.rotacionCuentasPagarDos=formatterDolar.format(parseFloat(this.formularioCompleto.rotacionCuentasPagarDos))  

    }

    if(this.sumaRotacionCuentas2 ===0 || this.sumaRotacionCuentas2 === undefined || this.sumaRotacionCuentas2 === Infinity || this.sumaRotacionCuentas2 === NaN){
      this.formularioCompleto.rotacionCuentasPagarTres = "---";

    }else{
      this.formularioCompleto.rotacionCuentasPagarTres =   (parseFloat(this.formularioCompleto.costoVentasDos.replace(/,/g,"")) / (parseFloat(this.formularioCompleto.cuentasProveedoresRelacionadoDos.replace(/,/g,"")) + (parseFloat(this.formularioCompleto.cuentasProveedoresNoRelacionadoDos.replace(/,/g,""))))).toFixed(2).toString();
      this.formularioCompleto.rotacionCuentasPagarTres=formatterDolar.format(parseFloat(this.formularioCompleto.rotacionCuentasPagarTres))  

    }


    //----------------------------------------------DIAS---------------------------------------------
    //this.formularioCompleto.diasCuentasPagarDos =  ( 365 / (parseFloat(this.formularioCompleto.rotacionCuentasPagarDos))).toFixed(0).toString();
    //this.formularioCompleto.diasCuentasPagarTres =  ( 365 / (parseFloat(this.formularioCompleto.rotacionCuentasPagarTres))).toFixed(0).toString();

    if (this.formularioCompleto.rotacionCuentasPagarDos != "---" || this.formularioCompleto.rotacionCuentasPagarDos != undefined || parseFloat(this.formularioCompleto.rotacionCuentasPagarDos) != Infinity || parseFloat(this.formularioCompleto.rotacionCuentasPagarDos) != 0) {
      this.formularioCompleto.diasCuentasPagarDos =  ( 365 / (parseFloat(this.formularioCompleto.rotacionCuentasPagarDos))).toFixed(0).toString(); 
       } else {
      
        this.formularioCompleto.diasCuentasPagarDos = "---"
    }
    if (this.formularioCompleto.rotacionCuentasPagarTres != "---" || this.formularioCompleto.rotacionCuentasPagarTres != undefined || parseFloat(this.formularioCompleto.rotacionCuentasPagarTres) != Infinity || parseFloat(this.formularioCompleto.rotacionCuentasPagarTres) != 0) {
      this.formularioCompleto.diasCuentasPagarTres =  ( 365 / (parseFloat(this.formularioCompleto.rotacionCuentasPagarTres))).toFixed(0).toString();
    } else {
      
        this.formularioCompleto.diasCuentasPagarTres = "---"
    } 
    //----------------------------------------------FIN DIAS---------------------------------------------



    this.formularioCompleto.rotacionActivosTotalesDos =   (parseFloat(this.formularioCompleto.ventasTotales.replace(/,/g,"")) / (parseFloat(this.formularioCompleto.totalDelActivo.replace(/,/g,"")) )).toFixed(2).toString();
    this.formularioCompleto.rotacionActivosTotalesTres =   (parseFloat(this.formularioCompleto.ventasTotalesDos.replace(/,/g,"")) / (parseFloat(this.formularioCompleto.totalDelActivoDos.replace(/,/g,"")) )).toFixed(2).toString();

    this.formularioCompleto.margenUtilidadDos =   ((parseFloat(this.formularioCompleto.utilidadRepartible.replace(/,/g,"")) / (parseFloat(this.formularioCompleto.ventasTotales.replace(/,/g,"")) ))*100).toFixed(2).toString();
    this.formularioCompleto.margenUtilidadTres =   ((parseFloat(this.formularioCompleto.utilidadRepartibleDos.replace(/,/g,"")) / (parseFloat(this.formularioCompleto.ventasTotalesDos.replace(/,/g,"")) ))*100).toFixed(2).toString();

    this.formularioCompleto.rendimientoActivosDos =   ((parseFloat(this.formularioCompleto.utilidadRepartible.replace(/,/g,"")) / (parseFloat(this.formularioCompleto.totalDelActivo.replace(/,/g,""))))*100).toFixed(2).toString();
    this.formularioCompleto.rendimientoActivosTres =  ((parseFloat(this.formularioCompleto.utilidadRepartibleDos.replace(/,/g,"")) / (parseFloat(this.formularioCompleto.totalDelActivoDos.replace(/,/g,""))))*100).toFixed(2).toString();

    this.formularioCompleto.rendimientoCapitalDos =   ((parseFloat(this.formularioCompleto.utilidadRepartible.replace(/,/g,"")) / (parseFloat(this.formularioCompleto.totalPatrimonio.replace(/,/g,""))))*100).toFixed(2).toString();
    this.formularioCompleto.rendimientoCapitalTres =   ((parseFloat(this.formularioCompleto.utilidadRepartibleDos.replace(/,/g,"")) / (parseFloat(this.formularioCompleto.totalPatrimonioDos.replace(/,/g,""))))*100).toFixed(2).toString();






      

    //DUPLICADO  
    /*
    this.formularioCompleto.totalActivosLargoPlazoDos = (
        parseFloat(this.formularioCompleto.totalActivoFijoDos.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.totalActivoDiferidoDos.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.inversionesParticipacionesDos.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.otrasInversionesLargoPlazoDos.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.cuentasPorCobrarRelacionadasDos.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.cuentasPorCobrarNoRelacionadasDos.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.otrasCuentasPorCobrarRelacionadasDos.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.otrasCuentasPorCobrarNoRelacionadasDos.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.provisionCuentasIncobrablesDos.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.otrosActivosLargoPlazoDos.replace(/,/g,""))
    ).toFixed(2).toString();
    */
    //FIN DEL DUPLICADO
    


    


     

   

    

   
    /*

    this.porcentaje1Tn1=((parseFloat(this.formularioCompleto.totalDelPasivo.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonio))*100).toFixed(2)
    this.porcentaje2Tpn1=((parseFloat(this.formularioCompleto.totalPatrimonio.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPatrimonio))*100).toFixed(2)
    console.log("NETO PORCENTAJE Dos"+" "+this.porcentaje1Tn1)
    console.log("NETO PORCENTAJE Dos"+" "+this.porcentaje2Tpn1)*/

    this.formularioCompleto.totalPasivoPatrimonioDos = (
        parseFloat(this.formularioCompleto.totalDelPasivoDos.replace(/,/g,"")) +
        parseFloat(this.formularioCompleto.totalPatrimonioDos.replace(/,/g,""))   
                
    ).toFixed(2).toString();

   
    
    //porcentajes finales activos
        this.porcentajeAcorriente1=(parseFloat(this.formularioCompleto.totalActivoCorriente)/parseFloat(this.formularioCompleto.totalDelActivo)*100).toFixed(0)
        console.log("valor del primer total"+this.porcentajeAcorriente1)
        this.porcentajeAcorriente2=(parseFloat(this.formularioCompleto.totalActivoCorrienteDos)/parseFloat(this.formularioCompleto.totalDelActivoDos)*100).toFixed(0)
        console.log("valor del primer total"+this.porcentajeAcorriente2)
        /* this.porcentajeTotalAFijo1=(parseFloat(this.formularioCompleto.totalActivoFijo.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalActivosLargoPlazo.replace(/,/g,""))*100).toFixed(2)
        console.log("Segundo porcentaje final"+this.porcentajeTotalAFijo1)
        this.porcentajeTotalADiferido1=(parseFloat(this.formularioCompleto.totalActivoDiferido.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalActivosLargoPlazo.replace(/,/g,""))*100).toFixed(2)
        console.log("Tercer porcentaje final"+this.porcentajeTotalADiferido1)*/
        this.porcentajeTotalAlargoPlazo1=(parseFloat(this.formularioCompleto.totalActivosLargoPlazo.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalDelActivo.replace(/,/g,""))*100).toFixed(0)
        console.log("porcentaje total 4"+ this.porcentajeTotalAlargoPlazo1)

        this.porcentajeTotalActivo1=(parseFloat(this.porcentajeAcorriente1.replace(/,/g,""))+parseFloat(this.porcentajeTotalAlargoPlazo1.replace(/,/g,""))).toFixed(0)
        console.log("total activo porcentaje 1"+this.porcentajeTotalActivo1)
/*
         this.porcentajeTotalAFijo2=(parseFloat(this.formularioCompleto.totalActivoFijoDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalActivosLargoPlazoDos.replace(/,/g,""))*100).toFixed(2)
          console.log("Segundo porcentaje final"+this.porcentajeTotalAFijo2)
          this.porcentajeTotalADiferido2=(parseFloat(this.formularioCompleto.totalActivoDiferidoDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalActivosLargoPlazoDos.replace(/,/g,""))*100).toFixed(2)
          console.log("Tercer porcentaje final"+this.porcentajeTotalADiferido2)*/
          
          this.porcentajeTotalAlargoPlazo2=(parseFloat(this.formularioCompleto.totalActivosLargoPlazoDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalDelActivoDos.replace(/,/g,""))*100).toFixed(0)
          console.log("porcentaje total 4"+ this.porcentajeTotalAlargoPlazo2)

          this.porcentajeTotalActivo2=(parseFloat(this.porcentajeAcorriente2.replace(/,/g,""))+parseFloat(this.porcentajeTotalAlargoPlazo2.replace(/,/g,""))).toFixed(0)
          console.log("total activo porcentaje 2"+this.porcentajeTotalActivo2)

          //pasivos finales totales
          
          this.porcentajeTotalPasivoC1=((parseFloat( this.formularioCompleto.totalPasivoCorriente.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoPatrimonio.replace(/,/g,"")))*100).toFixed(0)
          this.porcentajeTotalPasivoC1prueba=((parseFloat( this.formularioCompleto.totalPasivoCorriente.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoPatrimonio.replace(/,/g,"")))*100).toFixed(2)

          console.log("porcentaje total pasivo corriente"+this.porcentajeTotalPasivoC1)

          this.porcentajeTotalPasivoLargoP1=((parseFloat(this.formularioCompleto.totPasivoLargoPlazo.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoPatrimonio.replace(/,/g,"")))*100).toFixed(0)
          this.porcentajeTotalPasivoLargoP1prueba=((parseFloat(this.formularioCompleto.totPasivoLargoPlazo.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoPatrimonio.replace(/,/g,"")))*100).toFixed(2)
          console.log("pasivo largo"+this.porcentajeTotalPasivoLargoP1)

          this.porcentajeTotalPasivo1=((parseFloat(this.porcentajeTotalPasivoC1)+parseFloat(this.porcentajeTotalPasivoLargoP1))).toFixed(0)
          console.log("porcentaje total pasivo"+this.porcentajeTotalPasivo1)

          this.porcentajeTotalPatrimonioN1=((parseFloat(this.formularioCompleto.totalPatrimonio.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoPatrimonio.replace(/,/g,"")))*100).toFixed(0)
          this.porcentajeTotalPatrimonioN1prueba=((parseFloat(this.formularioCompleto.totalPatrimonio.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoPatrimonio.replace(/,/g,"")))*100).toFixed(2)

          console.log("porcentaje total patrimonio"+this.porcentajeTotalPatrimonioN1)
          
          this.porcentajePatrimonioPasivo1=((parseFloat(this.porcentajeTotalPasivoC1prueba.replace(/,/g,""))+parseFloat(this.porcentajeTotalPasivoLargoP1prueba.replace(/,/g,""))+parseFloat(this.porcentajeTotalPatrimonioN1prueba.replace(/,/g,"")))).toFixed(0)
          console.log("porcentajePatrimonio"+this.porcentajePatrimonioPasivo1)

//-----------------------SEGUNDO AÑO----------------------------

          this.porcentajeTotalPasivoC2=((parseFloat( this.formularioCompleto.totalPasivoCorrienteDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoPatrimonioDos.replace(/,/g,"")))*100).toFixed(0)
          this.porcentajeTotalPasivoC2prueba=((parseFloat( this.formularioCompleto.totalPasivoCorrienteDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoPatrimonioDos.replace(/,/g,"")))*100).toFixed(2)

          console.log("porcentaje total pasivo corriente"+this.porcentajeTotalPasivoC2)
         
          this.porcentajeTotalPasivoLargoP2=((parseFloat(this.formularioCompleto.totPasivoLargoPlazoDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoPatrimonioDos.replace(/,/g,"")))*100).toFixed(0)
          this.porcentajeTotalPasivoLargoP2prueba=((parseFloat(this.formularioCompleto.totPasivoLargoPlazoDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoPatrimonioDos.replace(/,/g,"")))*100).toFixed(2)

          console.log("pasivo largo"+this.porcentajeTotalPasivoLargoP2)
         
          this.porcentajeTotalPasivo2=((parseFloat(this.porcentajeTotalPasivoC2)+parseFloat(this.porcentajeTotalPasivoLargoP2))).toFixed(0)
          console.log("porcentaje total pasivo"+this.porcentajeTotalPasivo2)
         
          this.porcentajeTotalPatrimonioN2=((parseFloat(this.formularioCompleto.totalPatrimonioDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoPatrimonioDos.replace(/,/g,"")))*100).toFixed(0)
          this.porcentajeTotalPatrimonioN2prueba=((parseFloat(this.formularioCompleto.totalPatrimonioDos.replace(/,/g,""))/parseFloat(this.formularioCompleto.totalPasivoPatrimonioDos.replace(/,/g,"")))*100).toFixed(2)
          console.log("porcentaje total patrimonio"+this.porcentajeTotalPatrimonioN2)
        
           //this.porcentajePatrimonioPasivo2=((parseFloat(this.porcentajeTotalPasivoC2.replace(/,/g,""))+parseFloat(this.porcentajeTotalPasivoLargoP2.replace(/,/g,""))+parseFloat(this.porcentajeTotalPatrimonioN2.replace(/,/g,"")))).toFixed(0)
           this.porcentajePatrimonioPasivo2=((parseFloat(this.porcentajeTotalPasivoC2prueba.replace(/,/g,""))+parseFloat(this.porcentajeTotalPasivoLargoP2prueba.replace(/,/g,""))+parseFloat(this.porcentajeTotalPatrimonioN2prueba.replace(/,/g,"")))).toFixed(0)

           console.log("porcentajePatrimonio"+this.porcentajePatrimonioPasivo2)

          

  //  Swal.fire('Correcto', `Calculos exitosos`, 'success');

  this.updateCharts();

}
updateCharts() {
   const formatterDolar = new Intl.NumberFormat('en-US', {
      currency: 'USD'
    })
    this.barChartData = [
        {
            data: [
                parseFloat(this.formularioCompleto.activoCorriente.replace(/,/g,"")),
                parseFloat(this.formularioCompleto.activoNoCorriente.replace(/,/g,"")),
                parseFloat(this.formularioCompleto.totalActivos.replace(/,/g,"")),
                parseFloat(this.formularioCompleto.pasivoCorriente.replace(/,/g,"")),
                parseFloat(this.formularioCompleto.pasivoNoCorriente.replace(/,/g,"")),
                parseFloat(this.formularioCompleto.totalPasivos.replace(/,/g,"")),
                parseFloat(this.formularioCompleto.patrimonio.replace(/,/g,"")),
                parseFloat(this.formularioCompleto.ventasCompleto.replace(/,/g,"")),
                parseFloat(this.formularioCompleto.utilidadPerdida.replace(/,/g,"")),
                parseFloat(this.formularioCompleto.capitalTrabajo.replace(/,/g,"")),
            ], label: this.formularioCompleto.balanceGeneralFecha
        },
        {
            data: [
                parseFloat(this.formularioCompleto.activoCorrienteDos.replace(/,/g,"")),
                parseFloat(this.formularioCompleto.activoNoCorrienteDos.replace(/,/g,"")),
                parseFloat(this.formularioCompleto.totalActivosDos.replace(/,/g,"")),
                parseFloat(this.formularioCompleto.pasivoCorrienteDos.replace(/,/g,"")),
                parseFloat(this.formularioCompleto.pasivoNoCorrienteDos.replace(/,/g,"")),
                parseFloat(this.formularioCompleto.totalPasivosDos.replace(/,/g,"")),
                parseFloat(this.formularioCompleto.patrimonioDos.replace(/,/g,"")),
                parseFloat(this.formularioCompleto.ventasCompletoDos.replace(/,/g,"")),
                parseFloat(this.formularioCompleto.utilidadPerdidaDos.replace(/,/g,"")),
                parseFloat(this.formularioCompleto.capitalTrabajoDos.replace(/,/g,"")),
            ], label: this.formularioCompleto.balanceGeneralFechaDos
        }
    ]
}


  //fin  de calculos pendejos de otrs pendejos
  quitarColorCritico() {
    document.getElementById("critico").style.backgroundColor = "white";

  }

  quitarColorRiesgoso() {
    document.getElementById("riesgoso").style.backgroundColor = "white";

  }

  quitarColorConfiable() {
    document.getElementById("confiable").style.backgroundColor = "white";

  }

  quitarColorNotable() {
    document.getElementById("notable").style.backgroundColor = "white";

  }


  cambiarColorCritico0() {
    document.getElementById("critico").style.backgroundColor = "#0A9F25";
  }
  cambiarColorRiesgoso() {
    document.getElementById("riesgoso").style.backgroundColor = "#0A9F25";

  }
  cambiarColorConfiable() {

    document.getElementById("confiable").style.backgroundColor = "#0A9F25";

  }
  cambiarColorNotable() {
    document.getElementById("notable").style.backgroundColor = "#0A9F25";

  }

  textoRojo0() {
    document.getElementById("0").style.color = "#D80D0D"
  }
  quitarColor0() {
    document.getElementById("0").style.color = "black"

  }
  textoRojo1() {
    document.getElementById("1").style.color = "#D80D0D"
  }
  quitarColor1() {
    document.getElementById("1").style.color = "black"

  }
  textoRojo2() {
    document.getElementById("2").style.color = "#D80D0D"
  }
  quitarColor2() {
    document.getElementById("2").style.color = "black"

  }
  textoRojo3() {
    document.getElementById("3").style.color = "#D80D0D"
  }
  quitarColor3() {
    document.getElementById("3").style.color = "black"
  }
  textoRojo4() {
    document.getElementById("4").style.color = "#D80D0D"
  }
  quitarColor4() {
    document.getElementById("4").style.color = "black"

  }
  textoRojo5() {
    document.getElementById("5").style.color = "#D80D0D"
  }
  quitarColor5() {
    document.getElementById("5").style.color = "black"

  }
  textoRojo6() {
    document.getElementById("6").style.color = "#D80D0D"
  }
  quitarColor6() {
    document.getElementById("6").style.color = "black"

  }
  textoRojo7() {
    document.getElementById("7").style.color = "#D80D0D"
  }
  quitarColor7() {
    document.getElementById("7").style.color = "black"

  }
  textoRojo8() {
    document.getElementById("8").style.color = "#D80D0D"
  }
  quitarColor8() {
    document.getElementById("8").style.color = "black"

  }
  textoRojo9() {
    document.getElementById("9").style.color = "#D80D0D"
  }
  quitarColor9() {
    document.getElementById("9").style.color = "black"

  }
  textoRojo10() {
    document.getElementById("10").style.color = "#D80D0D"
  }
  quitarColor10() {
    document.getElementById("10").style.color = "black"

  }



  addReporteCompleto() {
    this.submitted = true;
  }
  //-------------------------Fortalezas y Debilidades-----------------------------------
  // fortaleza1
  // fortaleza2
  // fortaleza3
  // fortaleza4
  // fortaleza5
  // fortaleza6
  // fortaleza7
  // fortaleza8
  // fortaleza9
  // fortaleza10
  // fortaleza11
  // fortaleza12
  // fortaleza13
  // fortaleza14
  // debilidad11
  // debilidad12
  // debilidad13
  // debilidad14
  // diasCuentas
  // diasCuentaPagar
  // diasCuentasCobrar
  // fecha
  // ano
  // anoFinal
  
  
  leyendas() {
    //  this.fecha = new Date();
    //  this.ano = this.fecha.getFullYear();
    //   console.log('El año actual es: '+this.ano);
    //al dia con las obligaciones
    // this.formularioCompleto.checksObligacionesLegales === "SI" && this.formularioCompleto.checksSuperintendencia === "SI" ?
    //   console.log(this.debilidad13 = "No se encuentra al día en sus obligaciones de ley.") : console.log(this.fortaleza13 = "Se encuentra al día en sus obligaciones de ley.");
    //razon corriente
    // parseFloat(this.formularioCompleto.razonCorriente) >= 1 ? console.log(this.debilidad1 = "Sus indicadores de liquidez  no muestran una adecuada cobertura de sus activos corrientes sobre sus obligaciones de corto plazo.")
    //   : console.log(this.fortaleza1 = "Sus indicadores de liquidez muestran una adecuada cobertura de sus activos corrientes sobre sus obligaciones de corto plazo.");

    //razon deuda

    // parseFloat(this.formularioCompleto.razonDeudaTotal) >= 0.80 ? console.log(this.debilidad2 = "Registra un alto nivel  de endeudamiento.") :
    //   console.log(this.fortaleza2 = "Registra un bajo nivel de endeudamiento.");

    // this.formularioCompleto.rendimientoActivosDos > this.formularioCompleto.rendimientoActivosTres ? console.log(this.fortaleza5 = "Su margen de rentabilidad sobre activos es" + this.formularioCompleto.rendimientoActivos + "% que muestra una evolucion en relación al año anterior.") :
    //   console.log(this.debilidad5 = "Su margen de rentabilidad sobre activos es" + " " + this.formularioCompleto.rendimientoActivos + " " + "% que muestra una tendencia negativa en relación al año anterior.");
    //ventas
    // parseFloat(this.formularioCompleto.ventasTotales) >= 1000000 ? console.log(this.fortaleza6 = "Registra un buen nivel de ventas.") :
    //   console.log(this.debilidad6 = "Registra un bajo nivel de ventas.");

    //valores ventas variacion

    // parseFloat(this.formularioCompleto.ventasTotales) >= 5 ? console.log(this.fortaleza7 = "Registra un aumento del" + " " + (parseFloat(this.formularioCompleto.ventasTotales).toFixed(2)) + " " + "% en ventas entre períodos.") :
    //   console.log(this.debilidad7 = "Registra una disminución del" + " " + parseFloat(this.formularioCompleto.ventasTotales).toFixed(2) + " " + "% en ventas entre períodos.");

    //valores ventas utilidad variacion
    // this.formularioCompleto.utilidadPerdida >=5?console.log(this.fortaleza8="Registra un aumento del"+" "+ (this.formularioCompleto.utilidadPerdidaDos).toFixed(2)+" "+"% en utilidad entre períodos."):
    // console.log(this.debilidad8="Registra una disminución del"+" "+(this.formularioCompleto.utilidadPerdidaTres.toFixed(2))+" "+"% en utilidad entre períodos.");

    //años de operacion
    // this.anoFinal > 10 ? console.log(this.fortaleza9 = "Varios años de operación de la empresa. ") : console.log(this.debilidad9 = "Los años de operacion de la empresa son bajos.");
    //tiene empresas relacionadas
    //   this.proveedor.descriptionTrue1 != ""? console.log(this.fortaleza10="Posee empresas relacionadas."):console.log(this.debilidad10="No posee empresas relacionadas.");
    //pertenece a grupo economico
    //    this.proveedor.descriptionTrue != ""? console.log(this.fortaleza11="Pertenece a un grupo económico.."):console.log(this.debilidad11="No pertenece a un grupo económico.");
    //infraestructura
    //    this.proveedor.checks4 === "Propia"? console.log(this.fortaleza12="Cuenta con infraestructura propia."):console.log(this.debilidad12="No cuenta con infraestructura propia.")
    //al dia con las obligaciones
    //   this.formularioCompleto.checksObligacionesLegales ==="SI" && this.proveedor.checksObligacionesPatronales ==="SI" && this.proveedor.checksObligacionSuper ==="SI"? 
    //    console.log(this.fortaleza13="Se encuentra al día en sus obligaciones de ley."):console.log(this.debilidad13="No se encuentra al día en sus obligaciones de ley.");
    //referencias a tiempo de
    //   this.proveedor.checkspuntualidadEntregaProveedor1 ==="a tiempo"? console.log(this.fortaleza14="Buenas referencias comerciales de sus proveedores."):
    //   console.log(this.debilidad14="");
  }

  myFunction() {
    // this.presionar = setTimeout(this.cagados, 10000);

    setTimeout(() => {
      this.quitarCero();
      this.sumarTotal();

      this.updateCharts()
      
    }, 3000);
   // Swal.fire('Correcto', `Calculos exitosos`, 'success');
  }

  checkForta(){
    //1
   if (this.formularioCompleto.checksRazonCorrienteEs === "SiMayor"){ 
    document.getElementById("text1").innerHTML =   'Sus  indicadores de liquidez muestran una adecuada  cobertura de sus activos corrientes sobre sus obligaciones de corto plazo.'
   } else {
    document.getElementById("text1").style.display ="none";
    }
    
   if (this.formularioCompleto.checksRazonCorrienteEs === "NoMayor"){
    document.getElementById("text").innerHTML = 'Sus indicadores de liquidez  no muestran una adecuada cobertura de sus activos corrientes sobre sus obligaciones de corto plazo.'
   }else {
    document.getElementById("text").style.display ="none";
   }
    //2
    this.formularioCompleto.checksRazonDeudaEs === "SiMayor"?
    document.getElementById("text2F").innerHTML = 'Registra un bajo nivel de endeudamiento.':
    document.getElementById("text2F").innerHTML = '';
    this.formularioCompleto.checksRazonDeudaEs === "NoMayor"?
    document.getElementById("text2D").innerHTML = 'Registra un alto nivel  de endeudamiento.':
    document.getElementById("text2D").innerHTML = '';
    //3
    this.formularioCompleto.checksDiaspromedioCobrar === "SiMayor"?
    document.getElementById("text3F").innerHTML = 'El periodo de recuperación de cartera se ubica en ( '+ [this.formularioCompleto.diasPromedioDos]+ ' ) dias  con una evolución positiva':
    document.getElementById("text3F").innerHTML = '';
    this.formularioCompleto.checksDiaspromedioCobrar === "NoMayor"?
    document.getElementById("text3D").innerHTML = 'El periodo de recuperación de cartera se ubica en ( '+ [this.formularioCompleto.diasPromedioDos]+ ' ) días con una tendencia negativa ':
    document.getElementById("text3D").innerHTML = '';
    //4
    this.formularioCompleto.checksDiaspromedioPagar === "SiMayor"?
    document.getElementById("text4F").innerHTML = 'El pago a proveedores se encuentra en ( ' + [this.formularioCompleto.diasCuentasPagarDos]+ ' ) días, mostrando una menor duración con respecto al ejercicio anterior.':
    document.getElementById("text4F").innerHTML = '';
    this.formularioCompleto.checksDiaspromedioPagar === "NoMayor"?
    document.getElementById("text4D").innerHTML = 'El pago a proveedores se encuentra en ( ' + [this.formularioCompleto.diasCuentasPagarDos]+ ' ) días, mostrando una mayor duración con respecto al ejercicio anterior.':
    document.getElementById("text4D").innerHTML = '';

    //5
    this.formularioCompleto.checksRendimientoActivo === "SiMayor"?
    document.getElementById("text5F").innerHTML = ('Su margen de rentabilidad sobre activos es ' + [this.formularioCompleto.rendimientoActivosDos]+ ' % que muestra una evolucion en relación al año anterior.'):
    document.getElementById("text5F").innerHTML = '';
    this.formularioCompleto.checksRendimientoActivo === "NoMayor"?
    document.getElementById("text5D").innerHTML = 'Su margen de rentabilidad sobre activos es ' + [this.formularioCompleto.rendimientoActivosDos]+ ' % que muestra una tendencia negativa en relación al año anterior.':
    document.getElementById("text5D").innerHTML = '';
    
    //6
    this.formularioCompleto.checksVentasMayor10mil === "SiMayor"?
    document.getElementById("text6F").innerHTML = 'Registra un buen nivel de ventas.':
    document.getElementById("text6F").innerHTML = '';
    this.formularioCompleto.checksVentasMayor10mil === "NoMayor"?
    document.getElementById("text6D").innerHTML = 'Registra un mal nivel de ventas.':
    document.getElementById("text6D").innerHTML = '';

    //7
    this.formularioCompleto.checksVentas5 === "SiMayor"?
    document.getElementById("text7F").innerHTML = 'Registra un aumento del ( ' + [this.formularioCompleto.porcentaje8]+ ' ) % en ventas entre períodos':
    document.getElementById("text7F").innerHTML = '';
    this.formularioCompleto.checksVentas5 === "NoMayor"?
    document.getElementById("text7D").innerHTML = 'Registra una disminución del ( ' + [this.formularioCompleto.porcentaje8]+ ' ) % en ventas entre períodos.':
    document.getElementById("text7D").innerHTML = '';

    //8
    this.formularioCompleto.checksUtilidad5 === "SiMayor"?
    document.getElementById("text8F").innerHTML = 'Registra un aumento del ' + [this.formularioCompleto.porcentaje9]+ ' % en utilidad entre períodos.':
    document.getElementById("text8F").innerHTML = '';
    this.formularioCompleto.checksUtilidad5 === "NoMayor"?
    document.getElementById("text8D").innerHTML = 'Registra una disminución del ' + [this.formularioCompleto.porcentaje9]+ ' % en utilidad entre períodos.':
    document.getElementById("text8D").innerHTML = '';


    //9
    this.formularioCompleto.checksOperacion10 === "SiMayor"?
    document.getElementById("text9F").innerHTML = 'Varios años de operación de la empresa.':
    document.getElementById("text9F").innerHTML = '';
    this.formularioCompleto.checksOperacion10 === "NoMayor"?
    document.getElementById("text9D").innerHTML = 'Pocos años de operación de la empresa.':
    document.getElementById("text9D").innerHTML = '';

    //10

    this.formularioCompleto.checksEmpresasCompleto === "Si"?
    document.getElementById("text10F").innerHTML = 'Posee empresas relacionadas.':
    document.getElementById("text10F").innerHTML = '';
    this.formularioCompleto.checksEmpresasCompleto === "No"?
    document.getElementById("text10D").innerHTML = 'No posee empresas relacionadas.':
    document.getElementById("text10D").innerHTML = '';

    //11
    this.formularioCompleto.checksGrupoEconomicoCompleto === "Si"?
    document.getElementById("text11F").innerHTML = 'Pertenece a un grupo económico.':
    document.getElementById("text11F").innerHTML = '';
    this.formularioCompleto.checksGrupoEconomicoCompleto === "No"?
    document.getElementById("text11D").innerHTML = 'No pertenece a un grupo económico.':
    document.getElementById("text11D").innerHTML = '';

    //12
    
    this.formularioCompleto.checksInfraestructura === "PROPIA"?
    document.getElementById("text12F").innerHTML = 'Cuenta con infraestructura propia.':
    document.getElementById("text12F").innerHTML = '';
    this.formularioCompleto.checksInfraestructura === "RENTADA"?
    document.getElementById("text12D").innerHTML = 'Cuenta con infraestructura rentada.':
    document.getElementById("text12D").innerHTML = '';


    //13

    this.formularioCompleto.checksRegistraJuicios === "SiMayor"?
    document.getElementById("text13D").innerHTML = 'Registra juicios.':
    document.getElementById("text13D").innerHTML = '';
    this.formularioCompleto.checksRegistraJuicios === "NoMayor"?
    document.getElementById("text13F").innerHTML = 'No registra juicios.':
    document.getElementById("text13F").innerHTML = '';


    //14
    
    this.formularioCompleto.checksObligacionesLegales === "SI"?
    document.getElementById("text14F").innerHTML = 'Se encuentra al día en sus obligaciones de ley.':
    document.getElementById("text14F").innerHTML = '';
    this.formularioCompleto.checksObligacionesLegales === "NO"?
    document.getElementById("text14D").innerHTML = 'No se encuentra al día en sus obligaciones de ley.':
    document.getElementById("text14D").innerHTML = '';
    //15

    this.formularioCompleto.checksPagaAtiempo === "Si"?
    document.getElementById("text15F").innerHTML = 'Buenas referencias comerciales de sus proveedores.':
    document.getElementById("text15F").innerHTML = '';
    this.formularioCompleto.checksPagaAtiempo === "No"?
    document.getElementById("text15D").innerHTML = 'Registra retrasos en los pagos con un proveedor':
    document.getElementById("text15D").innerHTML = '';

    //fin

  }



  imprimir()  {
    window.print();
   
  }

  atras(): void {
    this.location.back();
  }


  infoRelevanteSi
  infoRelevateNo
  
  informacionRelevant() {
    this.formularioCompleto.checksListaClinton === "SiLista" ? console.log(this.infoRelevanteSi = "* El nombre de referencia SI se encuentra incluido en el programa SDNT de la OFAC (Lista Clinton).", document.getElementById("infoRelevanteColor").style.color = "red") :
      console.log(this.infoRelevanteSi = "* El nombre de referencia NO se encuentra incluido en el programa SDNT de la OFAC (Lista Clinton).", document.getElementById("infoRelevanteColor").style.color = "blue");

   
  }


  listascores: Array<any> = [
    {
      name: "", ciudades1: ["NO HAY INFORMACIÓN", ]
    },
    {
      name: "0", ciudades1: [" Cliente no apto para realizar gestiones comerciales. 0",]
    },
    {
      name: "1", ciudades1: [" Recomendamos no otorgar ningún tipo de crédito a este cliente debido a su mala experiencia crediticia bancaria y comercial. 1",]
    },
    {
      name: "2", ciudades1: [" El perfil por analizar tiene un record de alto riesgo. 2",]
    },
    {
      name: "3", ciudades1: [" Recomendamos no otorgar ningún tipo de crédito a este cliente debido a su mala experiencia crediticia y bancaria. 3",]
    },
    {
      name: "4", ciudades1: [" Recomendamos trabajar solo al contado con este cliente. 4",]
    },
    {
      name: "5", ciudades1: [" Recomendamos solicitar algún tipo de garantía para trabajar con este cliente, debido a su  alto nivel de endeudamiento. 5",]
    },
    {
      name: "6", ciudades1: [" Recomendamos mantener relaciones comerciales con este cliente. 6",]
    },
    {
      name: "7", ciudades1: [" Recomendamos mantener relaciones comerciales con este cliente y aumentar su capacidad de endeudamiento. 7",]
    },
    {
      name: "8", ciudades1: [" Recomendamos mantener relaciones comerciales a largo plazo con este cliente. 8",]
    },
    {
      name: "9", ciudades1: [" Recomendamos otorgar créditos de mediana y alta cuantía sujeto de crédito con notables desempeños crediticios y financieros. 9",]
    },
    {
      name: "10", ciudades1: ["  Recomendamos otorgar créditos de mediana y alta cuantía sujeto de crédito con notables desempeños crediticios y financieros. 10",]
    },
  ];

  ciudades1: Array<any>;
  cambioProvincia1(count) {
    this.ciudades1 = this.listascores.find(con => con.name == count).ciudades1;
  }

  listascores2: Array<any> = [
    {
      name: "", ciudades2: ["NO HAY INFORMACIÓN", ]
    },
    {
      name: "0", ciudades2: ["Score: Cliente no apto para realizar gestiones comerciales 0",]
    },
    {
      name: "1", ciudades2: [" Score: Recomendamos no otorgar ningún tipo de crédito a este cliente debido a su mala experiencia crediticia bancaria y comercial. 1",]
    },
    {
      name: "2", ciudades2: [" Score:  El perfil por analizar tiene un record de alto riesgo 2",]
    },
    {
      name: "3", ciudades2: ["Score: Recomendamos no otorgar ningún tipo de crédito a este cliente debido a su mala experiencia crediticia y bancaria.3",]
    },
    {
      name: "4", ciudades2: ["  Score: Recomendamos trabajar solo al contado con este cliente. 4",]
    },
    {
      name: "5", ciudades2: ["  Score: Recomendamos solicitar algún tipo de garantía para trabajar con este cliente, debido a su  alto nivel de endeudamiento. 5",]
    },
    {
      name: "6", ciudades2: ["  Score: Recomendamos mantener relaciones comerciales con este cliente. 6",]
    },
    {
      name: "7", ciudades2: ["  Score: Recomendamos mantener relaciones comerciales con este cliente y aumentar su capacidad de endeudamiento. 7",]
    },
    {
      name: "8", ciudades2: ["  Score: Recomendamos mantener relaciones comerciales a largo plazo con este cliente. 8",]
    },
    {
      name: "9", ciudades2: [" Score: Recomendamos otorgar créditos de mediana y alta cuantía sujeto de crédito con notables desempeños crediticios y financieros. 9",]
    },
    {
      name: "10", ciudades2: ["  Score: Recomendamos otorgar créditos de mediana y alta cuantía sujeto de crédito con notables desempeños crediticios y financieros. 10",]
    },
  ];

  ciudades2: Array<any>;
  cambioProvincia2(count) {
    this.ciudades2 = this.listascores2.find(con => con.name == count).ciudades2;
  }

  listascores3: Array<any> = [
    {
      name: "", ciudades3: ["NO HAY INFORMACIÓN", ]
    },
    {
      name: "0", ciudades3: ["Score: Cliente no apto para realizar gestiones comerciales 0",]
    },
    {
      name: "1", ciudades3: [" Score: Recomendamos no otorgar ningún tipo de crédito a este cliente debido a su mala experiencia crediticia bancaria y comercial. 1",]
    },
    {
      name: "2", ciudades3: [" Score:  El perfil por analizar tiene un record de alto riesgo 2",]
    },
    {
      name: "3", ciudades3: ["Score: Recomendamos no otorgar ningún tipo de crédito a este cliente debido a su mala experiencia crediticia y bancaria.3",]
    },
    {
      name: "4", ciudades3: ["  Score: Recomendamos trabajar solo al contado con este cliente. 4",]
    },
    {
      name: "5", ciudades3: ["  Score: Recomendamos solicitar algún tipo de garantía para trabajar con este cliente, debido a su  alto nivel de endeudamiento. 5",]
    },
    {
      name: "6", ciudades3: ["  Score: Recomendamos mantener relaciones comerciales con este cliente. 6",]
    },
    {
      name: "7", ciudades3: ["  Score: Recomendamos mantener relaciones comerciales con este cliente y aumentar su capacidad de endeudamiento. 7",]
    },
    {
      name: "8", ciudades3: ["  Score: Recomendamos mantener relaciones comerciales a largo plazo con este cliente. 8",]
    },
    {
      name: "9", ciudades3: [" Score: Recomendamos otorgar créditos de mediana y alta cuantía sujeto de crédito con notables desempeños crediticios y financieros. 9",]
    },
    {
      name: "10", ciudades3: ["  Score: Recomendamos otorgar créditos de mediana y alta cuantía sujeto de crédito con notables desempeños crediticios y financieros. 10",]
    },
  ];

  ciudades3: Array<any>;
  cambioProvincia3(count) {
    this.ciudades3 = this.listascores3.find(con => con.name == count).ciudades3;
  }
  listascores4: Array<any> = [
    {
      name: "", ciudades4: ["NO HAY INFORMACIÓN", ]
    },
    {
      name: "0", ciudades4: ["Score: Cliente no apto para realizar gestiones comerciales 0",]
    },
    {
      name: "1", ciudades4: [" Score: Recomendamos no otorgar ningún tipo de crédito a este cliente debido a su mala experiencia crediticia bancaria y comercial. 1",]
    },
    {
      name: "2", ciudades4: [" Score:  El perfil por analizar tiene un record de alto riesgo 2",]
    },
    {
      name: "3", ciudades4: ["Score: Recomendamos no otorgar ningún tipo de crédito a este cliente debido a su mala experiencia crediticia y bancaria.3",]
    },
    {
      name: "4", ciudades4: ["  Score: Recomendamos trabajar solo al contado con este cliente. 4",]
    },
    {
      name: "5", ciudades4: ["  Score: Recomendamos solicitar algún tipo de garantía para trabajar con este cliente, debido a su  alto nivel de endeudamiento. 5",]
    },
    {
      name: "6", ciudades4: ["  Score: Recomendamos mantener relaciones comerciales con este cliente. 6",]
    },
    {
      name: "7", ciudades4: ["  Score: Recomendamos mantener relaciones comerciales con este cliente y aumentar su capacidad de endeudamiento. 7",]
    },
    {
      name: "8", ciudades4: ["  Score: Recomendamos mantener relaciones comerciales a largo plazo con este cliente. 8",]
    },
    {
      name: "9", ciudades4: [" Score: Recomendamos otorgar créditos de mediana y alta cuantía sujeto de crédito con notables desempeños crediticios y financieros. 9",]
    },
    {
      name: "10", ciudades4: ["  Score: Recomendamos otorgar créditos de mediana y alta cuantía sujeto de crédito con notables desempeños crediticios y financieros. 10",]
    },
  ];

  ciudades4: Array<any>;
  cambioProvincia4(count) {
    this.ciudades4 = this.listascores4.find(con => con.name == count).ciudades4;
  }

  listascores5: Array<any> = [
    {
      name: "", ciudades5: ["NO HAY INFORMACIÓN", ]
    },
    {
      name: "0", ciudades5: ["Score: Cliente no apto para realizar gestiones comerciales 0",]
    },
    {
      name: "1", ciudades5: [" Score: Recomendamos no otorgar ningún tipo de crédito a este cliente debido a su mala experiencia crediticia bancaria y comercial. 1",]
    },
    {
      name: "2", ciudades5: [" Score:  El perfil por analizar tiene un record de alto riesgo 2",]
    },
    {
      name: "3", ciudades5: ["Score: Recomendamos no otorgar ningún tipo de crédito a este cliente debido a su mala experiencia crediticia y bancaria.3",]
    },
    {
      name: "4", ciudades5: ["  Score: Recomendamos trabajar solo al contado con este cliente. 4",]
    },
    {
      name: "5", ciudades5: ["  Score: Recomendamos solicitar algún tipo de garantía para trabajar con este cliente, debido a su  alto nivel de endeudamiento. 5",]
    },
    {
      name: "6", ciudades5: ["  Score: Recomendamos mantener relaciones comerciales con este cliente. 6",]
    },
    {
      name: "7", ciudades5: ["  Score: Recomendamos mantener relaciones comerciales con este cliente y aumentar su capacidad de endeudamiento. 7",]
    },
    {
      name: "8", ciudades5: ["  Score: Recomendamos mantener relaciones comerciales a largo plazo con este cliente. 8",]
    },
    {
      name: "9", ciudades5: [" Score: Recomendamos otorgar créditos de mediana y alta cuantía sujeto de crédito con notables desempeños crediticios y financieros. 9",]
    },
    {
      name: "10", ciudades5: ["  Score: Recomendamos otorgar créditos de mediana y alta cuantía sujeto de crédito con notables desempeños crediticios y financieros. 10",]
    },
  ];

  ciudades5: Array<any>;
  cambioProvincia5(count) {
    this.ciudades5 = this.listascores5.find(con => con.name == count).ciudades5;
  }

  myFunction1() {
    // this.presionar = setTimeout(this.cagados, 10000);

    setTimeout(() => {
      if(this.formularioCompleto.provinciaCompleto != null){ 
        this.cambioProvincia(this.formularioCompleto.provinciaCompleto);
        };
        if(this.formularioCompleto.valorEvolucionRatingComple != null){
        this.cambioProvincia1(this.formularioCompleto.valorEvolucionRatingComple);
        };
        if(this.formularioCompleto.valorUnoEvolucionRatingComple != null){
        this.cambioProvincia2(this.formularioCompleto.valorUnoEvolucionRatingComple);
        };
        if(this.formularioCompleto.valorDosEvolucionRatingComple != null){
        this.cambioProvincia3(this.formularioCompleto.valorDosEvolucionRatingComple);
        };
        if(this.formularioCompleto.valorTresEvolucionRatingComple != null ){
        this.cambioProvincia4(this.formularioCompleto.valorTresEvolucionRatingComple);
        };
        if(this.formularioCompleto.valorCuatroEvolucionRatingComple != null){
        this.cambioProvincia5(this.formularioCompleto.valorCuatroEvolucionRatingComple);
        };
      this.cargarInformes()
      this.informacionRelevant();
      this.updateCharts();
     
      //this.getInputValues(this.pieChartDataUno,any,posi)
    //getInputValues(pieChartName, value, position: number, needSum, numOrder: number)
      

    }, 3000);

  }


  cargarInformes() {
    setTimeout(() => {
      checksEMpresas()
      checksInformeCompleto()
      this.chart
      this.chart1
      this.chart3
      this.chart4;
      this.sumarTotal();
      this.checkForta();

    }, 3000);
    this.completoService.cargarInformes()
      .subscribe((resp: any) => {

        // this.completo3 = resp.completo3;
        console.log('informe cargado')
      });
  }

cienMail(){
  if(this.tareaActual.estado === "100%",this.lista.usuario['email']){
    this._MessageService.sendEmail(environment.URL_SERVICIOS+"/informando", this.tareaActual.estado).subscribe(
   
      data => {
      
         console.log('ENVIAR CORREO AL CLIENTE')
          let res:any = data;
          console.log(
          `👏 > 👏 > 👏 > 👏 ${ this.tareaActual.estado} se a enviado el mensaje correctamente`
        )
          
    })
  }

}


}