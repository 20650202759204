import { Component, OnInit } from '@angular/core';
import { Contact3 } from 'src/app/models/contactTarea';
import { Usuario } from 'src/app/models/usuario.model';
import { UsuarioService } from 'src/app/services/usuario.service';
import { ListainformesService } from 'src/app/services/listainformes.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-historial-informes',
  templateUrl: './historial-informes.component.html',
  styleUrls: ['./historial-informes.component.css']
})
export class HistorialInformesComponent implements OnInit {

  usuario: Usuario;
  
  formularios: Contact3[];
  formulariosUsuario: Contact3[] = [];
 /* fileArr = [];
  imgArr = [];
  fileObj = [];
  form: FormGroup;
  msg: string;
  progress: number = 0; */

  constructor(private _usuarioService: UsuarioService, private listainforme:ListainformesService, private location: Location, 
  //  public fb: FormBuilder,
  //  private sanitizer: DomSanitizer,
  //  public dragdropService: DragdropService
  ) {
   
  }

  ngOnInit() { 

    this.usuario = this._usuarioService.usuario;
    this.getFormularios();
  }



  getFormularios() {
    const usuario = JSON.parse(localStorage.getItem('usuario')) as Usuario;
    this.listainforme.getProveedores3(usuario._id).subscribe(
      result => { 
      
         this.formularios =  result 
         this.formularios.sort(this.sortObjetc1)
         console.log(this.formularios)
     });
  }

  sortObjetc1(a, b) {
    return a.estado  - b.estado ? 100 : -25;
  };
 

 /* upload(e) {
    const fileListAsArray = Array.from(e);
    fileListAsArray.forEach((item, i) => {
      const file = (e as HTMLInputElement);
      const url = URL.createObjectURL(file[i]);
      this.imgArr.push(url);
      this.fileArr.push({ item, url: url });
    })

    this.fileArr.forEach((item) => {
      this.fileObj.push(item.item)
    })

    // Set files form control
    this.form.patchValue({
      avatar: this.fileObj
    })

    this.form.get('avatar').updateValueAndValidity()

    // Upload to server
    this.dragdropService.addFiles(this.form.value.avatar)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:
            console.log('Documento cargado exitosamente!', event.body);
            setTimeout(() => {
              this.progress = 0;
              this.fileArr = [];
              this.fileObj = [];
              this.msg = "Documento cargado exitosamente!"
            }, 3000);
        }
      })
  }

  // Clean Url for showing image preview
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
     */

    atras(): void {
      this.location.back();
    }
    
actualizar(){
  this.getFormularios();

}

}
