import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Solicitud } from '../models/solicitud.model';

import { UsuarioService } from './usuario.service';

import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class SolicitudService {
  token: string;
  private informeSolicitudUrl = environment.URL_SERVICIOS +'/solicitud';  // URL to web api

  constructor( private http: HttpClient,public _usuarioService: UsuarioService) { }
  

  getInformesCompletos(): Observable<Solicitud[]> {
    return this.http.get<Solicitud[]>(this.informeSolicitudUrl, httpOptions);

  }
  getInformeCompleto(id: string): Observable<Solicitud> {
    const url = `${this.informeSolicitudUrl}/${id}`;
    return this.http.get<Solicitud>(url);
  }

  addInformeCompleto (informeCompleto: Solicitud): Observable<Solicitud> {

    this.informeSolicitudUrl += '?token=' + this._usuarioService.token;
    return this.http.post<Solicitud>(this.informeSolicitudUrl, informeCompleto);
  }

  deleteInformeCompleto (informeCompleto: Solicitud | string): Observable<Solicitud> {
    const id = typeof informeCompleto === 'string' ? informeCompleto : informeCompleto._id;
    const url = `${this.informeSolicitudUrl}/${id}`;

    return this.http.delete<Solicitud>(url);
  }

  updateInformeCompleto (informeCompleto: Solicitud): Observable<any> {
    return this.http.put(this.informeSolicitudUrl, informeCompleto);
  }

  buscarUsuarios( termino: string ) {
    let url = `${environment.URL_SERVICIOS }/busqueda/coleccion/solicitud/${ termino }`;

    return this.http.get( url ).pipe(map((resp: any) => resp.solicitud));
  }


}


