import { Component, OnInit } from '@angular/core';
import { Solicitud1Service } from '../../services/solicitud1.service';

import { ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { SolicitudCompra } from 'src/app/models/solicitudCompra.model';
import Swal from 'sweetalert2';

declare function init_plugins()
@Component({
  selector: 'app-desactivar-compras',
  templateUrl: './desactivar-compras.component.html',
  styleUrls: ['./desactivar-compras.component.css']
})
export class DesactivarComprasComponent implements OnInit {
  infSolicitud: SolicitudCompra[]= [];

  desde: number = 0;

  totalRegistros: number = 0;
  cargando: boolean = true;
 

  constructor(private solicitudService: Solicitud1Service, private route: ActivatedRoute, private location: Location) { }

  ngOnInit() {
    init_plugins();
    this.getSolicitud();

    
  }

  getSolicitud(){
    this.solicitudService.getInformesCompletos1().subscribe(solicitud=>{
      console.log(solicitud);
      this.infSolicitud = solicitud;
      this.cargando = false;
     /*  if(this.infSolicitud[0].urlPDF  != null){
        document.getElementById("deshabilitarBoton").style.display ="none";
          Swal.fire(
            'Ooop',
            'No hay archivo Subido',
            'success'
          );
        } */
     })

  }


  buscarUsuario(termino: string) {

    if (termino.length <= 0) {
      this.getSolicitud()
      return;
    }
   
    this.solicitudService.buscarUsuarios(termino)
      .subscribe((infSolicitud:  SolicitudCompra[]) => {

        this.infSolicitud = infSolicitud;
      
      });

  }


  guardarPro(  infSolicitud: SolicitudCompra ) {
  
    this.solicitudService.updateProveedor1( infSolicitud )
            .subscribe();
            Swal.fire('Correcto', `La información fue actualizada con éxito`, 'success');
  }


}


/*if(listaproveedores[0].estadoFormulario === "CALIFICADO"){
  document.getElementById("habilitarBoton").style.display ="inline";
} */