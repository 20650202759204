import { Userdocumento } from './../../../models/file.models';
import { Component, OnInit } from '@angular/core';
import { DragdropService } from 'src/app/services/dragdrop.service';
import Swal from 'sweetalert2';

declare function init_plugins()


@Component({
  selector: 'app-lista-archivos',
  templateUrl: './lista-archivos.component.html',
  styleUrls: ['./lista-archivos.component.css']
})
export class ListaArchivosComponent implements OnInit {
  customer = new Userdocumento() ;
  submitted = false;
  message: string;
  //Tareas
  listaproveedores: Userdocumento[] = [];
  filtroArchivos:'';
  constructor( private opcionesServices: DragdropService ) { }
  opcionesGenerales = [];
  ngOnInit() {
    init_plugins();
    this.getListaProveedores()

    this.opcionesServices.notificacion.subscribe( 
      resp =>
      this.getListaProveedores()
    
     );
   // this.getOpciones1();
  }


 /* getOpciones1() {
    return this.opcionesServices.getDocumentos()
      .subscribe(
        opcionesGenerales => {
          console.log(opcionesGenerales["userDocumentos"]);
          this.opcionesGenerales = opcionesGenerales["userDocumentos"]
          for(var e in this.opcionesGenerales){
            console.log(this.opcionesGenerales[e].avatar);
          }
        }
      );
  }
*/
getListaProveedores() {
  return this.opcionesServices.getDocumentos()
    .subscribe(
      listaproveedores => {
        console.log(listaproveedores);
        this.listaproveedores = listaproveedores["userDocumentos"]
    
      },
      
    );
};



  delete(listaproveedores: Userdocumento) {
    this.submitted = true;
    this.opcionesServices.deleteCustomer(listaproveedores)

        .subscribe(result => { 
      
          this.opcionesServices.notificacion.emit( result );
          console.log(result)
          Swal.fire(
            'Documento!',
            'ELIMINADO CON EXITO!',
            'success'
          )
        },
      
          );
          console.log(listaproveedores)
  }

  nohay(){
    Swal.fire(
  
      'NO HAY',
      'ADJUNTOS EN ESTE INFORME',
      'warning'
    );
  
  }

}
