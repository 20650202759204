import { Component, OnInit} from '@angular/core';
import { Tarea } from 'src/app/models/tarea.model';
import { Usuario } from 'src/app/models/usuario.model';
import { Contact } from 'src/app/models/contact.model';
import { TareaService } from 'src/app/services/tareas.service';
import { ListainformesService } from 'src/app/services/listainformes.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-inicio-operador',
  templateUrl: './inicio-operador.component.html',
  styleUrls: ['./inicio-operador.component.css']
})
export class InicioOperadorComponent implements OnInit {
  //

  //Usuario logeado
  usuario: Usuario;

  //Formularios por hacer
  lista: Contact[] = [];

  //Tareas
  pendientes: Tarea[] = [];

    //paginacion
    desde1: number = 0;
    totalRegistros1: number = 1;

  constructor(public _usuarioService: UsuarioService, private _tareaService: TareaService, private listainforme: ListainformesService) {
   
   }
  
  filtroInformes = "";
  ngOnInit() {
    this.usuario = this._usuarioService.usuario;
    this.getTareas();
    
  }
  getCustomers1() {
 
    return this.listainforme.getCustomers2()
 
      .subscribe(
        
        lista => {
        
          for (var form in lista) {
            for (var tarea in this.pendientes) {
              if (this.pendientes[tarea]._idFormulario == lista[form]._id) {
                lista[form].fechaAsignada = this.pendientes[tarea].fechaAsingada
                lista[form].fechaLimite = this.pendientes[tarea].fechaLimite
                lista[form].estado = this.pendientes[tarea].estado;
                this.listainforme.updateCustomer(lista[form]).subscribe(result=>{console.log(result)});
               
                this.lista.push(lista[form]);

              }
            }
          }
          lista.sort(this.sortObjetc1);
        }
      );
  }
  sortObjetc1(a, b) {
    return a.estado  - b.estado ? 100 : -25;
  };

  getTareas() {
    this._tareaService.getTareas().subscribe(tareas => {
      for (var tarea in tareas) {
        if (tareas[tarea]._idResponsable == this.usuario._id) {
          this.pendientes.push(tareas[tarea]);
        }
      }
      this.getCustomers1();
    });
  }


nohay(){
  Swal.fire(

    'NO HAY',
    'ADJUNTOS EN ESTE INFORME',
    'warning'
  );

}

pruebacarga() {
  // this.presionar = setTimeout(this.cagados, 10000);
 
  setTimeout(() => {
    this.getCustomers1()
  }, 3000);
}



}
