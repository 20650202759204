import { ServicioService } from 'src/app/services/servicio.service';

import { BrowserModule } from '@angular/platform-browser';

import { NgModule } from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NopagefoundComponent } from './nopagefound/nopagefound.component';
import { LoginAdminComponent } from './pages/administrador/login-admin/login-admin.component';
import { InicioAdminComponent } from './pages/administrador/inicio-admin/inicio-admin.component';
import { HeaderComponent } from './complemento/header/header.component';
import { SidebarComponent } from './complemento/sidebar/sidebar.component';
import { OpcionesGeneralesService } from './services/serviciosProveedores/opciones-generales.service';
import { FormProveedoresServiceService } from './services/form-proveedores-service.service';
import { UsuarioService } from './services/usuario.service';
import { SolicitudService } from './services/solicitud.service';
import { HttpClientModule } from '@angular/common/http';
import { ChartsModule } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PagesComponent } from './pages/pages.component';
import { RegisterComponent } from './pages/administrador/register/register.component';
import { IdProveedoresComponent } from './pages/administrador/id-proveedores/id-proveedores.component';
import { CalificacionProveedoresComponent } from './pages/administrador/calificacion-proveedores/calificacion-proveedores.component';
import { AdminInformesComponent } from './pages/administrador/admin-informes/admin-informes.component';
import { DetalleCompletoComponent } from './pages/administrador/detalle-completo/detalle-completo.component';
import { DetalleExpressComponent } from './pages/administrador/detalle-express/detalle-express.component';

import { AdminProvComponent } from './pages/administrador/admin-prov/admin-prov.component';
import { LoginProveedoresComponent } from './layout/calificacionProveedores/login-proveedores/login-proveedores.component';
import { SolicitudProvComponent } from './layout/calificacionProveedores/solicitud-prov/solicitud-prov.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { DetalleSolicitudComponent } from './pages/administrador/detalle-solicitud/detalle-solicitud.component';
import { RequisitosProveedoresComponent } from './layout/calificacionProveedores/requisitos-proveedores/requisitos-proveedores.component';
import { LoginComercialesComponent } from './layout2/informesComerciales/login-comerciales/login-comerciales.component';
import { InicioComercialesComponent } from './layout2/informesComerciales/inicio-comerciales/inicio-comerciales.component';
import { SidebarService } from './services/sidebar.service';
import { PipesModule } from './pipes/pipes.module';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { SubirArchivoService } from './services/subir-archivo.service';
import { CommonModule } from '@angular/common';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { InformeCompletoComponent } from './layout2/informesComerciales/informe-completo/informe-completo.component';
import { InformeExpressComponent } from './layout2/informesComerciales/informe-express/informe-express.component';

import { Header2Component } from './header2/header2.component';

import { PerfilProveedorComponent } from './layout/calificacionProveedores/perfil-proveedor/perfil-proveedor.component';
import { LayoutComponent } from './layout/layout.component';
import { HeaderProveedorComponent } from './complementoProveedores/header-proveedor/header-proveedor.component';
import { SidebarProveedorComponent } from './complementoProveedores/sidebar-proveedor/sidebar-proveedor.component';
import { AdminGuard } from './services/admin.guard';
import { SidebarComercialesComponent } from './complementoInfoComerciales/sidebar-comerciales/sidebar-comerciales.component';
import { HeaderComercialesComponent } from './complementoInfoComerciales/header-comerciales/header-comerciales.component';
import { Layout2Component } from './layout2/layout2.component';

import { PerfilComercialComponent } from './layout2/informesComerciales/perfil-comercial/perfil-comercial.component';

import { LoginProveedorGuard } from './services/login-proveedor.guard';
import { LoginComercialGuard } from './services/login-comercial.guard';
import { ExcelService } from './services/excel.service';
import { ListaArchivosComponent } from './pages/administrador/lista-archivos/lista-archivos.component';
import { ToastrModule } from 'ngx-toastr';
import { IdInformesComponent } from './pages/administrador/id-informes/id-informes.component';
//import { ListainformesCompletosComponent } from './layout2/informesComerciales/listainformes-completos/listainformes-completos.component';
import { AdminTareasComponent } from './pages/administrador/admin-tareas/admin-tareas.component';
import { ResponseResetComponent } from './recuperarContraseña/response-reset/response-reset.component';

import { RequestResetComponent } from './recuperarContraseña/request-reset/request-reset.component';
import { AuthService } from './services/auth.service';
import { FormularioExpressComponent } from './layout2/informesComerciales/formulario-express/formulario-express.component';
import { LoginClienteComponent } from './layout3/login-cliente/login-cliente.component';

import { HeaderClienteComponent } from './complementoClientes/header-cliente/header-cliente.component';
import { SidebarClienteComponent } from './complementoClientes/sidebar-cliente/sidebar-cliente.component';
import { Layout3Component } from './layout3/layout3.component';
import { PerfilClienteComponent } from './layout3/perfil-cliente/perfil-cliente.component';
import { SidebarOperadorComponent } from './complementoOperador/sidebar-operador/sidebar-operador.component';
import { HeaderOperadorComponent } from './complementoOperador/header-operador/header-operador.component';
import { InicioOperadorComponent } from './layout4/inicio-operador/inicio-operador.component';
import { Layout4Component } from './layout4/layout4.component';
import { LoginOperadorComponent } from './layout4/login-operador/login-operador.component';
import { PerfilOperadorComponent } from './layout4/perfil-operador/perfil-operador.component';
import { InformesRealizadosComponent } from './layout4/informes-realizados/informes-realizados.component';

//import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AccoutSettingsComponent } from './accout-settings/accout-settings.component';
import { SettingsService } from './services/settings/settings.service';
import { EditorModule } from '@tinymce/tinymce-angular';
import { CalificacionProveedores2Component } from './layout3/calificacion-proveedores2/calificacion-proveedores2.component';

import { DragDropFileUploadDirective } from './services/drag-drop-file-upload.directive';
import { VerificaTokenGuard } from './services/verifica-token.guard';
import { InformesComercialesRealizadosComponent } from './layout2/informes-comerciales-realizados/informes-comerciales-realizados.component';

import { HeaderComprasComponent } from './complementoCompras/header-compras/header-compras.component';
import { SidebarComprasComponent } from './complementoCompras/sidebar-compras/sidebar-compras.component';




import { Solicitud1Service } from './services/solicitud1.service';
import { SolicitudCompraComponent } from './pages/administrador/solicitud-compra/solicitud-compra.component';
import { DetalleCompraComponent } from './pages/administrador/solicitudCompra/detalle-compra/detalle-compra.component';

import {MatTableModule} from '@angular/material/table';
import { AdminInformesExpressComponent } from './pages/administrador/admin-informes-express/admin-informes-express.component';

import { HeaderAnalistaComponent } from './complementoAnalista/header-analista/header-analista.component';
import { SidebarAnalistaComponent } from './complementoAnalista/sidebar-analista/sidebar-analista.component';
import { LoginAnalistaComponent } from './layout6/login-analista/login-analista.component';
import { TareasAsignadasComponent } from './layout6/tareas-asignadas/tareas-asignadas.component';
import { Layout6Component } from './layout6/layout6.component';
import { PerfilAnalistaComponent } from './layout6/perfil-analista/perfil-analista.component';
import { CalificacionAnalistaComponent } from './layout6/calificacion-analista/calificacion-analista.component';
import { LoginAnalistaGuard } from './services/login-analista.guard';
import { ComprobantePagoComponent } from './layout/comprobante-pago/comprobante-pago.component';
import { ComprobantesComponent } from './pages/administrador/comprobantes/comprobantes.component';
import { ModalUploadComponent } from './modal-upload/modal-upload.component';
import { ModalUploadService } from './modal-upload/modal-upload.service';




import { CertificadosProveedorComponent } from './pages/certificados-proveedor/certificados-proveedor.component';

import { HistorialCalificacionComponent } from './layout/historial-calificacion/historial-calificacion.component';
import { AppmovilComponent } from './pages/appmovil/appmovil.component';
import { HistorialInformesComponent } from './layout2/historial-informes/historial-informes.component';
import { InformeExistenteComponent } from './layout2/informe-existente/informe-existente.component';
import { DesactivarComponent } from './pages/desactivar/desactivar.component';


import { ImportProveedoresComponent } from './layout3/import-proveedores/import-proveedores.component';
import { HistorialProveedoresComponent } from './layout3/historial-proveedores/historial-proveedores.component';
import { SolicitudCalificacionComponent } from './layout3/solicitud-calificacion/solicitud-calificacion.component';
import { CalificacionesRealizadasComponent } from './layout3/calificaciones-realizadas/calificaciones-realizadas.component';
import { EstadoProveedoresAdminComponent } from './pages/administrador/estado-proveedores-admin/estado-proveedores-admin.component';
import { DesactivarProveedoresComponent } from './pages/desactivar-proveedores/desactivar-proveedores.component';
import { DataTablesModule } from 'angular-datatables';
import { BuscarInformeComponent } from './layout2/buscar-informe/buscar-informe.component';
import { RegistrarseComponent } from './layout2/registrarse/registrarse.component';
import { FormularioExpressCompletoComponent } from './layout2/formulario-express-completo/formulario-express-completo.component';
import { SolicitudPagoComponent } from './layout2/solicitud-pago/solicitud-pago.component';
import { FormularioAdcicionalComponent } from './layout2/formulario-adcicional/formulario-adcicional.component';
import { FormularioAdicional2Component } from './layout2/formulario-adicional2/formulario-adicional2.component';
import { SolicitudPago2Component } from './layout2/solicitud-pago2/solicitud-pago2.component';
import { HistorialSolicitudComponent } from './layout2/historial-solicitud/historial-solicitud.component';
import { DesactivarComprasComponent } from './pages/desactivar-compras/desactivar-compras.component';
import { TodoslosInformesComponent } from './pages/todoslos-informes/todoslos-informes.component';
import { InformeexpressEditableComponent } from './pages/informeexpress-editable/informeexpress-editable.component';
import { InformecompletoEditableComponent } from './pages/informecompleto-editable/informecompleto-editable.component';
import { DetalleExpress2Component } from './pages/todoslos-informes/detalle-express2/detalle-express2.component';
import { DetalleCompleto2Component } from './pages/todoslos-informes/detalle-completo2/detalle-completo2.component';



@NgModule({
  declarations: [
    DragDropFileUploadDirective,
 
    AppComponent,
    NopagefoundComponent,
    LoginAdminComponent,
    InicioAdminComponent,
    HeaderComponent,
    SidebarComponent,
    PagesComponent,
    RegisterComponent,
    IdProveedoresComponent,
    CalificacionProveedoresComponent,
    AdminInformesComponent,
    DetalleCompletoComponent,
    DetalleExpressComponent,
 
    AdminProvComponent,
    LoginProveedoresComponent,
    SolicitudProvComponent,
    DetalleSolicitudComponent,
    RequisitosProveedoresComponent,
    LoginComercialesComponent,
    InicioComercialesComponent,
    PerfilComponent,
    UsuariosComponent,
  
    InformeCompletoComponent,
    InformeExpressComponent,
    Header2Component,
   
    PerfilProveedorComponent,
   
    LayoutComponent,
   
    HeaderProveedorComponent,
   
    SidebarProveedorComponent,
   
    SidebarComercialesComponent,
   
    HeaderComercialesComponent,
   
    Layout2Component,

   
    PerfilComercialComponent,



   
    ListaArchivosComponent,

   
    IdInformesComponent,

   
//    ListainformesCompletosComponent,

   
    AdminTareasComponent,

   
    ResponseResetComponent,
    RequestResetComponent,
    FormularioExpressComponent,
    LoginClienteComponent,

    HeaderClienteComponent,
    SidebarClienteComponent,
    Layout3Component,
    PerfilClienteComponent,
    SidebarOperadorComponent,
    HeaderOperadorComponent,
    InicioOperadorComponent,
    Layout4Component,
    LoginOperadorComponent,
    PerfilOperadorComponent,
    InformesRealizadosComponent,

    AccoutSettingsComponent,
    CalificacionProveedores2Component,
 
    InformesComercialesRealizadosComponent,
 
  
 
    HeaderComprasComponent,
 
    SidebarComprasComponent,
 
    BuscarInformeComponent,
 
    RegistrarseComponent,

 
    FormularioExpressCompletoComponent,
 
    SolicitudPagoComponent,
 
    SolicitudCompraComponent,
 
    DetalleCompraComponent,
 
    AdminInformesExpressComponent,
 

 
    HeaderAnalistaComponent,
 
    SidebarAnalistaComponent,
 
    LoginAnalistaComponent,
 
    TareasAsignadasComponent,
 
    Layout6Component,
 
    PerfilAnalistaComponent,
 
    CalificacionAnalistaComponent,
 
    ComprobantePagoComponent,
 
    ComprobantesComponent,
 
    ModalUploadComponent,
 
    FormularioAdcicionalComponent,
 
  
    ImportProveedoresComponent,
 
    EstadoProveedoresAdminComponent,
 
    CertificadosProveedorComponent,
 

 
    HistorialCalificacionComponent,
 

 
    AppmovilComponent,
 

 
    HistorialInformesComponent,
 

 
    InformeExistenteComponent,
 

 
    DesactivarComponent,
 

 
    FormularioAdicional2Component,
 

 
    SolicitudPago2Component,
 

 
    HistorialProveedoresComponent,
 

 
    SolicitudCalificacionComponent,
 

 
    CalificacionesRealizadasComponent,
 

 
    DesactivarProveedoresComponent,
 

 
    HistorialSolicitudComponent,
 

 
    DesactivarComprasComponent,
 

 
    TodoslosInformesComponent,
 

 
    InformeexpressEditableComponent,
 

 
    InformecompletoEditableComponent,
 

 
    DetalleExpress2Component,
 

 
    DetalleCompleto2Component,
 

 
  
  
 

 
   

  
    
  ],
  imports: [
    CommonModule,
    MatTableModule,
    NgxPaginationModule,
    BrowserModule,
    AppRoutingModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgbModule,
    EditorModule,
    ChartsModule,
    NgxSpinnerModule,
    DataTablesModule,
    PipesModule,
    
   // CKEditorModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    })
  
  ],
  providers: [
    SettingsService,
    ServicioService,
    AuthService,
    UsuarioService,
    SolicitudService,
    Solicitud1Service,
    FormProveedoresServiceService, 
    OpcionesGeneralesService,
    SidebarService,
    SubirArchivoService, 
    VerificaTokenGuard,
    AdminGuard,
    LoginAnalistaGuard,
    LoginProveedorGuard,
    LoginComercialGuard,
    ExcelService,
    ModalUploadService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
