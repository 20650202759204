import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-todoslos-informes',
  templateUrl: './todoslos-informes.component.html',
  styleUrls: ['./todoslos-informes.component.css']
})
export class TodoslosInformesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
