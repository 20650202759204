import { Component, OnInit } from '@angular/core';
import { Contact } from '../models/contact.model';


declare function init_plugins()
@Component({
  selector: 'app-layout2',
  templateUrl: './layout2.component.html',
  styleUrls: []
})
export class Layout2Component implements OnInit {
  lista = new Contact();
  submitted = false;

  
  constructor() { 
    init_plugins();

  }

  ngOnInit() {
    init_plugins();

  }

}
