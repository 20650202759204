import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OpcionesGenerales } from '../../models/modelosProveedores/opcionesGenerales';
import { environment } from 'src/environments/environment';



const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class OpcionesGenerales3Service {
  private opcionesUrl =  environment.URL_SERVICIOS + '/opcionesGenerales3';  // URL to web api
  constructor(
    private http: HttpClient
  ) { }
  getOpciones3(): Observable<OpcionesGenerales[]> {
    return this.http.get<OpcionesGenerales[]>(this.opcionesUrl)
  }

  getOpcion3(id: string): Observable<OpcionesGenerales> {
    const url = `${this.opcionesUrl}/${id}`;
    return this.http.get<OpcionesGenerales>(url);
  }

  addOpcion3 (proveedor: OpcionesGenerales): Observable<OpcionesGenerales> {
    return this.http.post<OpcionesGenerales>(this.opcionesUrl, proveedor, httpOptions);
  }

  deleteOpcion3 (opcion: OpcionesGenerales | string): Observable<OpcionesGenerales> {
    const id = typeof opcion === 'string' ? opcion : opcion._id;
    const url = `${this.opcionesUrl}/${id}`;

    return this.http.delete<OpcionesGenerales>(url, httpOptions);
  }

  updateOpcion3 (proveedor: OpcionesGenerales): Observable<any> {
    return this.http.put(this.opcionesUrl, proveedor, httpOptions);
  }
}
