import { Injectable, EventEmitter } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Contact } from '../models/contact.model';
import { Tarea } from '../models/tarea.model';
import { Usuario } from '../models/usuario.model';
import { UsuarioService } from './usuario.service';

import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
    providedIn: 'root'
})
export class TareaService {
    usuario: Usuario;
    formulario: Contact;
    tareas2: Tarea;
    token: string;
// notificacion para escuchar evento
public notificacion = new EventEmitter<any>();
    private urlTareas = environment.URL_SERVICIOS + '/tareas';  // URL to web api
    private urlTareas1 = environment.URL_SERVICIOS + '/tareas/075';
    constructor(private http: HttpClient, private _usuarioService: UsuarioService) {

        this.notificacion.subscribe(
            resp =>
                   this.tareas2
            
        )
     }

    getTareas(): Observable<Tarea[]> {
        return this.http.get<Tarea[]>(this.urlTareas)
    }

    getTareas1(): Observable<Tarea[]> {
        return this.http.get<Tarea[]>(this.urlTareas1)
    }
    getTarea(tarea: Tarea | string): Observable<Tarea> {
        const id = typeof tarea === 'string' ? tarea : tarea._id;
        const url = `${this.urlTareas}/${id}`;
        return this.http.get<Tarea>(url);
    }
    addTarea(tarea:Tarea): Observable<Tarea>{
         this.urlTareas = environment.URL_SERVICIOS +'/tareas'
       //  this.urlTareas += '?token=' + this._usuarioService.token;
        return this.http.post<Tarea>(this.urlTareas, tarea);
    }
    updateTarea(tarea:Tarea): Observable<Tarea>{
        this.urlTareas = environment.URL_SERVICIOS +'/tareas';
      //  this.urlTareas += '?token=' + this._usuarioService.token;
        return this.http.put<Tarea>(this.urlTareas, tarea);
    }

    updateTarea100(tarea:Tarea): Observable<Tarea>{
        this.urlTareas = environment.URL_SERVICIOS +'/tareas';
       // this.urlTareas = '?token=' + this._usuarioService.token;
        return this.http.put<Tarea>(this.urlTareas, tarea);
    }

    updateTarea1(tarea:Tarea): Observable<Tarea>{
   
      //  this.urlTareas += '?token=' + this._usuarioService.token;
        return this.http.put<Tarea>(this.urlTareas, tarea);
    }
    deleteTarea(tarea: Tarea): Observable<Tarea> {
     
        const id = typeof tarea === 'string' ? tarea : tarea._id;
        const url = `${this.urlTareas}/${id}`;
        return this.http.delete<Tarea>(url);
     }



 buscarUsuarios( termino: string ) {
    let url = `${ environment.URL_SERVICIOS }/busqueda/coleccion/tareas/${ termino }`;

    return this.http.get( url ).pipe(map((resp: any) => resp.tareas));
  }
}
