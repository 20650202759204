import { Injectable } from '@angular/core';
import { CanActivate} from '@angular/router';

import { UsuarioService } from './usuario.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class LoginClienteGuard implements CanActivate {
  constructor(
    public _usuarioService: UsuarioService
  ) { }
  canActivate(){

    if ( this._usuarioService.usuario.role === 'CLIENTE_PROVEEDOR_ROLE'|| 'ADMIN_ROLE' ) {
      return true;
    }else {
      console.log( 'Bloqueado por el COMERCIAL GUARD');
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'No es usuario cliente',
      
      });
      this._usuarioService.logOutCliente();
      return false;
    }

  }
  
}
