import { Component, OnInit } from '@angular/core';
import { SolicitudService } from '../../../services/solicitud.service';
import { Solicitud } from '../../../models/solicitud.model';
import { ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';

declare function init_plugins()
@Component({
  selector: 'app-admin-prov',
  templateUrl: './admin-prov.component.html',
  styleUrls: ['./admin-prov.component.css']
})
export class AdminProvComponent implements OnInit {
  
  infSolicitud: Solicitud[]= [];

  desde: number = 0;

  totalRegistros: number = 0;
  cargando: boolean = true;
 

  constructor(private solicitudService: SolicitudService, private route: ActivatedRoute, private location: Location) { 
    init_plugins();
  }
  buscarProveedor = '';
  ngOnInit() {
    init_plugins();
    this.getSolicitud();

    
  }

  getSolicitud(){
    this.solicitudService.getInformesCompletos().subscribe(solicitud=>{
      console.log(solicitud);
      this.infSolicitud = solicitud;
      this.cargando = false;
    })

  }


  buscarUsuario(termino: string) {

    if (termino.length <= 0) {
      this.getSolicitud()
      return;
    }



    this.solicitudService.buscarUsuarios(termino)
      .subscribe((infSolicitud:  Solicitud[]) => {

        this.infSolicitud = infSolicitud;

      });

  }
}
