import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormProveedores } from '../../models/modelosProveedores/formularioProveedores';
import { Usuario } from '../../models/usuario.model';
import { Router } from '@angular/router';
import { UsuarioService } from '../usuario.service';

import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { AlertaService } from '../alerta.service';
import { SubirarchivoProveedorService } from '../subirarchivo-proveedor.service';
import { environment } from 'src/environments/environment';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class FormProveedoresServiceService {
  proveedor: FormProveedores;
  usuario: Usuario;
  token: string;
  private proveedoresUrl = environment.URL_SERVICIOS + '/proveedor';  // URL to web api
  constructor( 
    private http: HttpClient,
    private _subirArchivoService: SubirarchivoProveedorService,
    private _alertaService: AlertaService,
    private router: Router,
    public _usuarioService: UsuarioService
  ) { }

  getProveedores (): Observable<FormProveedores[]> {
    return this.http.get<FormProveedores[]>(this.proveedoresUrl+'/todos')
  }

  getProveedores1 (usuario_id:string): Observable<FormProveedores[]> {
    return this.http.get<FormProveedores[]>(this.proveedoresUrl+'?usuario_id='+usuario_id)
  }

  getProveedor(id: string): Observable<FormProveedores> {
    const url = `${this.proveedoresUrl}/${id}`;
    return this.http.get<FormProveedores>(url);
  }


  getCustomers(): Observable<FormProveedores[]> {
    return this.http.get<FormProveedores[]>(this.proveedoresUrl+'/todos')
  }

  addProveedor (proveedor: FormProveedores): Observable<FormProveedores> {
  
    return this.http.post<FormProveedores>(this.proveedoresUrl, proveedor, httpOptions);
  }

  deleteProveedor (proveedor: FormProveedores | string): Observable<FormProveedores> {
    const id = typeof proveedor === 'string' ? proveedor : proveedor._id;
    const url = `${this.proveedoresUrl}/${id}`;

    return this.http.delete<FormProveedores>(url, httpOptions);
  }

  updateCustomer (contacto:FormProveedores): Observable<any> {
    this.proveedoresUrl = environment.URL_SERVICIOS +'/proveedor'+'?token=' + this._usuarioService.token;
    return this.http.put(this.proveedoresUrl, contacto, httpOptions);
  }

  

  updateProveedor (proveedor: FormProveedores): Observable<any> {
   // this.proveedoresUrl += '?token=' + this._usuarioService.token;
    return this.http.put(this.proveedoresUrl, proveedor, httpOptions);
  }

  updateProveedor1 (proveedor: FormProveedores): Observable<any> {
  //   this.proveedoresUrl += '?token=' + this._usuarioService.token;
     return this.http.put(this.proveedoresUrl, proveedor, httpOptions);
   }

  estaLogueado() {
    return ( localStorage.getItem('token') ) ? true : false;
  }

  logOut() {
    this.usuario = null;
    this.token = '';
    localStorage.removeItem('id');
    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    localStorage.removeItem('itempages');

    this.router.navigate(['/loginProveedor']);
  }


  cargarUsuarios1( desde: number = 0 ) {

    let url = environment.URL_SERVICIOS + '/proveedor?desde=' + desde;
    return this.http.get( url );

  }


  buscarUsuarios( termino: string ) {
    let url = `${ environment.URL_SERVICIOS }/busqueda/coleccion/proveedor/${ termino }`;

    return this.http.get( url ).pipe(map((resp: any) => resp.proveedor));
  }


  cambiarImagen( archivo: File ) {

    this._subirArchivoService.subirArchivo( archivo, 'proveedor' )
          .then( (resp: any) => {

            this.proveedor.img = resp.proveedor.img;
            Swal.fire( 'Imagen Actualizada', this.proveedor.razonSocial, 'success' );
           

          })
          .catch( resp => {
            console.log( resp );
          }) ;

  }

  actualizarUsuario( proveedor: FormProveedores ) {
    let url = `${ environment.URL_SERVICIOS }/proveedor/${ proveedor._id }?token=${ this.token }`;

    return this.http.put(url, proveedor).pipe(map((resp: any) => {

      if ( proveedor._id === this.proveedor._id ) {
        let proveedorDB = resp.proveedor;
      
      }

      this._alertaService.mostrarAlerta('success', resp.mensaje, proveedor.razonSocial, 2000);
      return resp;
    }));
  }

}