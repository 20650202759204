import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout6',
  templateUrl: './layout6.component.html',
  styleUrls: ['./layout6.component.css']
})
export class Layout6Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
