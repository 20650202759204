
import { Component, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';

import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ListainformesService } from 'src/app/services/listainformes.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Usuario } from 'src/app/models/usuario.model';
import { Contact3 } from 'src/app/models/contactTarea';
import { SolicitudPagoComponent } from '../solicitud-pago/solicitud-pago.component';
import { SolicitudCompra } from 'src/app/models/solicitudCompra.model';
import { OpcionesGenerales2 } from 'src/app/models/modelosProveedores/opcinesGenerales2';
import { OpcionesGenerales2Service } from 'src/app/services/serviciosProveedores/opcines-generales2.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DragdropService } from 'src/app/services/dragdrop.service';
import { Solicitud1Service } from 'src/app/services/solicitud1.service';
import { Location } from '@angular/common';
import { HttpEvent, HttpEventType } from '@angular/common/http';

declare function init_plugins()

@Component({
  selector: 'app-formulario-adcicional',
  templateUrl: './formulario-adcicional.component.html',
  styleUrls: ['./formulario-adcicional.component.css']
})




export class FormularioAdcicionalComponent implements OnInit {

 
  formularioUsuario: FormGroup;
 SolicitudPagoComponent
  message: String;
  tipoInforme: String;
  razonSocial: String;
  ruc: String ;
  ciudad: String;
  telefono: String;
  email: String;
  lineaCredito: String;
  ventasAnuales: String;
  plazoPagoCredito: String;
  observaciones: String;
  tipocliente: String = "CLIENTE N";

lista = new Contact3();

lista2 =new Contact3();
submitted = false;
urlPDF:string;
urlPDF2:string;
//variables drop
fileArr = [];
imgArr = [];
fileObj = [];
//form: FormGroup;
msg: string;
progress: number = 0;
progresobarra:number =50
estadoFormulario: String = "ACTIVO" 

solicitudModel = new SolicitudCompra();
opcionesGenerales: OpcionesGenerales2[];//opciones que traigo de la  base
formularioSolicitud: FormGroup;

empresainforme:string;
rucinforme:String;
tipoinforme:String;

razonSocialCalificacionProveedor: String;
nombreComercialCalificacionProveedor: String;
rucCalificacionProveedor: String;

telefonoCalificacionProveedor: String;
paginaWebCalificacionProveedor: String;
correoCalificacionProveedor: String;
referenciaCalificacionProveedor: String;
cargoCalificacionProveedor: String;
correoContactoCalificacionProveedor: String;
fechaCalificacionProveedor: String= new Date().toISOString();
tipoPago: String;
bancoCalificacionProveedor: String;
cuentacorrienteCalificacionProveedor: String;
pagoanombredeCalificacionProveedor: String;

ndefacturaCalificacionProveedor: String;
detalledepagoCalificacionProveedor: String;
existe:String= "INFORME EXISTENTE"
//expresiones regulares

emailPattern = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z.ec.A-Z-a-z]{2,}))$/);
//textPattern= new RegExp(/^[a-zA-Z ]+$/);
textPattern = new RegExp(/^[A-Z]{1}[a-zA-ZñÑáéíóúÁÉÍÓÚ&.\s]+$/);
numberPattern = new RegExp(/^[0-9/-]+$/);
//pageWebPattern= new RegExp(/^[w]+([\.]+[\w]+)+$/);
pageWebPattern = new RegExp(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/);







constructor(
  
  private fb: FormBuilder, private listainforme:ListainformesService,  private route: ActivatedRoute, private solicitudService: Solicitud1Service,private location:Location, private router: Router,private opcionesServices: OpcionesGenerales2Service,  
  private sanitizer: DomSanitizer,  public dragdropService: DragdropService ) {
    this.buildForm();
  }
ngOnInit() {
  this.getOpciones1();
  init_plugins();
  const id = this.route.snapshot.paramMap.get('id');
  this.listainforme.getCustomer(id)
    .subscribe(lista => {
      this.lista = lista
   
      this.ruc= this.solicitudModel.rucCalificacionProveedor;
      this.razonSocial= this.solicitudModel.razonSocialCalificacionProveedor;
      this.tipoInforme = this.solicitudModel.tipoinforme
    });

}


buildForm() {
  /**
   * @description Asignamos a la propiedad "formularioUsuario" los campos que se van a controlar de la vista
   */
  this.formularioUsuario = this.fb.group({
    tipoInforme: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(45)]],
    razonSocial: ['', [Validators.required, Validators.maxLength(30)]],
    ruc: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(450)]],
    ciudad: ['', [ Validators.minLength(5), Validators.maxLength(45)]],
    telefono: ['', [ Validators.minLength(10), Validators.maxLength(10)]],
    email: [''],
    lineaCredito: ['', [ Validators.minLength(3), Validators.maxLength(45)]],
    ventasAnuales:[''],
    plazoPagoCredito: ['', [ Validators.minLength(3), Validators.maxLength(45)]],
    observaciones: [''],
    tipocliente: [''],

    razonSocialCalificacionProveedor: ['', [ Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
    nombreComercialCalificacionProveedor: [''],
//    direccionCalificacionProveedor: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60)]],
     empresainforme:['',[ Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
//rucinforme:['',[ Validators.minLength(13), Validators.maxLength(13), Validators.pattern(this.numberPattern)]],
//tipoinforme:[''],



 //   rucCalificacionProveedor: ['', [ Validators.minLength(13), Validators.maxLength(13), Validators.pattern(this.numberPattern)]],

    // en ves del express
  //  provinciasEcuadorCalificacionProveedor: ['', [Validators.required]],
  //  ciudadesEcuadorCalificacionProveedor: ['', [Validators.required]],
    comprobantePago: [''],
    avatar: [null],
    telefonoCalificacionProveedor: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(50), Validators.pattern(this.numberPattern)]],
    correoCalificacionProveedor: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
    paginaWebCalificacionProveedor: ['', [Validators.required, Validators.pattern(this.pageWebPattern)]],


    referenciaCalificacionProveedor: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
    cargoCalificacionProveedor: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
    correoContactoCalificacionProveedor: ['', [Validators.required, Validators.pattern(this.emailPattern)]],


    fechaCalificacionProveedor: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10),/*Validators.pattern(this.numberPattern)*/]],
    ndefacturaCalificacionProveedor: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
    detalledepagoCalificacionProveedor: ['', [Validators.required, Validators.pattern(this.textPattern)]],

    bancoCalificacionProveedor: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(13)]],
    cuentacorrienteCalificacionProveedor: ['', [Validators.required, Validators.minLength(3)]],
    pagoanombredeCalificacionProveedor: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
    tipoPago: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],

  //  depositoCalificacionProveedor: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
  //  chequeCalificacionProveedor: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
  //  transferenciaCalificacionProveedor: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
  
});
}

newLista(): void {
  this.submitted = false;
  this.lista = new Contact3();
}

addLista() {
  this.submitted = true;
  this.save1();

}
 
private save1(): void {
  console.log(this.lista);
  this.lista2.ruc = this.ruc;
  this.lista2.razonSocial = this.razonSocial;
  this.lista2.tipoInforme = this.tipoInforme;
  this.lista2.usuario = JSON.parse(localStorage.getItem('usuario')) as Usuario;
  this.listainforme.addCustomer1(this.lista2)
 
      .subscribe(res =>{
      console.log(res)
        console.log(res)
        Swal.fire(
          'Muy Bien',
           'Datos Guardados Éxitosamente',
           'success'
         );

      });
     
        this.onResetForm();
}


onResetForm2() {
  this.formularioUsuario.reset();

}

update(): void {
  this.submitted = true;
  this.lista.estado = "0"
  this.lista.usuario = JSON.parse(localStorage.getItem('usuario')) as Usuario;

  this.listainforme.addCustomer1(this.lista)
  
    .subscribe(result => this.message = "El proveedor fue actualizado");

    console.log(this.lista);
  Swal.fire('Correcto', `La información fue actualizada con éxito`, 'success');

  this.onResetForm2();
}

/*==========================================================================================================*/

saveData() {
  //  this.razonSocialCalificacionProveedor = this.formularioSolicitud.value.razonSocialCalificacionProveedor;
  //  this.direccionCalificacionProveedor = this.formularioSolicitud.value.direccionCalificacionProveedor;
   // this.nombreComercialCalificacionProveedor = this.formularioSolicitud.value.nombreComercialCalificacionProveedor;


    // en ves del express
  //  this.ciudadesEcuadorCalificacionProveedor = this.formularioSolicitud.value.ciudadesEcuadorCalificacionProveedor;
  //  this.provinciasEcuadorCalificacionProveedor = this.formularioSolicitud.value.provinciasEcuadorCalificacionProveedor;



 //   this.rucCalificacionProveedor = this.formularioSolicitud.value.rucCalificacionProveedor;
    this.telefonoCalificacionProveedor = this.formularioSolicitud.value.telefonoCalificacionProveedor;
    this.correoCalificacionProveedor = this.formularioSolicitud.value.correoCalificacionProveedor;
    this.paginaWebCalificacionProveedor = this.formularioSolicitud.value.paginaWebCalificacionProveedor;

    this.referenciaCalificacionProveedor = this.formularioSolicitud.value.referenciaCalificacionProveedor;
    this.cargoCalificacionProveedor = this.formularioSolicitud.value.cargoCalificacionProveedor;

    this.correoContactoCalificacionProveedor = this.formularioSolicitud.value.correoContactoCalificacionProveedor;
    this.fechaCalificacionProveedor = this.formularioSolicitud.value.fechaCalificacionProveedor;

   // this.depositoCalificacionProveedor = this.formularioSolicitud.value.depositoCalificacionProveedor;
   // this.chequeCalificacionProveedor = this.formularioSolicitud.value.chequeCalificacionProveedor;
   // this.transfereciaCalificacionProveedor = this.formularioSolicitud.value.transfereciaCalificacionProveedor;
   this.tipoPago  = this.formularioSolicitud.value.tipoPago;
    this.bancoCalificacionProveedor = this.formularioSolicitud.value.bancoCalificacionProveedor;
    this.cuentacorrienteCalificacionProveedor = this.formularioSolicitud.value.cuentacorrienteCalificacionProveedor;
    this.pagoanombredeCalificacionProveedor = this.formularioSolicitud.value.pagoanombredeCalificacionProveedor;
    this.detalledepagoCalificacionProveedor = this.formularioSolicitud.value.detalledepagoCalificacionProveedor;
    this.ndefacturaCalificacionProveedor = this.formularioSolicitud.value.ndefacturaCalificacionProveedor;

    alert("Se Han ingresado exitosamente los datos");
    console.log(this.formularioSolicitud.value);
    this.onResetForm();
  }
 /* back() {
    setTimeout(() => {
      this.location.back();
    }, 1400);
  }  */

  addSolicitud() {

    this.save();
   // this.back();
   this.onResetForm();
  }




  getOpciones1() {
    return this.opcionesServices.getOpciones()
      .subscribe(
        opcionesGenerales => {
        //  this.toastr.success('Seleccione una opcion para validar si manda el documento solicitado o no!', 'Hola!');
          console.log(opcionesGenerales);
          this.opcionesGenerales = opcionesGenerales
        }
      );
  }
  
  /*goBack(): void {
    this.location.back();
  }*/

  private save(): void {
    this.solicitudModel.informeExistente = this.existe;
    this.solicitudModel.rucinforme =this.lista.ruc;
    this.solicitudModel.razonSocialCalificacionProveedor= this.lista.razonSocial;
    this.solicitudModel.tipoinforme = this.lista.tipoInforme;
    this.solicitudModel.estado = this.estadoFormulario;
    this.solicitudModel.progreso = this.progresobarra;
    this.solicitudModel.urlPDF = this.urlPDF;
    this.solicitudModel.usuario = JSON.parse(localStorage.getItem('usuario')) as Usuario;
    if (this.solicitudModel.nombreComercialCalificacionProveedor ===undefined ||
 
    this.solicitudModel.telefonoCalificacionProveedor===undefined ||  this.solicitudModel.referenciaCalificacionProveedor===undefined /*|| !this.solicitudModel*/) {
   // Swal.fire(`Por favor todos los campos son obligatorios.`, 'Verifique');
    Swal.fire(
      'Solicitud no enviada',
      'Por favor, todos los campos son obligatorios.',
      'error');
      
    } else {
      console.log(this.solicitudModel);
      this.solicitudService.addInformeCompleto(this.solicitudModel)
        .subscribe();
        Swal.fire('Correcto', `La información fue guardada con éxito`, 'success');
   
    }
    

  }


  onResetForm() {
    this.formularioUsuario.reset();

  }


  requisito(){
    this.router.navigateByUrl('/requisito');
  }



  //nueva importacion de archivos drop
  upload(e) {
    const fileListAsArray = Array.from(e);
    fileListAsArray.forEach((item, i) => {
      const file = (e as HTMLInputElement);
      const url = URL.createObjectURL(file[i]);
      this.imgArr.push(url);
      this.fileArr.push({ item, url: url });
    })

    this.fileArr.forEach((item) => {
      this.fileObj.push(item.item)
    })

    // Set files form control
    this.formularioUsuario.patchValue({
      avatar: this.fileObj
    })

    this.formularioUsuario.get('avatar').updateValueAndValidity()

    // Upload to server
    this.dragdropService.addFiles(this.formularioUsuario.value.avatar)
      .subscribe((event: HttpEvent<any>) => {
     //   console.log(event.body.userCreate);
        switch (event.type) {
          case HttpEventType.Sent:
            console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:
            console.log('File uploaded successfully!', event.body);
             this.urlPDF= event.body.userCreated.avatar[0];
            setTimeout(() => {
              this.progress = 0;
              this.fileArr = [];
              this.fileObj = [];
              this.msg = "Archivo subido corretamente!"
              
            }, 3000);
        }
      })
  }

  // Clean Url for showing image preview
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }







}


