import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';
import { TareaService } from 'src/app/services/tareas.service';
import { ListainformesService } from 'src/app/services/listainformes.service';

//import { MatTableModule } from '@angular/material/table';
import { NgxSpinnerService } from "ngx-spinner";

import { Usuario } from 'src/app/models/usuario.model';
import { Contact } from 'src/app/models/contact.model';
import { Tarea } from 'src/app/models/tarea.model';
import Swal from 'sweetalert2';
import { FormProveedoresServiceService } from 'src/app/services/form-proveedores-service.service';

import { FormExpress } from 'src/app/models/formularioExpress';
import { CompletoService } from 'src/app/services/completo.service';
import { formCompleto } from 'src/app/models/formularioCompleto';

import { Contact3 } from 'src/app/models/contactTarea';
declare function init_plugins()

@Component({
  selector: 'app-desactivar',
  templateUrl: './desactivar.component.html',
  styleUrls: ['./desactivar.component.css']
})
export class DesactivarComponent implements OnInit {

  //Importa todos los inputs checkBox para la asignación de tarea
  @ViewChildren("myInput") inputs: QueryList<any>
  //Usuario responsable
  userOption: String;
  //Todos los informes creados
  completosInf = []
  expressosInf = []

  lista3 = new Contact3();
  lista1: Tarea[] = [];

  customer = new Tarea() ;
  submitted = false;
  message: string;
  //Tareas

  tareas = []
  //Usuarios 
  lista: Contact[] = [];
  //Operadores
  usuarios: Usuario[] = [];
  desde: number = 0;
  totalRegistros: number = 0;
  cargando: boolean = true;
  buscarinforme = '';

  //paginacion
  desde1: number = 0;
  totalRegistros1: number = 1;


  
  filtroInformes = "";



  constructor(public _usuarioService: UsuarioService,
    private _tareaService: TareaService,
    private listainforme: ListainformesService,
    //Servicio para traer 
    private informeService: FormProveedoresServiceService,
    private completoService: CompletoService,
    //Servicio para crear 
    private proveedoresService: FormProveedoresServiceService,
 
    private spinner: NgxSpinnerService) {
      init_plugins()
     }
  filtroTareas = "";
  ngOnInit() {
    init_plugins();
  this.cargarUsuarios()
   
    this.getCustomers1();
  //  this.getFormulariosCreados();
   // this.getTareas();
   this._tareaService.notificacion.subscribe( 
     resp =>
     this.getCustomers1()
   
    );
 
  }

  getCustomers1() {
    return this.listainforme.getCustomers1()
      .subscribe(
        
        lista => {
          console.log(lista)
          for (var form in lista) {
            for (var user in this.usuarios) {
              if (this.usuarios[user]._id == lista[form].usuario) {
                lista[form].usuario = this.usuarios[user].nombre;
              }
              if (lista[form].fechaLimite == undefined || lista[form].fechaLimite == null) {
                var date = new Date();
                lista[form].fechaLimite = (date.getFullYear().toString() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + (date.getDate() + 2)).slice(-2));
              }
            }
          }
          lista.sort(this.sortObjetc);
          this.lista = lista
         // console.log(this.lista);
          lista.sort(this.sortObjetc1);
          this.lista = lista
        //  console.log(this.lista);
          this.getTareas();
        }
      );
  }
  sortObjetc(a, b) {
    return a.fechaLimite > b.fechaLimite ? 1 : -1;
  };
  sortObjetc1(a, b) {
    return a.estado  - b.estado ? 100 : -25;
  };
  asignarTarea() {
    this.inputs.forEach(input => {
      var date = new Date();
      var fecha = (date.getFullYear().toString() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + (date.getDate())).slice(-2));
      if (input.nativeElement.checked) {
        var tarea = new Contact;
        tarea.usuario = this.userOption;
       
        for (var t in this.lista) {
          if (this.lista[t]._id == input.nativeElement.value) {
            tarea.fechaLimite = this.lista[t].fechaLimite;
          }
        }
        tarea.estado = "0";
        this.listainforme.addCustomer(tarea).subscribe(resp => {
          input.nativeElement.checked = false;
         // this._tareaService.notificacion.emit( resp );
        //  this.crearFormularios()
          Swal.fire("Asignación existosa", "", "success").then(function (e) {
          
          //  window.location.reload();
          });

          this._tareaService.notificacion.emit( resp );
        }, err => {
          if (err.status = (409)) {
            Swal.fire('Usuario', 'Ya existe!', 'error');
          }
          console.log(err)
        },
        
        
        
        
        );
      }
    })
  }
  crearFormularios() {
    for (var form in this.lista) {
      var id: string = (this.lista[form]._id);
      if (this.lista[form].tipoInforme == "express") {
        if (this.expressosInf.indexOf(id) == -1) {
          //Crea el informe Express
          var express = new FormExpress()
          express._id = id;
          express.razonSocialExpress = this.lista[form].razonSocial;
          express.rucExpress = parseInt(this.lista[form].ruc);
          express.solicitanteExpress = this.lista[form].usuario["nombre"];
          this.proveedoresService.addInforme(express).subscribe();
        }
      } else if (this.lista[form].tipoInforme == "completo") {
        if (this.completosInf.indexOf(id) == -1) {
          //Crea el informe Completo
          var completo = new formCompleto();
          completo._id = id;
          completo.rucCompleto = this.lista[form].ruc;
          completo.solicitanteCompleto = this.lista[form].usuario["nombre"];
          this.completoService.addInformeCompleto(completo).subscribe();
        }
      }
    }
    this.getFormulariosCreados();
  }
  getFormulariosCreados() {
    this.completoService.getInformesCompletos().subscribe(informesCompletos => {
      for (var completo in informesCompletos) {
        this.completosInf.push(informesCompletos[completo]._id)
      }
    }
    );
    this.informeService.getInformes().subscribe(informesExpress => {
      for (var express in informesExpress) {
        this.expressosInf.push(informesExpress[express]._id);
      }
    }
    );
  }
  getTareas() {
    this._tareaService.getTareas().subscribe(respTareas => {
      for (var tarea in respTareas) {
        this.tareas.push(respTareas[tarea]);
        for (var form in this.lista) {
          if (respTareas[tarea]._idFormulario == this.lista[form]._id) {
            for (var user in this.usuarios) {
              if (respTareas[tarea]._idResponsable == this.usuarios[user]._id) {
              //  console.log(this.usuarios[user]);
              //  console.log(respTareas[tarea]);
                
           //     console.log(this.usuarios[user].nombre);
                this.lista[form].fechaAsignada = respTareas[tarea].fechaAsingada
                this.lista[form].fechaLimite = respTareas[tarea].fechaLimite
           //     console.log(this.lista[form]);
               
          //      console.log(this.lista[form]["responsable"]);
                this.lista[form]["responsable"] = this.usuarios[user].nombre;
           //     console.log(this.lista[form]["responsable"]);
              }
            }
          }
        }
      }
      this.cargando = false;
    });
  }



 /* buscarUsuario(termino: string) {

    if (termino.length <= 0) {
      this.getCustomers1();
      return;
    }



    this.listainforme.buscarinforme(termino)
      .subscribe((  lista: Contact[]) => {

        this.lista = lista;

      });

       delete(): void {

  this._tareaService.deleteTarea()
      .subscribe(result => 
        Swal.fire('Tarea', 'eliminada!')
        
     );
}

  }
  */
delete(_id) {
  this.submitted = true;
  this.listainforme.deleteCustomer(_id)
      .subscribe(result => { 
        
        this._tareaService.notificacion.emit( result );
        console.log(result)
        Swal.fire(
          'TAREA!',
          'ELIMINADA CON EXITO!',
          'success'
        )
      }
        );
}

ActulizarEstado1( lista: Contact ) {
  //  lista.estado2 ='0'
    this.listainforme.updateCustomer( lista )
            .subscribe()
            Swal.fire('INFORME  '  + lista.estado2,` Exitosamente`, 'success');
   
  }
//==========================================PROVEEDORES==================================================================================


cargarUsuarios() {
  this.cargando = true;
  this._usuarioService.cargarUsuarios1(this.desde)
    .subscribe((resp: any) => {
      for (var user in resp.usuarios) {
        //Habilitar en caso de querer cargar todos los roles de usuario en la asignación de tareas.
        if (resp.usuarios[user].role == "ANALISTA_ROLE" || resp.usuarios[user].role == "ADMIN_ROLE") {
          this.usuarios.push(resp.usuarios[user]);
        }
      }
      this.totalRegistros = resp.total;
      this.cargando = false;
    });
}

update(): void {
  this.submitted = true;
  this.lista3.estado = "0"
  this.lista3.usuario = JSON.parse(localStorage.getItem('usuario')) as Usuario;
  this.listainforme.updateCustomer1(this.lista3)
  
    .subscribe(result => this.message = "El proveedor fue actualizado");

    console.log(this.lista3);
  Swal.fire('Correcto', `La información fue actualizada con éxito`, 'success');


}

}
