import { Component, OnInit, ɵConsole, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormCalificacion } from '../../models/modelosProveedores/formularioCalificacion';
import { FormProveedores } from '../../models/modelosProveedores/formularioProveedores';
import { FormProveedoresServiceService } from '../../services/serviciosProveedores/form-proveedores-service.service';
import { ActivatedRoute, Data } from '@angular/router';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';

import { OpcionesGenerales } from '../../models/modelosProveedores/opcionesGenerales';
import { OpcionesGeneralesService } from '../../services/serviciosProveedores/opciones-generales.service';
//MICHAEL
//import { isNull } from 'util';
import { InformeFinalService } from '../../services/serviciosProveedores/informe-final.service';
//grafos
//import { Chart} from 'chart.js';
import { grafos } from './grafos';
import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { SingleDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import plugin, * as pluginDataLabels from 'chartjs-plugin-datalabels';
//import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
//import { style } from '@angular/animations';
//import { ConsoleReporter } from 'jasmine';
import * as ClassicEditorBuild from '@ckeditor/ckeditor5-build-classic';
import { OpcionesGenerales3 } from 'src/app/models/modelosProveedores/opcionesGenerales3';
import { OpcionesGenerales3Service } from 'src/app/services/serviciosProveedores/opciones-generales3.service';
declare function init_plugins()

@Component({
  selector: 'app-calificacion-proveedores2',
  templateUrl: './calificacion-proveedores2.component.html',
  styleUrls: ['./calificacion-proveedores2.component.css']
})
export class CalificacionProveedores2Component   implements OnInit {
  @ViewChild('formularioCalificacion', { static: true })
  public Editor = ClassicEditorBuild;


  //modelo exportado
  proveedor = new FormProveedores();//modelo que vamos a traer
  currentDate = new Date();
  anoBalance1: string
  anoBalance2: string
  //Barras
  public barChartOptions: ChartOptions = {
    responsive: true,
    title: {
      display: true,
      text: 'GRAFICO COMPARATIVO ENTRE  PERIODOS',
      fontSize: 30,
      fontColor: 'black',
      fontFamily: "Candara",
    },
    legend: {
      position: 'bottom',
    },
    plugins: {
      datalabels: {
        align: function (context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];

          return value < 6 ? 'end' : 'start'
        },
        formatter: function (value, context) {
          return Math.round(value * 100) + '%';
        },


        color: 'transparent',
        anchor: 'end',

        font: {
          size: 15,
          weight: 100
        },
      }
    }
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [pluginDataLabels];
  public barChartData: ChartDataSets[] = [
    { data: [], label: 'Año A' },
    { data: [], label: 'Año B' }
  ];


  //fin  barras


  // Pie 1
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: "bottom",
    },
    plugins: {
      datalabels: {
        align: function (context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];
          return value == 0.00 ? 'end' : 'start'
        },
        formatter: function (value) {
          return Math.round((value * 100) / 100).toFixed(0) + '%';

        },
        color: function (context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];
          return value < 1 ? 'transparent' : 'black'
        },
        anchor: 'end',

        font: {
          size: 15,
          weight: 600
        },
      }
    }
  };
  public pieChartLabels: Label[] = [];
  public pieChartData: SingleDataSet = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: ["#FF6384", "#6384FF",
        "#63FF84",
        "#A55FEB",
        "#FF800D",
        "#8463FF",
        "#6384FF",
        "#5757FF",
        "#CA00CA",
        "#FF2626",
        "#66FF00",
      ],
    },
  ];


  public pieChartLabels3: Label[] = [];
  public pieChartData3: SingleDataSet = [];
  public pieChartType3: ChartType = 'pie';
  public pieChartLegend3 = true;
  public pieChartPlugins3 = [pluginDataLabels];
  public pieChartColors3 = [
    {
      backgroundColor: ["#FF6384", "#6384FF",
        "#63FF84",
        "#A55FEB",
        "#FF800D",
        "#8463FF",
        "#5757FF",
        "#CA00CA",
        "#FF2626",
        "#66FF00",
      ],
    },
  ];

  // Pie 2
  public pieChartOptions2: ChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
    },
    plugins: {
      datalabels: {
        color: function (context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];
          return value != 0.00 ? 'black' : 'transparent'
        },
        anchor: 'end',
        align: function (context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];
          return value == 0 ? 'end' : 'start'
        },
        formatter: function (value) {
          return Math.round((value * 100) / 100).toFixed(0) + '%';
        },
        font: {
          size: 15,
          weight: 600
        },
      }
    }
  };
  public pieChartLabels2: Label[] = [];
  public pieChartData2: SingleDataSet = [];
  public pieChartType2: ChartType = 'pie';
  public pieChartLegend2 = true;
  public pieChartPlugins2 = [pluginDataLabels];
  public pieChartColors2 = [
    {
      backgroundColor: ["#FF6384", "#6384FF",
        "#63FF84",
        "#A55FEB",
        "#FF800D",
        "#8463FF",
        "#5757FF",
        "#CA00CA",
        "#FF2626",
        "#66FF00",
      ],
    },
  ];

  // Pie 3
  public pieChartOptions3: ChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
    },
    plugins: {
      datalabels: {
        color: function (context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];
          return value != 0.00 ? 'black' : 'transparent'
        },
        anchor: 'end',
        align: function (context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];
          return value == 0 ? 'end' : 'start'
        },
        formatter: function (value) {
          return Math.round((value * 100) / 100).toFixed(0) + '%';
        },
        font: {
          size: 15,
          weight: 600
        },
      }
    }
  };
  // Pie 4
  public pieChartOptions4: ChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
    },
    plugins: {
      datalabels: {
        color: function (context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];
          return value != 0.00 ? 'black' : 'transparent'
        },
        anchor: 'end',
        align: function (context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];
          return value == 0 ? 'end' : 'start'
        },
        formatter: function (value) {
          return Math.round((value * 100) / 100).toFixed(0) + '%';
        },
        font: {
          size: 15,
          weight: 600
        },
      }
    }
  };
  public pieChartLabels4: Label[] = [];
  public pieChartData4: SingleDataSet = [0, 0, 0, 0, 0, 0, 0];
  public pieChartType4: ChartType = 'pie';
  public pieChartLegend4 = true;
  public pieChartPlugins4 = [pluginDataLabels];
  public pieChartColors4 = [
    {
      backgroundColor: ["#FF6384", "#6384FF",
        "#63FF84",
        "#A55FEB",
        "#FF800D",
        "#8463FF",
        "#5757FF",
        "#CA00CA",
        "#FF2626",
        "#66FF00",
      ],
    },
  ];

  // Pie 5
  public pieChartOptions5: ChartOptions = {
    responsive: true,

    legend: {
      position: 'bottom',
    },
    plugins: {
      datalabels: {
        color: function (context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];
          return value != 0.00 ? 'black' : 'transparent'
        },
        anchor: 'end',
        align: function (context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];
          return value == 0 ? 'end' : 'start'
        },
        formatter: function (value) {
          return Math.round((value * 100) / 100).toFixed(0) + '%';
        },
        font: {
          size: 15,
          weight: 600
        },
      }
    }
  };
  public pieChartLabels5: Label[] = [];
  public pieChartData5: SingleDataSet = [];
  public pieChartType5: ChartType = 'pie';
  public pieChartLegend5 = true;
  public pieChartPlugins5 = [pluginDataLabels];
  public pieChartColors5 = [
    {
      backgroundColor: ["#FF6384", "#6384FF",
        "#63FF84",
        "#A55FEB",
        "#FF800D",
        "#8463FF",
        "#5757FF",
        "#CA00CA",
        "#FF2626",
        "#66FF00",
      ],
    },
  ];





  //Informacion general

  informe = new FormCalificacion();

  message: String;

  submitted = false;
  //select
  fechainicio : string;
  fechafin: string;
  equivaleciaFraseBase: String;
  valorCalificacionResultado: String;
  equivalenciatotalBase:String; 
  opcionesGenerales3: OpcionesGenerales3[];
  opcionesGenerales: OpcionesGenerales[];//opciones que traigo de la  base
  cartaPresentacionRequisito: String;
  ruRequisito: String;
  planillaIessRequisito: String;
  certfSuperintendeciaRequisito: String;
  certificadoSriRequisito: String;
  predialRequisito: String
  patenteMunicipalRequisito: String
  copiaFacturaRequisito: String;
  certMarcaEspecialRequisito: String;
  certSistemRequisito: String;
  certBancPagoRequisito: String;
  certComerClienRequisito: String;
  certComerProveedorRequisito: String;
  certRiesgoRequisito: String;
  estadoFinancieroRequisito: String;


  //campos
  formularioCalificacion: FormGroup;
  tipoProveedorCalificacion: String;
  razonSocialCalificacion: String;
  descripcion: String;
  nombreComercialCalificacion: String;
  rucCalificacion: String;
  formaLegalCalificacion: String;
  capitalSPCalificacion: Number;
  estadoCalificacion: String;
  //iniActiCalificacion: String;
  iniActiCalificacion: Number;
  objSocialCalificacion: String;
  actPrinCalificacion: String;
  ubicacionCalificacion: String;
  direccionCalificacion: String;
  telfCallificacion: String;
  celCalificacion: String
  pagWebCalificacion: String;
  emailCalificacion: String;
  representanteLegalCalificacion: String;
  personContactlCalificacion: String;
  personCargolCalificacion: String;
  personEmaillCalificacion: String;
  empresaRelacionlCalificacion: String;
  grupoEcolCalificacion: String;
  representLegalCedCalificacion: String;
  codActivCalificacion: String;
  impuestoRentaCalificacion: Number;
  impuestoRentaCalificacion1: Number;
  impuestoDivisasCalificacion: Number;
  impuestoDivisasCalificacion1: Number;
  //nuevos
  actividadFinancieraPuntaje: String;
  actividadFinancieraEquivalencia: String;
  capacidadOperativaPuntaje: String;
  capacidadOperativaEquivalencia: String;
  gestionCalidadPuntaje: String;
  gestionCalidadEquivalencia: String;
  comercialPuntaje: String;
  comercialEquivalencia: String;
  cumplimientoRegularesPuntaje: String;
  cumplimientoRegularesEquivalencia: String;



  checksCalidadProductoR1: String;
  checksCalidadProductoDos: String;
  checksObligacionesTributarias: String;
  checksObligacionesPatronales: String;
  checksPagoImpuestoPredial: String;
  checksObligacionSuper: String
  checksTipoSeguros: String;
  checksPatenteMunicipalActualizado: String;

  checkspuntualidadEntregaUno: String;
  checkspuntualidadEntregaDos: String;
  //nuevo
  directivosNombreUno: String;
  directivosCargoUno: String;
  directivosCargoDos: String;
  directivosNombreDos: String;
  directivosNombreTres: String;
  directivosCargoTres: String;
  directivosNombreCuatro: String;
  directivosCargoCuatro: String;
  directivosNombreCinco: String;
  directivosCargoCinco: String;
  participacionAccionaria: String;
  participacionAccionariaPorcentaje: String;
  participacionAccionariaDos: String;
  participacionAccionariaPorcentajeDos: String;
  nombreReferenciaUno: String;
  nombreReferenciaDos: String;
  direccionReferenciaUno: String;
  direccionReferenciaDos: String;
  telefonoReferenciaUno: String;
  telefonoReferenciaDos: String;
  contactReferenciaUno: String;
  proveedroDesdeReferenciaUno: String;
  proveedroDesdeReferenciaDos: String;
  productoServicioReferenciaUno: String;
  productoServicioReferenciaDos: String;
  contactUno: String;
  refeDosContacto: String;
  //Siguientes
  checksInfraestructura: String;
  checksSucursales: String;
  checksContratacion: String;
  checksMaquinaria: String;
  checksMaquinariaPlan: String;
  checksMedioDistribucion: String;
  checksMedicionCapacidad: String;
  checksControlInventario: String;
  checks12: String;
  checks13: String;
  checks14: String;
  checks15: String;
  checks16: String;
  checks17: String;
  checks18: String;
  checks19: String;
  checks20: String;
  checks21: String;
  checks22: String;
  checks23: String;
  checks24: String;
  checks25: String;
  checks26: String;
  checks27: String;
  checks28: String;
  checks29: String;
  participacionAccionariaTres: String;
  participacionAccionariaPorcentajeTres: String;
  participacionAccionariaCuarto: String;
  participacionAccionariaPorcentajeCuatro: String;
  participacionAccionariaCinco: String;
  participacionAccionariaPorcentajeCinco: String;
  DescripcionInfraestructura: String;
  checks4: String;
  DescripcionSucursales: String;
  checks13SiDescripcion: String;
  check12SiDescripcion: String;
  checks15SiDescripcion: String;
  checks16SiDescripcion: String;
  checks17SiDescripcionEmpr: String;
  checks18SiDescripcion: String;
  checks14SiDescripcion: String;
  checks19DescripcionSi: String;
  checks20Uno: String;
  checks20Dos: String;
  checks20Tres: String;
  checks20Cuatro: String;
  checks20Cinco: String;
  checks20Seis: String;
  checks20OtrosDescripcion: String;
  Chck4DescripRentada: String;
  check5Cuantas: String;
  controlNumero: String;
  controlNumoeroPesonal: String;
  checks6: String;
  checks7: String;
  checks8: String;
  checks9: String;
  checks10: String;
  checks10SiDescripcion: String;
  checks11: String;
  check11SiDescripcion: String;
  checks8DescriMantenimiento: String;
  nombreReferenciaComercial: String;
  nombreReferenciaComercial2: String;
  numeroReferenciaComercial: String;
  checks27U: String;
  checks27SiDescripcion: String;
  numeroReferenciaComercial2: String;
  check21SiPorcentaje: String;
  checks22SiPaises: String;
  checks23SIDescripcion: String;
  checks24SiDescripcion: String;
  check25SiDescripcion: String;
  checks26SiDescripcion: String;
  checks28SiDescripcion: String;
  checks29SiDescripcion: String;
  refeUnoContacto: String;
  refeUnoContact: String;
  numeroReferenciaComercialDos: String;
  refeUnoContactoUno: String;
  ContactoUno: String;


  //campos de checks de seguros
  checks2: String;
  checks2Uno: String;
  checks2Dos: String;
  checks2Tres: String;
  checks2Cuatro: String;
  checks2Descripcion: String;
  //checks lista negra
  checksListaClinton: String;

  //add anos financieros
  totalUnidadesUno: String;
  totalUnidadesDos: String;
  //tabla Unidades
  ventas16: Number;
  ventas17: Number;
      activos16:Number;
      activos17:Number;
      pasivos16:Number;
      pasivos17: Number;
      patrimonio16: Number;
      patrimonio17: Number;
      utilidad16: Number;
      utilidad17: Number;

      



  bancoUno: String;
  clienteDesdeUno: String;
  protestoUno: String;
  comentarioUno: String;
  bancoDos: String;
  saldoPromedioDos: String;
  protestoDos: String;
  comentarioDos: String;
  //
  totalPuntaje: String;
  totalEquivalencia: String;
  descripcionCalificacion: String;
  institucion: String;
  fechasCorte: String;
  tipoRegistro: String;
  tipoCredito: String;
  totalValancer: String;
  dem: String;
  cart: String;
  saldoDeuda: String;
  totalVencido: String;
  fechasCorte2: String;
  institucion2: String;
  tipoRegistro2: String;
  tipoCredito2: String;
  totalValancer2: String;
  totalVencido2: String;
  dem2: String;
  cart2: String;
  saldoDeuda2: String;
  contctDos: String;
  salProme: String;
  salProme1: String;
  banc1: String;
  clienteDesdeD1: String;
  protest: String;
  protesto2: String;
  comentario: String;
  coment2: String;
  nombreProveedor: String;
  nombreReferenciaProveedor: String;
  nombreReferenciaProveedor2: string;
  direccionProveedor1: String;
  direccionProveedor2: String;
  numeroProveedorUno: String;
  numeroRefeProveedor2: String;
  ContactoUProveedor1: String;
  contcProveedor2: String;
  lineaCredito: String;
  lineaCreditoDias: String;
  lineaCreditoDos: String;
  lineaCreditoDosDias: String;
  productoServicioProveedorUno: String;
  productoServicioProveedorDos: String;
  clientedesdeProveedorUno: String;
  clientedesdeProveedorDos: String;
  compraMensualProveedorUno: String;
  compraMensualProveedorDos: String;
  checkspuntualidadEntregaProveedor1: String;
  checkspuntualidadEntregaProveedor2: String;
  numeroRefeProveedorUno: String;
  listadoProceso: Text;
  nombreDirectivo2: String;
  fortalezacamp1: String;
  fortalezacamp2: String;
  fortalezacamp3: String;
  fortalezacamp4: String;
  fortalezacamp6: String;
  fortalezacamp7: String;
  fortalezacamp8: String;
  fortalezacamp9: String;
  fortalezacamp10: String;
  fortalezacamp11: String;
  fortalezacamp12: String;
  checks9SiDescripcion: String;
 


  //BAncario
  bancarioUno: String;
  ValorCalificacion: Number = 0;
  //Tarea judicial
  procesoJudicialInforme: string;
  listadoProceso2: string;
  informacionRelevante: string;
  textoSuper:string;

  //editor 
  renta: Text;
  salidaDivisas: Text;




  //------------------------------------------------------------------------------------
  /*----------  Campos Informacion Financiera  ----------------*/
  // anos financieros

  //Activos Corriente Parte 1
  cajaCorrienteYear1: number = 0;
  cajaCorrienteYear2Porcentaje: number = 0;
  inversionCorrienteYear1: number = 0;
  localesRelacionadosCorrienteYear1//: number = 0;
  localesNoRelacionadosCorrienteYear1//: number = 0;
  cuentasRelacionadosCorrienteYear1: number = 0;
  cuentasNoRelacionadosCorrienteYear1: number = 0;
  provisionCorrienteYear1: number = 0;
  creditosCorrienteYear1: number = 0;
  inventariosCorrienteYear1: number = 0;
  pagadosCorrienteYear1: number = 0;
  otrosCorrienteYear1: number = 0;
  /*Suma Total de Activo Corriente Year 1*/
  year: number = 0;

  //porcentaje Activo Corriente Year 1 
  porcentajeCorrienteYear1: Array<number> = [];
  //porcentaje Total Activo Corriente Year 1
  porcentajeActivoTotal1: number = 0;

  //Activos Corriente Parte 2
  cajaCorrienteYear2: number = 0;
  inversionCorrienteYear2: number = 0;
  localesRelacionadosCorrienteYear2//: number = 0;
  localesNoRelacionadosCorrienteYear2//: number = 0;
  cuentasRelacionadosCorrienteYear2: number = 0;
  cuentasNoRelacionadosCorrienteYear2: number = 0;
  provisionCorrienteYear2: number = 0;
  creditosCorrienteYear2: number = 0;
  inventariosCorrienteYear2: number = 0;
  pagadosCorrienteYear2: number = 0;
  otrosCorrienteYear2: number = 0;
  /*Suma Total de Activo Corriente Year 2*/
  year1: number = 0;

  //porcentaje Activo Corriente Year 2 
  porcentajeCorrienteYear2: Array<number> = [];
  //porcentaje Total Activo Corriente Year 2
  porcentajeActivoTotal2: number = 0;


  //Activos Diferido Parte 1
  totalActivoFijo1: number = 0;
  totalActivoDiferido1: number = 0;
  inversionNoCorriente1: number = 0;
  otrasInversiones1: number = 0;
  cuentasNoCorrientes1: number = 0;
  cuentasNoCorrientesNoRelacionadas1: number = 0;
  otrosNoCorriente1: number = 0;
  otrosCorrienteNoRelacionados1: number = 0;
  provisionCuentas1: number = 0;
  otrosActivosYear1: number = 0;
  /*Suma Total de Activo Diferido Year 1*/
  activo1: number = 0;

  //porcentaje Activo Diferido Year 1 
  porcentajeDiferidoYear1: Array<number> = [];
  //porcentaje Total Activo Diferido Year 1
  porcentajeDiferidoTotal1: number;

  //Activos Diferido Parte 2
  totalActivoFijo2: number = 0;
  totalActivoDiferido2: number = 0;
  inversionNoCorriente2: number = 0;
  otrasInversiones2: number = 0;
  cuentasNoCorrientes2: number = 0;
  cuentasNoCorrientesNoRelacionadas2: number = 0;
  otrosNoCorriente2: number = 0;
  otrosCorrienteNoRelacionados2: number = 0;
  provisionCuentas2: number = 0;
  otrosActivosYear2: number = 0
  /*Suma Total de Activo Diferido Year 2*/
  activo2: number = 0;

  //porcentaje Activo Diferido Year 2 
  porcentajeDiferidoYear2: Array<number> = [];
  //porcentaje Total Activo Diferido Year 2
  porcentajeDiferidoTotal2: number = 0;

  /*Suma Total Year 1 & Year 2*/
  totalBalanceActivoYear1: number = 0;
  totalBalanceActivoYear2: number = 0;

  //Total Suma Porcentaje
  totalPorcentajeYear1: number = 0;
  totalPorcentajeYear2: number = 0;

  /*---------            Pasivo y Patrimonio ---------------*/

  /*campos Pasivos*/
  activoCuentasPasivo1//: number;
  pasivoCuentasNoRelacionados1//: number;
  pasvioObligaciones1: number;
  pasivoPrestamosAccionistas1: Number;
  pasivosCuentasCorrrientesRelacionados1: number;
  pasivoCuentasNoRelacionadosPorPagar1: number;
  pasivoImpuestoRentaPorPagar1: number;
  pasivoParticipacionTrabajadores1: number;
  pasivosOtrosPasivosCrrientes1: number;
  porPagarProveedoresRelacionados: number;
  pasivos1: number = 0;
  porcentajeCorrientePasivos1: Array<number> = [];
  porcentajeAnioTotal1: number = 0;

  activoCuentasPasivo2//: number;
  pasivoCuentasNoRelacionados2//: number;
  pasvioObligaciones2: number;
  pasivoPrestamosAccionistas2: number;
  pasivosCuentasCorrrientesRelacionados2: number;
  pasivoCuentasNoRelacionadosPorPagar2: Number;
  pasivoImpuestoRentaPorPagar2: number;
  pasivoParticipacionTrabajadores2: number;
  pasivosOtrosPasivosCrrientes2: number;
  pasivos2: number = 0;
  porcentajeCorrientePasivos2: Array<number> = [];
  porcentajePasivosTotal2: number = 0;
  porcentajeAnioTotal2: number = 0;



  pasivoCuentaPorPagarProveedoresAnio: number;
  pasivoCuentasNoCorrientesNoRelacionalAnio: number;
  pasivoObligacionesInstitucionesAnio: number;
  pasivoPrestamosAccionistasAnio: number;
  pasivoOtrasCuentasPorPagarAnio: number;
  pasivoOtrasCuentasPorPagarNoCorrientesAnio: number;
  pasivosOtroPasivoNoCorrienteAnio: number;
  anio: number = 0;
  porcentajeDiferidoPasivos1: Array<number> = [];
  porcentajeDiferidosTotal1: number = 0;

  pasivoCuentaPorPagarProveedoresAnio1: number;
  pasivoCuentasNoCorrientesNoRelacionalAnio1: number;
  pasivoObligacionesInstitucionesAnio1: number;
  pasivoPrestamosAccionistasAnio1: number;
  pasivoOtrasCuentasPorPagarAnio1: number;
  pasivoOtrasCuentasPorPagarNoCorrientesAnio1: number;
  pasivosOtroPasivoNoCorrienteAnio1: number;
  anio1: number = 0;
  porcentajeDiferidoPasivos2: Array<number> = [];
  porcentajeDiferidosTotal2: number = 0;

  /* Patrimonio Neto */

  //Parte 1
  capitalPatrimonio1: number = 0;
  integralesPatrimonio1: number = 0;
  aportesPatrimonio1: number = 0;
  reservasPatrimonio1: number = 0;
  patrimonioNIIF1: number = 0;
  utilidadPatrimonio1: number = 0;
  acumuladaPatrimonio1: number = 0;
  ejercicioPatrimonio1: number = 0;
  patrimonio1: number = 0;
  porcentajeDiferidoPatrimonio1: Array<number> = [];

  //Parte2
  capitalPatrimonio2: number = 0;
  integralesPatrimonio2: number = 0;
  aportesPatrimonio2: number = 0;
  reservasPatrimonio2: number = 0;
  patrimonioNIIF2: number = 0;
  utilidadPatrimonio2: number = 0;
  acumuladaPatrimonio2: number = 0;
  ejercicioPatrimonio2: number = 0;
  patrimonio2: number = 0;
  porcentajeDiferidoPatrimonio2: Array<number> = [];

  //TOTAL SUMA PORCENTAJES
  totalBalancePasivos1: number = 0;
  totalBalancePasivos2: number = 0;
  totalPorcentajePasivos1: number = 0;
  totalPorcentajePasivos2: number = 0;
  totalPatrimonio: number = 0;
  totalPatrimonio2: number = 0;
  porcentajePatrimonioTotal1: number = 0;
  porcentajePatrimonioTotal2: number = 0;

  //Total Patrimonio y Pasivo
  totalPasivoYPatrimonio: number = 0;
  totalPasivoYPatrimonio2: number = 0;


  /*-----------     ESTADO DE RESULTADO (USD) UNIDADES           -----------*/

  //Utilidad Bruta Year 1
  ventasTotalesYear1//: number = 0;
  costoVenta//: number = 0;
  utilidadBruta: number = 0;

  //Utilidad Operacional Year 1
  gatosAdministrativos//: number = 0;
  utilidadOpercional: number = 0;

  //Utilidad antes de Impuestos Year1
  ingresoNoOperacional//: number = 0;
  gastosNoOperacionales//: number = 0;
  utilidadAntesImpuestos: number = 0;

  //Utilidad Tributable Year1
  participacionTrabajador//: number = 0;
  utilidadTributable: number = 0;

  //Utilidad Repartible Year 1
  impuestoRenta//: number = 0;
  utilidadRepartible//: number = 0;

  //porcentaje Year1
  porcentajeUtilidadesYear1: Array<number> = [];

  //utilidades Parte 2

  //Utilidad Bruta Year 2
  ventasTotalesYear2//: number = 0;
  costoVenta2//: number = 0;
  utilidadBruta2//: number = 0;

  //Utilidad Operacional Year 2
  gatosAdministrativos2: number = 0;
  utilidadOpercional2//: number = 0;

  //Utilidad antes de Impuestos Year2
  ingresoNoOperacional2: number = 0;
  gastosNoOperacionales2: number = 0;
  utilidadAntesImpuestos2//: number = 0;

  //Utilidad Tributable Year2
  participacionTrabajador2: number = 0;
  utilidadTributable2//: number = 0;

  //Utilidad Repartible Year 2
  impuestoRenta2: number = 0;
  utilidadRepartible2//: number = 0;

  //Porcentaje Year2
  porcentajeUtilidadesYear2: Array<number> = [];

  estadoFormulario: string;

  //Expresiones Regulares
  emailPattern = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  //textPattern= new RegExp(/^[a-zA-Z ]+$/);
  textPattern = new RegExp(/^[A-Z]{1}[a-zA-ZñÑáéíóúÁÉÍÓÚ&.\s]+$/);
  //textPatternCompleto = new RegExp(/^[A-Z]{1}[a-zA-ZñÑáéíóúÁÉÍÓÚ&:;0-9.,-/\s]+$/);
  textPatternCompleto = new RegExp(/^[A-Z]{1}[a-zA-ZñÑáéíóúÁÉÍÓÚ&:;0-9.,()-/\s]+$/);
  numberPatternTelefonos = new RegExp(/^[A-Z]{1}[a-zA-Z0-9-/\s]+$/);
  numberPattern = new RegExp(/^[0-9 ]+$/);
  pageWebPattern = new RegExp(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/);
  decimalPattern = new RegExp(/^\d{1,3}(?:,\s?\d{3})*(?:\.\d*)?$/);

  constructor(public fb: FormBuilder,
    private proveedorDetalle: FormProveedoresServiceService,
    private route: ActivatedRoute, private location: Location,
    private opcionesServices: OpcionesGeneralesService, private opcionesServices3: OpcionesGenerales3Service,
    private informeDetalle: InformeFinalService) {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
    this.formularioCalificacion = this.fb.group({
      estadoFormulario: ['',],
      cartaPresentacionRequisito: ['', [Validators.required]],
      ruRequisito: ['', [Validators.required]],
      certificadoSriRequisito: ['', [Validators.required]],
      planillaIessRequisito: ['', [Validators.required]],
      certfSuperintendeciaRequisito: ['', [Validators.required]],
      predialRequisito: ['', [Validators.required]],
      patenteMunicipalRequisito: ['', [Validators.required]],
      copiaFacturaRequisito: ['', [Validators.required]],
      certMarcaEspecialRequisito: ['', [Validators.required]],
      certSistemRequisito: ['', [Validators.required]],
      certBancPagoRequisito: ['', [Validators.required]],
      certComerClienRequisito: ['', [Validators.required]],
      certComerProveedorRequisito: ['', [Validators.required]],
      certRiesgoRequisito: ['', [Validators.required]],
      estadoFinancieroRequisito: ['', [Validators.required]],
      tipoProveedorCalificacion: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPatternCompleto)]],
      razonSocialCalificacion: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPatternCompleto)]],
      descripcion: [''],
      nombreComercialCalificacion: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(80), Validators.pattern(this.textPattern)]],
      rucCalificacion: ['', [Validators.required, Validators.minLength(13), Validators.maxLength(13), Validators.pattern(this.numberPattern)]],
      formaLegalCalificacion: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      capitalSPCalificacion: ['', [Validators.required]],
      estadoCalificacion: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      iniActiCalificacion: ['', [Validators.required]],
      objSocialCalificacion: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(300), Validators.pattern(this.textPatternCompleto)]],
      actPrinCalificacion: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(300), Validators.pattern(this.textPatternCompleto)]],
      ubicacionCalificacion: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      direccionCalificacion: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(300), Validators.pattern(this.textPatternCompleto)]],
      telfCallificacion: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(100)]],
      celCalificacion: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(100)]],
      pagWebCalificacion: ['', [Validators.required, Validators.pattern(this.pageWebPattern)]],
      emailCalificacion: ['', [Validators.required, , Validators.pattern(this.emailPattern)]],
      representanteLegalCalificacion: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(this.textPattern)]],
      representLegalCedCalificacion: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(this.numberPattern)]],
      personContactlCalificacion: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      personCargolCalificacion: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      personEmaillCalificacion: ['', [Validators.required, , Validators.pattern(this.emailPattern)]],
      empresaRelacionlCalificacion: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      grupoEcolCalificacion: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      codActivCalificacion: ['', [Validators.required,]],
      impuestoRentaCalificacion: ['', [Validators.required, Validators.pattern(this.decimalPattern)]],
      impuestoRentaCalificacion1: ['', [Validators.required, Validators.pattern(this.decimalPattern)]],
      impuestoDivisasCalificacion: ['', [Validators.required, Validators.pattern(this.decimalPattern)]],
      impuestoDivisasCalificacion1: ['', [Validators.required, Validators.pattern(this.decimalPattern)]],

      //nuevos
      actividadFinancieraPuntaje: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      actividadFinancieraEquivalencia: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      capacidadOperativaPuntaje: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      capacidadOperativaEquivalencia: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      gestionCalidadPuntaje: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      gestionCalidadEquivalencia: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      comercialPuntaje: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      comercialEquivalencia: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      cumplimientoRegularesPuntaje: ['', [Validators.required]],
      cumplimientoRegularesEquivalencia: ['', [Validators.required]],



      checksCalidadProductoR1: ['', [Validators.required]],
      checksCalidadProductoDos: ['', [Validators.required]],
      checksObligacionesTributarias: ['', [Validators.required]],
      checksObligacionesPatronales: ['', [Validators.required]],
      checksPagoImpuestoPredial: ['', [Validators.required]],
      checksObligacionSuper: ['', Validators.required],
      checksTipoSeguros: [''],
      checksPatenteMunicipalActualizado: ['', [Validators.required]],
      checkspuntualidadEntregaUno: ['', [Validators.required]],
      checkspuntualidadEntregaDos: ['', [Validators.required]],

      //editor
      renta: [''],
      salidaDivisas: [''],
      //anos financieros
      totalUnidadesUno: [''],
      totalUnidadesDos: [''],
      totalCalificacion:[''],
      directivosNombreUno: [''],
      directivosCargoUno: [''],
      directivosNombreDos: [''],
      directivosCargoDos: [''],
      directivosNombreTres: [''],
      directivosCargoTres: [''],
      directivosNombreCuatro: [''],
      directivosCargoCuatro: [''],
      directivosNombreCinco: [''],
      directivosCargoCinco: [''],
      participacionAccionaria: [''],
      participacionAccionariaPorcentaje: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(2), Validators.pattern(this.numberPattern)]],
      participacionAccionariaDos: [''],
      participacionAccionariaPorcentajeDos: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(2), Validators.pattern(this.numberPattern)]],
      nombreReferenciaUno: [''],
      nombreReferenciaDos: [''],
      direccionReferenciaUno: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(300), Validators.pattern(this.textPatternCompleto)]],
      direccionReferenciaDos: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(300), Validators.pattern(this.textPatternCompleto)]],
      telefonoReferenciaUno: [''],
      telefonoReferenciaDos: [''],

      proveedroDesdeReferenciaUno: ['', [Validators.required,]],
      proveedroDesdeReferenciaDos: ['', [Validators.required,]],
      productoServicioReferenciaUno: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(300), Validators.pattern(this.textPattern)]],
      productoServicioReferenciaDos: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(300), Validators.pattern(this.textPattern)]],
      //uno y dos borrar

      refeUnoContacto: [''],
      refeDosContacto: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      refeUnoContact: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      //puesto
      checksInfraestructura: ['', [Validators.required]],
      checksSucursales: ['', [Validators.required]],
      checksContratacion: ['', [Validators.required]],
      checksMaquinaria: ['', [Validators.required]],
      checksMaquinariaPlan: ['', [Validators.required]],
      checksMedioDistribucion: ['', [Validators.required]],
      checksMedicionCapacidad: ['', [Validators.required]],
      checksControlInventario: ['', [Validators.required]],


      //chcecks de seguros
      checks2: ['', [Validators.required]],
      checks2Uno: ['', [Validators.required]],
      checks2Dos: ['', [Validators.required]],
      checks2Tres: ['', [Validators.required]],
      checks2Cuatro: ['', [Validators.required]],
      checks2Descripcion: ['', [Validators.required]],

      checks12: ['', [Validators.required]],
      checks13: ['', [Validators.required]],
      checks14: ['', [Validators.required]],
      checks15: ['', [Validators.required]],
      checks16: ['', [Validators.required]],
      checks17: ['', [Validators.required]],
      checks18: ['', [Validators.required]],
      checks19: ['', [Validators.required]],
      checks20: ['', [Validators.required]],
      checks21: ['', [Validators.required]],
      checks22: ['', [Validators.required]],
      checks23: ['', [Validators.required]],
      checks24: ['', [Validators.required]],
      checks25: ['', [Validators.required]],
      checks26: ['', [Validators.required]],
      checks27: ['', [Validators.required]],
      checks28: ['', [Validators.required]],
      checks29: ['', [Validators.required]],
      participacionAccionariaTres: [''],
      participacionAccionariaPorcentajeTres: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(2), Validators.pattern(this.numberPattern)]],
      participacionAccionariaCuarto: [''],
      participacionAccionariaPorcentajeCuatro: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(2), Validators.pattern(this.numberPattern)]],
      participacionAccionariaCinco: [''],
      participacionAccionariaPorcentajeCinco: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(2), Validators.pattern(this.numberPattern)]],
      DescripcionInfraestructura: [''],
      checks4: [''],
      DescripcionSucursales: [''],
      checks5: [''],
      checks13SiDescripcion: [''],
      check12SiDescripcion: [''],
      checks15SiDescripcion: [''],
      checks16SiDescripcion: [''],
      checks17SiDescripcionEmpr: [''],
      checks18SiDescripcion: [''],
      checks14SiDescripcion: [''],
      checks19DescripcionSi: [''],
      checks20Uno: [''],
      checks20Dos: [''],
      checks20Tres: [''],
      checks20Cuatro: [''],
      checks20Cinco: [''],
      checks20Seis: [''],
      checks20OtrosDescripcion: [''],
      Chck4DescripRentada: [''],
      check5Cuantas: [''],
      controlNumero: ['', [Validators.required, Validators.pattern(this.numberPattern)]],
      controlNumoeroPesonal: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(100), Validators.pattern(this.numberPattern)]],
      checks6: [''],
      checks7: [''],
      checks8: [''],
      checks9: [''],
      checks10: [''],
      checks10SiDescripcion: [''],
      checks11: [''],
      check11SiDescripcion: [''],
      checks8DescriMantenimiento: [''],
      nombreReferenciaComercial: [''],
      nombreReferenciaComercial2: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      numeroReferenciaComercial: [''],
      checks27U: [''],
      checks27SiDescripcion: [''],

      numeroReferenciaComercial2: [''],
      check21SiPorcentaje: [''],
      checks22SiPaises: [''],
      checks23SIDescripcion: [''],
      checks24SiDescripcion: [''],
      check25SiDescripcion: [''],
      checks26SiDescripcion: [''],
      checks28SiDescripcion: [''],
      checks29SiDescripcion: [''],
      numeroReferenciaComercialDos: [''],
      checks9SiDescripcion: [''],

      refeUnoContactoUno: [''],
      ContactoUno: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(this.textPattern)]],
      contctDos: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(this.textPattern)]],

      bancoUno: ['', [Validators.required]],
      clienteDesdeUno: ['', [Validators.required]],
      protest: ['', [Validators.required]],
      comentario: ['', [Validators.required]],
      banc1: ['', [Validators.required]],
      clienteDesdeD1: ['', [Validators.required]],
      salProme: ['', [Validators.required]],
      salProme1: ['', [Validators.required]],
      protesto2: ['', [Validators.required]],
      coment2: ['', [Validators.required]],
      totalPuntaje: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      totalEquivalencia: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      descripcionCalificacion: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      institucion: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      fechasCorte: ['', [Validators.required]],
      tipoRegistro: ['', [Validators.required]],
      tipoCredito: ['', [Validators.required]],
      totalValancer: ['', [Validators.required]],
      dem:['', [Validators.required]],
      cart: ['', [Validators.required]],
      saldoDeuda:['', [Validators.required]],
      totalVencido: ['', [Validators.required]],
      fechasCorte2: ['', [Validators.required]],
      institucion2: ['', [Validators.required]],
      tipoRegistro2: ['', [Validators.required]],
      tipoCredito2: ['', [Validators.required]],
      totalValancer2: ['', [Validators.required]],
      totalVencido2: ['', [Validators.required]],
      dem2: ['', [Validators.required]],
      cart2: ['', [Validators.required]],
      fechainicio : [''],
      fechafin: [''],
      saldoDeuda2:['', [Validators.required]],
      nombreProveedor: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      nombreReferenciaProveedor: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      nombreReferenciaProveedor2: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      direccionProveedor1: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(300), Validators.pattern(this.textPatternCompleto)]],
      direccionProveedor2: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPatternCompleto)]],
      numeroProveedor1: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(10), Validators.pattern(this.numberPattern)]],
      numeroProveedorUno: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(10), Validators.pattern(this.textPattern)]],
      numeroRefeProveedor2: [''],
      ContactoUProveedor1: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      contcProveedor2: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      lineaCredito: ['', [Validators.required]],
      lineaCreditoDias: ['', [Validators.required]],
      lineaCreditoDos: ['', [Validators.required]],
      lineaCreditoDosDias: ['', [Validators.required]],
      productoServicioProveedorUno: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(300), Validators.pattern(this.textPattern)]],
      productoServicioProveedorDos: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(300), Validators.pattern(this.textPattern)]],
      clientedesdeProveedorUno: ['', [Validators.required,]],
      clientedesdeProveedorDos: ['', [Validators.required,]],
      compraMensualProveedorUno: ['', [Validators.required]],
      compraMensualProveedorDos: ['', [Validators.required]],
      checkspuntualidadEntregaProveedor1: ['', [Validators.required]],
      checkspuntualidadEntregaProveedor2: ['', [Validators.required]],
      numeroRefeProveedorUno: [''],
      listadoProceso: [''],
      nombreDirectivo2: ['',],
      fortalezacamp1: ['',],
      fortalezacamp2: ['',],
      fortalezacamp3: ['',],
      fortalezacamp4: ['',],
      fortalezacamp5: ['',],
      fortalezacamp6: ['',],
      fortalezacamp7: ['',],
      fortalezacamp8: ['',],
      fortalezacamp9: ['',],
      fortalezacamp10: ['',],
      fortalezacamp11: ['',],
      fortalezacamp12: ['',],
      ventas16:['',],
      ventas17:['',],
      activos16:['',],
      activos17:['',],
      pasivos16:['',],
      pasivos17:['',],
      patrimonio16: ['',],
      patrimonio17: ['',],
      utilidad16: ['',],
      utilidad17: ['',],


      //BAncaria
      bancarioUno: ['', [Validators.required]],
      ValorCalificacion: ['', [Validators.required]],
      ventas16Calificacion: ['', [Validators.required]],
      ventas17calificacion: ['', [Validators.required]],
      activos16calificacion: ['', [Validators.required]],
      activos17Calificacion: ['', [Validators.required]],
      pasivos16Calificacion: ['', [Validators.required]],
      pasivos17Calificacion: ['', [Validators.required]],
      patrimonio16Calificaion: ['', [Validators.required]],
      patrimonio17Calificacion: ['', [Validators.required]],
      utilidad16Calificacion: ['', [Validators.required]],
      utilidad17Calificacion: ['', [Validators.required]],
// Tabla unidades





      /*----------     Formulario Informe Finaciero Activo         ----------*/

      cajaCorrienteYear1: ['', /*[Validators.pattern(this. )]*/],
      cajaCorrienteYear1Porcentaje: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      inversionCorrienteYear1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      localesRelacionadosCorrienteYear1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      localesNoRelacionadosCorrienteYear1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      cuentasRelacionadosCorrienteYear1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      cuentasNoRelacionadosCorrienteYear1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      provisionCorrienteYear1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      creditosCorrienteYear1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      inventariosCorrienteYear1: ['0', /*[Validators.pattern(this.decimalPattern)]*/],
      pagadosCorrienteYear1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      otrosCorrienteYear1: ['', /*[Validators.pattern(this.decimalPattern)]*/],

      cajaCorrienteYear2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      inversionCorrienteYear2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      localesRelacionadosCorrienteYear2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      localesNoRelacionadosCorrienteYear2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      cuentasRelacionadosCorrienteYear2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      cuentasNoRelacionadosCorrienteYear2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      provisionCorrienteYear2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      creditosCorrienteYear2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      inventariosCorrienteYear2: ['0', /*[Validators.pattern(this.decimalPattern)]*/],
      pagadosCorrienteYear2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      otrosCorrienteYear2: ['', /*[Validators.pattern(this.decimalPattern)]*/],

      //Activo parte 2
      totalActivoFijo1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      totalActivoFijo2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      totalActivoDiferido1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      totalActivoDiferido2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      inversionNoCorriente1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      inversionNoCorriente2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      otrasInversiones1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      otrasInversiones2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      cuentasNoCorrientes1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      cuentasNoCorrientes2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      cuentasNoCorrientesNoRelacionadas1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      cuentasNoCorrientesNoRelacionadas2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      otrosNoCorriente1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      otrosNoCorriente2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      otrosCorrienteNoRelacionados1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      otrosCorrienteNoRelacionados2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      provisionCuentas1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      provisionCuentas2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      otrosActivosYear1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      otrosActivosYear2: ['', /*[Validators.pattern(this.decimalPattern)]*/],

      /*-----------             Pasivo y Patrimonio      ----------------------- */

      /*suma de pasivos*/
      activoCuentasPasivo1: ['', /*/*[Validators.pattern(this.decimalPattern)]*/],
      pasivoCuentasNoRelacionados1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      pasvioObligaciones1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      pasivoPrestamosAccionistas1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      pasivosCuentasCorrrientesRelacionados1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      pasivoCuentasNoRelacionadosPorPagar1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      pasivoImpuestoRentaPorPagar1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      pasivoParticipacionTrabajadores1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      pasivosOtrosPasivosCrrientes1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      porPagarProveedoresRelacionados: ['', /*[Validators.pattern(this.decimalPattern)]*/],

      activoCuentasPasivo2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      pasivoCuentasNoRelacionados2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      pasvioObligaciones2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      pasivoPrestamosAccionistas2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      pasivosCuentasCorrrientesRelacionados2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      pasivoCuentasNoRelacionadosPorPagar2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      pasivoImpuestoRentaPorPagar2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      pasivoParticipacionTrabajadores2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      pasivosOtrosPasivosCrrientes2: ['', /*[Validators.pattern(this.decimalPattern)]*/],

      pasivoCuentaPorPagarProveedoresAnio: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      pasivoCuentasNoCorrientesNoRelacionalAnio: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      pasivoObligacionesInstitucionesAnio: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      pasivoPrestamosAccionistasAnio: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      pasivoOtrasCuentasPorPagarAnio: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      pasivoOtrasCuentasPorPagarNoCorrientesAnio: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      pasivosOtroPasivoNoCorrienteAnio: ['', /*[Validators.pattern(this.decimalPattern)]*/],


      pasivoCuentaPorPagarProveedoresAnio1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      pasivoCuentasNoCorrientesNoRelacionalAnio1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      pasivoObligacionesInstitucionesAnio1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      pasivoPrestamosAccionistasAnio1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      pasivoOtrasCuentasPorPagarAnio1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      pasivoOtrasCuentasPorPagarNoCorrientesAnio1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      pasivosOtroPasivoNoCorrienteAnio1: ['', /*[Validators.pattern(this.decimalPattern)]*/],

      /* -----    Patrimonio -----*/
      //Parte 1
      capitalPatrimonio1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      integralesPatrimonio1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      aportesPatrimonio1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      reservasPatrimonio1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      patrimonioNIIF1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      utilidadPatrimonio1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      acumuladaPatrimonio1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      ejercicioPatrimonio1: ['', /*[Validators.pattern(this.decimalPattern)]*/],

      //Parte2
      capitalPatrimonio2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      integralesPatrimonio2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      aportesPatrimonio2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      reservasPatrimonio2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      patrimonioNIIF2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      utilidadPatrimonio2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      acumuladaPatrimonio2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      ejercicioPatrimonio2: ['', /*[Validators.pattern(this.decimalPattern)]*/],

      /*-----------     ESTADO DE RESULTADO (USD) UNIDADES           -----------*/

      ventasTotalesYear1: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      costoVenta: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      gatosAdministrativos: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      ingresoNoOperacional: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      gastosNoOperacionales: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      participacionTrabajador: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      impuestoRenta: ['', /*[Validators.pattern(this.decimalPattern)]*/],

      //utilidades Parte 2

      ventasTotalesYear2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      costoVenta2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      gatosAdministrativos2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      ingresoNoOperacional2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      gastosNoOperacionales2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      participacionTrabajador2: ['', /*[Validators.pattern(this.decimalPattern)]*/],
      impuestoRenta2: ['', /*[Validators.pattern(this.decimalPattern)]*/],

      //Tarea judicial
      procesoJudicialInforme: [''],
      listadoProceso2: [''],
      informacionRelevante: [''],
      textoSuper:[''],
      //lista negra
      checksListaClinton: [''],

    });
  }

  //INICIO DE TABLAS
  /*----------  Campos Informacion Financiera  ----------------*/

  /*Suma de Array Activos*/
  capturaDatosYear() {
    var arrayInput = new Array();
    var total = 0;
    var porcentaje = 0;
    var inputsValues = document.getElementsByClassName('sumaInput1'),
      nameValues = [].map.call(inputsValues, function (sumaInput1) {
        arrayInput.push(parseFloat(sumaInput1.value.replace(/,/g,"")));
      });
    arrayInput.forEach(function (inputsValuesData) {
      if (inputsValuesData != '') {
        total = total + parseFloat(inputsValuesData);
      } else {
        inputsValuesData = 0;
        total = total + parseFloat(inputsValuesData);
      }
    });
    //porcentaje
    this.porcentajeCorrienteYear1 = [];
    for (var i = 0; i <= arrayInput.length - 1; i++) {
      if (arrayInput[i] != '') {
        porcentaje = arrayInput[i] * 100 / total;
        if (porcentaje == Infinity || porcentaje == -Infinity) {
          document.getElementById('inputTablaComp' + (i + 1)).innerHTML = 'Error';
        } else {
          document.getElementById('inputTablaComp' + (i + 1)).innerHTML = Math.round(porcentaje).toString();
        }
        this.porcentajeCorrienteYear1.push(porcentaje)
      }
    }
    console.log("asdsadassadadsad" + this.porcentajeCorrienteYear1);
    return this.year = total, this.porcentajeCorrienteYear1;
  }

  capturaDatosYear1() {
    var arrayInput = new Array();
    var total = 0;
    var porcentaje = 0;
    var inputsValues = document.getElementsByClassName('sumaInput2'),
      nameValues = [].map.call(inputsValues, function (sumaInput1) {
        arrayInput.push(parseFloat(sumaInput1.value.replace(/,/g,"")));
      });
    arrayInput.forEach(function (inputsValuesData) {
      if (inputsValuesData != '') {
        total = total + parseFloat(inputsValuesData);
      } else {
        inputsValuesData = 0;
        total = total + parseFloat(inputsValuesData);
      }
    });

    //porcentaje
    this.porcentajeCorrienteYear2 = [];
    for (var i = 0; i <= arrayInput.length - 1; i++) {
      if (arrayInput[i] != '') {
        porcentaje = arrayInput[i] * 100 / total;
        if (porcentaje == Infinity || porcentaje == -Infinity) {
          document.getElementById('inputTablaComp0' + (i + 1)).innerHTML = 'Error';
        } else {
          document.getElementById('inputTablaComp0' + (i + 1)).innerHTML = Math.round(porcentaje).toString();
        }
        this.porcentajeCorrienteYear2.push(porcentaje)
      }
    }

    return this.year1 = total, this.porcentajeCorrienteYear2;
  }

  //Inputs Activos Parte 2

  capturaDatosActivos1() {
    var arrayInput = new Array();
    var total = 0;
    var porcentaje = 0;
    var inputsValues = document.getElementsByClassName('sumaInput3'),
      nameValues = [].map.call(inputsValues, function (sumaInput1) {
        arrayInput.push(parseFloat(sumaInput1.value.replace(/,/g,"")));
      });
    arrayInput.forEach(function (inputsValuesData) {
      if (inputsValuesData != '') {
        total = total + parseFloat(inputsValuesData);
      } else {
        inputsValuesData = 0;
        total = total + parseFloat(inputsValuesData);
      }
    });

    //porcentaje
    this.porcentajeDiferidoYear1 = [];
    for (var i = 0; i <= arrayInput.length - 1; i++) {
      if (arrayInput[i] != '') {
        porcentaje = arrayInput[i] * 100 / total;
        if (porcentaje == Infinity || porcentaje == -Infinity) {
          document.getElementById('inputTablaDiferido' + (i + 1)).innerHTML = 'Error';
        } else {
          document.getElementById('inputTablaDiferido' + (i + 1)).innerHTML = Math.round(porcentaje).toString();
        }
        this.porcentajeDiferidoYear1.push(porcentaje)
      }
    }

    return this.activo1 = total, this.porcentajeDiferidoYear1;
  }
  capturaDatosActivos2() {
    var arrayInput = new Array();
    var total = 0;
    var porcentaje = 0;
    var inputsValues = document.getElementsByClassName('sumaInput4'),
      nameValues = [].map.call(inputsValues, function (sumaInput1) {
        arrayInput.push(parseFloat(sumaInput1.value.replace(/,/g,"")));
      });
    arrayInput.forEach(function (inputsValuesData) {
      if (inputsValuesData != '') {
        total = total + parseFloat(inputsValuesData);
      } else {
        inputsValuesData = 0;
        total = total + parseFloat(inputsValuesData);
      }
    });
    //porcentaje
    this.porcentajeDiferidoYear2 = [];
    for (var i = 0; i <= arrayInput.length - 1; i++) {
      if (arrayInput[i] != '') {
        porcentaje = arrayInput[i] * 100 / total;
        if (porcentaje == Infinity || porcentaje == -Infinity) {
          document.getElementById('inputTablaDiferido0' + (i + 1)).innerHTML = 'Error';
        } else {
          document.getElementById('inputTablaDiferido0' + (i + 1)).innerHTML = Math.round(porcentaje).toString();
        }
        this.porcentajeDiferidoYear2.push(porcentaje)
      }
    }
    return this.activo2 = total, this.porcentajeDiferidoYear2;
  }

  //Total Suma

  totalSuma() {
    this.totalBalanceActivoYear1 = 0; this.totalPorcentajeYear1 = 0;
    this.totalPorcentajeYear2 = 0; this.totalBalanceActivoYear2 = 0;
    this.porcentajeDiferidoTotal1 = 0; this.porcentajeDiferidoTotal2 = 0;
    this.porcentajeActivoTotal1 = 0; this.porcentajeActivoTotal2 = 0;

    //Suma
    this.totalBalanceActivoYear1 = this.year + this.activo1;
    this.totalBalanceActivoYear2 = this.year1 + this.activo2;

    //porcentaje Total Activo Corriente
    this.porcentajeActivoTotal1 = this.year * 100 / this.totalBalanceActivoYear1;
    document.getElementById('totalCorriente1').innerHTML = Math.round(this.porcentajeActivoTotal1).toString();

    this.porcentajeActivoTotal2 = this.year1 * 100 / this.totalBalanceActivoYear2;
    document.getElementById('totalCorriente2').innerHTML = Math.round(this.porcentajeActivoTotal2).toString();

    //porcentaje Total Diferido 
    this.porcentajeDiferidoTotal1 = this.activo1 * 100 / this.totalBalanceActivoYear1;
    document.getElementById('totalDiferido1').innerHTML = Math.round(this.porcentajeDiferidoTotal1).toString();

    this.porcentajeDiferidoTotal2 = this.activo2 * 100 / this.totalBalanceActivoYear2;
    document.getElementById('totalDiferido2').innerHTML = Math.round(this.porcentajeDiferidoTotal2).toString();

    //Total Suma Porcentaje
    this.totalPorcentajeYear1 = this.porcentajeActivoTotal1 + this.porcentajeDiferidoTotal1;
    document.getElementById('totalYear1').innerHTML = Math.round(this.totalPorcentajeYear1).toString();

    this.totalPorcentajeYear2 = this.porcentajeActivoTotal2 + this.porcentajeDiferidoTotal2;
    document.getElementById('totalYear2').innerHTML = Math.round(this.totalPorcentajeYear2).toString();

    if (this.totalBalanceActivoYear1 != 0) {
      return this.totalBalanceActivoYear1, this.totalPorcentajeYear1, this.totalPorcentajeYear2,
        this.porcentajeDiferidoTotal1, this.porcentajeDiferidoTotal2, this.porcentajeActivoTotal1,
        this.porcentajeActivoTotal2
    } else if (this.totalBalanceActivoYear2 != 0) {
      return this.totalBalanceActivoYear2, this.totalPorcentajeYear1, this.totalPorcentajeYear2,
        this.porcentajeDiferidoTotal1, this.porcentajeDiferidoTotal2, this.porcentajeActivoTotal1,
        this.porcentajeActivoTotal2
    }
  }

  /*------------    Pasivo y Patrimonio          ------------------*/

  /*pasivo*/
  capturaDatosPasivos1() {
    var arrayInput = new Array();
    var total = 0;
    var porcentaje = 0;
    var inputsValues = document.getElementsByClassName('sumaInput5'),
      nameValues = [].map.call(inputsValues, function (sumaInput1) {
        arrayInput.push(parseFloat(sumaInput1.value.replace(/,/g,"")));
      });
    arrayInput.forEach(function (inputsValuesData) {
      if (inputsValuesData != '') {
        total = total + parseFloat(inputsValuesData);
      } else {
        inputsValuesData = 0;
        total = total + parseFloat(inputsValuesData);
      }
    });
    //porcentaje
    this.porcentajeCorrientePasivos1 = [];
    for (var i = 0; i <= arrayInput.length - 1; i++) {
      if (arrayInput[i] != '') {
        porcentaje = arrayInput[i] * 100 / total;
        if (porcentaje == Infinity || porcentaje == -Infinity) {
          document.getElementById('inputTablaPasivo' + (i + 1)).innerHTML = 'Error';
        } else {
          document.getElementById('inputTablaPasivo' + (i + 1)).innerHTML = Math.round(porcentaje).toString();
        }
        this.porcentajeCorrientePasivos1.push(porcentaje)
      }
    }
    console.log("wwwwwww" + this.porcentajeCorrientePasivos1)
    return this.pasivos1 = total, this.porcentajeCorrientePasivos1;


  }

  capturaDatosPasivos2() {
    var arrayInput = new Array();
    var total = 0;
    var porcentaje = 0;
    var inputsValues = document.getElementsByClassName('sumaInput6'),
      nameValues = [].map.call(inputsValues, function (sumaInput1) {
        arrayInput.push(parseFloat(sumaInput1.value.replace(/,/g,"")));
      });
    arrayInput.forEach(function (inputsValuesData) {
      if (inputsValuesData != '') {
        total = total + parseFloat(inputsValuesData);
      } else {
        inputsValuesData = 0;
        total = total + parseFloat(inputsValuesData);
      }
    });

    //porcentaje
    this.porcentajeCorrientePasivos2 = [];
    for (var i = 0; i <= arrayInput.length - 1; i++) {
      if (arrayInput[i] != '') {
        porcentaje = arrayInput[i] * 100 / total;
        if (porcentaje == Infinity || porcentaje == -Infinity) {
          document.getElementById('inputTablaPasivo0' + (i + 1)).innerHTML = 'Error';
        } else {
          document.getElementById('inputTablaPasivo0' + (i + 1)).innerHTML = Math.round(porcentaje).toString();
        }
        this.porcentajeCorrientePasivos2.push(porcentaje)
      }
    }

    return this.pasivos2 = total, this.porcentajeCorrientePasivos2;
  }

  capturaDatosAnio() {
    var arrayInput = new Array();
    var total = 0;
    var porcentaje = 0;
    var inputsValues = document.getElementsByClassName('sumaInput7'),
      nameValues = [].map.call(inputsValues, function (sumaInput1) {
        arrayInput.push(parseFloat(sumaInput1.value.replace(/,/g,"")));
      });
    arrayInput.forEach(function (inputsValuesData) {
      if (inputsValuesData != '') {
        total = total + parseFloat(inputsValuesData);
      } else {
        inputsValuesData = 0;
        total = total + parseFloat(inputsValuesData);
      }
    });

    //porcentaje
    this.porcentajeDiferidoPasivos1 = [];
    for (var i = 0; i <= arrayInput.length - 1; i++) {
      if (arrayInput[i] != '') {
        porcentaje = arrayInput[i] * 100 / total;
        if (porcentaje == Infinity || porcentaje == -Infinity) {
          document.getElementById('inputPasivoPrimera' + (i + 1)).innerHTML = 'Error';
        } else {
          document.getElementById('inputPasivoPrimera' + (i + 1)).innerHTML = Math.round(porcentaje).toString();
        }
        this.porcentajeDiferidoPasivos1.push(porcentaje)
      }
    }

    return this.anio = total, this.porcentajeDiferidoPasivos1;
  }
  capturaDatosAnio1() {
    var arrayInput = new Array();
    var total = 0;
    var porcentaje = 0;
    var inputsValues = document.getElementsByClassName('sumaInput8'),
      nameValues = [].map.call(inputsValues, function (sumaInput1) {
        arrayInput.push(parseFloat(sumaInput1.value.replace(/,/g,"")));
      });
    arrayInput.forEach(function (inputsValuesData) {
      if (inputsValuesData != '') {
        total = total + parseFloat(inputsValuesData);
      } else {
        inputsValuesData = 0;
        total = total + parseFloat(inputsValuesData);
      }
    });
    //porcentaje
    this.porcentajeDiferidoPasivos2 = [];
    for (var i = 0; i <= arrayInput.length - 1; i++) {
      if (arrayInput[i] != '') {
        porcentaje = arrayInput[i] * 100 / total;
        if (porcentaje == Infinity || porcentaje == -Infinity) {
          document.getElementById('inputPasivoPrimera0' + (i + 1)).innerHTML = 'Error';
        } else {
          document.getElementById('inputPasivoPrimera0' + (i + 1)).innerHTML = Math.round(porcentaje).toString();
        }
        this.porcentajeDiferidoPasivos2.push(porcentaje)
      }
    }
    return this.anio1 = total, this.porcentajeDiferidoPasivos2;
  }

  /*------- Patrimonio Parte 2 --------*/

  calcularPatrimonioNeto1() {
    var arrayInput = new Array();
    var total = 0;
    var porcentaje = 0;
    var inputsValues = document.getElementsByClassName('sumaInput9'),
      nameValues = [].map.call(inputsValues, function (sumaInput1) {
        arrayInput.push(parseFloat(sumaInput1.value.replace(/,/g,"")));
      });
    arrayInput.forEach(function (inputsValuesData) {
      if (inputsValuesData != '') {
        total = total + parseFloat(inputsValuesData);
      } else {
        inputsValuesData = 0;
        total = total + parseFloat(inputsValuesData);
      }
    });
    //porcentaje
    this.porcentajeDiferidoPatrimonio1 = [];
    for (var i = 0; i <= arrayInput.length - 1; i++) {
      if (arrayInput[i] != '') {
        porcentaje = arrayInput[i] * 100 / total;
        if (porcentaje == Infinity || porcentaje == -Infinity) {
          document.getElementById('inputPatrimonioPrimera' + (i + 1)).innerHTML = 'Error';
        } else {
          document.getElementById('inputPatrimonioPrimera' + (i + 1)).innerHTML = Math.round(porcentaje).toString();
        }
        this.porcentajeDiferidoPatrimonio1.push(porcentaje)
      }
    }
    return this.patrimonio1 = total, this.porcentajeDiferidoPatrimonio1;
  }

  calcularPatrimonioNeto2() {
    var arrayInput = new Array();
    var total = 0;
    var porcentaje = 0;
    var inputsValues = document.getElementsByClassName('sumaInput10'),
      nameValues = [].map.call(inputsValues, function (sumaInput1) {
        arrayInput.push(parseFloat(sumaInput1.value.replace(/,/g,"")));
      });
    arrayInput.forEach(function (inputsValuesData) {
      if (inputsValuesData != '') {
        total = total + parseFloat(inputsValuesData);
      } else {
        inputsValuesData = 0;
        total = total + parseFloat(inputsValuesData);
      }
    });
    //porcentaje
    this.porcentajeDiferidoPatrimonio2 = [];
    for (var i = 0; i <= arrayInput.length - 1; i++) {
      if (arrayInput[i] != '') {
        porcentaje = arrayInput[i] * 100 / total;
        if (porcentaje == Infinity || porcentaje == -Infinity) {
          document.getElementById('inputPatrimonioPrimera0' + (i + 1)).innerHTML = 'Error';
        } else {
          document.getElementById('inputPatrimonioPrimera0' + (i + 1)).innerHTML = Math.round(porcentaje).toString();
        }
        this.porcentajeDiferidoPatrimonio2.push(arrayInput[i])
      }
    }
    return this.patrimonio2 = total, this.porcentajeDiferidoPatrimonio2;
  }

  //Total Suma Pasivo
  totalSumaPasivo() {
    this.totalBalancePasivos1 = 0; this.totalPorcentajePasivos1 = 0;
    this.totalPorcentajePasivos2 = 0; this.totalBalancePasivos2 = 0;
    this.porcentajeDiferidosTotal1 = 0; this.porcentajeDiferidosTotal2 = 0;
    this.porcentajeAnioTotal1 = 0; this.totalPatrimonio = 0;
    this.totalPatrimonio2 = 0; this.porcentajeAnioTotal2 = 0;
    this.porcentajePatrimonioTotal1 = 0; this.porcentajePatrimonioTotal2 = 0;

    //Suma
    this.totalBalancePasivos1 = this.pasivos1 + this.anio;
    this.totalBalancePasivos2 = this.pasivos2 + this.anio1;

    //Patrimonio Total 
    this.totalPatrimonio = this.pasivos1 + this.anio + this.patrimonio1;
    this.totalPatrimonio2 = this.pasivos2 + this.anio1 + this.patrimonio2;

    //porcentaje Total Activo Diferido
    this.porcentajeAnioTotal1 = this.pasivos1 * 100 / this.totalPatrimonio;
    document.getElementById('totalPasivo1').innerHTML = Math.round(this.porcentajeAnioTotal1).toString();

    this.porcentajeAnioTotal2 = this.pasivos2 * 100 / this.totalPatrimonio2;
    document.getElementById('totalPasivo2').innerHTML = Math.round(this.porcentajeAnioTotal2).toString();

    //porcentaje Total Diferido 
    this.porcentajeDiferidosTotal1 = this.anio * 100 / this.totalPatrimonio;
    document.getElementById('totalPasivoPorcentaje1').innerHTML = Math.round(this.porcentajeDiferidosTotal1).toString();

    this.porcentajeDiferidosTotal2 = this.anio1 * 100 / this.totalPatrimonio2;
    document.getElementById('totalPasivoPorcentaje2').innerHTML = Math.round(this.porcentajeDiferidosTotal2).toString();

    //porcentaje Total Patrimonio 
    this.porcentajePatrimonioTotal1 = this.patrimonio1 * 100 / this.totalPatrimonio;
    document.getElementById('totalPatrimonioPorcentaje1').innerHTML = Math.round(this.porcentajePatrimonioTotal1).toString();

    this.porcentajePatrimonioTotal2 = this.patrimonio2 * 100 / this.totalPatrimonio2;
    document.getElementById('totalPatrimonioPorcentaje2').innerHTML = Math.round(this.porcentajePatrimonioTotal2).toString();

    //Total Suma Porcentaje
    this.totalPorcentajePasivos1 = this.porcentajeAnioTotal1 + this.porcentajeDiferidosTotal1;
    document.getElementById('totalPorcentajeDiferido').innerHTML = Math.round(this.totalPorcentajePasivos1).toString();

    this.totalPorcentajePasivos2 = this.porcentajeAnioTotal2 + this.porcentajeDiferidosTotal2;
    document.getElementById('totalPorcentajeDiferido2').innerHTML = Math.round(this.totalPorcentajePasivos2).toString();

    //Suma Total de Pasivo
    this.totalPasivoYPatrimonio = this.porcentajePatrimonioTotal1 + this.totalPorcentajePasivos1;
    document.getElementById('totalPasivoPatrimonio').innerHTML = Math.round(this.totalPasivoYPatrimonio).toString();

    this.totalPasivoYPatrimonio2 = this.porcentajePatrimonioTotal1 + this.totalPorcentajePasivos1;
    document.getElementById('totalPasivoPatrimonio2').innerHTML = Math.round(this.totalPasivoYPatrimonio2).toString();

    if (this.totalBalancePasivos1 != 0) {
      return this.totalBalancePasivos1, this.totalPorcentajePasivos1, this.totalPorcentajePasivos2,
        this.porcentajeDiferidosTotal1, this.porcentajeDiferidosTotal2, this.porcentajeAnioTotal1,
        this.porcentajePatrimonioTotal1, this.porcentajePatrimonioTotal2, this.porcentajeAnioTotal2,
        this.totalPatrimonio, this.totalPatrimonio2
    } else if (this.totalBalancePasivos2 != 0) {
      return this.totalBalancePasivos2, this.totalPorcentajePasivos1, this.totalPorcentajePasivos2,
        this.porcentajeDiferidosTotal1, this.porcentajeDiferidosTotal2, this.porcentajeAnioTotal1,
        this.porcentajePatrimonioTotal1, this.porcentajePatrimonioTotal2, this.porcentajeAnioTotal2,
        this.totalPatrimonio, this.totalPatrimonio2
    }
  }

  /*-----------     ESTADO DE RESULTADO (USD) UNIDADES      -----------*/

  restaUtilidadYear1() {
    //Resta Utilidad Bruta
    // this.utilidadBruta = parseFloat((+this.ventasTotalesYear1 - this.costoVenta).toFixed(2));
    this.utilidadBruta = parseFloat((+Number(this.ventasTotalesYear1.toString().replace(/,/g,"")) - (Number(this.costoVenta.toString().replace(/,/g,"")))).toFixed(2));

    //Utilidad Operacional
    this.utilidadOpercional = parseFloat((this.utilidadBruta - Number(this.gatosAdministrativos.toString().replace(/,/g,""))).toFixed(2));
    //Utilidad antes de Impuestos
    this.utilidadAntesImpuestos = parseFloat((Number(this.utilidadOpercional) + Number(this.ingresoNoOperacional.toString().replace(/,/g,"")) - Number(this.gastosNoOperacionales.toString().replace(/,/g,""))).toFixed(2));
    //Utilidad Tributable
    this.utilidadTributable = parseFloat((+this.utilidadAntesImpuestos - Number(this.participacionTrabajador.toString().replace(/,/g,""))).toFixed(2));
    //Utilidad Repartible 
    this.utilidadRepartible = parseFloat((+this.utilidadTributable - Number(this.impuestoRenta.toString().replace(/,/g,""))).toFixed(2));

    
    return this.utilidadBruta, this.utilidadOpercional, this.utilidadTributable,
      this.utilidadAntesImpuestos, this.utilidadRepartible
  }

  //porcentaje
  metodoporcentajeUtilidades() {
    var arrayInput = new Array();
    var total = 0;
    var porcentaje = 0;
    var unico = 0;
    var inputsValues = document.getElementsByClassName('porcentInputYear1'),
      nameValues = [].map.call(inputsValues, function (sumaInput1) {
        arrayInput.push(parseFloat(sumaInput1.value.replace(/,/g,"")));
      });

    this.porcentajeUtilidadesYear1 = [];
    for (var i = 1; i <= arrayInput.length - 1; i++) {
      if (arrayInput[i] != '') {
        if (i != 10) {
          porcentaje = arrayInput[i] * 100 / arrayInput[0];
          if (porcentaje == Infinity || porcentaje == -Infinity) {
            document.getElementById('inputUtilidadesPorcent' + (i)).innerHTML = 'Error';
          } else if (isNaN(porcentaje)) {
            document.getElementById('inputUtilidadesPorcent' + (i)).innerHTML = '00.0';
          } else {
            document.getElementById('inputUtilidadesPorcent' + (i)).innerHTML = porcentaje.toFixed(1).toString();
          }
          this.porcentajeUtilidadesYear1.push(arrayInput[i])
        } else {
          porcentaje = arrayInput[i] * 100 / arrayInput[2];
          if (porcentaje == Infinity || porcentaje == -Infinity) {
            document.getElementById('inputUtilidadesPorcent' + (i)).innerHTML = 'Error';
          } else if (isNaN(porcentaje)) {
            document.getElementById('inputUtilidadesPorcent' + (i)).innerHTML = '00.0';
          } else {
            document.getElementById('inputUtilidadesPorcent' + (i)).innerHTML = porcentaje.toFixed(1).toString();
          }
          this.porcentajeUtilidadesYear1.push(arrayInput[i])
        }
      }
    }
  }

  //Suma Utilidades Year 2

  restaUtilidadYear2() {
    //Resta Utilidad Bruta
    // this.utilidadBruta2 = (+this.ventasTotalesYear2 - this.costoVenta2).toFixed(2);
    this.utilidadBruta2 = parseFloat((+Number(this.ventasTotalesYear2.toString().replace(/,/g,"")) - (Number(this.costoVenta2.toString().replace(/,/g,"")))).toFixed(2));
    //Utilidad Operacional
    this.utilidadOpercional2 = parseFloat((+this.utilidadBruta2 - Number(this.gatosAdministrativos2.toString().replace(/,/g,""))).toFixed(2));
    //Utilidad antes de Impuestos
    this.utilidadAntesImpuestos2 = parseFloat((Number(this.utilidadOpercional2) + Number(this.ingresoNoOperacional2.toString().replace(/,/g,"")) - Number(this.gastosNoOperacionales2.toString().replace(/,/g,""))).toFixed(2));
    //Utilidad Tributable
    this.utilidadTributable2 = parseFloat((+this.utilidadAntesImpuestos2 - Number(this.participacionTrabajador2.toString().replace(/,/g,""))).toFixed(2));
    //Utilidad Repartible 
    this.utilidadRepartible2 = parseFloat((+this.utilidadTributable2 - Number(this.impuestoRenta2.toString().replace(/,/g,""))).toFixed(2));

    return this.utilidadBruta2, this.utilidadOpercional2, this.utilidadTributable2,
      this.utilidadAntesImpuestos2, this.utilidadRepartible2
  }

  //porcentaje
  metodoporcentajeUtilidades2() {
    var arrayInput = new Array();
    var total = 0;
    var porcentaje = 0;
    var unico = 0;
    var inputsValues = document.getElementsByClassName('porcentInputYear2'),
      nameValues = [].map.call(inputsValues, function (sumaInput1) {
        arrayInput.push(parseFloat(sumaInput1.value.replace(/,/g,"")));
      });

    this.porcentajeUtilidadesYear2 = [];
    for (var i = 1; i <= arrayInput.length - 1; i++) {
      if (arrayInput[i] != '') {
        if (i != 10) {
          porcentaje = arrayInput[i] * 100 / arrayInput[0];
          if (porcentaje == Infinity || porcentaje == -Infinity) {
            document.getElementById('inputUtilidadesPorcent0' + (i)).innerHTML = 'Error';
          } else if (isNaN(porcentaje)) {
            document.getElementById('inputUtilidadesPorcent0' + (i)).innerHTML = '00.0';
          } else {
            document.getElementById('inputUtilidadesPorcent0' + (i)).innerHTML = porcentaje.toFixed(1).toString();
          }
          this.porcentajeUtilidadesYear1.push(arrayInput[i])
        } else {
          porcentaje = arrayInput[i] * 100 / arrayInput[2];
          if (porcentaje == Infinity || porcentaje == -Infinity) {
            document.getElementById('inputUtilidadesPorcent0' + (i)).innerHTML = 'Error';
          } else if (isNaN(porcentaje)) {
            document.getElementById('inputUtilidadesPorcent0' + (i)).innerHTML = '00.0';
          } else {
            document.getElementById('inputUtilidadesPorcent0' + (i)).innerHTML = porcentaje.toFixed(1).toString();
          }
          this.porcentajeUtilidadesYear1.push(arrayInput[i])
        }
      }
    }
  }
  //FIN DE TABLAS
  //INDICES FINANCIEROS
  capitalTrabajo
  razonCorriente //:number;
  pruebaAcida //:number;
  razonEfectivo //:number;
  razonDeudaTotal//:number;
  rotacionInventario//:number;
  diasVentaInventario//:number;
  rotacionCuentasXCobrar//:number;
  diasPromXCuentasCobrar//:number;
  rotacionCuentasXPagar//:number;
  diasCuentasXPagar//:number;
  rotacionActivosTotales//:number;
  margenUtilidad//:number;
  rendmientosSobreActivos//:number;
  rendimientoSobreCapital//:number;
  //INDICES FINANCIEROS PARTE 2
  capitalTrabajo1
  razonCorriente1 //:number;
  pruebaAcida1 //:number;
  razonEfectivo1 //:number;
  razonDeudaTotal1//:number;
  rotacionInventario1//:number;
  diasVentaInventario1//:number;
  rotacionCuentasXCobrar1//:number;
  diasPromXCuentasCobrar1//:number;
  rotacionCuentasXPagar1//:number;
  diasCuentasXPagar1//:number;
  rotacionActivosTotales1//:number;
  margenUtilidad1//:number;
  rendmientosSobreActivos1//:number;
  rendimientoSobreCapital1//:number;

  addValores() {

    this.inventariosCorrienteYear1 = this.formularioCalificacion.value.inventariosCorrienteYear1;
    this.cajaCorrienteYear1 = this.formularioCalificacion.value.cajaCorrienteYear1;
    this.costoVenta = this.formularioCalificacion.value.costoVenta;
    this.ventasTotalesYear1 = this.formularioCalificacion.value.ventasTotalesYear1;
    this.gatosAdministrativos = this.formularioCalificacion.value.gatosAdministrativos;
    this.ingresoNoOperacional = this.formularioCalificacion.value.ingresoNoOperacional
    this.gastosNoOperacionales = this.formularioCalificacion.value.gastosNoOperacionales
    this.participacionTrabajador = this.formularioCalificacion.value.participacionTrabajador
    this.impuestoRenta = this.formularioCalificacion.value.impuestoRenta
    this.localesRelacionadosCorrienteYear1 = this.formularioCalificacion.value.localesRelacionadosCorrienteYear1;
    this.localesNoRelacionadosCorrienteYear1 = this.formularioCalificacion.value.localesNoRelacionadosCorrienteYear1;
    this.activoCuentasPasivo1 = this.formularioCalificacion.value.activoCuentasPasivo1;
    this.pasivoCuentasNoRelacionados1 = this.formularioCalificacion.value.pasivoCuentasNoRelacionados1;
    //segundo año
    this.inventariosCorrienteYear2 = this.formularioCalificacion.value.inventariosCorrienteYear2;
    this.cajaCorrienteYear2 = this.formularioCalificacion.value.cajaCorrienteYear2;
    this.costoVenta2 = this.formularioCalificacion.value.costoVenta2;
    this.ventasTotalesYear2 = this.formularioCalificacion.value.ventasTotalesYear2;
    this.gatosAdministrativos2 = this.formularioCalificacion.value.gatosAdministrativos2
    this.gastosNoOperacionales2 = this.formularioCalificacion.value.gastosNoOperacionales2
    this.participacionTrabajador2 = this.formularioCalificacion.value.participacionTrabajador2
    this.impuestoRenta2 = this.formularioCalificacion.value.impuestoRenta2
    this.localesRelacionadosCorrienteYear2 = this.formularioCalificacion.value.localesRelacionadosCorrienteYear2;
    this.localesNoRelacionadosCorrienteYear2 = this.formularioCalificacion.value.localesNoRelacionadosCorrienteYear2;
    this.activoCuentasPasivo2 = this.formularioCalificacion.value.activoCuentasPasivo2;
    this.pasivoCuentasNoRelacionados2 = this.formularioCalificacion.value.pasivoCuentasNoRelacionados2;

  }



  valoresPrimerAFinales
  valoresSegundoAFinales
  activoCorrientFinal
  activoNoCorrienteFinal
  totalActivoFinal
  pasivoCorrienteFinal
  pasivoNoCorrienteFinal
  pasivoFinal
  patrimonioFinal
  ventasFinales
  utiliPerdidaFinal
  capitalTrabajoFinal
  //parte 2

  activoCorrientFinal1
  activoNoCorrienteFinal1
  totalActivoFinal1
  pasivoCorrienteFinal1
  pasivoNoCorrienteFinal1
  pasivoFinal1
  patrimonioFinal1
  ventasFinales1
  utiliPerdidaFinal1
  capitalTrabajoFinal1

  variacion() {
    this.activoCorrientFinal = (this.year - this.year1);
    this.activoNoCorrienteFinal = (this.activo1 - this.activo2);
    this.totalActivoFinal = (this.totalBalanceActivoYear1 - this.totalBalanceActivoYear2);
    this.pasivoCorrienteFinal = (this.pasivos1 - this.pasivos2);
    this.pasivoNoCorrienteFinal = (this.anio - this.anio1);
    this.pasivoFinal = (this.totalBalancePasivos1 - this.totalBalancePasivos2);
    this.patrimonioFinal = (this.patrimonio1 - this.patrimonio2);
    this.ventasFinales = (parseFloat(this.proveedor.ventasTotalesYear1.toString().replace(/,/g,"") ) - parseFloat(this.proveedor.ventasTotalesYear2.toString().replace(/,/g,"")));
    this.utiliPerdidaFinal = (this.utilidadRepartible - this.utilidadRepartible2)
    this.capitalTrabajoFinal = (this.capitalTrabajo - this.capitalTrabajo1);
    //calculos parte 2

    this.activoCorrientFinal1 = (this.activoCorrientFinal / this.year1) * 100;
    this.activoNoCorrienteFinal1 = (this.activoNoCorrienteFinal / this.activo2) * 100;
    this.totalActivoFinal1 = (this.totalActivoFinal / this.totalBalanceActivoYear2) * 100;
    this.pasivoCorrienteFinal1 = (this.pasivoCorrienteFinal / this.pasivos2) * 100;
    this.pasivoNoCorrienteFinal1 = (this.pasivoNoCorrienteFinal / this.anio1) * 100;
    this.pasivoFinal1 = (this.pasivoFinal / this.totalBalancePasivos2) * 100;
    this.patrimonioFinal1 = (this.patrimonioFinal / this.patrimonio2) * 100;
    this.ventasFinales1 = (this.ventasFinales / parseFloat(this.proveedor.ventasTotalesYear2.toString().replace(/,/g,""))) * 100;
    this.utiliPerdidaFinal1 = (this.utiliPerdidaFinal / this.utilidadRepartible2) * 100;
    this.capitalTrabajoFinal1 = (this.capitalTrabajoFinal / this.capitalTrabajo1) * 100;
    

  }

  calculos() {
    this.addValores();
    this.calculos2();
    const formatterDolar = new Intl.NumberFormat('en-US', {
      currency: 'USD'
    })
    //Razon corriente
    this.razonCorriente = (this.year / this.pasivos1).toFixed(2);
    //Capital trabajo
    this.capitalTrabajo = (this.year - this.pasivos1).toFixed(2);
    //Prueba Acida
    this.pruebaAcida = ((this.year - parseFloat(this.inventariosCorrienteYear1.toString().replace(/,/g,""))) / this.pasivos1).toFixed(2);
    //Razon Efectiva
    this.razonEfectivo = (parseFloat(this.cajaCorrienteYear1.toString().replace(/,/g,"")) / this.pasivos1).toFixed(2);
    //Razon deudal
    this.razonDeudaTotal = ((this.totalBalanceActivoYear1 - this.patrimonio1) / this.totalBalanceActivoYear1).toFixed(2);
    //Rotacion Inventario
     if (parseFloat(this.inventariosCorrienteYear1.toString().replace(/,/g,"")) === 0 || parseFloat(this.inventariosCorrienteYear1.toString().replace(/,/g,"")) === undefined || parseFloat(this.inventariosCorrienteYear1.toString().replace(/,/g,"")) === Infinity || parseFloat(this.inventariosCorrienteYear1.toString().replace(/,/g,"")) === null) {
      this.rotacionInventario = "---";
    } else {
      this.rotacionInventario = (parseFloat(this.costoVenta.toString().replace(/,/g,"") )/ parseFloat(this.inventariosCorrienteYear1.toString().replace(/,/g,""))).toFixed(2);

    }
    //Dias de rotacion de inventario
    if (this.rotacionInventario != "---") {
      this.diasVentaInventario = (365 / this.rotacionInventario).toFixed(0);
    } else {
      this.diasVentaInventario = "---";
    }
    //Rotacion cuentas por cobrar
    this.rotacionCuentasXCobrar = (parseFloat(this.ventasTotalesYear1) / (parseFloat(this.localesRelacionadosCorrienteYear1) + parseFloat(this.localesNoRelacionadosCorrienteYear1))).toFixed(2);
    //Dias cuentas por cobrar
    this.diasPromXCuentasCobrar = (365 / this.rotacionCuentasXCobrar).toFixed(0);
    //Rotacion cuentas por pagar
    this.rotacionCuentasXPagar = (parseFloat(this.costoVenta.toString().replace(/,/g,"")) / (parseFloat(this.activoCuentasPasivo1.toString().replace(/,/g,"")) + parseFloat(this.pasivoCuentasNoRelacionados1.toString().replace(/,/g,"")))).toFixed(2);
    // Dias cuentas por pagar
  
    if (this.rotacionCuentasXPagar === "---" || this.rotacionCuentasXPagar === undefined || parseFloat(this.rotacionCuentasXPagar) === Infinity || parseFloat(this.rotacionCuentasXPagar) === 0 ) {
      this.diasCuentasXPagar = "---"
    } else {
      this.diasCuentasXPagar = (365 / this.rotacionCuentasXPagar).toFixed(0);
    }
    
    //Rotacion activos 
    this.rotacionActivosTotales = (parseFloat(this.ventasTotalesYear1.toString().replace(/,/g,"")) / this.totalBalanceActivoYear1).toFixed(2);
    //Margen de utilidad
    this.margenUtilidad = ((parseFloat(this.utilidadRepartible) / parseFloat(this.ventasTotalesYear1.toString().replace(/,/g,""))) * 100).toFixed(2);
    //Rendimientos sobre activos
    this.rendmientosSobreActivos = ((parseFloat(this.utilidadRepartible) / this.totalBalanceActivoYear1) * 100).toFixed(2);
    //Rendimiento sobre capital
    this.rendimientoSobreCapital = ((parseFloat(this.utilidadRepartible) / this.patrimonio1) * 100).toFixed(2);

    this.variacion();

  }

  calculos2() {
    //Razon Corriente 2
    this.razonCorriente1 = (this.year1 / this.pasivos2).toFixed(2);
    //Capital Trabajo 2
    this.capitalTrabajo1 = (this.year1 - this.pasivos2).toFixed(2);
    //Prueba Acida 2
    this.pruebaAcida1 = ((this.year1 - parseFloat(this.inventariosCorrienteYear2.toString().replace(/,/g,""))) / this.pasivos2).toFixed(2);
    //Razon Efectivo 2
    this.razonEfectivo1 = (parseFloat(this.cajaCorrienteYear2.toString().replace(/,/g,"")) / this.pasivos2).toFixed(2);
    //Razon Deudal 2
    this.razonDeudaTotal1 = ((this.totalBalanceActivoYear2 - this.patrimonio2) / this.totalBalanceActivoYear2).toFixed(2);
    //Rotacion Inventario 2
    if (parseFloat(this.inventariosCorrienteYear2.toString().replace(/,/g,"")) === 0 || parseFloat(this.inventariosCorrienteYear2.toString().replace(/,/g,"")) === undefined || parseFloat(this.inventariosCorrienteYear2.toString().replace(/,/g,"")) === Infinity || parseFloat(this.inventariosCorrienteYear2.toString().replace(/,/g,"")) === null) {
      this.rotacionInventario1 = "---";
    } else {

      this.rotacionInventario1 = (parseFloat(this.costoVenta2.toString().replace(/,/g,"")) / parseFloat(this.inventariosCorrienteYear2.toString().replace(/,/g,""))).toFixed(2);
    }
    console.log("rotacion inventario 2"+""+this.rotacionInventario1)
    //Dias de rotacion de inventario 2
    if (this.rotacionInventario1 != "---") {
      this.diasVentaInventario1 = (365 / this.rotacionInventario1).toFixed(0);
    } else {
      this.diasVentaInventario1 = "---";
    }
    //Rotacion cuentas por cobrar 2
    this.rotacionCuentasXCobrar1 = (parseFloat(this.ventasTotalesYear2.toString().replace(/,/g,"")) / (parseFloat(this.localesRelacionadosCorrienteYear2.toString().replace(/,/g,"")) + parseFloat(this.localesNoRelacionadosCorrienteYear2.toString().replace(/,/g,"")))).toFixed(2);
    //Dias cuentas por cobrar 2
    this.diasPromXCuentasCobrar1 = (365 / this.rotacionCuentasXCobrar1).toFixed(0);
    //Rotacion cuentas por pagar 2
    this.rotacionCuentasXPagar1 = (parseFloat(this.costoVenta2.toString().replace(/,/g,"")) / (parseFloat(this.activoCuentasPasivo2.toString().replace(/,/g,"")) + parseFloat(this.pasivoCuentasNoRelacionados2.toString().replace(/,/g,"")))).toFixed(2);
    //Dias cuentas por pagar  2
    if (this.rotacionCuentasXPagar1 === "---" || this.rotacionCuentasXPagar1 === undefined || parseFloat(this.rotacionCuentasXPagar1) === Infinity || parseFloat(this.rotacionCuentasXPagar1) === 0) {
      this.diasCuentasXPagar1 = "---"
    
    } else {
      this.diasCuentasXPagar1 = (365 / this.rotacionCuentasXPagar1).toFixed(0);
    }
    //Rotacion Activos 2
    this.rotacionActivosTotales1 = (parseFloat(this.ventasTotalesYear2.toString().replace(/,/g,"")) / this.totalBalanceActivoYear2).toFixed(2);
    //Margen Utilidad 2
    this.margenUtilidad1 = ((parseFloat(this.utilidadRepartible2) / parseFloat(this.ventasTotalesYear2.toString().replace(/,/g,""))) * 100).toFixed(2);
    //Rendimiento sobre activos 2
    this.rendmientosSobreActivos1 = ((parseFloat(this.utilidadRepartible2) / this.totalBalanceActivoYear2) * 100).toFixed(2);
    //Rendimiento sobre capital 2
    this.rendimientoSobreCapital1 = ((parseFloat(this.utilidadRepartible2) / this.patrimonio2) * 100).toFixed(2);


  }



  graficoBarras() {
    this.anoBalance1 = this.proveedor.totalUnidadesUno
    this.anoBalance2 = this.proveedor.totalUnidadesDos
    this.valoresPrimerAFinales = [this.year, this.activo1, this.totalBalanceActivoYear1, this.pasivos1, this.anio, this.totalBalancePasivos1, this.patrimonio1, parseFloat(this.ventasTotalesYear1.replace(/,/g,"")), this.utilidadRepartible, this.capitalTrabajo];
    this.valoresSegundoAFinales = [this.year1, this.activo2, this.totalBalanceActivoYear2, this.pasivos2, this.anio1, this.totalBalancePasivos2, this.patrimonio2, parseFloat(this.ventasTotalesYear2.replace(/,/g,"")), this.utilidadRepartible2, this.capitalTrabajo1];
    console.log(this.valoresPrimerAFinales)
    console.log(this.valoresSegundoAFinales)
    this.barChartLabels = ['ACTIVO CORRIENTE', 'ACTIVO NO CORRIENTE', 'TOTAL DE ACTIVOS', 'PASIVO CORRIENTE ', 'PASIVO NO CORRIENTE', 'TOTAL PASIVOS ', 'PATRIMONIO', 'VENTAS', 'UTILIDAD/PERDIDA', 'CAPITAL DE TRABAJO'];
    this.barChartData = [

      { data: this.valoresPrimerAFinales, label: this.anoBalance1 },
      { data: this.valoresSegundoAFinales, label: this.anoBalance2 }
    ];
    this.barChartLegend
    this.barChartOptions
    this.barChartPlugins
    this.barChartType

  }

  graficar() {
    //this.anoBalance1=this.proveedor.totalUnidadesUno
    this.variacion();
    this.calculos();
    this.graficoBarras();
    //grafico1

    this.pieChartData = this.porcentajeCorrienteYear1;
    this.pieChartLabels = ['CAJA,BANCOS', 'INV. CORRIENTES', 'CXC RELAC',
      'CXC NO RELAC', 'OTRAS CXC RELAC',
      'OTRAS CXC NO RELAC', 'PROV C.INCOBRABLES', 'CREDITO TRIBUTARIO',
      'INVENTARIOS', 'ACT PAGADO POR ANTIC', 'OTROS ACT.CORR'];
    this.pieChartLegend
    this.pieChartOptions
    this.pieChartPlugins
    this.pieChartType
    this.pieChartColors

    //grafico 2
    this.pieChartData2 = this.porcentajeDiferidoYear1;
    this.pieChartLabels2 = ['T ACT. FIJO ',
      'T ACT. DIFERIDO ',
      'INV NO CORRIENTES',
      'INV NO CORR OTRAS ',
      'CXC CLIENTES RELAC',
      'CXC CLIEN NO CORR NO RELAC',
      'OTRAS CXC CLIEN NO CORR RELAC',
      'OTRAS CXC CLIEN NO CORR NO RELAC',
      '(-) PROV CTS INCOBRABLES', 'OTROS ACT NO CORR'];
    this.pieChartLegend2
    this.pieChartOptions2
    this.pieChartPlugins2
    this.pieChartType2
    this.pieChartColors2
    // grafico 3

    this.pieChartData3 = this.porcentajeCorrientePasivos1;
    this.pieChartLabels3 = ['CXP PROVEE CORR RELAC',
      'CXP PROVEE CORR NO RELAC',
      'OBLIGACIONES INST FINANCIERAS',
      'PRESTAMOS ACCIONISTAS ', 'OTRAS CXP CORR RELAC',
      'OTRAS CXP CORRNO RELAC',
      'IMPUESTO A LA RENTA POR PAGAR', 'PART TRABAJADORES X PAGAR',
      'OTROS PASIVOS CORRIENTES'];
    this.pieChartLegend3
    this.pieChartOptions3
    this.pieChartPlugins3
    this.pieChartType3
    this.pieChartColors3

    //grafico 4

    this.pieChartData4 = this.porcentajeDiferidoPasivos1;
    this.pieChartLabels4 = ['CXP PROVEE NO CORR RELAC',
      'CXP PROVEE NO CORR NO RELAC ',
      'OBLIGACIONES INST FINANCIERAS', 'PRESTAMOS ACCIONISTAS',
      'OTRAS CXP NO CORR RELAC', 'OTRAS CXP NO CORR NO RELAC',
      'OTROS PASIVOS NO CORRIENTES'
    ];
    this.pieChartLegend4
    this.pieChartOptions4
    this.pieChartPlugins4
    this.pieChartType4
    this.pieChartColors4

    //grafico 5
    this.pieChartData5 = this.porcentajeDiferidoPatrimonio1;
    this.pieChartLabels5 = ['CAPITAL SUSC Y/O ASIG',
      'OTRS RESULT  INTEGRALES ',
      'APORTES DE SOCIOS O ACCIONISTAS',
      'RESERVA LEGAL-FACULTATIVA',
      'RESULT ACUM DE  NIIF',
      'UTILIDAD NO DISTRI EJER ANT',
      '(-) PERDIDA ACUM EJER ANT',
      'UTILIDAD DEL EJERCICIO',


    ];
    this.pieChartLegend5
    this.pieChartOptions5
    this.pieChartPlugins5
    this.pieChartType5
    this.pieChartColors5

  }
  //--------------------------------------Tabla de calificación final----------------------------------------------
  //variables a usar:
  valorActividad1
  valorActividad2
  totalActividad
  totalActividad1
  valorActividad() {
    //Segmento de actividad
    //Actividad
    this.iniActiCalificacion = this.formularioCalificacion.value.iniActiCalificacion
    //this.iniActiCalificacion>0 && this.iniActiCalificacion<=3? /*|| this.iniActiCalificacion===3?*/  console.log(0):
    this.iniActiCalificacion > 0 && this.iniActiCalificacion <= 3 ? console.log(this.valorActividad1 = 0) :
      this.iniActiCalificacion >= 4 && this.iniActiCalificacion <= 7 ? console.log(this.valorActividad1 = 2) :
        this.iniActiCalificacion >= 8 && this.iniActiCalificacion <= 11 ? console.log(this.valorActividad1 = 3) : console.log(this.valorActividad1 = 4);
    this.proveedor.descriptionTrue === "SI" || this.proveedor.descriptionTrue1 === "SI" ? console.log(this.valorActividad2 = 2) : console.log(this.valorActividad2 = 0);
    this.totalActividad = this.valorActividad1 + this.valorActividad2
    this.totalActividad1 = ((this.totalActividad * 65) / 100).toFixed(0);
    console.log("Total de actividad es :" + " " + this.totalActividad);
  }
  totalReferencia1
  valorReferencia1
  valorReferencia2
  valorReferencia3
  totalReferencia
  valorReferencia() {
    this.proveedor.checkspuntualidadEntregaUno === "a tiempo" ? console.log(this.valorReferencia1 = 3) :
      this.proveedor.checkspuntualidadEntregaUno === "retraso" ? console.log(this.valorReferencia1 = 2) : console.log(this.valorReferencia1 = 0);
    this.proveedor.checkspuntualidadEntregaProveedor1 === "a tiempo" ? console.log(this.valorReferencia2 = 3) :
      this.proveedor.checkspuntualidadEntregaProveedor1 === "retraso" ? console.log(this.valorReferencia2 = 2) : console.log(this.valorReferencia2 = 0);
    this.proveedor.bancarioUno === "buena" ? console.log(this.valorReferencia3 = 4) : console.log(this.valorReferencia3 = 0);
    this.totalReferencia = this.valorReferencia1 + this.valorReferencia2 + this.valorReferencia3
    this.totalReferencia1 = ((this.totalReferencia * 76) / 100).toFixed(0);
    console.log("Total de referencia es:" + "" + this.totalReferencia);
  }


  totalSolidezFinanciera1
  totalSolidezFinanciera
  valorSolidez1
  valorSolidez2
  valorSolidez3
  valorSolidez4
  valorSolidez5
  valorSolidez6
  valorSolidez7
  solidezFinanciera() {
    this.proveedor.ventasTotalesYear1 > this.proveedor.ventasTotalesYear2 && this.utilidadBruta > 0 ? console.log(this.valorSolidez1 = 3) :
      this.proveedor.ventasTotalesYear1 < this.proveedor.ventasTotalesYear2 && this.utilidadBruta > 0 ? console.log(this.valorSolidez1 = 2) :
        this.proveedor.ventasTotalesYear1 > this.proveedor.ventasTotalesYear2 && this.utilidadBruta < 0 ? console.log(this.valorSolidez1 = 1) :
          this.proveedor.ventasTotalesYear1 < this.proveedor.ventasTotalesYear2 && this.utilidadBruta < 0 ? console.log(this.valorSolidez1 = 0) : console.log("error");
    this.utilidadOpercional > 0 ? console.log(this.valorSolidez2 = 2) : console.log(this.valorSolidez2 = 0);
    this.capitalTrabajo > 0 ? console.log(this.valorSolidez3 = 2) : console.log(this.valorSolidez3 = 0);
    this.razonDeudaTotal >= 0.40 && this.razonDeudaTotal <= 0.70 ? console.log(this.valorSolidez4 = 1) :
      this.razonDeudaTotal < 0.40 ? console.log(this.valorSolidez4 = 3) : console.log(this.valorSolidez4 = 0);
    this.razonCorriente > 0.00 ? console.log(this.valorSolidez5 = 2) : console.log(this.valorSolidez5 = 0);
    this.proveedor.ventasTotalesYear1 > this.proveedor.ventasTotalesYear2 ? console.log(this.valorSolidez6 = 1) : console.log(this.valorSolidez6 = 0);
    //ojo
    this.proveedor.checks2Cuatro || this.proveedor.checks2Uno || this.proveedor.checks2Dos || this.proveedor.checks2 === "NO" ? console.log(this.valorSolidez7 = 0) : console.log(this.valorSolidez7 = 4);
    this.totalSolidezFinanciera = this.valorSolidez1 + this.valorSolidez2 + this.valorSolidez3 + this.valorSolidez4 +
      this.valorSolidez5 + this.valorSolidez6 + this.valorSolidez7;
    this.totalSolidezFinanciera1 = ((this.totalSolidezFinanciera * 60) / 100).toFixed(0);
    console.log("El total de solidez financiera es:" + " " + this.totalSolidezFinanciera);
  }


  totalCapacidad
  valorCapacidad1
  valorCapacidad2
  valorCapacidad3
  valorCapacidad4
  valorCapacidad5
  valorCapacidad6
  valorCapacidad7
  valorCapacidad8
  valorCapacidad9
  segmentoCapacidad() {
    this.proveedor.checks4 === "Propia" ? console.log(this.valorCapacidad1 = 2) : console.log(this.valorCapacidad1 = 0);
    this.proveedor.checks5 === "SI" ? console.log(this.valorCapacidad2 = 3) : console.log(this.valorCapacidad2 = 0);
    this.proveedor.controlNumero >= 41 ? console.log(this.valorCapacidad3 = 2) : console.log(this.valorCapacidad3 = 1);
    this.proveedor.controlNumoeroPesonal > 0 ? console.log(this.valorCapacidad4 = 4) : console.log(this.valorCapacidad4 = 0);
    this.proveedor.checks6 === "IndividualContratacionEmpre" || this.proveedor.checks6 === "ColectivoContratacionEmpre" ? console.log(this.valorCapacidad5 = 2) : console.log(this.valorCapacidad5 = 0);
    this.proveedor.checks7 === "propiaMaqui" || this.proveedor.checks7 === "NoAplicaMaquinaria" ? console.log(this.valorCapacidad6 = 2) : console.log(this.valorCapacidad6 = 1);
    this.proveedor.checks8 === "SI" || this.proveedor.checks8 === "NoApliMantenimiento" ? console.log(this.valorCapacidad7 = 4) : console.log(this.valorCapacidad7 = 0);
    this.proveedor.checks9 === "SI" || this.proveedor.checks9 === "noApliTransporte" ? console.log(this.valorCapacidad8 = 4) : console.log(this.valorCapacidad8 = 2);
    this.proveedor.checks10 === "SI" || this.proveedor.checks10 === "NoAplicaMedicionCapacidad" ? console.log(this.valorCapacidad9 = 4) : console.log(this.valorCapacidad9 = 0);
    this.proveedor.checks11 === "SI" || this.proveedor.checks11 === "noApliInventario" ? console.log(this.valorCapacidad9 = 4) : console.log(this.valorCapacidad9 = 0);
    this.totalCapacidad = this.valorCapacidad1 + this.valorCapacidad2 + this.valorCapacidad3 + this.valorCapacidad4 + this.valorCapacidad5 + this.valorCapacidad6 +
      this.valorCapacidad7 + this.valorCapacidad8 + this.valorCapacidad9;
    console.log("Total de capacidad es :" + " " + this.totalCapacidad)
  }


  totalGestion
  valorCalidad1
  valorCalidad2
  valorCalidad3
  valorCalidad4
  valorCalidad5
  valorCalidad6
  valorCalidad7

  segmentoCalidad() {
    this.proveedor.checks12 === "SI" ? console.log(this.valorCalidad1 = 4) : console.log(this.valorCalidad1 = 0);
    this.proveedor.checks13 === "SI" || this.proveedor.checks13 === "noApliSeguridad" ? console.log(this.valorCalidad2 = 2) : console.log(this.valorCalidad2 = 0);
    this.proveedor.checks14 === "SI" ? console.log(this.valorCalidad3 = 2) : console.log(this.valorCalidad3 = 0);
    this.proveedor.checks15 === "SI" ? console.log(this.valorCalidad4 = 3) : console.log(this.valorCalidad4 = 0);
    this.proveedor.checks16 === "SI" ? console.log(this.valorCalidad5 = 3) : console.log(this.valorCalidad5 = 0);
    this.proveedor.checks17 === "SI" ? console.log(this.valorCalidad6 = 3) : console.log(this.valorCalidad6 = 0);
    this.proveedor.checks18 === "SI" || this.proveedor.checks18 === "noApliNormasAmb" ? console.log(this.valorCalidad7 = 3) : console.log(this.valorCalidad7 = 0);
    this.totalGestion = this.valorCalidad1 + this.valorCalidad2 + this.valorCalidad3 + this.valorCalidad4 + this.valorCalidad5 + this.valorCalidad6 + this.valorCalidad7;
    console.log("total de la gestion es :" + " " + this.totalGestion);

  }


  totalComercial
  valorComercial1
  valorComercial2
  valorComercial3
  valorComercial4
  valorComercial5
  valorComercial6
  valorComercial7
  valorComercial8
  valorComercial9
  valorComercial10
  segmentoComercial() {

    this.proveedor.checks20Cinco === "REPUESTOS" || this.proveedor.checks20Cuatro === "CALIDAD"
      || this.proveedor.checks20Tres === "ENTREGAPUNTUAL" || this.proveedor.checks20Dos === "SERVICIO/ATENCION"
      || this.proveedor.checks20Uno === "PRECIO" || this.proveedor.checks20 === "OTROS" ? console.log(this.valorComercial1 = 3) : console.log(this.valorComercial1 = 0);
    this.proveedor.checks20Seis != "NINGUNA" ? console.log(this.valorComercial1 = 3) : console.log(this.valorComercial1 = 0);
    this.proveedor.checks21 != "SI" ? console.log(this.valorComercial2 = 2) : (this.valorComercial2 = 0);
    this.proveedor.checks22 === "SI" ? console.log(this.valorComercial3 = 2) : (this.valorComercial3 = 1);
    this.proveedor.checks23 === "SI" ? console.log(this.valorComercial4 = 2) : (this.valorComercial4 = 0);
    this.proveedor.checks24 === "SI" ? console.log(this.valorComercial5 = 2) : (this.valorComercial5 = 0);
    this.proveedor.checks25 === "SI" ? console.log(this.valorComercial6 = 3) : (this.valorComercial6 = 0);
    this.proveedor.checks26 === "SI" ? console.log(this.valorComercial7 = 2) : (this.valorComercial7 = 0);
    this.proveedor.checks27U === "SI" ? console.log(this.valorComercial8 = 2) : (this.valorComercial8 = 0);
    this.proveedor.checks28 === "SI" ? console.log(this.valorComercial9 = 2) : (this.valorComercial9 = 0);
    this.proveedor.checks29 === "SI" ? console.log(this.valorComercial10 = 2) : (this.valorComercial10 = 0);
    this.totalComercial = this.valorComercial1 + this.valorComercial2 + this.valorComercial3 + this.valorComercial4 + this.valorComercial5 +
      this.valorComercial6 + this.valorComercial7 + this.valorComercial8 + this.valorComercial9 + this.valorComercial10;
    console.log("El total comercial es:" + "" + this.totalComercial)

  }


  totalObligaciones
  valorObligacion1
  valorObligacion2
  valorObligacion3
  valorObligacion4
  obligacionesLegales() {
    this.proveedor.checksObligacionesTributarias === "SI" ? console.log(this.valorObligacion1 = 1) : (this.valorObligacion1 = 0);
    this.proveedor.checksObligacionesPatronales === "SI" ? console.log(this.valorObligacion2 = 1) : (this.valorObligacion2 = 0);
    this.proveedor.checksPagoImpuestoPredial === "SI" || this.proveedor.checksPagoImpuestoPredial === "NoPalica" ? console.log(this.valorObligacion3 = 1) : (this.valorObligacion3 = 0);
    this.proveedor.checksPatenteMunicipalActualizado === "SI" ? console.log(this.valorObligacion4 = 1) : (this.valorObligacion4 = 0);
    this.totalObligaciones = this.valorObligacion1 + this.valorObligacion2 + this.valorObligacion3 + this.valorObligacion4;
    console.log("El total de obligaciones legales es:" + " " + this.totalObligaciones);
  }

  //subTotalCalificacion;
  //totalCalificacion;
  totalGestion1
  totalComercial1
  totalObligaciones1
  totalCapacidad1

  subCalificacionGeneral() {


    this.totalCapacidad1 = ((this.totalCapacidad * 100) / 100).toFixed(0);
    this.totalGestion1 = ((this.totalGestion * 100) / 100).toFixed(0);
    this.totalComercial1 = ((this.totalComercial * 93) / 100).toFixed(0);
    this.totalObligaciones1 = ((this.totalObligaciones * 100) / 100).toFixed(0);

    console.log(this.totalCapacidad1)
    console.log(this.totalGestion1)
    console.log(this.totalComercial1)
    console.log(this.totalObligaciones1)

  }

  subTotalCalificacion
  totalCalificacion
  sumValoresFinales() {
    this.subCalificacionGeneral();
    this.subTotalCalificacion = (parseInt(this.totalActividad1) + parseInt(this.totalReferencia1) + parseInt(this.totalSolidezFinanciera1))
    this.totalCalificacion = (parseInt(this.subTotalCalificacion) + parseInt(this.totalCapacidad1) + parseInt(this.totalGestion1) + parseInt(this.totalComercial1) + parseInt(this.totalObligaciones1) + this.formularioCalificacion.value.ValorCalificacion)
    console.log(this.subTotalCalificacion)
    console.log(this.totalCalificacion)
    this.valorCalificacionResultado = this.totalCalificacion  
    console.log(this.valorCalificacionResultado,'ESTOY AQUI')
  }


  equivalenciaActividad
  equivalenciaOperativa
  equivalenciaGestion
  equivalenciaGeneral
  equivalenciaObligaciones
  equivalenciaTotal
  equivalenciaFrase
  calificacionGeneral() {
    this.sumValoresFinales();
    //primera
    this.subTotalCalificacion <= 7 ? console.log(this.equivalenciaActividad = "Regular") :
      this.subTotalCalificacion <= 15 ? console.log(this.equivalenciaActividad = "Bueno") :
        this.subTotalCalificacion <= 24 ? console.log(this.equivalenciaActividad = "Muy bueno") : console.log(this.equivalenciaActividad = "Excelente");
    //segunda
    this.totalCapacidad1 <= 8 ? console.log(this.equivalenciaOperativa = "Regular") :
      this.totalCapacidad1 <= 12 ? console.log(this.equivalenciaOperativa = "Bueno") :
        this.totalCapacidad1 <= 17 ? console.log(this.equivalenciaOperativa = "Muy bueno") : console.log(this.equivalenciaOperativa = "Excelente");
    //tercera
    this.totalGestion1 <= 5 ? console.log(this.equivalenciaGestion = "Regular") :
      this.totalGestion1 <= 10 ? console.log(this.equivalenciaGestion = "Bueno") :
        this.totalGestion1 <= 14 ? console.log(this.equivalenciaGestion = "Muy bueno") : console.log(this.equivalenciaGestion = "Excelente");
    //cuarta
    this.totalComercial1 <= 5 ? console.log(this.equivalenciaGeneral = "Regular") :
      this.totalComercial1 <= 12 ? console.log(this.equivalenciaGeneral = "Bueno") :
        this.totalComercial1 <= 16 ? console.log(this.equivalenciaGeneral = "Muy bueno") : console.log(this.equivalenciaGeneral = "Excelente");
    //quinta
    this.totalObligaciones1 <= 0 ? console.log(this.equivalenciaObligaciones = "Regular") :
      this.totalObligaciones1 <= 2 ? console.log(this.equivalenciaObligaciones = "Bueno") :
        this.totalObligaciones1 <= 3 ? console.log(this.equivalenciaObligaciones = "Muy bueno") : console.log(this.equivalenciaObligaciones = "Excelente");


    /*---------Seccion de total-------- */
    this.totalCalificacion <= 30 ? console.log(this.equivalenciaTotal = "Riesgo D", this.equivalenciaFrase = "Desempeño Critico no recomendamos entablar relaciones comerciales", document.getElementById("colorCalificacion").style.color = "red", document.getElementById("colorCalificacion1").style.color = "red") :
      this.totalCalificacion <= 50 && this.totalCalificacion > 30 ? console.log(this.equivalenciaTotal = "Riesgo C", this.equivalenciaFrase = "Desempeño riesgoso recomendamos respaldarse con algún tipo de garantía", document.getElementById("colorCalificacion").style.color = "orange", document.getElementById("colorCalificacion1").style.color = "orange") :
        this.totalCalificacion <= 69 && this.totalCalificacion > 50 ? console.log(this.equivalenciaTotal = "Riesgo B", this.equivalenciaFrase = "Desempeño confiable recomendamos establecer relaciones comerciales, sin embargo tomar ciertas previsiones", document.getElementById("colorCalificacion").style.color = "blue", document.getElementById("colorCalificacion1").style.color = "blue") :
          this.totalCalificacion <= 89 && this.totalCalificacion > 69 ? console.log(this.equivalenciaTotal = "Riesgo A", this.equivalenciaFrase = "Buen Desempeño recomendamos trabajar con este proveedor", document.getElementById("colorCalificacion").style.color = "green", document.getElementById("colorCalificacion1").style.color = "green") :
            console.log(this.equivalenciaTotal = "Riesgo AA", this.equivalenciaFrase = "Desempeño Notable recomendamos establecer relaciones comerciales a largo plazo con este proveedor", document.getElementById("colorCalificacion").style.color = "green", document.getElementById("colorCalificacion1").style.color = "green");
    console.log(this.equivalenciaActividad + " " + this.equivalenciaOperativa + " " + this.equivalenciaGestion + " " + this.equivalenciaGeneral + " " +
      this.equivalenciaObligaciones + " " + this.equivalenciaTotal);

 this.equivalenciatotalBase = this.equivalenciaTotal;
  
  this.equivaleciaFraseBase = this.equivalenciaFrase;
  console.log(this.equivaleciaFraseBase,'AQUI ESTA LA FRASE');
  }

  //----------------------INFORMACION RELEVANTE---------------------
  infoRelevanteSi
  infoRelevateNo

  informacionRelevant() {
    this.proveedor.checksListaClinton === "SiLista" ? console.log(this.infoRelevanteSi = "* El nombre de referencia SI se encuentra incluido en el programa SDNT de la OFAC (Lista Clinton).", document.getElementById("infoRelevanteColor").style.color = "red") :
      console.log(this.infoRelevanteSi = "* El nombre de referencia NO se encuentra incluido en el programa SDNT de la OFAC (Lista Clinton).", document.getElementById("infoRelevanteColor").style.color = "blue");

  }

 

  checkCalificacionSri(){
    this.proveedor.checksObligacionesTributarias === "SI"?
    document.getElementById("checksSRINoDescriba").style.display = "none":
    document.getElementById("checksSRINoDescriba").style.display = "inline";
  }

  checkCalificacionSuperIntendecias(){
    this.proveedor.checksObligacionSuper === "SI"?
    document.getElementById("checkSuperintendenciasNO").style.display = "none":
    document.getElementById("checkSuperintendenciasNO").style.display = "inline";
  }



  //-------------------------Fortalezas y Debilidades-----------------------------------
  fortaleza1
  fortaleza2
  fortaleza3
  fortaleza4
  fortaleza5
  fortaleza6
  fortaleza7
  fortaleza8
  fortaleza9
  fortaleza10
  fortaleza11
  fortaleza12
  fortaleza13
  fortaleza14

  debilidad1
  debilidad2
  debilidad3
  debilidad4
  debilidad5
  debilidad6
  debilidad7
  debilidad8
  debilidad9
  debilidad10
  debilidad11
  debilidad12
  debilidad13
  debilidad14
  diasCuentas
  diasCuentaPagar
  fecha
  ano
  anoFinal

  leyendas() {
    this.diasCuentas = this.diasCuentasXPagar - this.diasCuentasXPagar1
    this.diasCuentaPagar = this.diasCuentasXPagar - this.diasCuentasXPagar1
    this.fecha = new Date();
    this.ano = this.fecha.getFullYear();
    console.log('El año actual es: ' + this.ano);
    this.anoFinal = this.ano - this.formularioCalificacion.value.iniActiCalificacion

    //razon corriente
    this.razonCorriente >= 1 ? console.log(this.fortaleza1 = "Sus indicadores de liquidez muestran una adecuada cobertura de sus activos corrientes sobre sus obligaciones de corto plazo.") :
      console.log(this.debilidad1 = "Sus indicadores de liquidez  no muestran una adecuada cobertura de sus activos corrientes sobre sus obligaciones de corto plazo.");
    //razon deuda
    this.razonDeudaTotal >= 0.80 ? console.log(this.debilidad2 = "Registra un alto nivel  de endeudamiento.") :
      console.log(this.fortaleza2 = "Registra un bajo nivel de endeudamiento.");
    //dias cuentas por cobrar
    this.diasPromXCuentasCobrar <= 90 ? console.log(this.fortaleza3 = "El periodo de recuperación de cartera se ubica en" + " " + this.diasPromXCuentasCobrar + " " + "dias  con una evolución positiva.") :
      this.diasCuentas <= 30 ? console.log(this.fortaleza3 = "El periodo de recuperación de cartera se ubica en" + " " + this.diasPromXCuentasCobrar + " " + " dias  con una evolución positiva.") :
        console.log(this.debilidad3 = "El periodo de recuperación de cartera se ubica en" + this.diasPromXCuentasCobrar + "días con una tendencia negativa ");
    //dias cuentas por pagar
    this.diasCuentasXPagar <= 90 ? console.log(this.fortaleza4 = "El pago a proveedores se encuentra en" + " " + this.diasCuentasXPagar + " " + "días, mostrando una menor duración con respecto al ejercicio anterior.") :
      this.diasCuentaPagar <= 30 ? console.log(this.fortaleza4 = "El pago a proveedores se encuentra en" + this.diasCuentasXPagar + "días, mostrando una menor duración con respecto al ejercicio anterior.") :
        console.log(this.debilidad4 = "El pago a proveedores se encuentra en" + " " + this.diasCuentasXPagar + " " + "días, mostrando una mayor duración con respecto al ejercicio anterior.");
    //Rendimiento sobre activos 
    this.rendmientosSobreActivos > this.rendmientosSobreActivos1 ? console.log(this.fortaleza5 = "Su margen de rentabilidad sobre activos es" + this.rendmientosSobreActivos + "% que muestra una evolucion en relación al año anterior.") :
      console.log(this.debilidad5 = "Su margen de rentabilidad sobre activos es" + " " + this.rendmientosSobreActivos + " " + "% que muestra una tendencia negativa en relación al año anterior.");
    //ventas
    this.ventasTotalesYear1 >= 1000000 ? console.log(this.fortaleza6 = "Registra un buen nivel de ventas.") :
      console.log(this.debilidad6 = "Registra un bajo nivel de ventas.");
    //valores ventas variacion
    this.ventasFinales >= 5 ? console.log(this.fortaleza7 = "Registra un aumento del" + " " + (this.ventasFinales1.toFixed(2)) + " " + "% en ventas entre períodos.") :
      console.log(this.debilidad7 = "Registra una disminución del" + " " + (this.ventasFinales1).toFixed(2) + " " + "% en ventas entre períodos.");
    //valores ventas utilidad variacion
    this.utiliPerdidaFinal >= 5 ? console.log(this.fortaleza8 = "Registra un aumento del" + " " + (this.utiliPerdidaFinal1).toFixed(2) + " " + "% en utilidad entre períodos.") :
      console.log(this.debilidad8 = "Registra una disminución del" + " " + (this.utiliPerdidaFinal1.toFixed(2)) + " " + "% en utilidad entre períodos.");
    //años de operacion
    this.anoFinal > 10 ? console.log(this.fortaleza9 = "Varios años de operación de la empresa. ") : console.log(this.debilidad9 = "Los años de operacion de la empresa son bajos.");
    //tiene empresas relacionadas
    this.proveedor.descriptionTrue1 != "" ? console.log(this.fortaleza10 = "Posee empresas relacionadas.") : console.log(this.debilidad10 = "No posee empresas relacionadas.");
    //pertenece a grupo economico
    this.proveedor.descriptionTrue != "" ? console.log(this.fortaleza11 = "Pertenece a un grupo económico..") : console.log(this.debilidad11 = "No pertenece a un grupo económico.");
    //infraestructura
    this.proveedor.checks4 === "Propia" ? console.log(this.fortaleza12 = "Cuenta con infraestructura propia.") : console.log(this.debilidad12 = "No cuenta con infraestructura propia.")
    //al dia con las obligaciones
    this.proveedor.checksObligacionesTributarias === "SI" && this.proveedor.checksObligacionesPatronales === "SI" && this.proveedor.checksObligacionSuper === "SI" ?
      console.log(this.fortaleza13 = "Se encuentra al día en sus obligaciones de ley.") : console.log(this.debilidad13 = "No se encuentra al día en sus obligaciones de ley.");
    //referencias a tiempo de
    this.proveedor.checkspuntualidadEntregaProveedor1 === "a tiempo" ? console.log(this.fortaleza14 = "Buenas referencias comerciales de sus proveedores.") :
      console.log(this.debilidad14 = "");
  }



  calificacionFinal() {
    this.valorActividad();
    this.valorReferencia();
    this.solidezFinanciera();
    this.segmentoCapacidad();
    this.segmentoCalidad();
    this.segmentoComercial();
    this.obligacionesLegales();

  }

  cargarMetodos() {
    this.capturaDatosYear();
    this.capturaDatosYear1();
    this.capturaDatosActivos1();
    this.capturaDatosActivos2();
    this.capturaDatosPasivos1();
    this.capturaDatosPasivos2();
    this.capturaDatosAnio();
    this.capturaDatosAnio1();
    this.calcularPatrimonioNeto1();
    this.calcularPatrimonioNeto2();
    this.graficar();
    this.totalSuma();
    this.totalSumaPasivo();
    this.comparativoUno();//nuevo met
    this.restaUtilidadYear1();
    this.metodoporcentajeUtilidades();
    this.restaUtilidadYear2();
    this.metodoporcentajeUtilidades2();
    this.variacion();
    this.comparativoDos();//nuevo met
    this.graficar();
    this.calificacionFinal();
    this.calificacionGeneral();
    this.informacionRelevant();
    this.checkCalificacionSri();
    this.checkCalificacionSuperIntendecias();
    this.leyendas();
    Swal.fire('Correcto', `Operación realizada exitosamente`, 'success');
  }
  cargarMetodos2() {
    this.restaUtilidadYear1();
    this.metodoporcentajeUtilidades();
    this.restaUtilidadYear2();
    this.metodoporcentajeUtilidades2();
    this.variacion();
    this.graficar();
    this.calificacionFinal();
    this.calificacionGeneral();
  }

  //funcion de comparacion debajo de Patrimonio Neto
  patrimonioActivo1
  patrimonioActivo2
  comparativoUno(){
    this.patrimonioActivo1=this.totalPatrimonio-this.totalBalanceActivoYear1;
    this.patrimonioActivo2=this.totalPatrimonio2-this.totalBalanceActivoYear2;
   
  }

  //funcion debajo de comparacion de resultados
  repartibleEjercicio1
  repartibleEjercicio2
  comparativoDos(){
    this.repartibleEjercicio1=(parseFloat(this.utilidadRepartible.toString().replace(/,/g,"")) - parseFloat(this.proveedor.ejercicioPatrimonio1.toString().replace(/,/g,""))).toFixed(2);
    this.repartibleEjercicio2=(parseFloat(this.utilidadRepartible2.toString().replace(/,/g,"")) - parseFloat(this.proveedor.ejercicioPatrimonio2.toString().replace(/,/g,""))).toFixed(2);
  console.log("valor1"+""+this.utilidadRepartible)
  console.log("valor1"+""+this.ejercicioPatrimonio1)

  }

  ngOnInit(): void {
    //this.currentDate = new Date();
    //this.proveedor.fechaActual=this.currentDate
    this.myFunction();
    init_plugins()
    this.getOpciones1();
    this.getOpciones3();
    const id = this.route.snapshot.paramMap.get('id');
    this.proveedorDetalle.getProveedor(id)
      .subscribe(proveedor => this.proveedor = proveedor);

  }


  //opciones generales
  getOpciones1() {
    return this.opcionesServices.getOpciones()
      .subscribe(
        opcionesGenerales => {
          console.log(opcionesGenerales);
          this.opcionesGenerales = opcionesGenerales
        }
      );
  }

  getOpciones3() {
    return this.opcionesServices3.getOpciones3()
      .subscribe(
        opcionesGenerales3 => {
          console.log(opcionesGenerales3);
          this.opcionesGenerales3 = opcionesGenerales3
        }
      );
  }


  addInforme() {
    this.submitted = true;
    this.saveData();
    Swal.fire('Correcto', `La información fue guardada con éxito`, 'success');
    //this.onResetForm();
  }
  update(): void {
    this.submitted = true;
    this.proveedor.valorCalificacionResultado = this.valorCalificacionResultado;
    this.proveedor.equivalenciatotalBase = this.equivalenciatotalBase;
    this.proveedor.equivaleciaFraseBase = this.equivaleciaFraseBase
    this.proveedorDetalle.updateProveedor(this.proveedor)
      .subscribe(result => this.message = "El proveedor fue actualizado");
    Swal.fire('Correcto', `La información fue actualizada con éxito`, 'success');
  }

  delete(): void {
    this.submitted = true;
    this.proveedorDetalle.deleteProveedor(this.proveedor._id)
      .subscribe(result => this.message = "El proveedor fue eliminado");
    Swal.fire('Proveedor eliminado', 'Eliminacion Completa!', 'error');
  
  this.atras()
  }
  atras(): void {
    this.location.back();
  }
  saveData(): void {
    console.log(this.informe);
    
    this.informeDetalle.addInforme(this.informe)
      .subscribe();
    Swal.fire('Correcto', `La información fue guardada con éxito`, 'success');
  }


  public get description() {
    return this.formularioCalificacion.controls.estado1Calificacion;
  }

  imprimir() {
    window.print();
  }

  //carga met automaticamente
  myFunction() {
    // this.presionar = setTimeout(this.cagados, 10000);
   
    setTimeout(() => {
     this.cargarMetodos()
    }, 2000);
  }

}