export class UsuarioComercial {
    constructor(
        public nombre: string,
        public email: string,
        public password: string,
        public role: string="CLIENTE_COMERCIAL_ROLE",
        public img?: string,
        public img2?: string,
        public img3?: string,
     
        public _id?: string
    ) {}
  }