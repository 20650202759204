import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'estadolista'
})
export class EstadolistaPipe implements PipeTransform {

  transform(value: String): String {
    switch (value) {
      case 'INFORME NUEVO':
        return 'EN PROCESO';
     
      default:
        return '';
    }
  }

}
