import { Component, OnInit } from '@angular/core';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import { FormProveedoresServiceService } from 'src/app/services/serviciosProveedores/form-proveedores-service.service';
import { FormProveedores } from 'src/app/models/modelosProveedores/formularioProveedores';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'app-certificados-proveedor',
  templateUrl: './certificados-proveedor.component.html',
  styleUrls: ['./certificados-proveedor.component.css']
})
export class CertificadosProveedorComponent implements OnInit {
  listaproveedores: FormProveedores[] = [];
  proveedor = new FormProveedores();
  //arrays del pdf
  gridDataToShow = [];
  gridDataToShow1 = [];
  rows = [];
  rows1 = [];


  constructor(private listaProveedoresService: FormProveedoresServiceService,  private proveedorDetalle: FormProveedoresServiceService, private route: ActivatedRoute, 
     private location: Location ) { }

  ngOnInit() {
   
    const id = this.route.snapshot.paramMap.get('id');
    this.proveedorDetalle.getProveedor(id)
      .subscribe(proveedor => this.proveedor = proveedor);
  }
 getListaProveedores() {
    return this.listaProveedoresService.getProveedores()
      .subscribe(
        listaproveedores => {
          console.log(listaproveedores);
          this.listaproveedores = listaproveedores
        
        }
      );
  };



  getPDF() {
    // div que vai tornar-se um pdf
    window.scrollTo(0, 0);
    const data = document.getElementById('contentToConvert');

    
    html2canvas(data, {
      scale: 2.5
    }).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/png');

      const pdf = new jsPDF('landscape', 'mm', 'a4'); // A4 size page of PDF

      const imgWidth = pdf.internal.pageSize.getWidth() + 0.2;
      const imgHeight = pdf.internal.pageSize.getHeight() + 0.2;

      // Posicionamento da imagem em relação ao papel
      const positionX = 0;
      const positionY = 0;

      pdf.addImage(
        contentDataURL,
        'PNG',
        positionX,
        positionY,
        imgWidth,
        imgHeight,
        null,
        'slow'
      );
      pdf.save('Certificado.pdf'); // PDF
      //setTimeout(window.close, 1000);
    });
 
 }
 atras(): void {
  this.location.back();
}

}
