import { Contact1 } from './../../models/contact1.models';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Contact } from 'src/app/models/contact.model';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ListainformesService } from 'src/app/services/listainformes.service';

@Component({
  selector: 'app-formulario-adicional2',
  templateUrl: './formulario-adicional2.component.html',
  styleUrls: ['./formulario-adicional2.component.css']
})
export class FormularioAdicional2Component implements OnInit {
  formularioUsuario: FormGroup;

  tipoInforme: String;
  razonSocial: String;
  ruc: string ;
  ciudad: String;
  telefono: String;
  email: String;
  lineaCredito: String;
  ventasAnuales: String;
  plazoPagoCredito: String;
  observaciones: String;
  tipocliente: String = "CLIENTE N";

lista = new Contact1();
submitted = false;


constructor(
  
  private fb: FormBuilder, private listainforme:ListainformesService ) {
    this.buildForm();
  }
ngOnInit() {



}


buildForm() {
  /**
   * @description Asignamos a la propiedad "formularioUsuario" los campos que se van a controlar de la vista
   */
  this.formularioUsuario = this.fb.group({
    tipoInforme: ['express', [Validators.required, Validators.minLength(3), Validators.maxLength(45)]],
    razonSocial: ['', [Validators.required, Validators.maxLength(30)]],
    ruc: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(450)]],
    ciudad: ['', [ Validators.minLength(5), Validators.maxLength(45)]],
    telefono: ['', [ Validators.minLength(10), Validators.maxLength(10)]],
    email: [''],
    lineaCredito: ['', [ Validators.minLength(3), Validators.maxLength(45)]],
    ventasAnuales:[''],
    plazoPagoCredito: ['', [ Validators.minLength(3), Validators.maxLength(45)]],
    observaciones: [''],
    tipocliente: ['']
  
});
}

newLista(): void {
  this.submitted = false;
  this.lista = new Contact1();
}

addLista() {
  this.submitted = true;
  this.save();

}
 
private save(): void {
  console.log(this.lista);
  this.listainforme.addCustomer2(this.lista)
      .subscribe(res =>{

        console.log(res)
        Swal.fire(
          'Muy Bien',
           'Datos Guardados Éxitosamente',
           'success'
         );

      });
     
        this.onResetForm();
}


onResetForm() {
  this.formularioUsuario.reset();

}
}
