import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Usuario } from 'src/app/models/usuario.model';

declare function init_plugins();
@Component({
  selector: 'app-header-analista',
  templateUrl: './header-analista.component.html',
  styleUrls: ['./header-analista.component.css']
})
export class HeaderAnalistaComponent implements OnInit {

  usuario: Usuario;
  constructor(private _usuarioService: UsuarioService) { }

  ngOnInit() {
    init_plugins();
   this.usuario= this._usuarioService.usuario
  }
cerrarSesion(){
  
  this._usuarioService.logOutAnalista()
}
}

