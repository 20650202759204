import { Component, OnInit } from '@angular/core';
import { Contact } from 'src/app/models/contact.model';
import { UsuarioService } from 'src/app/services/usuario.service';
import { ListainformesService } from 'src/app/services/listainformes.service';
import { Usuario } from 'src/app/models/usuario.model';

@Component({
  selector: 'app-informes-comerciales-realizados',
  templateUrl: './informes-comerciales-realizados.component.html',
  styleUrls: ['./informes-comerciales-realizados.component.css']
})
export class InformesComercialesRealizadosComponent implements OnInit {


  filtroTareas = "";
  desde: number = 0;

  totalRegistros: number = 1;
 
  formularios: Contact[];
  formulariosUsuario: Contact[] = [];
  usuario: Usuario;
  constructor(private _usuarioService: UsuarioService, private listainforme: ListainformesService) { }
  ngOnInit() {
    this.usuario = this._usuarioService.usuario;
    this.getFormularios();
  }

  getFormularios() {
    const usuario = JSON.parse(localStorage.getItem('usuario')) as Usuario;
    this.listainforme.getCustomers().subscribe(result => {
      
      this.formularios = result; this.asignar() });
  }
  asignar() {
    for(var e in this.formularios){
      if(this.formularios[e].usuario["_id"]==this.usuario._id && this.formularios[e].estado=="100%"&& this.formularios[e].estado2=="ACTIVO"){
        this.formulariosUsuario.push(this.formularios[e]);
      }
    }
    console.log(this.formulariosUsuario);
  }
}
