import { Usuario } from '../usuario.model';

export class FormProveedores {
  _id: string;

  analista: String;
  //campos de select de documentos solicitados
  optionGeneralRequisito :String;
  optionCartaRequisito:String;
  optionRucRequisito:String;
  optionCedulaRequisito:String;
  optionCertificadoSriRequisito:String;
  optionPlanillaAporteIessRequisito:String;
  optionSuperintendenciaRequisito:String;
  optionImpPredialRequisito:String;
  optionPatenteRequisito:String;
  optionFacClienteRequisito:String;
  optionMarcasEspRequisito:String;
  optionCertificadoSisRequisito:String;
  optionCertificadoBancarioRequisito:String;
  optionCertificadoComercialRequisito:String;
  optionCertificadoProveedorRequisito:String;
  optionCertificadoCentralRiesgosRequisito:String;
  optionEstadoFinancieroRequisito:String;
  //campos formularios
  razonSocial:string;
  nombreComercial:String;
  direccion:String;
  provincia:String;
  ruc:String;
  ciudad:String;
  telefono:String;
  celular:String;
  paginaWeb:String;
  correo:String;
  referencia:String;
  cargo:String;
  correoContacto:String;
  checks:String;
  checks1:String;
  descriptionTrue:String;
  descriptionTrue1:String;
  nombreDirectivo:String;
  cargoDirectivo:String;
  nombreParticipacion:String;
  porcentajeParticipacion:Number;
  nombreProveedor:String;
  numeroProveedor:Number;
  nombreReferenciaComercial:String;
  numeroReferenciaComercial:String;
  nombreDirectivo2: String;
  cargoDirectivo2: String;
  nombreDirectivo3: String;
  cargoDirectivo3: String;
  nombreDirectivo4: String;
  cargoDirectivo4: String;
  nombreDirectivo5: String;
  cargoDirectivo5: String;
  nombreParticipacion2: String;
  porcentajeParticipacion2: Number;
  nombreParticipacion3: String;
  porcentajeParticipacion3: Number;
  nombreParticipacion4: String;
  porcentajeParticipacion4: Number;
  nombreParticipacion5: String;
  porcentajeParticipacion5: Number;
  nombreProveedor2: String;
  numeroProveedor2: Number;
  nombreReferenciaComercial2:String;
  numeroReferenciaComercial2:String;
  urlPDF:String;
  valorCalificacionResultado: String;
  equivalenciatotalBase:String; 
  equivaleciaFraseBase: String;
  fechainicio : string;
  fechafin: string;
 //campos completados de formulario proveedor 
  checks2: String;
  checks3: String;
  checks4: String;
  checks5: String;
  checks6: String;
  checks7: String;
  checks8: String;
  checks9: String;
  checks10: String;
  checks11: String;
  checks12: String;
  checks13: String;
  checks14: String;
  checks15: String;
  checks16: String;
  checks17: String;
  checks18: String;
  checks19: String;
  checks20: String;
  checks21: String;
  checks22: String;
  checks23: String;
  checks24: String;
  checks25: String;
  checks26: String;
  checks27: String;
  checks28: String;
  checks29: String;
  ventas17: Number;
  ventas16: Number;
  activos17: Number;
  activos16: Number;
  pasivos17: Number;
  pasivos16: Number;
  patrimonio17: Number;
  patrimonio16: Number;
  utilidad17: Number;
  utilidad16: Number;
  controlNumero: Number;
  controlNumoeroPesonal: Number;
  Chck4DescripRentada: String;
  check5Cuantas: String;
  checks8DescriMantenimiento: String;
  checks10SiDescripcion: String;
  check11SiDescripcion: String;
  check12SiDescripcion: String;
  checks13SiDescripcion: String;
  checks14SiDescripcion: String;
  checks15SiDescripcion: String;
  checks16SiDescripcion: String;
  checks17SiDescripcionEmpr: String;
  checks18SiDescripcion: String;
  checks19DescripcionSi: String;
  checks20OtrosDescripcion: String;
  check21SiPorcentaje: String;
  checks22SiPaises: String;
  checks23SIDescripcion: String;
  checks24SiDescripcion: String;
  check25SiDescripcion: String;
  checks26SiDescripcion: String;
  checks27SiDescripcion: String;
  checks28SiDescripcion: String;
  checks29SiDescripcion: String;
  checks2Descripcion: String;
  selects: String;
  totalUnidadesUno: string;
  totalUnidadesDos: string;
  checks27U:String;

  checks20Uno: String;
  checks20Dos: String;
  checks20Tres: String;
  checks20Cuatro: String;
  checks20Cinco: String;
  checks20Seis: String;
  checks2Uno: String;
  checks2Dos: String;
  checks2Tres: String;
  checks2Cuatro: String;
  checks12Seguridad:String;
  //siguientes campos calificación
  formularioCalificacion: String;
  razonSocialCalificacion: String
  nombreComercialCalificacion: String;
  rucCalificacion: String;
  ubicacionCalificacion: String;
  direccionCalificacion: String;
  telfCallificacion: String;
  pagWebCalificacion: String;
  emailCalificacion: String;
  
  personContactlCalificacion: String;
  personCargolCalificacion: String;
  personEmaillCalificacion: String;
  empresaRelacionlCalificacion: String;
  grupoEcolCalificacion: String;
 
  //nuevos
  actividadFinancieraPuntaje: String;
  actividadFinancieraEquivalencia: String;
  capacidadOperativaPuntaje: String;
  capacidadOperativaEquivalencia: String;
  gestionCalidadPuntaje: String;
  gestionCalidadEquivalencia: String;
  comercialPuntaje: String;
  comercialEquivalencia: String;
  cumplimientoRegularesPuntaje: String;
  cumplimientoRegularesEquivalencia: String;
  
  
  
  checksCalidadProductoR1: String;
  checksCalidadProductoDos:String;
  checksObligacionesTributarias: String;
  checksObligacionesPatronales: String;
  checksPagoImpuestoPredial: String;
  checksObligacionSuper:String;
  checksTipoSeguros: String;
  checksPatenteMunicipalActualizado: String;
  checksATiempoPuntualidad: String;
  checksATiempoPuntualidad2: String;
  checkspuntualidadEntregaUno: String;
  checkspuntualidadEntregaDos: String;
  //nuevo
  directivosNombreUno: String;
  directivosCargoUno: String;
  directivosCargoDos: String;
  directivosNombreDos: String;
  directivosNombreTres: String;
  directivosCargoTres: String;
  directivosNombreCuatro: String;
  directivosCargoCuatro: String;
  directivosNombreCinco: String;
  directivosCargoCinco: String;
  participacionAccionaria: String;
  participacionAccionariaPorcentaje: String;
  participacionAccionariaDos: String;
  participacionAccionariaPorcentajeDos: String;
  nombreReferenciaUno: String;
  nombreReferenciaDos: String;
  direccionReferenciaUno: String;
  direccionReferenciaDos: String;
  telefonoReferenciaUno: String;
  telefonoReferenciaDos: String;
  contactReferenciaUno: String;
  proveedroDesdeReferenciaUno: String;
  proveedroDesdeReferenciaDos: String;
  productoServicioReferenciaUno: String;
  productoServicioReferenciaDos: String;
  contactUno: String;
  refeDosContacto: String;
  //Siguientes
  checksInfraestructura: String;
  checksSucursales: String;
  checksContratacion: String;
  checksMaquinaria: String;
  checksMaquinariaPlan: String;
  checksMedioDistribucion: String;
  checksMedicionCapacidad: String;
  checksControlInventario: String;
  participacionAccionariaTres: String;
  participacionAccionariaPorcentajeTres: String;
  participacionAccionariaCuarto: String;
  participacionAccionariaPorcentajeCuatro: String;
  participacionAccionariaCinco: String;
  participacionAccionariaPorcentajeCinco: String;
  DescripcionInfraestructura: String;
  DescripcionSucursales: String;
  
  refeUnoContacto: String;
  refeUnoContact: String;
  numeroReferenciaComercialDos: String;
  refeUnoContactoUno: String;
  ContactoUno: String;
  contctDos:String;
  salProme:String;
  salProme1:String;
  banc1:String;
  clienteDesdeD1:String;
  protest:String;
  protesto2:String;
  comentario:String;
  coment2:String;
  nombreReferenciaProveedor:String;
  nombreReferenciaProveedor2:String;
  direccionProveedor1:String;
  direccionProveedor2:String;
  numeroProveedor1:String;
  numeroRefeProveedor2:String;
  ContactoUProveedor1:String;
  contcProveedor2:String;
  lineaCredito:String;
  lineaCreditoDias:String;
  lineaCreditoDos:String;
  lineaCreditoDosDias:String;
  productoServicioProveedorUno:String;
  productoServicioProveedorDos:String;
  clientedesdeProveedorUno:String;
  clientedesdeProveedorDos:String;
  compraMensualProveedorUno:String;
  compraMensualProveedorDos:String;
  checkspuntualidadEntregaProveedor1:String;
  checkspuntualidadEntregaProveedor2:String;
  numeroRefeProveedorUno:String;
  listadoProceso:String;
  fortalezacamp1:String;
  fortalezacamp2:String;
  fortalezacamp3:String;
  fortalezacamp4:String;
  fortalezacamp5:String;
  fortalezacamp6:String;
  fortalezacamp7:String;
  fortalezacamp8:String;
  fortalezacamp9:String;
  fortalezacamp10:String;
  fortalezacamp11:String;
  fortalezacamp12:String;
  checks9SiDescripcion:String;



  bancoUno: String="";
  clienteDesdeUno: String="";
  protestoUno: String="";
  comentarioUno: String="";
  bancoDos: String="";
  saldoPromedioDos: String="";
  protestoDos: String="";
  comentarioDos: String="";
  //
  totalPuntaje: String="";
  totalEquivalencia: String="";
  descripcionCalificacion: String="";
  institucion: String="";
  fechasCorte: String="";
  fechasCorte2: String="";
  tipoRegistro: String="";
  tipoCredito: String="";
  totalValancer: String="";
  dem: String="";
  cart: String="";
  saldoDeuda: String="";
  totalVencido: String="";
  institucion2: String="";
  tipoRegistro2: String="";
  tipoCredito2: String="";
  totalValancer2: String="";
  totalVencido2: String="";
  dem2: String="";
  cart2: String="";
  saldoDeuda2: String="";
  //Bancario
  bancarioUno:String;
  ValorCalificacion:Number=0;
 


  
  //informes:String;
  //campos informe final
    tipoProveedorCalificacion: String="";
    formaLegalCalificacion: String="";
    capitalSPCalificacion: Number=0;
    estadoCalificacion: String="";
    codActivCalificacion:String="";
    //iniActiCalificacion: String="";
    iniActiCalificacion: Number=0;
    objSocialCalificacion: String="";
    actPrinCalificacion: String="";
    representanteLegalCalificacion: String="";
    representLegalCedCalificacion: String="";
    impuestoRentaCalificacion: Number=0;
    impuestoRentaCalificacion1: Number=0;
    impuestoDivisasCalificacion: Number=0;
    impuestoDivisasCalificacion1: Number=0;
     /*----------  Campos Informacion Financiera  ----------------*/
     
  //Activos Corriente Parte 1
  cajaCorrienteYear1: number=0;
  cajaCorrienteYear2Porcentaje:number=0;
  inversionCorrienteYear1: number=0;
  localesRelacionadosCorrienteYear1: number=0;
  localesNoRelacionadosCorrienteYear1: number=0;
  cuentasRelacionadosCorrienteYear1: number=0;
  cuentasNoRelacionadosCorrienteYear1: number=0;
  provisionCorrienteYear1: number=0;
  creditosCorrienteYear1: number=0;
  inventariosCorrienteYear1: Number=0;
  pagadosCorrienteYear1: number=0;
  otrosCorrienteYear1: number=0;
  /*Suma Total de Activo Corriente Year 1*/
  year: number=0 ;

  //porcentaje Activo Corriente Year 1 
  porcentajeCorrienteYear1: Array<number>=[] ;

  //Activos Corriente Parte 2
  cajaCorrienteYear2: number=0;
  inversionCorrienteYear2: number=0;
  localesRelacionadosCorrienteYear2: number=0;
  localesNoRelacionadosCorrienteYear2: number=0;
  cuentasRelacionadosCorrienteYear2: number=0;
  cuentasNoRelacionadosCorrienteYear2: number=0;
  provisionCorrienteYear2: number=0;
  creditosCorrienteYear2: number=0;
  inventariosCorrienteYear2: number=0;
  pagadosCorrienteYear2: number=0;
  otrosCorrienteYear2: number=0;
  /*Suma Total de Activo Corriente Year 2*/
  year1: number=0 ;

  //porcentaje Activo Corriente Year 2 
  porcentajeCorrienteYear2:Array<number>=[] ;


  //Activos Diferido Parte 1
  totalActivoFijo1: number=0;
  totalActivoDiferido1: number=0;
  inversionNoCorriente1: number=0;
  otrasInversiones1: number=0;
  cuentasNoCorrientes1: number=0;
  cuentasNoCorrientesNoRelacionadas1: number=0;
  otrosNoCorriente1: number=0;
  otrosCorrienteNoRelacionados1: number=0;
  provisionCuentas1: number=0;
  otrosActivosYear1: number=0;
  /*Suma Total de Activo Diferido Year 1*/
  activo1: number=0 ;

  //porcentaje Activo Diferido Year 1 
  porcentajeDiferidoYear1:Array<number>=[] ;

  //Activos Diferido Parte 2
  totalActivoFijo2: number=0;
  totalActivoDiferido2: number=0;
  inversionNoCorriente2: number=0;
  otrasInversiones2: number=0;
  cuentasNoCorrientes2: number=0;
  cuentasNoCorrientesNoRelacionadas2: number=0;
  otrosNoCorriente2: number=0;
  otrosCorrienteNoRelacionados2: number=0;
  provisionCuentas2: number=0;
  otrosActivosYear2: number=0
  /*Suma Total de Activo Diferido Year 2*/
  activo2: number=0;

  //porcentaje Activo Diferido Year 2 
  porcentajeDiferidoYear2:Array<number>=[];

  /*Suma Total Year 1 & Year 2*/
  totalBalanceActivoYear1: number=0;
  totalBalanceActivoYear2: number=0 ;

  /*---------            Pasivo y Patrimonio ---------------*/

  /*campos Pasivos*/
  activoCuentasPasivo1: number=0;
  pasivoCuentasNoRelacionados1: number=0;
  pasvioObligaciones1: number=0;
  pasivoPrestamosAccionistas1: Number=0;
  pasivosCuentasCorrrientesRelacionados1: number=0;
  pasivoCuentasNoRelacionadosPorPagar1: number=0;
  pasivoImpuestoRentaPorPagar1: number=0;
  pasivoParticipacionTrabajadores1: number=0;
  pasivosOtrosPasivosCrrientes1: number=0;
  porPagarProveedoresRelacionados: number=0;
  pasivos1: number=0 ;
  porcentajeCorrientePasivos1: Array<number>=[] ;
  porcentajeAnioTotal1: number=0 ;

  activoCuentasPasivo2: number=0;
  pasivoCuentasNoRelacionados2: number=0;
  pasvioObligaciones2: number=0;
  pasivoPrestamosAccionistas2: number=0;
  pasivosCuentasCorrrientesRelacionados2: number=0;
  pasivoCuentasNoRelacionadosPorPagar2: Number=0;
  pasivoImpuestoRentaPorPagar2: number=0;
  pasivoParticipacionTrabajadores2: number=0;
  pasivosOtrosPasivosCrrientes2: number=0;
  pasivos2: number=0 ;
  porcentajeCorrientePasivos2: Array<number>=[];
  porcentajePasivosTotal2: number=0 ;
  porcentajeAnioTotal2: number=0 ;



  pasivoCuentaPorPagarProveedoresAnio: number=0;
  pasivoCuentasNoCorrientesNoRelacionalAnio: number=0;
  pasivoObligacionesInstitucionesAnio: number=0;
  pasivoPrestamosAccionistasAnio: number=0;
  pasivoOtrasCuentasPorPagarAnio: number=0;
  pasivoOtrasCuentasPorPagarNoCorrientesAnio: number=0;
  pasivosOtroPasivoNoCorrienteAnio: number=0;
  anio: number=0 ;
  porcentajeDiferidoPasivos1: Array<number>=[] ;
  porcentajeDiferidosTotal1: number=0 ;

  pasivoCuentaPorPagarProveedoresAnio1: number=0;
  pasivoCuentasNoCorrientesNoRelacionalAnio1: number=0;
  pasivoObligacionesInstitucionesAnio1: number=0;
  pasivoPrestamosAccionistasAnio1: number=0;
  pasivoOtrasCuentasPorPagarAnio1: number=0;
  pasivoOtrasCuentasPorPagarNoCorrientesAnio1: number=0;
  pasivosOtroPasivoNoCorrienteAnio1: number=0;
  anio1: number=0 ;
  porcentajeDiferidoPasivos2: Array<number>=[] ;
  porcentajeDiferidosTotal2: number=0 ;

  /* Patrimonio Neto */

  //Parte 1
  capitalPatrimonio1: number=0 ;
  integralesPatrimonio1: number=0 ;
  aportesPatrimonio1: number=0 ;
  reservasPatrimonio1: number =0;
  patrimonioNIIF1: number=0 ;
  utilidadPatrimonio1: number=0 ;
  acumuladaPatrimonio1: number =0;
  ejercicioPatrimonio1: number=0 ;
  patrimonio1: number=0 ;
  porcentajeDiferidoPatrimonio1: Array<number> =[];

  //Parte2
  capitalPatrimonio2: number=0 ;
  integralesPatrimonio2: number=0 ;
  aportesPatrimonio2: number=0 ;
  reservasPatrimonio2: number =0;
  patrimonioNIIF2: number=0 ;
  utilidadPatrimonio2: number=0 ;
  acumuladaPatrimonio2: number =0;
  ejercicioPatrimonio2: number=0 ;
  patrimonio2: number=0 ;
  porcentajeDiferidoPatrimonio2: Array<number>=[] ;

  //TOTAL SUMA PORCENTAJES
  totalBalancePasivos1: number=0 ;
  totalBalancePasivos2: number=0 ;
  totalPorcentajePasivos1: number=0 ;
  totalPorcentajePasivos2: number=0 ;
  totalPatrimonio: number=0 ;
  totalPatrimonio2: number=0 ;
  porcentajePatrimonioTotal1: number=0 ;
  porcentajePatrimonioTotal2: number=0 ;

  //Total Patrimonio y Pasivo
  totalPasivoYPatrimonio: number =0;
  totalPasivoYPatrimonio2: number =0;

  
  /*-----------     ESTADO DE RESULTADO (USD) UNIDADES           -----------*/

  //Utilidad Bruta Year 1
  ventasTotalesYear1: number =0;
  costoVenta: number =0;
  utilidadBruta: number =0;

  //Utilidad Operacional Year 1
  gatosAdministrativos: number =0;
  utilidadOpercional: number =0;

  //Utilidad antes de Impuestos Year1
  ingresoNoOperacional: number =0;
  gastosNoOperacionales: number =0;
  utilidadAntesImpuestos: number =0;

  //Utilidad Tributable Year1
  participacionTrabajador: number =0;
  utilidadTributable: number =0;

  //Utilidad Repartible Year 1
  impuestoRenta: number =0;
  utilidadRepartible: number =0;

  //porcentaje Year1
  porcentajeUtilidadesYear1: Array<number> ;

  //utilidades Parte 2

  //Utilidad Bruta Year 2
  ventasTotalesYear2: number =0;
  costoVenta2: number =0;
  utilidadBruta2: number =0;

  //Utilidad Operacional Year 2
  gatosAdministrativos2: number =0;
  utilidadOpercional2: number =0;

  //Utilidad antes de Impuestos Year2
  ingresoNoOperacional2: number =0;
  gastosNoOperacionales2: number =0;
  utilidadAntesImpuestos2: number =0;

  //Utilidad Tributable Year2
  participacionTrabajador2: number =0;
  utilidadTributable2: number =0;

  //Utilidad Repartible Year 2
  impuestoRenta2: number =0;
  utilidadRepartible2: number =0;
  //Tarea judicial
  procesoJudicialInforme: string="";
  textoSuper: string="";
  listadoProceso2:string="";
  informacionRelevante:string="";
  //campo Lista negra
  checksListaClinton:string="";

  //Porcentaje Year2
  porcentajeUtilidadesYear2: Array<number> =[];
  img?: string;
  //usuario: string;
  usuario: Usuario;
  solicitud?:string

  estado2: string;

  estado: String;
  descripcion? : string;
  renta: Text;
  salidaDivisas: Text;
  documento: string;
  estadoFormulario:string="EN PROCESO";
  usuario2: string;
  responsable: String;
  fechaAsignada: String;
  fechaLimite: String;
}
