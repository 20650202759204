import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as faker from 'faker';
import { Contact } from '../../../models/contact.model';
import { Usuario } from 'src/app/models/usuario.model';
import { ExcelService } from '../../../services/excel.service';
import { ListainformesService } from 'src/app/services/listainformes.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

import { Subject } from 'rxjs';

@Component({
  selector: 'app-inicio-comerciales',
  templateUrl: './inicio-comerciales.component.html',
  styleUrls: ['./inicio-comerciales.component.css']
})
export class InicioComercialesComponent implements OnInit {
  usuario : Usuario;
  importContacts: Contact[] = [];
  exportContacts: Contact[] = [];
//============================================
spinnerEnabled = false;
keys: string[];
dataSheet = new Subject();
@ViewChild('inputFile',{static:false}) inputFile: ElementRef;
isExcelFile: boolean;
//=============================================
  
  //metodos para enviar la base
  lista = new Contact();
  submitted = false;

  constructor(private excelSrv: ExcelService, private listainforme:ListainformesService,public _usuarioServices: UsuarioService) { 
  }

  ngOnInit() {
    this.usuario = this._usuarioServices.usuario;
    
    for (let index = 0; index <10; index++) {
      const contact = new Contact();
      contact.tipoInforme = faker.name.findName();
      contact.razonSocial = faker.name.findName();
      contact.ruc = faker.address.zipCode();
      contact.ciudad = faker.address.country();
      contact.telefono = faker.phone.phoneNumber();
      contact.email = faker.internet.email();
      contact.lineaCredito = faker.name.findName();
      contact.ventasAnuales = faker.finance.mask();
      contact.plazoPagoCredito = faker.finance.mask();
      contact.observaciones = faker.hacker.phrase();
      // contact.contacto = faker.phone.phoneNumber();
      // contact.fecha = faker.date.past();
       contact.tipocliente = 'CLIENTE SR'
      //contact.nombreSolicitante = faker.name.findName();
      this.exportContacts.push(contact);
      console.log(contact);
      return (contact)
    
  }
  }
  onFileChange(evt: any) {
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length > 1) {
      this.inputFile.nativeElement.value = '';
    }
    if (target.files.length !== 1) throw new Error('No se pueden utilizar varios archivos');

    

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
    const bstr: string = e.target.result;
  
      
      const data = <any[]>this.excelSrv.importFromFile(bstr);
      const header: string[] = Object.getOwnPropertyNames(new Contact());
      const importedData = data.slice(1,-1);
      this.importContacts = importedData.map(arr => {
        var obj = {};
       
        for (let i = 0; i < header.length; i++) {
          const k = header[i];
          obj[k] = arr[i];
        }
        obj["usuario"] = this.usuario
        console.log(obj);
      
        this.listainforme.addCustomer(<Contact>obj)

        .subscribe();
        
        return <Contact>obj;        
      })
      
    
    };
    
    reader.readAsBinaryString(target.files[0]);
    Swal.fire('Correcto', `La información fue guardada con éxito`, 'success');
  }



//========================PRUEBA====================================================================
/* onChange(evt) {
  let data, header;
  const target: DataTransfer = <DataTransfer>(evt.target);
  this.isExcelFile = !!target.files[0].name.match(/(.xls|.xlsx)/);
  if (target.files.length > 1) {
    this.inputFile.nativeElement.value = '';
  }
  if (this.isExcelFile) {
    this.spinnerEnabled = true;
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook 
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet 
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data 
      data = XLSX.utils.sheet_to_json(ws);
    };

    reader.readAsBinaryString(target.files[0]);

    reader.onloadend = (e) => {
      this.spinnerEnabled = false;
      this.keys = Object.keys(data[0]);
      this.dataSheet.next(data)
    }
  } else {
    this.inputFile.nativeElement.value = '';
  }
}

removeData() {
  this.inputFile.nativeElement.value = '';
  this.dataSheet.next(null);
  this.keys = null;
}

*/

  
}
   