import { Tarea2 } from './../../models/tarea2.model';
import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Usuario } from 'src/app/models/usuario.model';
import { TareaService } from 'src/app/services/tareas.service';
import { FormProveedores } from 'src/app/models/modelosProveedores/formularioProveedores';
import { Tarea2Service } from 'src/app/services/serviciosProveedores/tareas2.service';
import { FormProveedoresServiceService } from 'src/app/services/serviciosProveedores/form-proveedores-service.service';
declare function init_plugins();

@Component({
  selector: 'app-calificaciones-realizadas',
  templateUrl: './calificaciones-realizadas.component.html',
  styleUrls: ['./calificaciones-realizadas.component.css']
})
export class CalificacionesRealizadasComponent implements OnInit {

 
  //Usuario logeado
  usuario: Usuario;

  //Formularios por hacer
  lista: FormProveedores[] = [];

  //Tareas
  pendientes: Tarea2[] = [];

    //paginacion
    desde1: number = 0;
    totalRegistros1: number = 1;

  constructor(public _usuarioService: UsuarioService, private _tareaService: Tarea2Service, private listainforme:FormProveedoresServiceService) { }

  ngOnInit() {
    this.usuario = this._usuarioService.usuario;
    this.getTareas();
    this.myFunction()
  }

  //carga met automaticamente
  myFunction() {
    // this.presionar = setTimeout(this.cagados, 10000);
   
    setTimeout(() => {
     this.getTareas()
    }, 2000);
  }

  getCustomers1() {
    return this.listainforme.getCustomers()
      .subscribe(
        lista => {
        
          for (var form in lista) {
            for (var tarea in this.pendientes) {
              if (this.pendientes[tarea]._idFormulario == lista[form]._id) {
                lista[form].fechaAsignada = this.pendientes[tarea].fechaAsingada
                lista[form].fechaLimite = this.pendientes[tarea].fechaLimite
                lista[form].estado = this.pendientes[tarea].estado;
                this.listainforme.updateCustomer(lista[form]).subscribe(result=>{console.log(result)});
                this.lista.push(lista[form]);
              }
            }
          }
        }
      );
  }
  getTareas() {
    this._tareaService.getTareas().subscribe(tareas => {
  
      for (var tarea in tareas) {
        if (tareas[tarea]._idResponsable == this.usuario._id) {
          this.pendientes.push(tareas[tarea]);
        }
      }
      this.getCustomers1();
    });
  }

  

}
