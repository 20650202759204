import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';


@Pipe({
  name: 'informe'
})
export class InformePipe implements PipeTransform {
  transform(img3: any, tipo: string = 'usuario'): any {

    let url = environment.URL_SERVICIOS + '/img';

    if (!img3) {

      return url + '/Informes/xxx';
    }

    if (img3.indexOf('https') >= 0) {
      return img3;
    }

    switch (tipo) {

      case 'usuario':
        url += '/Informes/' + img3;

        break;
       
        default:
            console.log('tipo de Archivo no existe');
            url + '/Informes/xxx';

    }



    return url;
  }

}
