import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';
import { TareaService } from 'src/app/services/tareas.service';
import { Tarea } from 'src/app/models/tarea.model';
import { Contact } from 'src/app/models/contact.model';
import { ListainformesService } from 'src/app/services/listainformes.service';

@Component({
  selector: 'app-informes-realizados',
  templateUrl: './informes-realizados.component.html',
  styleUrls: ['./informes-realizados.component.css']
})
export class InformesRealizadosComponent implements OnInit {

  tareas : Tarea[];
  formularios : Contact[];

  constructor(public _usuarioService: UsuarioService,
    private _tareaService: TareaService, 
    private listainforme: ListainformesService,
    ) { }

  ngOnInit() {
    this.traerTareas();
  }

  traerTareas() {
    this._tareaService.getTareas().subscribe(response => this.tareas = response);
    console.log(this.tareas);
  }
  traerLista(){
    this.listainforme.getCustomers().subscribe(response => this.formularios = response);
  }
  

}
