export class Usuario {
  constructor(
      public nombre: string,
      public email: string,
      public password: string,
    
      public role?: string,
      public google?: boolean,
      public _id?: string,
      public img?: string,
      public img2?: string,
      public img3?: string,
      
  ) {}
}
