import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Usuario } from 'src/app/models/usuario.model';



//subir archivos importaciones drog
import { DragdropService } from "../../services/dragdrop.service";
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';





@Component({
  selector: 'app-comprobante-pago',
  templateUrl: './comprobante-pago.component.html',
  styleUrls: ['./comprobante-pago.component.css']
})
export class ComprobantePagoComponent implements OnInit {
  usuario : Usuario;
  imagenSubir1: File;
  imagenTemporal: any;
  imagenTemporal1: any;
  imagenSubir2: File;

  formularioSolicitud: FormGroup;
  img3:string;
  //variables drop
  fileArr = [];
  imgArr = [];
  fileObj = [];
  //form: FormGroup;
  msg: string;
  progress: number = 0;
  public url: string;

    constructor(public _usuarioServices: UsuarioService, public fb: FormBuilder,  private sanitizer: DomSanitizer,  
      public dragdropService: DragdropService) {
     this.usuario = this._usuarioServices.usuario;
    this.url = environment.URL_SERVICIOS;
     this.formularioSolicitud = this.fb.group({
      avatar: [null],
     })
     }
  
    ngOnInit() {
    }
    
  
    guardar(usuario:Usuario){
     this.usuario.img3 = this.img3;
      this.usuario.nombre = usuario.nombre;
      this.usuario.email = usuario.email;
      
      //funcion de actulizar 
      this._usuarioServices.actualizarUsuario(this.usuario)
      .subscribe( resp =>{
        console.log(resp);
  
        Swal.fire('Usuario', `Actualizado con exito`, 'success');
      })
   }
  
   seleccionaImagen( archivo: File){
     
    if(!archivo){
      this.imagenSubir1 = null;
     return;
    }
  
    this.imagenSubir1 = archivo;
   console.log(event);
  
   let reader = new FileReader();
   let urlImagenTemp = reader.readAsDataURL( archivo );
  
   reader.onloadend = () =>{
    this.imagenTemporal = reader.result;
  
   } 
   }


   seleccionaImagen1( archivo: File){
     
    if(!archivo){
      this.imagenSubir2 = null;
     return;
    }
  
    this.imagenSubir2 = archivo;
   console.log(event);
  
   let reader = new FileReader();
   let urlImagenTemp = reader.readAsDataURL( archivo );
  
   reader.onloadend = () =>{
    this.imagenTemporal1 = reader.result;
  
   } 
   }
  
  
   cambiarImagen(){
  
    this._usuarioServices.cambiarImagen2(this.imagenSubir1, this.usuario._id);
    console.log(this.imagenSubir1)
   }

   cambiarImagen1(){
  
    this._usuarioServices.cambiarImagen3(this.imagenSubir2, this.usuario._id);
    console.log(this.imagenSubir2)
   }


//nueva importacion de archivos drop
 upload(e) {
  const fileListAsArray = Array.from(e);
  fileListAsArray.forEach((item, i) => {
    const file = (e as HTMLInputElement);
    const url = URL.createObjectURL(file[i]);
    this.imgArr.push(url);
    this.fileArr.push({ item, url: url });
  })

  this.fileArr.forEach((item) => {
    this.fileObj.push(item.item)
  })

  // Set files form control
  this.formularioSolicitud.patchValue({
    avatar: this.fileObj
  })

  this.formularioSolicitud.get('avatar').updateValueAndValidity()

  // Upload to server
  this.dragdropService.addFiles(this.formularioSolicitud.value.avatar)
    .subscribe((event: HttpEvent<any>) => {
   //   console.log(event.body.userCreate);
      switch (event.type) {
        case HttpEventType.Sent:
          console.log('Request has been made!');
          break;
        case HttpEventType.ResponseHeader:
          console.log('Response header has been received!');
          break;
        case HttpEventType.UploadProgress:
          this.progress = Math.round(event.loaded / event.total * 100);
          console.log(`Uploaded! ${this.progress}%`);
          break;
        case HttpEventType.Response:
          console.log('File uploaded successfully!', event.body);
           this.img3= event.body.userCreated.avatar[0];
          setTimeout(() => {
            this.progress = 0;
            this.fileArr = [];
            this.fileObj = [];
            this.msg = "Archivo subido corretamente!"
            
          }, 3000);
      }
    })
}

// Clean Url for showing image preview
sanitize(url: string) {
  return this.sanitizer.bypassSecurityTrustUrl(url);
}

 




   
  
  }