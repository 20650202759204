import { Component, OnInit } from '@angular/core';
import * as faker from 'faker';
import { Contact2 } from 'src/app/models/contact2.models';
import { Usuario } from 'src/app/models/usuario.model';
import { ListaproveedorService } from 'src/app/services/listaproveedor.service';
import { ExcelService } from 'src/app/services/excel.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import Swal from 'sweetalert2';


declare function init_plugins()
@Component({
  selector: 'app-estado-proveedores-admin',
  templateUrl: './estado-proveedores-admin.component.html',
  styleUrls: ['./estado-proveedores-admin.component.css']
})
export class EstadoProveedoresAdminComponent implements OnInit {
exportContacts: Contact2[] = [];

lista: Contact2[] = [];
usuario: Usuario[];
desde: number = 0;


  customer = new Contact2() ;
  submitted = false;

  totalRegistros: number = 0;
  cargando: boolean = true;

  constructor(private listainforme:ListaproveedorService ,private excelSrv: ExcelService,
    private users: UsuarioService) { 
      init_plugins();

    }
    filtroInformes = "";
  ngOnInit() {
    init_plugins();

     // this.getTareas();
     this.listainforme.notificacion.subscribe( 
      resp =>
      this.getCustomers1()
    
     ); 

   // this.cargarlista();
    this.getCustomers1();
    for (let index = 0; index < 10; index++) {
      const contact = new Contact2();
     
      contact.email = faker.internet.email();
      contact.personaContacto = faker.name.findName();
      contact.telefono = faker.phone.phoneNumber();
      contact.tipo = faker.hacker.phrase();
      contact.estado = faker.name.findName();
      contact.fecha = faker.date.past();
    //  contact.ruc = faker.address.zipCode();
    //  contact.ciudad = faker.address.country();
     
    
    //  contact.lineaCredito = faker.name.findName();
    //  contact.ventasAnuales = faker.finance.mask();
    //  contact.plazoPagoCredito = faker.finance.mask();
    
      // contact.contacto = faker.phone.phoneNumber();
    
     //  contact.tipocliente = 'CLIENTE SERVIRATING'
      //contact.nombreSolicitante = faker.name.findName();
      this.exportContacts.push(contact);
      console.log(contact);
      return (contact)
    }
   // this.cargarUsuario();
  }


  getCustomers1() {
    return this.listainforme.cargarInformes2()
      .subscribe(
        lista => {
        
          console.log(lista);
          this.lista = lista;
          this.cargando = false;


        }
      );
  };

/*  cargarUsuario(){
   return this.users.cargarUsuarios().subscribe(
usuario=>{
  console.log(usuario)
this.usuario = usuario
}

   )

  }



  buscarUsuario(termino: string) {

    if (termino.length <= 0) {
      this.getCustomers1();
      this.cargarUsuario()
      return;
    }



    this.listainforme.buscarinforme(termino)
      .subscribe((lista: Contact[]) => {

        this.lista = lista;

      });

  }

*/

exportData(tableId: string) {
  this.excelSrv.exportToFile("contacts", tableId);
    }


    ActulizarEstado( lista: Contact2 ) {

      this.listainforme.updateCustomer( lista )
              .subscribe()
              Swal.fire('Estado', `Actulizado exitosamente`, 'success');
     
    }

  /*  cargarlista() {

      this.cargando = true;
  
      this.listainforme.cargarinformes1( this.desde )
      .subscribe((resp: any) => {
        for (var user in resp.lista) {
          //Habilitar en caso de querer cargar todos los roles de usuario en la asignación de tareas.
          if (resp.lista[user].estado) {
            this.lista.push(resp.lista[user]);
          }
        }
        this.totalRegistros = resp.total;
     
        this.cargando = false;
      });
              
  
    } */


    delete(_id) {
      this.submitted = true;
      this.listainforme.deleteCustomer(_id)
          .subscribe(result => { 
            console.log(result)
            
          this.listainforme.notificacion.emit( result );
          Swal.fire(
            'Documento!',
            'ELIMINADO CON EXITO!',
            'success'
          )
          });
    }
       



}
