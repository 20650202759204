import { Usuario } from './usuario.model';

export class Contact2 {
    _id: string;
    razonSocial : String= "";
    ruc : String ="";
    personaContacto: String = "";
    telefono: String = "";
    email: String = "";
    ciudad: String = "";
    nacionalInternacional :String = "";
  
   observaciones: String ="";
   
   
    usuario: Usuario;
    estado: String;
    tipo: String;
    fecha: String = new Date().toDateString();
    responsable: String;
    fechaAsignada: String;
    fechaLimite: String;




    
}