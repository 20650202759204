import { Component, OnInit } from '@angular/core';
import { UsuarioComercial } from 'src/app/models/usuarioComercial';
import { UsuarioComercialService } from 'src/app/services/usuario-comercial.service';
import { Router } from '@angular/router';
import { Usuario } from 'src/app/models/usuario.model';


declare function init_plugins();
@Component({
  selector: 'app-header-compras',
  templateUrl: './header-compras.component.html',
  styleUrls: ['./header-compras.component.css']
})
export class HeaderComprasComponent implements OnInit {
  usuario: Usuario;
  constructor(private _usuarioService: UsuarioComercialService,
    public router: Router) { }

  ngOnInit() {
    init_plugins();
   this.usuario= this._usuarioService.usuario
  }





cerrarSesion(){
  
  this._usuarioService.logOut()
}

buscar( termino: string ) {
  this.router.navigate(['/buscarinforme', termino ]);
}
}