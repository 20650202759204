
import {Injectable, EventEmitter} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Router} from '@angular/router';
import { environment } from 'src/environments/environment';


@Injectable()
export class ServicioService {
  apiUrl: string;
  messages: any;
// notificacion para escuchar evento
  public notificacion = new EventEmitter<any>();

  constructor(private http: HttpClient, private routes: Router) {
    this.apiUrl = environment.URL_SERVICIOS;

  }

  get(url: string) {
    return this.http.get(this.apiUrl + url);
  }

  post(url: string, data: any) {
    return this.http.post(this.apiUrl + url, data);
  }

  put(url: string, data: any) {
    return this.http.put(this.apiUrl + url, data);
  }

  delete(url: string) {
    return this.http.delete(this.apiUrl + url);
  }

  validarCorreoElectronico(correoElectronico: string) {
    const expreg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (expreg.test(correoElectronico.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  }

  validarSoloNumeros(cadena: string) {
    const expreg = /^[0-9]*$/;
    return expreg.test(cadena);
  }

  validarSoloLetrasConEspacio(cadena: string) {
    const expreg = /^[A-Z_ ]+([A-Z]+)*$/;
    return expreg.test(cadena.toUpperCase());
  }

  logOut() {
    localStorage.removeItem('usuario');
    localStorage.removeItem('token');
    localStorage.removeItem('isLoggedin');
    this.routes.navigate(['/authentication/login']);
  }

  obtenerMensaje(codigo: string) {
    for (let i = 0; i < this.messages.length; i++) {
      if (this.messages[i].code === codigo) {
        return this.messages[i].mensaje;
      }
    }
  }



}






