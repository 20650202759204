import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-header2',
  templateUrl: './header2.component.html',
  styleUrls: ['./header2.component.css']
})
export class Header2Component implements OnInit {
  @Input() activeNav: boolean;
  @Input() servicioNav: boolean;
  @Input() experienciaNav: boolean;
  @Input() procesoNav: boolean;
  @Input() inicioNav: boolean;

  
  constructor() {
    this.activeNav = false;
    this.servicioNav = false;
    this.experienciaNav = false;
    this.procesoNav = false;
    this.inicioNav = false;
   }

  ngOnInit() {
  }

  UrlInicio(){
    window.location.href="http://servirating.com/#/inicio"; 
    window.scrollTo(0, 0)
    location.reload()
  }

  UrlServicio(){
    window.location.href="http://servirating.com/#/Servicios"; 
    window.scrollTo(0, 0)
    location.reload()
  }

  UrlServicioGestion(){
    window.location.href="http://servirating.com/#/Servicios"; 
    window.scrollTo(0, 650)
  }

  UrlSobreNosotros(){
    window.location.href="http://servirating.com/#/Sobre Nosotros";
    window.scrollTo(0, 0);
    location.reload()
  }

  UrlSobreNosotrosVision(){
    window.location.href="http://servirating.com/#/Sobre Nosotros";
    window.scrollTo(0, 600);
    location.reload()
  }

  UrlExperiencia(){
    window.location.href="http://servirating.com/#/experiencia";
    window.scrollTo(0, 0);
    location.reload()
  }

  UrlExperienciaCliente(){
    window.location.href="http://servirating.com/#/experiencia";
    location.reload();
    window.scrollTo(0, 850);
    
  }

  UrlprocesoCalificacion(){
    window.location.href="http://servirating.com/#/procesoCalificacion";
    window.scrollTo(0, 0);
    location.reload()
  }

  UrlprocesoCalificacionPerfil(){
    window.location.href="http://servirating.com/#/procesoCalificacion";
    location.reload()
    window.scrollTo(0, 470);
  }

  UrlprocesoCalificacionProceso(){
    window.location.href="http://servirating.com/#/procesoCalificacion";
    location.reload()
    window.scrollTo(0, 1350);
  }

  UrlContacto(){
    window.location.href="http://servirating.com/#/contacto";
    window.scrollTo(0, 0);
    location.reload()
  }

}
