import { Component, OnInit } from '@angular/core';
import { FormProveedores } from 'src/app/models/modelosProveedores/formularioProveedores';
import { FormProveedoresServiceService1 } from 'src/app/services/serviciosProveedores/form-proveedores-service.service1';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-desactivar-proveedores',
  templateUrl: './desactivar-proveedores.component.html',
  styleUrls: ['./desactivar-proveedores.component.css']
})
export class DesactivarProveedoresComponent implements OnInit {

  cargando: boolean = true;

  listaproveedores: FormProveedores[] = [];
  filtrolistaproveedor:'';

  constructor(   private listaProveedoresService: FormProveedoresServiceService1, ) { }

  ngOnInit() {

    this.getListaProveedores()
  }
  getListaProveedores() {
    return this.listaProveedoresService.getProveedores3()
      .subscribe(
        listaproveedores => {
          console.log(listaproveedores);
          this.listaproveedores = listaproveedores
          this.cargando = false;
        },
        
      );
  };
  
  /* update(): void {
  
    this.listaProveedoresService.updateProveedor1(this.proveedor)
      .subscribe(result => this.message = "El proveedor fue actualizado");
    Swal.fire('Correcto', `La información fue actualizada con éxito`, 'success');
  } */
  
  guardarPro(  listaproveedores: FormProveedores ) {
  
    this.listaProveedoresService.updateProveedor1( listaproveedores )
            .subscribe();
            Swal.fire('Correcto', `La información fue actualizada con éxito`, 'success');
  }





}
