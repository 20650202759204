import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

// Services
import { AlertaService } from '../services/alerta.service';




import Swal from 'sweetalert2';
import { SubirArchivoService } from './subir-archivo.service';
import { FormProveedores } from '../models/modelosProveedores/formularioProveedores';
import { UsuarioComercial } from '../models/usuarioComercial';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UsuarioComercialService {
  usuario: UsuarioComercial;
  proveedor: FormProveedores
  token: string;
  menu: any[] = [];
  constructor(
    private _subirArchivoService: SubirArchivoService,
    private router: Router,
    private http: HttpClient,
    private _alertaService: AlertaService,
  
  ) { 
    this.cargarStorage();
  }
 estaLogueado() {
    return ( localStorage.getItem('token') ) ? true : false;
  }

  cargarStorage() {

    if ( localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
      this.usuario = JSON.parse( localStorage.getItem('usuario') );
      this.menu = JSON.parse( localStorage.getItem('menu') );
    } else {
      this.token = '';
      this.usuario = null;
      this.menu = [];
    }

  }

  guardarStorage( id: string, token: string, usuario: UsuarioComercial, menu: any ) {

    localStorage.setItem('id', id );
    localStorage.setItem('token', token );
    localStorage.setItem('usuario', JSON.stringify(usuario) );
    localStorage.setItem('menu', JSON.stringify(menu) );

    this.usuario = usuario;
    this.token = token;
    this.menu = menu;
  }

  logOut() {
    this.usuario = null;
    this.token = '';
    localStorage.removeItem('id');
    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    localStorage.removeItem('itempages');
    localStorage.removeItem('menu');
    this.router.navigate(['/loginCompra']);
  }




  loginGoogle( token: string ) {
    let url = `${ environment.URL_SERVICIOS }/login/google`;

    return this.http.post(url, { token }).pipe(map((resp: any) => {
      this.guardarStorage(resp.id, resp.token, resp.usuario, this.menu);
      return true;
    }));
  }

  login( usuario: UsuarioComercial, recuerdame: boolean = false ) {
    if ( !recuerdame ) {
      localStorage.setItem('email', usuario.email);
     
    } else {
      localStorage.removeItem('email');
    }

    let url = `${ environment.URL_SERVICIOS }/loginCompra`;

    return this.http.post( url, usuario ).pipe(map((resp: any) => {
      this.guardarStorage( resp.id, resp.token, resp.usuario, resp.menu );
      console.log(resp)
      return true;
    }));
  }

  crearUsuario( usuario: UsuarioComercial ) {
    let url = `${ environment.URL_SERVICIOS }/usuario`;

    return this.http.post( url, usuario ).pipe(map((resp: any) => resp.usuario));
  }

  actualizarUsuario( usuario: UsuarioComercial ) {
    let url = `${ environment.URL_SERVICIOS }/usuario/${ usuario._id }?token=${ this.token }`;

    return this.http.put(url, usuario).pipe(map((resp: any) => {

      if ( usuario._id === this.usuario._id ) {
        let usuarioDB = resp.usuario;
        this.guardarStorage(usuarioDB._id, this.token, usuarioDB,this.menu);
      }
      Swal.fire('Usuario actualizado', usuario.nombre, 'success' );
      console.log(resp)
      return resp;
     
    }));
  }

  cargarUsuarios1( desde: number = 0 ) {

    let url = environment.URL_SERVICIOS + '/usuario?desde=' + desde;
    return this.http.get( url );

  }

  cargarUsuarios() {
    let url = `${ environment.URL_SERVICIOS }/usuario`;

    return this.http.get( url ).pipe(map((resp: any) => resp));
  }

  buscarUsuarios( termino: string ) {
    let url = `${ environment.URL_SERVICIOS }/busqueda/coleccion/usuarioComercial/${ termino }`;

    return this.http.get( url ).pipe(map((resp: any) => resp.usuarios));
  }

  borrarUsuario( id: string ) {
    let url = `${ environment.URL_SERVICIOS }/usuario/${ id }?token=${ this.token }`;

    return this.http.delete( url ).pipe(map((resp: any) => resp.usuario));
  }



  cambiarImagen( archivo: File, id: string ) {

    this._subirArchivoService.subirArchivo( archivo, 'usuarios', id )
          .then( (resp: any) => {

            this.usuario.img = resp.usuario.img;
            Swal.fire( 'Imagen Actualizada', this.usuario.nombre, 'success' );
            this.guardarStorage( id, this.token, this.usuario, this.menu );

          })
          .catch( resp => {
            console.log( resp );
          }) ;

  }



  renuevaToken() {

    let url = environment.URL_SERVICIOS + '/loginCompras/renuevatoken';
    url += '?token=' + this.token;

    return this.http.get( url ).pipe( 
                map( (resp: any) => {

                  this.token = resp.token;
                  localStorage.setItem('token', this.token );
                  console.log('Token renovado');

                  return true;
                }))
               /* .catch( err => {
                  this.router.navigate(['/login']);
                  Swal.fire( 'No se pudo renovar token', 'No fue posible renovar token', 'error' );
                  return Observable.throw( err );
                });*/


  }

 
  
}


