import { Injectable, EventEmitter } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Tarea } from '../../models/tarea.model';
import { Usuario } from '../../models/usuario.model';
import { UsuarioService } from '../usuario.service';

import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FormProveedores } from 'src/app/models/modelosProveedores/formularioProveedores';
import { Tarea2 } from 'src/app/models/tarea2.model';


const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
    providedIn: 'root'
})
export class Tarea2Service {
    usuario: Usuario;
    formulario: FormProveedores;
    tareas2: Tarea2;
    token: string;
// notificacion para escuchar evento
public notificacion = new EventEmitter<any>();
    private urlTareas = environment.URL_SERVICIOS + '/tareas2';  // URL to web api

    constructor(private http: HttpClient, private _usuarioService: UsuarioService) {

        this.notificacion.subscribe(
            resp =>
                   this.tareas2
            
        )
     }

    getTareas(): Observable<Tarea2[]> {
        return this.http.get<Tarea2[]>(this.urlTareas)
    }
    getTarea(tarea: Tarea2 | string): Observable<Tarea2> {
        const id = typeof tarea === 'string' ? tarea : tarea._id;
        const url = `${this.urlTareas}/${id}`;
        return this.http.get<Tarea2>(url, httpOptions);
    }
    addTarea(tarea:Tarea2): Observable<Tarea2>{
         this.urlTareas = environment.URL_SERVICIOS +'/tareas2'+'?token=' + this._usuarioService.token;
        this.urlTareas += '?token=' + this._usuarioService.token;
        return this.http.post<Tarea2>(this.urlTareas, tarea, httpOptions);
    }
    updateTarea(tarea:Tarea2): Observable<Tarea2>{
        this.urlTareas = environment.URL_SERVICIOS +'/tareas2'+'?token=' + this._usuarioService.token;
      //  this.urlTareas += '?token=' + this._usuarioService.token;
        return this.http.put<Tarea>(this.urlTareas, tarea, httpOptions);
    }

    updateTarea1(tarea:Tarea2): Observable<Tarea2>{
   
      //  this.urlTareas += '?token=' + this._usuarioService.token;
        return this.http.put<Tarea2>(this.urlTareas, tarea, httpOptions);
    }
    deleteTarea(tarea: Tarea2): Observable<Tarea2> {
     
        const id = typeof tarea === 'string' ? tarea : tarea._id;
        const url = `${this.urlTareas}/${id}`;
        return this.http.delete<Tarea2>(url, httpOptions);
     }



 buscarUsuarios( termino: string ) {
    let url = `${ environment.URL_SERVICIOS }/busqueda/coleccion/tareas2/${ termino }`;

    return this.http.get( url ).pipe(map((resp: any) => resp.tareas));
  }
}
