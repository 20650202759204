import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { FormProveedores } from '../../../models/modelosProveedores/formularioProveedores';
import { FormProveedoresServiceService } from '../../../services/serviciosProveedores/form-proveedores-service.service';
import Swal from 'sweetalert2';
import { Usuario } from 'src/app/models/usuario.model';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Tarea2 } from 'src/app/models/tarea2.model';
import { Tarea2Service } from 'src/app/services/serviciosProveedores/tareas2.service';

declare function  init_plugins()
@Component({
  selector: 'app-id-proveedores',
  templateUrl: './id-proveedores.component.html',
  styleUrls: ['./id-proveedores.component.css']
})
export class IdProveedoresComponent implements OnInit {


    //Importa todos los inputs checkBox para la asignación de tarea
    @ViewChildren("myInput") inputs: QueryList<any>
    //Usuario responsable
    userOption: String;
    //Todos los informes creados

    tareas = []
  customer = new Tarea2() ;
  submitted = false;

  lista: FormProveedores[] = [];
  listaproveedores: FormProveedores[] = [];
  listaCertificados: FormProveedores[] = [];
  usuarios: Usuario[] = [];
  usuarios2: Usuario[] = [];
  desde: number = 0;

  totalRegistros: number = 0;
  cargando: boolean = true;
  proveedor = new FormProveedores();
  message: String;
  analista: String;

  constructor(private listaProveedoresService: FormProveedoresServiceService,  private _tareaService: Tarea2Service,
    public _usuarioService: UsuarioService) { 
      init_plugins()
    }

  ngOnInit() {
this.myFunction();
   // this.getListaProveedores();
    this.cargarUsuarios();
    this.cargarUsuarios2();
    this.getCustomers1();
    this._tareaService.notificacion.subscribe( 
      resp =>
      this.getCustomers1()
    
     );
  
  }
  myFunction() {
    // this.presionar = setTimeout(this.cagados, 10000);
   
    setTimeout(() => {
      init_plugins();
    }, 3000);
  }

/*  getListaProveedores() {
    return this.listaProveedoresService.getProveedores()
      .subscribe(
        listaproveedores => {
          console.log(listaproveedores);
          this.listaproveedores = listaproveedores
          this.cargando = false;
        },
        
      );
  }; */

 /* update(): void {
  
    this.listaProveedoresService.updateProveedor1(this.proveedor)
      .subscribe(result => this.message = "El proveedor fue actualizado");
    Swal.fire('Correcto', `La información fue actualizada con éxito`, 'success');
  } */

  guardarPro(  listaproveedores: FormProveedores ) {

    this.listaProveedoresService.updateProveedor1( listaproveedores )
            .subscribe();
            Swal.fire('Correcto', `La información fue actualizada con éxito`, 'success');
  }

  cargarUsuarios() {
    this.cargando = true;
    this._usuarioService.cargarUsuarios1(this.desde)
      .subscribe((resp: any) => {
        for (var user in resp.usuarios) {
          //Habilitar en caso de querer cargar todos los roles de usuario en la asignación de tareas.
          if (resp.usuarios[user].role == "ANALISTA_PROVEEDOR") {
            this.usuarios.push(resp.usuarios[user]);
          }
        }
      
      });
  }

  cargarUsuarios2() {
    this.cargando = true;
    this._usuarioService.cargarUsuarios1(this.desde)
      .subscribe((resp: any) => {
        for (var user in resp.usuarios) {
          //Habilitar en caso de querer cargar todos los roles de usuario en la asignación de tareas.
          if (resp.usuarios[user].role == "CLIENTE_PROVEEDOR_ROLE") {
            this.usuarios2.push(resp.usuarios[user]);
          }
        }
      
      });
  }



 buscarUsuario(termino: string) {

    if (termino.length <= 0) {
    //  this.getListaProveedores()
    this.getCustomers1();
      return;
    }



    this.listaProveedoresService.buscarUsuarios(termino)
      .subscribe((listaproveedores: FormProveedores[]) => {

        this.listaproveedores = listaproveedores;

      });

  }
//===========================================TAREAS==================================================================//
getCustomers1() {
  return this.listaProveedoresService.getCustomers()
    .subscribe(
      
      lista => {
        for (var form in lista) {
          for (var user in this.usuarios2) {
            if (this.usuarios2[user]._id == lista[form].usuario2) {
              lista[form].usuario2 = this.usuarios2[user].nombre;
            }
            if (lista[form].fechaLimite == undefined || lista[form].fechaLimite == null) {
              var date = new Date();
              lista[form].fechaLimite = (date.getFullYear().toString() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + (date.getDate() + 2)).slice(-2));
            }
          }
        }
        lista.sort(this.sortObjetc);
        this.lista = lista
        console.log(this.lista);
        this.getTareas();
      }
    );
}
sortObjetc(a, b) {
  return a.fechaLimite > b.fechaLimite ? 1 : -1;
};
asignarTarea() {
  this.inputs.forEach(input => {
    var date = new Date();
    var fecha = (date.getFullYear().toString() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + (date.getDate())).slice(-2));
    if (input.nativeElement.checked) {
      var tarea = new Tarea2;
      tarea._idResponsable = this.userOption;
      tarea._idFormulario = input.nativeElement.value;
      tarea.fechaAsingada = fecha;
      for (var t in this.lista) {
        if (this.lista[t]._id == input.nativeElement.value) {
          tarea.fechaLimite = this.lista[t].fechaLimite;
        }
      }
      tarea.estado = "0";
      tarea.estado2 = "ACTIVO";
      this._tareaService.addTarea(tarea).subscribe(resp => {
        input.nativeElement.checked = false;
       // this._tareaService.notificacion.emit( resp );
       // this.crearFormularios()
        Swal.fire("Asignación existosa", "", "success").then(function (e) {
        
        //  window.location.reload();
        });

        this._tareaService.notificacion.emit( resp );
      }, err => {
        if (err.status = (409)) {
          Swal.fire('Usuario', 'Ya existe!', 'error');
        }
        console.log(err)
      },
      
      
      
      
      );
    }
  })
}

getTareas() {
  this._tareaService.getTareas().subscribe(respTareas => {
    for (var tarea in respTareas) {
      this.tareas.push(respTareas[tarea]);
      for (var form in this.lista) {
        if (respTareas[tarea]._idFormulario == this.lista[form]._id) {
          for (var user in this.usuarios2) {
            if (respTareas[tarea]._idResponsable == this.usuarios2[user]._id) {
              console.log(this.usuarios2[user]);
              console.log(respTareas[tarea]);
              
              console.log(this.usuarios2[user].nombre);
              this.lista[form].fechaAsignada = respTareas[tarea].fechaAsingada
              this.lista[form].fechaLimite = respTareas[tarea].fechaLimite
              console.log(this.lista[form]);
             
              console.log(this.lista[form]["responsable"]);
              this.lista[form]["responsable"] = this.usuarios2[user].nombre;
              console.log(this.lista[form]["responsable"]);
            }
          }
        }
      }
    }
    this.cargando = false;
  });
}
nohay(){
  Swal.fire(

    'NO HAY',
    'ADJUNTOS EN ESTE INFORME',
    'warning'
  );

}


}
