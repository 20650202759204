import { Component, OnInit } from '@angular/core';


declare function init_plugins();

@Component({
  selector: 'app-inicio-admin',
  templateUrl: './inicio-admin.component.html',
  styleUrls: ['./inicio-admin.component.css']
})
export class InicioAdminComponent implements OnInit {

  constructor() { 
    init_plugins();
  }

  ngOnInit() {
    init_plugins();
  }

}
