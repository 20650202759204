import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {formCompleto} from '../models/formularioCompleto';

import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
//import * as io from "socket.io-client";
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class CompletoService {

 // socket = io('http://144.91.108.171:4000');
  private informeCompletoUrl = environment.URL_SERVICIOS +'/completo';  // URL to web api
// notificacion para escuchar evento
//public notificacion = new EventEmitter<any>();
  constructor( private http: HttpClient) { 
  /*  this.socket.on('update-data', function(data: any) {
      this.updateInformeCompleto()
      
     console.log(data,'funciona')
    }.bind(this)); */

  }
  

  getInformesCompletos(): Observable<formCompleto[]> {
    return this.http.get<formCompleto[]>(this.informeCompletoUrl);

  }
  getInformeCompleto1(id: string): Observable<formCompleto> {
    const url = `${this.informeCompletoUrl}/${id}`;
    //this.notificacion.emit(formCompleto)
    return this.http.get<formCompleto>(url,  httpOptions);
  }
  getInformeCompleto(id: string): Observable<formCompleto> {
    const url = `${this.informeCompletoUrl}/${id}`;
    return this.http.get<formCompleto>(url, httpOptions);
  }


  cargarInformes(): Observable<formCompleto[]> {
    let url = `${ environment.URL_SERVICIOS }/completo `;

    return this.http.get( url ).pipe(map((resp: any) => resp , httpOptions)
    
   );
 
  }

  getChart(): Observable<formCompleto[]>{
    let url = `${ environment.URL_SERVICIOS }/completo `;
    return this.http.get(url).pipe(map((resp: any) => resp, ));
   
  }

  addInformeCompleto (informeCompleto: formCompleto): Observable<formCompleto> {
    return this.http.post<formCompleto>(this.informeCompletoUrl, informeCompleto, httpOptions);
  }

  deleteInformeCompleto (informeCompleto: formCompleto | string): Observable<formCompleto> {
    const id = typeof informeCompleto === 'string' ? informeCompleto : informeCompleto._id;
    const url = `${this.informeCompletoUrl}/${id}`;

    return this.http.delete<formCompleto>(url, httpOptions);
  }

  updateInformeCompleto (informeCompleto: formCompleto): Observable<any> {
    return this.http.put(this.informeCompletoUrl, informeCompleto, httpOptions);
  }

  buscarCompletos( termino: string ) {
    let url = `${ environment.URL_SERVICIOS }/busqueda/coleccion/completo/${ termino }`;

    return this.http.get( url ).pipe(map((resp: any) => resp.completo));
  }




}
