import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';
import { SidebarService } from '../../services/sidebar.service';
import { Usuario } from 'src/app/models/usuario.model';


@Component({
  selector: 'app-sidebar-operador',
  templateUrl: './sidebar-operador.component.html',
  styleUrls: ['./sidebar-operador.component.css']
})
export class SidebarOperadorComponent implements OnInit {
  usuario: Usuario;
  constructor(private _usuarioService: UsuarioService,  public _sidebar: SidebarService,
    ) { }

  ngOnInit() {
    this.usuario = this._usuarioService.usuario;
    this._sidebar.cargarMenu();

  }
cerrarSesion(){
  this._usuarioService.logOutOperador()
}
}