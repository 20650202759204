import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/models/usuario.model';
import { FormProveedores } from 'src/app/models/modelosProveedores/formularioProveedores';
import { ServicioService } from 'src/app/services/servicio.service';
import Swal from 'sweetalert2';
import { UsuarioService } from 'src/app/services/usuario.service';
import { FormProveedoresServiceService } from 'src/app/services/serviciosProveedores/form-proveedores-service.service';

@Component({
  selector: 'app-historial-calificacion',
  templateUrl: './historial-calificacion.component.html',
  styleUrls: ['./historial-calificacion.component.css']
})
export class HistorialCalificacionComponent implements OnInit {
 
  
  formularios:FormProveedores[];
  formulariosUsuario: FormProveedores[] = [];


 usuario: Usuario;
  constructor(private _usuarioService: UsuarioService, private listainforme: FormProveedoresServiceService,) { }
  ngOnInit() {
    this.usuario = this._usuarioService.usuario;
    this.getFormularios();
  }

  getFormularios() {
    const usuario = JSON.parse(localStorage.getItem('usuario')) as Usuario;
    this.listainforme.getProveedores1(usuario._id).subscribe(
      result => { 
         this.formularios =  result 
         console.log(this.formularios)
     });
  }
}