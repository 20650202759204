import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/models/usuario.model';
import { Contact2 } from 'src/app/models/contact2.models';
import { UsuarioService } from 'src/app/services/usuario.service';
import { ListaproveedorService } from 'src/app/services/listaproveedor.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-historial-proveedores',
  templateUrl: './historial-proveedores.component.html',
  styleUrls: ['./historial-proveedores.component.css']
})
export class HistorialProveedoresComponent implements OnInit {

  usuario: Usuario;
  
  formularios: Contact2[];
  formulariosUsuario: Contact2[] = [];


  constructor(private _usuarioService: UsuarioService,private listainforme:ListaproveedorService, private location: Location, ) { }

  ngOnInit() { 

    this.usuario = this._usuarioService.usuario;
    this.getFormularios();
  }



  getFormularios() {
    const usuario = JSON.parse(localStorage.getItem('usuario')) as Usuario;
    this.listainforme.getProveedores3(usuario._id).subscribe(
      result => { 
         this.formularios =  result 
         console.log(this.formularios)
     });
  }

  
  atras(): void {
    this.location.back();
  }
  
actualizar(){
this.getFormularios();

}

}