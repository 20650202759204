import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Contact } from '../models/contact.model';
import { Usuario } from '../models/usuario.model';
import { UsuarioService } from './usuario.service';

import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Contact3 } from '../models/contactTarea';
import { Contact1 } from '../models/contact1.models';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', },)
};


@Injectable({
  providedIn: 'root'
})
export class ListainformesService {
  usuario: Usuario;
  token: string;

  private listaUrl = environment.URL_SERVICIOS +'/lista';  // URL to web api
  private listaUrl1 = environment.URL_SERVICIOS +'/lista/listaapp';
  private listaUrl2 = environment.URL_SERVICIOS +'/lista/todas';
  private listaUrl3 = environment.URL_SERVICIOS +'/lista/operador';

  constructor( private http: HttpClient, private _usuarioService: UsuarioService) { }

  getCustomers (): Observable<Contact[]> {
    this.listaUrl = environment.URL_SERVICIOS +'/lista'+'?token=' + this._usuarioService.token;
    return this.http.get<Contact[]>(this.listaUrl)
  }

  getCustomers1 (): Observable<Contact[]> {
    return this.http.get<Contact[]>(this.listaUrl2)
  }

  getCustomers2 (): Observable<Contact[]> {
    this.listaUrl = environment.URL_SERVICIOS +'/lista/operador'+'?token=' + this._usuarioService.token;
    return this.http.get<Contact[]>(this.listaUrl)
  }


  getProveedores1 (usuario_id:string): Observable< Contact[]> {
    return this.http.get< Contact[]>(this.listaUrl+'?usuario_id='+usuario_id)
     }
   
     getProveedores3 (usuario_id:string): Observable< Contact3[]> {
      return this.http.get< Contact3[]>(this.listaUrl1+'?usuario_id='+usuario_id)
       }
     

  
  getCustomersApp (): Observable<Contact[]> {
    return this.http.get<Contact[]>(this.listaUrl +'/app')
  }

  getCustomer(id: string): Observable<Contact3> {
    const url = `${this.listaUrl}/${id}`;
    return this.http.get<Contact3>(url);
  }

  addCustomer1 (contacto: Contact3): Observable<Contact> {
 
    this.listaUrl = environment.URL_SERVICIOS +'/lista/lista'+'?token=' + this._usuarioService.token;
      return this.http.post<Contact>(this.listaUrl, contacto, httpOptions);
    }



    updateCustomer1 (contacto: Contact3): Observable<any> {
      this.listaUrl = environment.URL_SERVICIOS +'/lista'+'?token=' + this._usuarioService.token;
      return this.http.put(this.listaUrl, contacto, httpOptions);
    }


/*  addCustomer (contacto: Contact): Observable<Contact> {
    this.listaUrl += '?token=' + this._usuarioService.token;
    return this.http.post<Contact>(this.listaUrl, contacto, httpOptions);
  }  */

  addCustomer (contacto: Contact): Observable<Contact> {
    this.listaUrl =environment.URL_SERVICIOS +'/lista'+'?token=' + this._usuarioService.token;
      return this.http.post<Contact>(this.listaUrl, contacto, httpOptions);
    }

    addCustomer2 (contacto: Contact1): Observable<Contact> {
      this.listaUrl =environment.URL_SERVICIOS +'/lista'+'?token=' + this._usuarioService.token  ;
        return this.http.post<Contact>(this.listaUrl, contacto, httpOptions);
      }
 
  

  deleteCustomer (contacto: Contact | string): Observable<Contact> {
    const id = typeof contacto === 'string' ? contacto : contacto._id;
    const url =  environment.URL_SERVICIOS +'/lista'+`/${id}`;

    return this.http.delete<Contact>(url, httpOptions);
  }

  updateCustomer (contacto: Contact): Observable<any> {
    this.listaUrl = environment.URL_SERVICIOS +'/lista'+'?token=' + this._usuarioService.token;
    return this.http.put(this.listaUrl, contacto, httpOptions);
  }


  updateCustomer100 (contacto: Contact): Observable<any> {
    this.listaUrl = environment.URL_SERVICIOS +'/lista'+'?token=' + this._usuarioService.token;
    return this.http.put(this.listaUrl, contacto, httpOptions);
  }


cargarInformes() {
    let url = `${ environment.URL_SERVICIOS }/lista `;

    return this.http.get( url ).pipe(map((resp: any) => resp));
  }


  buscarinforme( termino: string ) {
    let url = `${ environment.URL_SERVICIOS }/busqueda/coleccion/lista/${ termino }`;

    return this.http.get( url ).pipe(map((resp: any) => resp.lista));
  }
  cargarinformes1( desde: number = 0 ) {

    let url = environment.URL_SERVICIOS + '/lista?desde=' + desde;
    return this.http.get( url );

  }
}
