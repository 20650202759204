import { Component, OnInit } from '@angular/core';

import { ListainformesService } from 'src/app/services/listainformes.service';
import { Contact } from 'src/app/models/contact.model';
import { Usuario } from 'src/app/models/usuario.model';
import { UsuarioService } from 'src/app/services/usuario.service';
import { ExcelService } from 'src/app/services/excel.service';
import * as faker from 'faker';
import Swal from 'sweetalert2';

declare function init_plugins()

@Component({
  selector: 'app-appmovil',
  templateUrl: './appmovil.component.html',
  styleUrls: ['./appmovil.component.css']
})
export class AppmovilComponent implements OnInit {
  exportContacts: Contact[] = [];

  lista: Contact[] = [];
usuario: Usuario[];
  desde: number = 0;

  totalRegistros: number = 0;
  cargando: boolean = true;

  constructor(private listainforme: ListainformesService,private excelSrv: ExcelService,
    private users: UsuarioService) {
      init_plugins()
    }
    filtroInformes = "";
  ngOnInit() {
    init_plugins();
    this.getCustomers1();
    for (let index = 0; index < 10; index++) {
      const contact = new Contact();
      contact.tipoInforme = faker.name.findName();
      contact.razonSocial = faker.name.findName();
      contact.ruc = faker.address.zipCode();
      contact.ciudad = faker.address.country();
      contact.telefono = faker.phone.phoneNumber();
      contact.email = faker.internet.email();
      contact.lineaCredito = faker.name.findName();
      contact.ventasAnuales = faker.finance.mask();
      contact.plazoPagoCredito = faker.finance.mask();
      contact.observaciones = faker.hacker.phrase();
      // contact.contacto = faker.phone.phoneNumber();
      // contact.fecha = faker.date.past();
      contact.noFactura =faker.phone.phoneNumber();
       contact.tipocliente = 'CLIENTE SERVIRATING'
      //contact.nombreSolicitante = faker.name.findName();
      this.exportContacts.push(contact);
      console.log(contact);
      return (contact)
    }
   // this.cargarUsuario();
  }


  getCustomers1() {
    return this.listainforme.getCustomersApp()
      .subscribe(
        lista => {
          console.log(lista);
          this.lista = lista;
          this.cargando = false;


        }
      );
  };

exportData(tableId: string) {
  this.excelSrv.exportToFile("contacts", tableId);
    }


    ActulizarEstado( lista: Contact ) {

      this.listainforme.updateCustomer( lista )
              .subscribe()
              Swal.fire('Nº DE FACTURA', `agregado exitosamente`, 'success');
     
    }

    nohay(){
      Swal.fire(
    
        'NO HAY',
        'ADJUNTOS EN ESTE INFORME',
        'warning'
      );
    
    }
    

}  
  