import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { UsuarioService } from './usuario.service';

@Injectable({
  providedIn: 'root'
})
export class LoginOperadorGuard implements CanActivate {
  constructor(
    public _usuarioService: UsuarioService
  ) { }
  canActivate(){

    if ((  this._usuarioService.usuario.role === 'ANALISTA_ROLE') || (this._usuarioService.usuario.role === 'ADMIN_ROLE' )) {
      return true;
    }else {
      console.log( 'Bloqueado por el COMERCIAL GUARD');
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'No es Analista de informes comerciales',
      
      });
      this._usuarioService.logOutOperador();
      return false;
    }

  }
  
}