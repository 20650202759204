import { Pipe, PipeTransform } from '@angular/core';
import { isUndefined } from 'util';
import { retry } from 'rxjs/operators';

@Pipe({
  name: 'filtro3'
})
export class Filtro3Pipe implements PipeTransform {

  transform(value: any, arg: any): any {
    const resultTareas = [];
    if(arg.length < 3||arg===""){
      return value;
    }
    for (const tarea of value) {
      if (isUndefined)
        if (typeof tarea.razonSocial !== "undefined") {
          if (tarea.razonSocial.toLowerCase().indexOf(arg.toLowerCase()) > -1) {
            resultTareas.push(tarea);
          }
        }
        if (typeof tarea.tipoInforme !== "undefined") {
          if (tarea.nombreEmpresa.toLowerCase().indexOf(arg.toLowerCase()) > -1) {
            resultTareas.push(tarea);
          }
        }
        if (typeof tarea.ruc !== "undefined") {
          if (tarea.ruc.toLowerCase().indexOf(arg.toLowerCase()) > -1) {
            resultTareas.push(tarea);
          }
        }
        if (typeof tarea.tipoInforme !== "undefined") {
          if (tarea.tipoInforme.toLowerCase().indexOf(arg.toLowerCase()) > -1) {
            resultTareas.push(tarea);
          }
        }
        if (typeof tarea.usuario.nombre !== "undefined") {
          if (tarea.usuario.nombre.toLowerCase().indexOf(arg.toLowerCase()) > -1) {
            resultTareas.push(tarea);
          }
        }
        console.log(tarea);
    }
    return resultTareas;
  }
}
