
export class Tarea2 {
    _id: string;
    _idResponsable: String;
    _idFormulario: String;
    fechaAsingada: String = new Date().toISOString();
    fechaLimite: String = new Date().toISOString();
    estado: String;
    estado2: String;
}
