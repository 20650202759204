import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Usuario } from 'src/app/models/usuario.model';


declare function init_plugins();
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  usuario: Usuario;
  constructor(private _usuarioService: UsuarioService) {
    init_plugins();
   }

  ngOnInit() {
    init_plugins();
   this.usuario= this._usuarioService.usuario
  }
cerrarSesion(){
  
  this._usuarioService.logOut()
}
}