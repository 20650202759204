import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Solicitud1Service } from '../../../../services/solicitud1.service';

import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ActivatedRoute, Params } from '@angular/router';
import { SolicitudCompra } from 'src/app/models/solicitudCompra.model';
import Swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';
import { DragdropService } from 'src/app/services/dragdrop.service';
@Component({
  selector: 'app-detalle-compra',
  templateUrl: './detalle-compra.component.html',
  styleUrls: ['./detalle-compra.component.css']
})
export class DetalleCompraComponent implements OnInit {
  solicitudModel = new SolicitudCompra();

  urlPDF:string;
  urlPDF2:string;
//variables drop
fileArr = [];
imgArr = [];
fileObj = [];
//form: FormGroup;
msg: string;
progress: number = 0;

progresobarra :number=100;

  formularioSolicitud: FormGroup;
  razonSocialCalificacionProveedor: String;
  nombreComercialCalificacionProveedor: String;
 

  // en ves del express


  rucCalificacionProveedor: String;


  telefonoCalificacionProveedor: String;
  paginaWebCalificacionProveedor: String;
  correoCalificacionProveedor: String;
  rucinforme: String
  tipoinforme:String
  informeExistente:String
  referenciaCalificacionProveedor: String;
  cargoCalificacionProveedor: String;
  correoContactoCalificacionProveedor: String;
  fechaCalificacionProveedor: String;

 
  //depositoCalificacionProveedor: String
 // chequeCalificacionProveedor: String
  //transfereciaCalificacionProveedor: String;

  bancoCalificacionProveedor: String;
  cuentacorrienteCalificacionProveedor: String;
  pagoanombredeCalificacionProveedor: String;

  ndefacturaCalificacionProveedor: String;
  detalledepagoCalificacionProveedor: String;

  emailPattern = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  //textPattern= new RegExp(/^[a-zA-Z ]+$/);
  textPattern = new RegExp(/^[A-Z]{1}[a-zA-ZñÑáéíóúÁÉÍÓÚ&.\s]+$/);
  numberPattern = new RegExp(/^[0-9 ]+$/);
  //pageWebPattern= new RegExp(/^[w]+([\.]+[\w]+)+$/);
  pageWebPattern = new RegExp(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/);



  constructor(public fb: FormBuilder, private solicitudService: Solicitud1Service,private location:Location, private route: ActivatedRoute, private router: Router,  private sanitizer: DomSanitizer,  public dragdropService: DragdropService) {
    this.formularioSolicitud = this.fb.group({
      razonSocialCalificacionProveedor: ['', [ Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      nombreComercialCalificacionProveedor: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      direccionCalificacionProveedor: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],

      rucCalificacionProveedor: ['', [ Validators.minLength(13), Validators.maxLength(13), Validators.pattern(this.numberPattern)]],

      // en ves del express
      provinciasEcuadorCalificacionProveedor: ['', [Validators.required]],
      ciudadesEcuadorCalificacionProveedor: ['', [Validators.required]],


      telefonoCalificacionProveedor: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      correoCalificacionProveedor: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      paginaWebCalificacionProveedor: ['', [Validators.required, Validators.pattern(this.pageWebPattern)]],


      referenciaCalificacionProveedor: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      cargoCalificacionProveedor: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      correoContactoCalificacionProveedor: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      rucinforme: [''],
      tipoinforme:[''],
      informeExistente:[''],
      avatar: [null],
      fechaCalificacionProveedor: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10),/*Validators.pattern(this.numberPattern)*/]],
      ndefacturaCalificacionProveedor: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(30), Validators.pattern(this.numberPattern)]],
      detalledepagoCalificacionProveedor: ['', [Validators.required, Validators.pattern(this.textPattern)]],

      bancoCalificacionProveedor: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(13)]],
      cuentacorrienteCalificacionProveedor: ['', [Validators.required, Validators.minLength(3)]],
      pagoanombredeCalificacionProveedor: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],

      depositoCalificacionProveedor: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      chequeCalificacionProveedor: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      transferenciaCalificacionProveedor: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],

    });
  }
  ngOnInit() { 
    const id = this.route.snapshot.paramMap.get('id');
    console.log(id);
    this.getSolicitudId(id);
  }

  getSolicitudId(id) {

    this.solicitudService.getInformeCompleto(id).subscribe(
      solicitud => {

        console.log(solicitud);
        this.solicitudModel = solicitud;
      }
    );
  }



  saveData() {
    this.razonSocialCalificacionProveedor = this.formularioSolicitud.value.razonSocialCalificacionProveedor;
   // this.direccionCalificacionProveedor = this.formularioSolicitud.value.direccionCalificacionProveedor;
    this.nombreComercialCalificacionProveedor = this.formularioSolicitud.value.nombreComercialCalificacionProveedor;


    // en ves del express
 //   this.ciudadesEcuadorCalificacionProveedor = this.formularioSolicitud.value.ciudadesEcuadorCalificacionProveedor;
  //  this.provinciasEcuadorCalificacionProveedor = this.formularioSolicitud.value.provinciasEcuadorCalificacionProveedor;



    this.rucCalificacionProveedor = this.formularioSolicitud.value.rucCalificacionProveedor;
    this.telefonoCalificacionProveedor = this.formularioSolicitud.value.telefonoCalificacionProveedor;
    this.correoCalificacionProveedor = this.formularioSolicitud.value.correoCalificacionProveedor;
    this.paginaWebCalificacionProveedor = this.formularioSolicitud.value.paginaWebCalificacionProveedor;

    this.referenciaCalificacionProveedor = this.formularioSolicitud.value.referenciaCalificacionProveedor;
    this.cargoCalificacionProveedor = this.formularioSolicitud.value.cargoCalificacionProveedor;

    this.correoContactoCalificacionProveedor = this.formularioSolicitud.value.correoContactoCalificacionProveedor;
    this.fechaCalificacionProveedor = this.formularioSolicitud.value.fechaCalificacionProveedor;

   // this.depositoCalificacionProveedor = this.formularioSolicitud.value.depositoCalificacionProveedor;
  //  this.chequeCalificacionProveedor = this.formularioSolicitud.value.chequeCalificacionProveedor;
  //  this.transfereciaCalificacionProveedor = this.formularioSolicitud.value.transfereciaCalificacionProveedor;

    this.bancoCalificacionProveedor = this.formularioSolicitud.value.bancoCalificacionProveedor;
    this.cuentacorrienteCalificacionProveedor = this.formularioSolicitud.value.cuentacorrienteCalificacionProveedor;
    this.pagoanombredeCalificacionProveedor = this.formularioSolicitud.value.pagoanombredeCalificacionProveedor;
    this.detalledepagoCalificacionProveedor = this.formularioSolicitud.value.detalledepagoCalificacionProveedor;
    this.ndefacturaCalificacionProveedor = this.formularioSolicitud.value.ndefacturaCalificacionProveedor;

    alert("Se Han ingresado exitosamente los datos");
    console.log(this.formularioSolicitud.value);
    this.onResetForm();
  }
 

  update(){
    this.solicitudModel.progreso = this.progresobarra;
    this.solicitudModel.urlPDF2 = this.urlPDF2;
    this.solicitudService.updateInformeCompleto(this.solicitudModel).subscribe(result=>"Se Actualizo");
    Swal.fire(
      'Muy Bien',
      'Datos Actualizados Éxitosamente',
      'success'
    );
    this.back();
  }

  delete(){
    this.solicitudService.deleteInformeCompleto(this.solicitudModel).subscribe(result=>"Se elimino");
    Swal.fire(
      'Muy Bien',
      'Solicitud eliminda exitosamente',
      'success'
    );
    this.back();

  }
  back() {
    setTimeout(() => {
      this.location.back();
    }, 1400);
  }


  onResetForm() {
    this.formularioSolicitud.reset();

  }


 //nueva importacion de archivos drop
 upload(e) {
  const fileListAsArray = Array.from(e);
  fileListAsArray.forEach((item, i) => {
    const file = (e as HTMLInputElement);
    const url = URL.createObjectURL(file[i]);
    this.imgArr.push(url);
    this.fileArr.push({ item, url: url });
  })

  this.fileArr.forEach((item) => {
    this.fileObj.push(item.item)
  })

  // Set files form control
  this.formularioSolicitud.patchValue({
    avatar: this.fileObj
  })

  this.formularioSolicitud.get('avatar').updateValueAndValidity()

  // Upload to server
  this.dragdropService.addFiles(this.formularioSolicitud.value.avatar)
    .subscribe((event: HttpEvent<any>) => {
   //   console.log(event.body.userCreate);
      switch (event.type) {
        case HttpEventType.Sent:
          console.log('Request has been made!');
          break;
        case HttpEventType.ResponseHeader:
          console.log('Response header has been received!');
          break;
        case HttpEventType.UploadProgress:
          this.progress = Math.round(event.loaded / event.total * 100);
          console.log(`Uploaded! ${this.progress}%`);
          break;
        case HttpEventType.Response:
          console.log('File uploaded successfully!', event.body);
           this.urlPDF2= event.body.userCreated.avatar[0];
          setTimeout(() => {
            this.progress = 0;
            this.fileArr = [];
            this.fileObj = [];
            this.msg = "Archivo subido corretamente!"
            
          }, 3000);
      }
    })
}

// Clean Url for showing image preview
sanitize(url: string) {
  return this.sanitizer.bypassSecurityTrustUrl(url);
}




}


