import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Usuario } from 'src/app/models/usuario.model';
import { Contact3 } from 'src/app/models/contactTarea';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ListainformesService } from 'src/app/services/listainformes.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-informe-existente',
  templateUrl: './informe-existente.component.html',
  styleUrls: ['./informe-existente.component.css']
})
export class InformeExistenteComponent implements OnInit {

  message: String;
  formularioUsuario: FormGroup;

  tipoInforme: String;
  razonSocial: String;
  ruc: string ;
  ciudad: String;
  telefono: String;
  email: String;
  lineaCredito: String;
  ventasAnuales: String;
  plazoPagoCredito: String;
  observaciones: String;


lista = new Contact3();
submitted = false;


constructor(
  
  private fb: FormBuilder, private listainforme:ListainformesService, private route: ActivatedRoute, ) {
    this.buildForm();
  }
ngOnInit() {

  const id = this.route.snapshot.paramMap.get('id');
  this.listainforme.getCustomer(id)
    .subscribe(lista => this.lista = lista);


}


buildForm() {
  /**
   * @description Asignamos a la propiedad "formularioUsuario" los campos que se van a controlar de la vista
   */
  this.formularioUsuario = this.fb.group({
    tipoInforme: ['express', [Validators.required, Validators.minLength(3), Validators.maxLength(45)]],
    razonSocial: ['', [Validators.required, Validators.maxLength(30)]],
    ruc: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(450)]],
    ciudad: ['', [ Validators.minLength(5), Validators.maxLength(45)]],
    telefono: ['', [ Validators.minLength(10), Validators.maxLength(10)]],
    email: [''],
    lineaCredito: ['', [ Validators.minLength(3), Validators.maxLength(45)]],
    ventasAnuales:[''],
    plazoPagoCredito: ['', [ Validators.minLength(3), Validators.maxLength(45)]],
    observaciones: [''],

  
});
}

newLista(): void {
  this.submitted = false;
  this.lista = new Contact3();
}

addLista() {
  this.submitted = true;
  this.save();

}
 
private save(): void {
  console.log(this.lista);
  this.lista.estado = "0"
  this.lista.usuario = JSON.parse(localStorage.getItem('usuario')) as Usuario;
  this.listainforme.addCustomer1(this.lista)
      .subscribe();
      Swal.fire(
        'Muy Bien',
         'Datos Guardados Éxitosamente',
         'success'
       );
        this.onResetForm();
}


onResetForm() {
  this.formularioUsuario.reset();

}
update(): void {
  this.submitted = true;
  this.lista.estado = "0"
  this.lista.usuario = JSON.parse(localStorage.getItem('usuario')) as Usuario;
  this.listainforme.updateCustomer1(this.lista)
  
    .subscribe(result => this.message = "El proveedor fue actualizado");

    console.log(this.lista);
  Swal.fire('Correcto', `La información fue actualizada con éxito`, 'success');
}

}