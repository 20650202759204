import { Component, OnInit } from '@angular/core';
import { FormExpress } from 'src/app/models/formularioExpress';
import { FormProveedoresServiceService } from '../../../services/form-proveedores-service.service';

@Component({
  selector: 'app-admin-informes-express',
  templateUrl: './admin-informes-express.component.html',
  styleUrls: ['./admin-informes-express.component.css']
})
export class AdminInformesExpressComponent implements OnInit {

  expressosInf: FormExpress[]=[];
 
  desde: number = 0;
  cargando: boolean = true;
  constructor( private informeService: FormProveedoresServiceService,) { }

  ngOnInit() {
    this.getInformeExpress();
  }


  getInformeExpress() {
    this.informeService.getInformes().subscribe(informesExpress => {
      console.log(informesExpress);
      this.expressosInf = informesExpress;
      this.cargando = false;
     
    }
    );
  }



  buscarExprees(termino: string) {

    if (termino.length <= 0) {
      this.getInformeExpress();
      return;
    }

this.informeService.buscarExpress(termino)
      .subscribe((  expressosInf: FormExpress[]) => {

        this.expressosInf = expressosInf;
         console.log(expressosInf)
      });

  }
}
