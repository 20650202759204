import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/models/usuario.model';
import { UsuarioService } from 'src/app/services/usuario.service';

declare function init_plugins();

@Component({
  selector: 'app-header-cliente',
  templateUrl: './header-cliente.component.html',
  styleUrls: ['./header-cliente.component.css']
})
export class HeaderClienteComponent implements OnInit {
  usuario: Usuario;
  constructor(private _usuarioService: UsuarioService) { }

  ngOnInit() {
    init_plugins();
   this.usuario= this._usuarioService.usuario
  }
cerrarSesion(){
  
  this._usuarioService.logOutCliente()
}
}