import { Component, OnInit } from '@angular/core';
import { Contact } from 'src/app/models/contact.model';
import { ListainformesService } from 'src/app/services/listainformes.service';
import { CompraInforme } from '../../models/compraInforme';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CustomerService } from 'src/app/services/customer.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';



declare function init_plugins();
@Component({
  selector: 'app-buscar-informe',
  templateUrl: './buscar-informe.component.html',
  styleUrls: ['./buscar-informe.component.css']
})
export class BuscarInformeComponent implements OnInit {

listainformes: Contact []= [];
desde: number = 0;

totalRegistros: number = 0;
cargando: boolean = true;

forma: FormGroup;


  constructor(public _usuarioService: ListainformesService, public customer_service: CustomerService,
    public router: Router) { }

  ngOnInit() {
    init_plugins();
    this.cargarUsuarios();

    this.forma = new FormGroup({
      nombreEmpresa: new FormControl(null, Validators.required),
      ruc: new FormControl(null, [Validators.required]),
      tipoInforme: new FormControl(null, Validators.required),
    });

    this.forma.setValue({
      nombreEmpresa: '',
      ruc: '',
      tipoInforme: '',

    });
}



  cargarUsuarios() {

    this.cargando = true;

    this._usuarioService.cargarinformes1(this.desde)
      .subscribe((resp: any) => {

        this.totalRegistros = resp.total;

        this.listainformes = resp.listainformes;
        this.cargando = false;

      });
  };

  cambiarDesde(valor: number) {

    let desde = this.desde + valor;

    if (desde >= this.totalRegistros) {
      return;
    }

    if (desde < 0) {
     
      return;
    }

 

    this.desde += valor;
    this.cargarUsuarios();

  }

  buscarUsuario(termino: string) {
  

    if (termino.length <= 0) {
   
      this.cargarUsuarios();
     
      return ;
    }

    this.cargando = true;
   
    this._usuarioService.buscarinforme(termino)
      .subscribe((listainformes: Contact[]) => {

       
        this.listainformes = listainformes;
        this.cargando = false;
      });
    console.log(this.listainformes)

  };


  registrarUsuario() {

    if (this.forma.invalid) {
      return;
    }


          

    let usuario = new CompraInforme(
      this.forma.value.nombreEmpresa,
      this.forma.value.ruc,
      this.forma.value.tipoInforme
    );

    this.customer_service.crearUsuario(usuario)
      .subscribe(resp => {
        console.log(usuario);

     //   Swal.fire('INFORME', `solicitado con exito para recibir informe llene la solicitud de pago`, 'success');
     Swal.fire({
      icon: 'info',
      title: 'INFORME',
      text: 'solicitado con exito para recibir informe llene la solicitud de pago! ',
    // footer: '<a href="http://localhost:4200/#/solicitudPago">LLENAR SOLICITUD DE PAGO</a>'
    })

      })

  }


}
