import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


/*const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};*/

@Injectable({
  providedIn: 'root'

})
export class UploadService {

  constructor(private http: HttpClient) { }

  uploadFile(formData) {
    let urlAPI = environment.URL_SERVICIOS +'/api/upload';
    return this.http.post(urlAPI, formData);
  }


  
   
}
