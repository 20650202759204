import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UsuarioService } from 'src/app/services/usuario.service';
import { AlertaService } from 'src/app/services/alerta.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Usuario } from 'src/app/models/usuario.model';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  forma: FormGroup;


  constructor( private router: Router,
  
    private _usuarioService: UsuarioService,
    private _alertaService: AlertaService) { }

    sonIguales( campo1: string, campo2: string ) {
      return ( group: FormGroup ) => {
        let pass1 = group.controls[campo1].value;
        let pass2 = group.controls[campo2].value;
  
        if ( pass1 === pass2 ) {
          return null;
        }
  
        return {
          sonIguales: true
        };
      };
    }

  ngOnInit() {
    this.forma = new FormGroup({
      nombre: new FormControl('', Validators.required),
      correo: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      password2: new FormControl('', Validators.required),
    
    }, { validators: this.sonIguales('password', 'password2') });

  
  }

 /* onRegister(form): void {
    this.authService.register(form.value).subscribe(res => {
      this.router.navigateByUrl('/Admin');
    });
  }  */

  registrarUsuario() {
    if ( !this.forma.valid ) {
      this._alertaService.mostrarAlerta('warning', 'Hay errores en los campos');
      return;
    }

    

    let usuario = new Usuario(
      this.forma.value.nombre,
      this.forma.value.correo,
      this.forma.value.password
    );

    this._usuarioService.crearUsuario( usuario ).subscribe((resp) => {
      console.log(resp);
      Swal.fire('Usuario', `creado exitosamente`, 'success');
      this._alertaService.mostrarAlerta('success', 'Usuario creado!', resp.email, 2000);
     this.resetUsuario();
    },
    err => {
      if (err.status = (409)) {
        Swal.fire('Usuario', 'Ya existe!', 'error');
      }
      console.log(err)
    },
    
    
    
    
    );
  }

  resetUsuario(){
    this.forma.reset()
  }



}