

export class Contact {
    _id: string;
    tipoInforme: String = "";
    razonSocial: string = "";
    ruc: string = "";
    ciudad: String = "";
    telefono: String = "";
    email: String = "";
    lineaCredito: String = "";
    ventasAnuales: String = "";
    plazoPagoCredito: String = "";
    observaciones: String = ""
    tipocliente: String = "CLIENTE SR";
    //contacto: String = "";
    noFactura: String ;
  
   
    fecha: String = new Date().toDateString();
    usuario: String;
    responsable: String;
    fechaAsignada: String;
    fechaLimite: String;
    estado: String;
    estado2: String
}



