
export class FormExpress {
  _id: string;
  razonSocialExpress: String;
  fecha: string;
  procesosJudicialesExpress2: Text;
  solicitanteExpress: string;
  nombreComercialExpress: string;
  rucExpress: number;
  riseExpress: string;
  formaLegalExpress: string;
  estadoExpress: string;
  inicioActividadesExpress: string;
  reiinicioActividadesExpress: string;
  actividadPrincipalExpress: string;
  provinciaExpress: string;
  direccionExpress: string;
  sucursalesExpress: string;
  telefonoExpress: number;
  correoExpress: string;
  paginaExpress: string;
  ciudadExpress: string;
  representanteLegalExpress: string;
  personaContactoExpress: string;
  cargoExpress: string;
  empresasRelacionadasExpress: string;
  numeroEmpleadosExpress: number;
  pagoRentaUnoExpress: Text;
  anioPagoImpuesto: Text;
  anioPagoImpuestoDos: string;
  pagoRentaDosExpress: Text;
  impuestoDivisasExpressUno: string;
  anioImpuestoDivisas: string;
  impuestoDivisasExpressDos: string;
  puntualidadPagosExpress: string;
  //calificacion
  requeridoClienteExpress: string;
  requeridoServiratingExpress: string;
  nombreReferenciaExpress: string;
  direccionReferenciaExpress: string;
  telefonoReferenciaExpress: string;
  contactoReferenciaExpress: string;
  lineaCreditoExpress: string;
  pagosReferenciaExpress: string;
  productoServicioReferenciaExpress: string;
  clienteDesdeExpress: string;
  compraMensualExpress: string;
  telefonoDosReferenciaExpress4: string;
  //selects
  checks: String;
  checks1: String;
  checksCalificaciones: string;
  checksCalificaciones10: String;
  //checks
  procesosJudicialesExpress: Text;
  accionesDelitosExpress: string;
  informacionRelevanteExpress: Text;
  informacionBancariaExpress: Text;
  ventas2014Express: string;
  ventas2015Express: string;
  activos2014Express: string;
  activos2015Express: string;
  activos2016Express: string;
  pasivo2014Express: string;
  pasivo2015Express: string;
  pasivo2016Express: string;
  patrimonio2014Express: string;
  patrimonio2015Express: string;
  patrimonio2016Express: string;
  utilidad2014Express: string;
  utilidad2015Express: string;
  utilidad2016Express: string;
  terrenoAreaExpress: string;
  terrenoAvaluoExpress: string;
  construccionAreaExpress: string;
  construccionAvaluoExpress: string;
  avaluoTotalAreaExpress: string;
  avaluoTotalAvaluoExpress: number;
  marcaExpress: string;
  marcaExpress1: string;
  marcaExpress2: string;
  marcaExpress3: string;
  marcaExpress4: string;
  tipoExpress: string;
  tipoExpress1: string;
  tipoExpress2: string;
  tipoExpress3: string;
  tipoExpress4: string;
  fechavahiculosExpress: string;
  fechavahiculosExpress1: string;
  fechavahiculosExpress2: string;
  fechavahiculosExpress3: string;
  fechavahiculosExpress4: string;
  avaluoExpress: string;
  avaluoExpress1: string;
  avaluoExpress2: string;
  avaluoExpress3: string;
  avaluoExpress4: string;
  observacionesExpress: string;
  //campos nuevos
  contactoReferenciaExpress2: String;
  telefonoReferenciaExpress3: String;
  checks5: string;
  checksEdad: string;
  representanteLegalEdadExpress: string;
  edadInvetigadoExpress: string;
  capitalExpress: string;
  actividadExpress: string;
  añoExpress: string;
  productosExpress: string;
  PaisesExpress: string;
  importeExpress: string;
  yearEvolucionRating: number;
  valorEvolucionRating: number;
  escoreEvolucionRating: string;
  yearUnoEvolucionRating: number;
  valorUnoEvolucionRating: number;
  escoreUnoEvolucionRating: string;
  yearDosEvolucionRating: number;
  valorDosEvolucionRating: number;
  escoreDosEvolucionRating: string;
  yearTresEvolucionRating: number;
  valortresEvolucionRating: number;
  escoreTresEvolucionRating: string;
  yearCuatroEvolucionRating: number;
  valorCuatroEvolucionRating: number;
  escoreCuatroEvolucionRating: string;
  yearEvolucionEmpleados: number;
  NumberEvolucionEmpleados: number;
  yearDosEvolucionEmpleados: number;
  NumberDosEvolucionEmpleados: number;
  yearTresEvolucionEmpleados: number;
  NumberTresEvolucionEmpleados: number;
  yearCuatroEvolucionEmpleados: number;
  NumberCuatroEvolucionEmpleados: number;
  relacionEmpresasUno: string;
  relacionEmpresasTres: string;
  yearEvolucionCapitalSocial: number;
  EvolucionCapitalSocial: String;
  yearDosCapitalSocial: String;
  dosevolucioncp: String;
  yearTresEvolucionCapitalSocial: String;
  tresevolucioncp: String;
  yearCuatroEvolucionCapitalSocial: String;
  cuartrovolucioncp: String;
  nombreDosReferenciaExpress: String;
  direccionDosReferenciaExpress: String;
  telefonoDosReferenciaExpress: String;
  contactoDosReferenciaExpress: String;
  lineaCreditoDosExpress: String;
  pagosDosReferenciaExpress: String;
  productoDosServicioReferenciaExpress: String;
  clienteDesdeDosExpress: String;
  compraMensualDosExpress: String;
  puntualidadPagosDosExpress: String;
  checksListaClinton: String;
  checksSucursales: String;
  descripcionSucursales: String;
  checksEmpresas: String;
  obligadocontabilidad: String;
  telefonoReferenciaExpress2: String;
  montosCreditoExpress: String;
  FormaPagoexpress: String;
  FormaPagoexpressDos: String;
  descriptionObligacionesLegalesExpress: String;
  descriptionObligacionesIESExpress: String;
  nombreReferenciaExpressTres: String;
  direccionReferenciaExpressTres: String;
  telefonoReferenciaExpressTres: String;
  contactoReferenciaExpressTres: String;
  lineaCreditoExpressTres: String;
  montosCreditoExpressTres: String;
  pagosReferenciaExpressTres: String;
  FormaPagoexpressTres: String;
  productoServicioReferenciaExpressTres: String;
  clienteDesdeExpressTress:String;
  compraMensualExpressTres: String;
  puntualidadPagosExpressTres: String;
  checksReferenciaPersonales: String;
  nombreReferenciaPersonal: String;
  direccionReferenciaPersonal: String;
  TelefonoReferenciaPersonal: String;
  contactoReferenciaPersonal: String;
  desdeTeimpoReferenciaPersonal: String;
  relacionReferenciaPersonal: String;
  recomendacionesReferenciaPersonal: String;
  nombreReferenciaPersonalDos: String;
  direccionReferenciaPersonalDos: String;
  TelefonoReferenciaPersonalDos: String;
  contactoReferenciaPersonalDos: String;
  nombreReferenciaPersonalTres: String;
  direccionReferenciaPersonalTres: String;
  TelefonoReferenciaPersonalTres: String;
  contactoReferenciaPersonalTres: String;
  desdeTeimpoReferenciaPersonalTres: String;
  relacionReferenciaPersonalTres: String;
  recomendacionesReferenciaPersonalTres: String;
  califica10: String;
  califica9: String;
  califica8: String;
  califica67: String;
  califica5: String;
  califica4: String;
  califica123: String;
  califica0: String;
  checksOblogacionTribuSRI: String;
  editorrelevante: Text;
 emailexpres: String;
 tipoInformeExpress: String;
 estadoFormExpress: String
}