

export class Contact1 {
    _id: string;
    tipoInforme: String = "";
    razonSocial: String = "";
    ruc: string = "";
    ciudad: String = "";
    telefono: String = "";
    email: String = "";
    lineaCredito: String = "";
    ventasAnuales: String = "";
    plazoPagoCredito: String = "";
    observaciones: String = ""
   tipocliente: String = "CLIENTE N"
    //contacto: String = "";
    noFactura: String="";
  
   
    fecha: String = new Date().toDateString();
    usuario: string;
    responsable: String;
    fechaAsignada: String;
    fechaLimite: String;
    estado: String;
}
