import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtro1'
})
export class Filtro1Pipe implements PipeTransform {
transform(value: any, arg: any[]): any {
      const resultadoProveedor = [];
  
      for(const proveedor of value){
        if(proveedor.estadoFormulario.toLowerCase().indexOf(arg) > -1){
        resultadoProveedor.push(proveedor);
        };
  
      
  
      }
      return resultadoProveedor;
    }

}
