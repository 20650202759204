import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
//import { FormProveedores } from '../models/formularioExpress';
import { FormExpress} from '../models/formularioExpress';
import { UsuarioService } from './usuario.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class FormProveedoresServiceService {
  private informesUrl = environment.URL_SERVICIOS +'/informe';  // URL to web api

// notificacion para escuchar evento
public actualizargafico = new EventEmitter<any>();

  constructor( 
    private http: HttpClient,
    public _usuarioService: UsuarioService
  ) { }

  getInformes (): Observable<FormExpress[]> {
    return this.http.get<FormExpress[]>(this.informesUrl);
  }

  getChart(){
    return this.http.get(this.informesUrl).pipe(map(result=>result));
  }

  getInforme(id: string): Observable<FormExpress> {
    const url = `${this.informesUrl}/${id}`;
    return this.http.get<FormExpress>(url);
  }

 

  addInforme (informe: FormExpress): Observable<FormExpress> {
    this.informesUrl = environment.URL_SERVICIOS +'/informe';
    return this.http.post<FormExpress>(this.informesUrl, informe);
  }

  deleteInforme (informe: FormExpress | string): Observable<FormExpress> {
    const id = typeof informe === 'string' ? informe : informe._id;
    const url = `${this.informesUrl}/${id}`;

    return this.http.delete<FormExpress>(url);
  }

 /* deleteInformeCompleto (informeCompleto: formCompleto | string): Observable<formCompleto> {
    const id = typeof informeCompleto === 'string' ? informeCompleto : informeCompleto._id;
    const url = `${this.informeCompletoUrl}/${id}`;

    return this.http.delete<formCompleto>(url, httpOptions);
  }
*/

  updateInforme (informe: FormExpress): Observable<any> {
    return this.http.put(this.informesUrl, informe);
  }

  buscarExpress( termino: string ) {
    let url = `${ environment.URL_SERVICIOS }/busqueda/coleccion/informe/${ termino }`;

    return this.http.get( url ).pipe(map((resp: any) => resp.informe));
  }
}