import { SolicitudCompra } from 'src/app/models/solicitudCompra.model';
import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/models/usuario.model';
import { Location } from '@angular/common';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Solicitud1Service } from 'src/app/services/solicitud1.service';
@Component({
  selector: 'app-historial-solicitud',
  templateUrl: './historial-solicitud.component.html',
  styleUrls: ['./historial-solicitud.component.css']
})
export class HistorialSolicitudComponent implements OnInit {
  usuario: Usuario;

  formularios : SolicitudCompra [];

  constructor(private _usuarioService: UsuarioService, private listainforme: Solicitud1Service, private location: Location,) { }

  ngOnInit() { 

    this.usuario = this._usuarioService.usuario;
    this.getFormularios();
  }



  getFormularios() {
    const usuario = JSON.parse(localStorage.getItem('usuario')) as Usuario;
    this.listainforme.getProveedores3(usuario._id).subscribe(
      result => { 
         this.formularios =  result 
         console.log(this.formularios)
     });
  }



    atras(): void {
      this.location.back();
    }
    
actualizar(){
  this.getFormularios();

}

}