import { Usuario } from './usuario.model';


export class Contact3 {
    _id: string;
    tipoInforme: String = "";
    razonSocial: String = "";
    ruc: String = "";
    ciudad: String = "";
    telefono: String = "";
    email: String = "";
    lineaCredito: String = "";
    ventasAnuales: String = "";
    plazoPagoCredito: String = "";
    observaciones: String = ""
    tipocliente: String = "CLIENTE SR";
    //contacto: String = "";
    noFactura: String ;
    urlPDF:string;
   
    fecha: String = new Date().toDateString();
    usuario: Usuario;
    responsable: String;
    fechaAsignada: String;
    fechaLimite: String;
    estado: String ="0";
}