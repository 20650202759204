import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cien'
})
export class CienPipe implements PipeTransform {

  transform(value: String,): any {
    switch(value){
      case 'porcentajePatrimonioPasivo1':
        return '100';
    }
 
  }

}
