import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { AlertaService } from 'src/app/services/alerta.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Usuario } from 'src/app/models/usuario.model';

declare function init_plugins();
@Component({
  selector: 'app-login-admin',
  templateUrl: './login-admin.component.html',
  styleUrls: ['./login-admin.component.css']
})
export class LoginAdminComponent implements OnInit {
  recuerdame: boolean = false;
  email: string = '';
  constructor( private router: Router,
    private _usuarioService: UsuarioService,
    private _alertaService: AlertaService) { }

  ngOnInit() {
    init_plugins();
    this.email = localStorage.getItem('email') || '';
  }


  ingresar( form: NgForm ) {
    if ( !form.valid ) {
      this._alertaService.mostrarAlerta('warning', 'Hay errores en los campos');

      return;
    }

    let usuario = new Usuario(
      null,
      form.value.email,
      form.value.password
    );

    this._usuarioService.login( usuario, form.value.recuerdame ).subscribe(res => {
    
      this.router.navigate(['/Inicio']);
      Swal.fire('Login', `Hola  has iniciado sesión con éxito!`, 'success');
    },
    err => {
      if (err.status = (409)) {
        Swal.fire('Error Login', 'Usuario o clave incorrectas!', 'error');
      }
      console.log(err)
    },
    );
  }




}
