import { Pipe, PipeTransform } from '@angular/core';
import { isUndefined } from 'util';
@Pipe({
  name: 'filtro'
})
export class FiltroPipe implements PipeTransform {

  transform(value: any, arg: any): any {
    const reporteComercial = [];
    if(arg.length < 3||arg===""){
      return value;
    };
    
   
    for(const lista of value){
      if (isUndefined)
      if (typeof lista.razonSocial !== "undefined") {
        if (lista.razonSocial.toLowerCase().indexOf(arg.toLowerCase()) > -1) {
          reporteComercial.push(lista);
        }
      }
     if (typeof lista.ruc !== "undefined") {
        if (lista.ruc.toLowerCase().indexOf(arg.toLowerCase()) > -1) {
          reporteComercial.push(lista);
        }
      }
      if (typeof lista.tipoInforme !== "undefined") {
        if (lista.tipoInforme.toLowerCase().indexOf(arg.toLowerCase()) > -1) {
          reporteComercial.push(lista);
        }
      }

      if (typeof lista.fechaReporte !== "undefined") {
        if (lista.fechaReporte.toLowerCase().indexOf(arg.toLowerCase()) > -1) {
          reporteComercial.push(lista);
        }
      }
      console.log(lista);
    }
    return reporteComercial;
  }



}
