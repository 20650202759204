import { Injectable } from '@angular/core';
import { UsuarioComercialService } from './usuario-comercial.service';

@Injectable({
  providedIn: 'root'
})
export class SidebarComercialService {
  menu: any[] = [];
  constructor(
    public _usuarioService: UsuarioComercialService
  ) { }

  cargarMenu() {
    this.menu = this._usuarioService.menu;
  }

}