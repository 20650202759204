import { Component, OnInit } from '@angular/core';
import { formCompleto } from 'src/app/models/formularioCompleto';
import { ActivatedRoute } from '@angular/router';
import { CompletoService } from 'src/app/services/completo.service';
import { ListainformesService } from 'src/app/services/listainformes.service';
import { FormProveedoresServiceService } from 'src/app/services/form-proveedores-service.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-informecompleto-editable',
  templateUrl: './informecompleto-editable.component.html',
  styleUrls: ['./informecompleto-editable.component.css']
})
export class InformecompletoEditableComponent implements OnInit {
  completosInf: formCompleto[];
  message: String;

  infCompleto = new formCompleto();

  desde: number = 0;

  totalRegistros: number = 0;
 



  

 
  cargando: boolean = true;
 
  constructor(private completoService: CompletoService, private informeService: FormProveedoresServiceService,private listainforme:ListainformesService,
    private route: ActivatedRoute,
    private location: Location) {

  }

  ngOnInit() {
    this.getInformeCompleto();
    
 
  }
  getInformeCompleto1(id) {
   
    this.completoService.getInformeCompleto(id).subscribe(
      informesCompletos => {
       
      console.log(informesCompletos);
      this.infCompleto = informesCompletos;
    }
    );
  }


  recargarPagina () {
 
    window.location.reload(true);
  };
  getInformeCompleto() {

    this.completoService.getInformesCompletos().subscribe(informesCompletos => {
      console.log(informesCompletos);
      this.completosInf = informesCompletos; 
      this.cargando = false;
    }
    );
  }



  buscarCompleto(termino: string) {

    if (termino.length <= 0) {
      this.getInformeCompleto()
      return;
    }



    this.completoService.buscarCompletos(termino)
      .subscribe((completosInf: formCompleto[]) => {

        this.completosInf = completosInf;

      });

  };



 
 // delete(): void {
   // this.completoService.deleteInformeCompleto(this.infCompleto).subscribe(result=>this.message="Se elimino");
  
    //Swal.fire({
    
      //title: 'Eliminado',
      //text: 'Datos Eliminados Correctamente',
      //type: 'success'
    //});
  //}
//;
}
