import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ListainformesService } from 'src/app/services/listainformes.service';
import { ActivatedRoute } from '@angular/router';
import { Usuario } from 'src/app/models/usuario.model';
import { Contact3 } from 'src/app/models/contactTarea';

//subir archivos importaciones drog
import { DragdropService } from "../../../services/dragdrop.service";
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-formulario-express',
  templateUrl: './formulario-express.component.html',
  styleUrls: ['./formulario-express.component.css']
})
export class FormularioExpressComponent implements OnInit {
  formularioUsuario: FormGroup;

  urlPDF:string;
 
//variables drop
fileArr = [];
imgArr = [];
fileObj = [];
//form: FormGroup;
msg: string;
progress: number = 0;


  tipoInforme: String;
  razonSocial: String;
  ruc: string ;
  ciudad: String;
  telefono: String;
  email: String;
  lineaCredito: String;
  ventasAnuales: String;
  plazoPagoCredito: String;
  observaciones: String;


lista = new Contact3();
submitted = false;


constructor(
  
  private fb: FormBuilder, private listainforme:ListainformesService, private route: ActivatedRoute,  private sanitizer: DomSanitizer,  public dragdropService: DragdropService ) {
    this.buildForm();
  }
ngOnInit() {

 /* const id = this.route.snapshot.paramMap.get('id');
  this.listainforme.getCustomer(id)
    .subscribe(lista => this.lista = lista); */


}


buildForm() {
  /**
   * @description Asignamos a la propiedad "formularioUsuario" los campos que se van a controlar de la vista
   */
  this.formularioUsuario = this.fb.group({
    tipoInforme: ['express', [Validators.required, Validators.minLength(3), Validators.maxLength(45)]],
    razonSocial: ['', [Validators.required, Validators.maxLength(30)]],
    ruc: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(450)]],
    ciudad: ['', [ Validators.minLength(5), Validators.maxLength(45)]],
    telefono: ['', [ Validators.minLength(10), Validators.maxLength(10)]],
    email: [''],
    lineaCredito: ['', [ Validators.minLength(3), Validators.maxLength(45)]],
    ventasAnuales:[''],
    plazoPagoCredito: ['', [ Validators.minLength(3), Validators.maxLength(45)]],
    observaciones: [''],
    avatar: [null],
  
});
}

newLista(): void {
  this.submitted = false;
  this.lista = new Contact3();
}

addLista() {
  this.submitted = true;
  this.save();

}
 
private save(): void {
  console.log(this.lista);
  this.lista.urlPDF = this.urlPDF;
  this.lista.usuario = JSON.parse(localStorage.getItem('usuario')) as Usuario;
  this.listainforme.addCustomer1(this.lista)
      .subscribe();
      Swal.fire(
        'Muy Bien',
         'Datos Guardados Éxitosamente',
         'success'
       );
        this.onResetForm();
}


onResetForm() {
  this.formularioUsuario.reset();

}

 //nueva importacion de archivos drop
 upload(e) {
  const fileListAsArray = Array.from(e);
  fileListAsArray.forEach((item, i) => {
    const file = (e as HTMLInputElement);
    const url = URL.createObjectURL(file[i]);
    this.imgArr.push(url);
    this.fileArr.push({ item, url: url });
  })

  this.fileArr.forEach((item) => {
    this.fileObj.push(item.item)
  })

  // Set files form control
  this.formularioUsuario.patchValue({
    avatar: this.fileObj
  })

  this.formularioUsuario.get('avatar').updateValueAndValidity()

  // Upload to server
  this.dragdropService.addFiles(this.formularioUsuario.value.avatar)
    .subscribe((event: HttpEvent<any>) => {
   //   console.log(event.body.userCreate);
      switch (event.type) {
        case HttpEventType.Sent:
          console.log('Request has been made!');
          break;
        case HttpEventType.ResponseHeader:
          console.log('Response header has been received!');
          break;
        case HttpEventType.UploadProgress:
          this.progress = Math.round(event.loaded / event.total * 100);
          console.log(`Uploaded! ${this.progress}%`);
          break;
        case HttpEventType.Response:
          console.log('File uploaded successfully!', event.body);
           this.urlPDF= event.body.userCreated.avatar[0];
          setTimeout(() => {
            this.progress = 0;
            this.fileArr = [];
            this.fileObj = [];
            this.msg = "Archivo subido corretamente!"
            
          }, 3000);
      }
    })
}

// Clean Url for showing image preview
sanitize(url: string) {
  return this.sanitizer.bypassSecurityTrustUrl(url);
}

}