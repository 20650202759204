import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SolicitudCompra } from '../models/solicitudCompra.model';

import { UsuarioService } from './usuario.service';

import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { SubirArchivoService } from './subir-archivo.service';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class Solicitud1Service {
  token: string;
  solicitud : SolicitudCompra;


  private informeSolicitudUrl = environment.URL_SERVICIOS +'/solicitud1';  // URL to web api
  private listaUrl1 = environment.URL_SERVICIOS +'/solicitud1/comprados';
  private listaUrl2 = environment.URL_SERVICIOS +'/solicitud1/todos';
  constructor( private http: HttpClient,public _usuarioService: UsuarioService,  private _subirArchivoService: SubirArchivoService) { }
  

  getInformesCompletos(): Observable<SolicitudCompra[]> {
    return this.http.get<SolicitudCompra[]>(this.informeSolicitudUrl);

  }

  getInformesCompletos1(): Observable<SolicitudCompra[]> {
    return this.http.get<SolicitudCompra[]>(this.listaUrl2);

  }
  getInformeCompleto(id: string): Observable<SolicitudCompra> {
    const url = `${this.informeSolicitudUrl}/${id}`;
    return this.http.get<SolicitudCompra>(url);
  }


  getProveedores3 (usuario_id:string): Observable<SolicitudCompra[]> {
    return this.http.get< SolicitudCompra[]>(this.listaUrl1+'?usuario_id='+usuario_id)
     }
   

  addInformeCompleto (informeCompleto: SolicitudCompra): Observable<SolicitudCompra> {

    this.informeSolicitudUrl = environment.URL_SERVICIOS +'/solicitud1/solito'+'?token=' + this._usuarioService.token;
    return this.http.post<SolicitudCompra>(this.informeSolicitudUrl, informeCompleto);
  }

  deleteInformeCompleto (informeCompleto: SolicitudCompra | string): Observable<SolicitudCompra> {
    const id = typeof informeCompleto === 'string' ? informeCompleto : informeCompleto._id;
    const url = `${this.informeSolicitudUrl}/${id}`;

    return this.http.delete<SolicitudCompra>(url, httpOptions);
  }

  updateInformeCompleto (informeCompleto: SolicitudCompra): Observable<any> {
    return this.http.put(this.informeSolicitudUrl, informeCompleto);
  }

  buscarUsuarios( termino: string ) {
    let url = `${ environment.URL_SERVICIOS }/busqueda/coleccion/solicitud1/${ termino }`;

    return this.http.get( url ).pipe(map((resp: any) => resp.solicitud1));
  }


  actualizarSolicitud( solicitud: SolicitudCompra ) {
    let url = `${ environment.URL_SERVICIOS }/solicitud1/${ solicitud._id }?token=${ this.token }`;

    return this.http.put(url, solicitud).pipe(map((resp: any) => {

      if ( solicitud._id === this.solicitud._id ) {
        let usuarioDB = resp.solicitud;
       // this.guardarStorage(usuarioDB._id, this.token,);
      }
      Swal.fire('Usuario actualizado', solicitud.tipoinforme.toString(), 'success' );
      console.log(resp)
      return resp;
     
    }));
  }


  updateProveedor1 (proveedor: SolicitudCompra ): Observable<any> {
    //   this.proveedoresUrl += '?token=' + this._usuarioService.token;
       return this.http.put(this.informeSolicitudUrl, proveedor);
     }


  cambiarImagen( archivo: File, id: string ) {

    this._subirArchivoService.subirArchivo( archivo, 'usuarios', id )
          .then( (resp: any) => {

            this.solicitud.img = resp.solicitud.img;
            Swal.fire( 'Imagen Actualizada', this.solicitud.empresainforme, 'success' );
           // this.guardarStorage( id, this.token, this.usuario, this.menu );

          })
          .catch( resp => {
            console.log( resp );
          }) ;

  }

}


