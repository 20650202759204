import { Component, OnInit } from '@angular/core';

import { UsuarioComercial } from 'src/app/models/usuarioComercial';
import { UsuarioComercialService } from 'src/app/services/usuario-comercial.service';
import { SidebarComercialService } from 'src/app/services/sidebar-comercial.service';
import { Usuario } from 'src/app/models/usuario.model';



declare function init_plugins();
@Component({
  selector: 'app-sidebar-compras',
  templateUrl: './sidebar-compras.component.html',
  styleUrls: ['./sidebar-compras.component.css']
})
export class SidebarComprasComponent implements OnInit {
  usuario: Usuario;
  constructor(private _usuarioService: UsuarioComercialService,  public _sidebar: SidebarComercialService,
    ) { }

  ngOnInit() {
    init_plugins();
    this.usuario = this._usuarioService.usuario;
    this._sidebar.cargarMenu();

  }
cerrarSesion(){
  this._usuarioService.logOut()
}
}