import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtro2'
})
export class Filtro2Pipe implements PipeTransform {

  transform(value: any, arg: any[]): any {
    const resultadoProveedor = [];
  
      for(const solicitudModel of value){
        if(solicitudModel.razonSocialCalificacionProveedor.toLowerCase().indexOf(arg) > -1){
        resultadoProveedor.push(solicitudModel);
        };
  
      }
      return resultadoProveedor;
    }
}
