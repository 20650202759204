import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UsuarioService } from './usuario.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class LoginComercialGuard implements CanActivate {

  constructor(
    public _usuarioService: UsuarioService
  ) { }
  canActivate(){

    if ( this._usuarioService.usuario.role === 'CLIENTE_COMERCIAL_ROLE' ) {
      return true;
    }else {
      console.log( 'Bloqueado por el COMERCIAL GUARD');
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'No es usuario de informes comerciales',
      
      });
      this._usuarioService.logOutComerciales();
      return false;
    }

  }
  
}
