import { Component, OnInit } from '@angular/core';
import { FormExpress } from 'src/app/models/formularioExpress';
import { FormProveedoresServiceService } from 'src/app/services/form-proveedores-service.service';

@Component({
  selector: 'app-informeexpress-editable',
  templateUrl: './informeexpress-editable.component.html',
  styleUrls: ['./informeexpress-editable.component.css']
})
export class InformeexpressEditableComponent implements OnInit {
  expressosInf: FormExpress[]=[];
  formExpress = new FormExpress();

  desde: number = 0;
  cargando: boolean = true;
  constructor( private informeService: FormProveedoresServiceService,) { }

  ngOnInit() {
    this.getInformeExpress();
  }


  getInformeExpress() {
    this.informeService.getInformes().subscribe(informesExpress => {
      console.log(informesExpress);
      this.expressosInf = informesExpress;
      this.cargando = false;
     
    }
    );
  }



  buscarExprees(termino: string) {

    if (termino.length <= 0) {
      this.getInformeExpress();
      return;
    }

this.informeService.buscarExpress(termino)
      .subscribe((  expressosInf: FormExpress[]) => {

        this.expressosInf = expressosInf;
         console.log(expressosInf)
      });

  }
}
