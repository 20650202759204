import { Userdocumento } from './../models/file.models';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';

import { Usuario } from '../models/usuario.model';
import { UsuarioService } from './usuario.service';
import { environment } from 'src/environments/environment';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class DragdropService {
  public notificacion = new EventEmitter<any>();
  usuario: Usuario;
  token: string;
  private proveedoresUrl = environment.URL_SERVICIOS + '/api/create-user';
  private Url = environment.URL_SERVICIOS + '/eliminar';
  constructor(private http: HttpClient, public _usuarioService: UsuarioService) { }

  addFiles(images: File) {
    var arr = []
    var formData = new FormData();
    arr.push(images);

    arr[0].forEach((item, i) => {
      formData.append('avatar', arr[0][i]);
    })
    this.proveedoresUrl = environment.URL_SERVICIOS + '/api/create-user' +'?token=' + this._usuarioService.token;
    return this.http.post( this.proveedoresUrl, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      catchError(this.errorMgmt)
    )
  }

  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }




  getDocumentos (): Observable<any[]> {
    return this.http.get<any[]>(environment.URL_SERVICIOS +'/api')
  }



 deleteCustomer (contacto:  Userdocumento | string): Observable< Userdocumento> {
  const id = typeof contacto === 'string' ? contacto : contacto._id;
  const url = `${this.Url}/${id}`;

  return this.http.delete<Userdocumento>(url, httpOptions);
}


}
