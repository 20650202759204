export class FormCalificacion {
    _id: string;
    //select
    //fechaActual:Date;
    /*cartaPresentacionRequisito:String;
    ruRequisito:String;
    certificadoSriRequisito:String;
    planillaIessRequisito:String;
    certfSuperintendeciaRequisito:String;
    predialRequisito:String;
    patenteMunicipalRequisito:String;*/
  
    //campos
    tipoProveedorCalificacion:String;
    //razonSocialCalificacion:String;
    //nombreComercialCalificacion:String;
    //rucCalificacion:String;
    formaLegalCalificacion:String;
    capitalSPCalificacion:Number;
    estadoCalificacion:String;
    iniActiCalificacion:Date;
    objSocialCalificacion:String;
    actPrinCalificacion:String;
    //ubicacionCalificacion:String;
    //direccionCalificacion:String;
    //telfCallificacion:String;
    //pagWebCalificacion:String;
    //emailCalificacion:String;
    representanteLegalCalificacion:String;
    representLegalCedCalificacion:String;
    //personContactlCalificacion:String;
    //personCargolCalificacion:String;
    //personEmaillCalificacion:String;
    //empresaRelacionlCalificacion:String;
    //grupoEcolCalificacion:String;
    codActivCalificacion:String;
    impuestoRentaCalificacion:Number;
    impuestoRentaCalificacion1:Number;
    impuestoDivisasCalificacion:Number;
    impuestoDivisasCalificacion1:Number;

     /*----------  Campos Informacion Financiera  ----------------*/
     
  //Activos Corriente Parte 1
  cajaCorrienteYear1: number;
  cajaCorrienteYear2Porcentaje:number;
  inversionCorrienteYear1: number;
  localesRelacionadosCorrienteYear1: number;
  localesNoRelacionadosCorrienteYear1: number;
  cuentasRelacionadosCorrienteYear1: number;
  cuentasNoRelacionadosCorrienteYear1: number;
  provisionCorrienteYear1: number;
  creditosCorrienteYear1: number;
  inventariosCorrienteYear1: number;
  pagadosCorrienteYear1: number;
  otrosCorrienteYear1: number;
  /*Suma Total de Activo Corriente Year 1*/
  year: number ;

  //porcentaje Activo Corriente Year 1 
  porcentajeCorrienteYear1: Array<number> ;

  //Activos Corriente Parte 2
  cajaCorrienteYear2: number;
  inversionCorrienteYear2: number;
  localesRelacionadosCorrienteYear2: number;
  localesNoRelacionadosCorrienteYear2: number;
  cuentasRelacionadosCorrienteYear2: number;
  cuentasNoRelacionadosCorrienteYear2: number;
  provisionCorrienteYear2: number;
  creditosCorrienteYear2: number;
  inventariosCorrienteYear2: number;
  pagadosCorrienteYear2: number;
  otrosCorrienteYear2: number;
  /*Suma Total de Activo Corriente Year 2*/
  year1: number ;

  //porcentaje Activo Corriente Year 2 
  porcentajeCorrienteYear2:Array<number> ;


  //Activos Diferido Parte 1
  totalActivoFijo1: number;
  totalActivoDiferido1: number;
  inversionNoCorriente1: number;
  otrasInversiones1: number;
  cuentasNoCorrientes1: number;
  cuentasNoCorrientesNoRelacionadas1: number;
  otrosNoCorriente1: number;
  otrosCorrienteNoRelacionados1: number;
  provisionCuentas1: number;
  otrosActivosYear1: number;
  /*Suma Total de Activo Diferido Year 1*/
  activo1: number ;

  //porcentaje Activo Diferido Year 1 
  porcentajeDiferidoYear1:Array<number> ;

  //Activos Diferido Parte 2
  totalActivoFijo2: number;
  totalActivoDiferido2: number;
  inversionNoCorriente2: number;
  otrasInversiones2: number;
  cuentasNoCorrientes2: number;
  cuentasNoCorrientesNoRelacionadas2: number;
  otrosNoCorriente2: number;
  otrosCorrienteNoRelacionados2: number;
  provisionCuentas2: number;
  otrosActivosYear2: number
  /*Suma Total de Activo Diferido Year 2*/
  activo2: number;

  //porcentaje Activo Diferido Year 2 
  porcentajeDiferidoYear2:Array<number>;

  /*Suma Total Year 1 & Year 2*/
  totalBalanceActivoYear1: number;
  totalBalanceActivoYear2: number ;

  /*---------            Pasivo y Patrimonio ---------------*/

  /*campos Pasivos*/
  activoCuentasPasivo1: number;
  pasivoCuentasNoRelacionados1: number;
  pasvioObligaciones1: number;
  pasivoPrestamosAccionistas1: Number;
  pasivosCuentasCorrrientesRelacionados1: number;
  pasivoCuentasNoRelacionadosPorPagar1: number;
  pasivoImpuestoRentaPorPagar1: number;
  pasivoParticipacionTrabajadores1: number;
  pasivosOtrosPasivosCrrientes1: number;
  porPagarProveedoresRelacionados: number;
  pasivos1: number ;
  porcentajeCorrientePasivos1: Array<number> ;
  porcentajeAnioTotal1: number ;

  activoCuentasPasivo2: number;
  pasivoCuentasNoRelacionados2: number;
  pasvioObligaciones2: number;
  pasivoPrestamosAccionistas2: number;
  pasivosCuentasCorrrientesRelacionados2: number;
  pasivoCuentasNoRelacionadosPorPagar2: Number;
  pasivoImpuestoRentaPorPagar2: number;
  pasivoParticipacionTrabajadores2: number;
  pasivosOtrosPasivosCrrientes2: number;
  pasivos2: number ;
  porcentajeCorrientePasivos2: Array<number> ;
  porcentajePasivosTotal2: number ;
  porcentajeAnioTotal2: number ;



  pasivoCuentaPorPagarProveedoresAnio: number;
  pasivoCuentasNoCorrientesNoRelacionalAnio: number;
  pasivoObligacionesInstitucionesAnio: number;
  pasivoPrestamosAccionistasAnio: number;
  pasivoOtrasCuentasPorPagarAnio: number;
  pasivoOtrasCuentasPorPagarNoCorrientesAnio: number;
  pasivosOtroPasivoNoCorrienteAnio: number;
  anio: number ;
  porcentajeDiferidoPasivos1: Array<number> ;
  porcentajeDiferidosTotal1: number ;

  pasivoCuentaPorPagarProveedoresAnio1: number;
  pasivoCuentasNoCorrientesNoRelacionalAnio1: number;
  pasivoObligacionesInstitucionesAnio1: number;
  pasivoPrestamosAccionistasAnio1: number;
  pasivoOtrasCuentasPorPagarAnio1: number;
  pasivoOtrasCuentasPorPagarNoCorrientesAnio1: number;
  pasivosOtroPasivoNoCorrienteAnio1: number;
  anio1: number ;
  porcentajeDiferidoPasivos2: Array<number> ;
  porcentajeDiferidosTotal2: number ;

  /* Patrimonio Neto */

  //Parte 1
  capitalPatrimonio1: number ;
  integralesPatrimonio1: number ;
  aportesPatrimonio1: number ;
  reservasPatrimonio1: number ;
  patrimonioNIIF1: number ;
  utilidadPatrimonio1: number ;
  acumuladaPatrimonio1: number ;
  ejercicioPatrimonio1: number ;
  patrimonio1: number ;
  porcentajeDiferidoPatrimonio1: Array<number> ;

  //Parte2
  capitalPatrimonio2: number ;
  integralesPatrimonio2: number ;
  aportesPatrimonio2: number ;
  reservasPatrimonio2: number ;
  patrimonioNIIF2: number ;
  utilidadPatrimonio2: number ;
  acumuladaPatrimonio2: number ;
  ejercicioPatrimonio2: number ;
  patrimonio2: number ;
  porcentajeDiferidoPatrimonio2: Array<number> ;

  //TOTAL SUMA PORCENTAJES
  totalBalancePasivos1: number ;
  totalBalancePasivos2: number ;
  totalPorcentajePasivos1: number ;
  totalPorcentajePasivos2: number ;
  totalPatrimonio: number ;
  totalPatrimonio2: number ;
  porcentajePatrimonioTotal1: number ;
  porcentajePatrimonioTotal2: number ;

  //Total Patrimonio y Pasivo
  totalPasivoYPatrimonio: number ;
  totalPasivoYPatrimonio2: number ;

  
  /*-----------     ESTADO DE RESULTADO (USD) UNIDADES           -----------*/

  //Utilidad Bruta Year 1
  ventasTotalesYear1: number ;
  costoVenta: number ;
  utilidadBruta: number ;

  //Utilidad Operacional Year 1
  gatosAdministrativos: number ;
  utilidadOpercional: number ;

  //Utilidad antes de Impuestos Year1
  ingresoNoOperacional: number ;
  gastosNoOperacionales: number ;
  utilidadAntesImpuestos: number ;

  //Utilidad Tributable Year1
  participacionTrabajador: number ;
  utilidadTributable: number ;

  //Utilidad Repartible Year 1
  impuestoRenta: number ;
  utilidadRepartible: number ;

  //porcentaje Year1
  porcentajeUtilidadesYear1: Array<number> ;

  //utilidades Parte 2

  //Utilidad Bruta Year 2
  ventasTotalesYear2: number ;
  costoVenta2: number ;
  utilidadBruta2: number ;

  //Utilidad Operacional Year 2
  gatosAdministrativos2: number ;
  utilidadOpercional2: number ;

  //Utilidad antes de Impuestos Year2
  ingresoNoOperacional2: number ;
  gastosNoOperacionales2: number ;
  utilidadAntesImpuestos2: number ;

  //Utilidad Tributable Year2
  participacionTrabajador2: number ;
  utilidadTributable2: number ;

  //Utilidad Repartible Year 2
  impuestoRenta2: number ;
  utilidadRepartible2: number ;

  //Porcentaje Year2
  porcentajeUtilidadesYear2: Array<number> ;
  nombreDirectivo2:String;
  checks9SiDescripcion:String;
  checks9: String;
  ventas16: Number;
  ventas17: Number;
      activos16:Number;
      activos17:Number;
      pasivos16:Number;
      pasivos17: Number;
      patrimonio16: Number;
      patrimonio17: Number;
      utilidad16: Number;
      utilidad17: Number;



  }
  