import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-layout4',
  templateUrl: './layout4.component.html',
  styleUrls: ['./layout4.component.css']
})
export class Layout4Component implements OnInit {
  constructor(){}
  ngOnInit(){

  }

}
