import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagenPipe } from './imagen.pipe';
import { FiltroPipe } from './filtro.pipe';
import { Filtro1Pipe } from './filtro1.pipe';
import { Filtro2Pipe } from './filtro2.pipe';
import { Filtro3Pipe } from './filtro3.pipe';
import { SortPipe } from './sort.pipe';
import { InformePipe } from './informe.pipe';
import { PagoPipe } from './pago.pipe';
import { CienPipe } from './cien.pipe';
import { FiltroproveedorlistaPipe } from './filtroproveedorlista.pipe';
import { EstadolistaPipe } from './estadolista.pipe';

@NgModule({
  declarations: [
    ImagenPipe,
    FiltroPipe,
    Filtro1Pipe,
    Filtro2Pipe,
    Filtro3Pipe,
    SortPipe,
    InformePipe,
    PagoPipe,
    CienPipe,
    FiltroproveedorlistaPipe,
    EstadolistaPipe
  
  ],
  imports: [
    CommonModule
  ],
  exports:[
    ImagenPipe,
    FiltroPipe,
    Filtro1Pipe,
    Filtro2Pipe,
    Filtro3Pipe,
    SortPipe,
    InformePipe,
    PagoPipe,
    EstadolistaPipe,
    FiltroproveedorlistaPipe
  ],
  providers: [
    
   
  ],
})
export class PipesModule { }
