import { Usuario } from 'src/app/models/usuario.model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { FormProveedoresServiceService } from '../../../services/serviciosProveedores/form-proveedores-service.service';
import { FormProveedores } from '../../../models/modelosProveedores/formularioProveedores';
import { UploadService } from '../../../services/serviciosProveedores/upload.service';
import { OpcionesGenerales2 } from '../../../models/modelosProveedores/opcinesGenerales2';
import { OpcionesGeneralesService } from '../../../services/serviciosProveedores/opciones-generales.service';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

import { Location } from '@angular/common';
//subir archivos importaciones drog
import { DragdropService } from "../../../services/dragdrop.service";
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';



import { UsuarioService } from 'src/app/services/usuario.service';
//alertas para ayudar al cliente
import { ToastrService } from 'ngx-toastr';
import { OpcionesGenerales3Service } from 'src/app/services/serviciosProveedores/opciones-generales3.service';
import { OpcionesGenerales3 } from 'src/app/models/modelosProveedores/opcionesGenerales3';
import { ActivatedRoute } from '@angular/router';
declare function init_plugins();
@Component({
  selector: 'app-requisitos-proveedores',
  templateUrl: './requisitos-proveedores.component.html',
  styleUrls: ['./requisitos-proveedores.component.css']
})
export class RequisitosProveedoresComponent implements OnInit {
  public isCollapsed = true;
  public isCollapsed2 = true;
  public isCollapsed3 = true;
  public isCollapsed4 = true;
  public isCollapsed5 = true;
  public isCollapsed6 = true;
  public isCollapsed7 = true;

  urlPDF:string;
//variables drop
fileArr = [];
imgArr = [];
fileObj = [];
//form: FormGroup;
msg: string;
progress: number = 0;




//metodo para archivos
  imagenSubir: File;
  imagenTemporal: any;

  //  inicio campos de ciudades y provincias 
  listaProvincias: Array<any> = [
    {
      name: "AZUAY", ciudades: ["CUENCA", "GIRÓN", "GUALACEO", "NABÓN", "PAUTE", "PUCARA", "SAN FERNANDO", "SANTA ISABEL", "SIGSIG", "OÑA", "CHORDELEG", "EL PAN", "SEVILLA DE ORO", "GUACHAPALA", "CAMILO PONCE ENRÍQUEZ"
      ]
    }, {
      name: "BOLIVAR", ciudades: ["GUARANDA", "CHILLANES", "CHIMBO", "ECHEANDÍA", "SAN MIGUEL", "CALUMA", "LAS NAVES"
      ]
    }, {
      name: "CAÑAR", ciudades: ["AZOGUES", "BIBLIÁN", "CAÑAR", "LA TRONCAL", "EL TAMBO", "DÉLEG", "SUSCAL"
      ]
    }, {
      name: "CARCHI", ciudades: ["TULCÁN", "BOLÍVAR", "ESPEJO", "MIRA", "MONTÚFAR", "SAN PEDRO DE HUACA"
      ]
    }, {
      name: "COTOPAXI", ciudades: ["LATACUNGA", "LA MANÁ", "PANGUA", "PUJILI", "SALCEDO", "SAQUISILÍ", "SIGCHOS"
      ]
    }, {
      name: "CHIMBORAZO", ciudades: ["RIOBAMBA", "ALAUSI", "COLTA", "CHAMBO", "CHUNCHI", "GUAMOTE", "GUANO", "PALLATANGA", "PENIPE", "CUMANDÁ"
      ]
    }, {
      name: "EL ORO", ciudades: ["MACHALA", "ARENILLAS", "ATAHUALPA", "BALSAS", "CHILLA", "EL GUABO", "HUAQUILLAS", "MARCABELÍ", "PASAJE", "PIÑAS", "PORTOVELO", "SANTA ROSA", "ZARUMA", "LAS LAJAS"
      ]
    }, {
      name: "ESMERALDAS", ciudades: ["ESMERALDAS", "ELOY ALFARO", "MUISNE", "QUININDÉ", "SAN LORENZO", "ATACAMES", "RIOVERDE", "LA CONCORDIA"
      ]
    }, {
      name: "GUAYAS", ciudades: ["GUAYAQUIL", "ALFREDO BAQUERIZO MORENO (JUJÁN)", "BALAO", "BALZAR", "COLIMES", "DAULE", "DURÁN", "EL EMPALME", "EL TRIUNFO", "MILAGRO", "NARANJAL", "NARANJITO", "PALESTINA", "PEDRO CARBO", "SAMBORONDÓN", "SANTA LUCÍA", "SALITRE (URBINA JADO)", "SAN JACINTO DE YAGUACHI", "PLAYAS", "SIMÓN BOLÍVAR", "CORONEL MARCELINO MARIDUEÑA", "LOMAS DE SARGENTILLO", "NOBOL", "GENERAL ANTONIO ELIZALDE", "ISIDRO AYORA"
      ]
    }, {
      name: "IMBABURA", ciudades: ["IBARRA", "ANTONIO ANTE", "COTACACHI", "OTAVALO", "PIMAMPIRO", "SAN MIGUEL DE URCUQUÍ"
      ]
    }, {
      name: "LOJA", ciudades: ["LOJA", "CALVAS", "CATAMAYO", "CELICA", "CHAGUARPAMBA", "ESPÍNDOLA", "GONZANAMÁ", "MACARÁ", "PALTAS", "PUYANGO", "SARAGURO", "SOZORANGA", "ZAPOTILLO", "PINDAL", "QUILANGA", "OLMEDO"
      ]
    }, {
      name: "LOS RIOS", ciudades: ["BABAHOYO", "BABA", "MONTALVO", "PUEBLOVIEJO", "QUEVEDO", "URDANETA", "VENTANAS", "VÍNCES", "PALENQUE", "BUENA FÉ", "VALENCIA", "MOCACHE", "QUINSALOMA"
      ]
    }, {
      name: "MANABI", ciudades: ["PORTOVIEJO", "BOLÍVAR", "CHONE", "EL CARMEN", "FLAVIO ALFARO", "JIPIJAPA", "JUNÍN", "MANTA", "MONTECRISTI", "PAJÁN", "PICHINCHA", "ROCAFUERTE", "SANTA ANA", "SUCRE", "TOSAGUA", "24 DE MAYO", "PEDERNALES", "OLMEDO", "PUERTO LÓPEZ", "JAMA", "JARAMIJÓ", "SAN VICENTE"
      ]
    }, {
      name: "MORONA SANTIAGO", ciudades: ["MORONA", "GUALAQUIZA", "LIMÓN INDANZA", "PALORA", "SANTIAGO", "SUCÚA", "HUAMBOYA", "SAN JUAN BOSCO", "TAISHA", "LOGROÑO", "PABLO SEXTO", "TIWINTZA"
      ]
    }, {
      name: "NAPO", ciudades: ["TENA", "ARCHIDONA", "EL CHACO", "QUIJOS", "CARLOS JULIO AROSEMENA TOLA"
      ]
    }, {
      name: "PASTAZA", ciudades: ["PASTAZA", "MERA", "SANTA CLARA", "ARAJUNO"
      ]
    }, {
      name: "PICHINCHA", ciudades: ["QUITO", "CAYAMBE", "MEJIA", "PEDRO MONCAYO", "RUMIÑAHUI", "SAN MIGUEL DE LOS BANCOS", "PEDRO VICENTE MALDONADO", "PUERTO QUITO"
      ]
    }, {
      name: "TUNGURAHUA", ciudades: ["AMBATO", "BAÑOS DE AGUA SANTA", "CEVALLOS", "MOCHA", "PATATE", "QUERO", "SAN PEDRO DE PELILEO", "SANTIAGO DE PÍLLARO", "TISALEO"
      ]
    }, {
      name: "ZAMORA CHINCHIPE", ciudades: ["ZAMORA", "CHINCHIPE", "NANGARITZA", "YACUAMBI", "YANTZAZA (YANZATZA)", "EL PANGUI", "CENTINELA DEL CÓNDOR", "PALANDA", "PAQUISHA"
      ]
    }, {
      name: "GALAPAGOS", ciudades: ["SAN CRISTÓBAL", "ISABELA", "SANTA CRUZ"
      ]
    }, {
      name: "SUCUMBIOS", ciudades: ["LAGO AGRIO", "GONZALO PIZARRO", "PUTUMAYO", "SHUSHUFINDI", "SUCUMBÍOS", "CASCALES", "CUYABENO"
      ]
    }, {
      name: "ORELLANA", ciudades: ["ORELLANA", "AGUARICO", "LA JOYA DE LOS SACHAS", "LORETO"
      ]
    }, {
      name: "SANTO DOMINGO DE LOS TSACHILAS", ciudades: ["SANTO DOMINGO"
      ]
    }, { name: "SANTA ELENA", ciudades: ["SANTA ELENA", "LA LIBERTAD", "SALINAS"] },
  ];
  ciudades: Array<any>;
  cambioProvincia(count) {
    this.ciudades = this.listaProvincias.find(con => con.name == count).ciudades;
  }
//fin de campos ciudades y provincias 
  //para mongo
  message: String;
  proveedor = new FormProveedores();
  uploadedFiles: Array<File>;//subida de archivos
  opcionesGenerales: OpcionesGenerales2[];//opciones que traigo de la  base
  opcionesGenerales3: OpcionesGenerales3[];
  submitted = false;
  //empieza formulario
  formularioProveedor: FormGroup;
  //documentos solicitados
  optionGeneralRequisito: String;
  optionCartaRequisito: String;
  optionRucRequisito: String;
  optionCedulaRequisito: String;
  optionCertificadoSriRequisito: String;
  optionPlanillaAporteIessRequisito: String;
  optionSuperintendenciaRequisito: String;
  optionImpPredialRequisito: String;
  optionPatenteRequisito: String;
  optionFacClienteRequisito: String;
  optionMarcasEspRequisito: String;
  optionCertificadoSisRequisito: String;
  optionCertificadoBancarioRequisito: String;
  optionCertificadoComercialRequisito: String;
  optionCertificadoProveedorRequisito: String;
  optionCertificadoCentralRiesgosRequisito: String;
  optionEstadoFinancieroRequisito: String;
  //campos
  razonSocial: String;
  nombreComercial: String;
  direccion: String;
  provincia: String;
  ruc: String;
  ciudad: String;
  telefono: String;
  celular: String;
  paginaWeb: String;
  correo: String;
  referencia: String;
  cargo: String;
  correoContacto: String;
  checks: String;
  checks1: String;
  descriptionTrue: String;
  descriptionTrue1: String;
  nombreDirectivo: String;
  cargoDirectivo: String;
  nombreParticipacion: String;
  porcentajeParticipacion: Number;
  nombreProveedor: String;
  numeroProveedor: Number;
  nombreReferenciaComercial: String;
  numeroReferenciaComercial: String;
  nombreDirectivo2: String;
  cargoDirectivo2: String;
  nombreDirectivo3: String;
  cargoDirectivo3: String;
  nombreDirectivo4: String;
  cargoDirectivo4: String;
  nombreDirectivo5: String;
  cargoDirectivo5: String;
  nombreParticipacion2: String;
  porcentajeParticipacion2: Number;
  nombreParticipacion3: String;
  porcentajeParticipacion3: Number;
  nombreParticipacion4: String;
  porcentajeParticipacion4: Number;
  nombreParticipacion5: String;
  porcentajeParticipacion5: Number;
  nombreProveedor2: String;
  numeroProveedor2: Number;
  nombreReferenciaComercial2: String;
  numeroReferenciaComercial2: String;
  checks2: String;
  checks3: String;
  checks4: String;
  checks5: String;
  checks6: String;
  checks7: String;
  checks8: String;
  checks9: String;
  checks10: String;
  checks11: String;
  checks12: String;
  checks13: String;
  checks14: String;
  checks15: String;
  checks16: String;
  checks17: String;
  checks18: String;
  checks19: String;
  checks20: String;
  checks21: String;
  checks22: String;
  checks23: String;
  checks24: String;
  checks25: String;
  checks26: String;
  checks27: String;
  checks28: String;
  checks29: String;
  ventas17: Number;
  ventas16: Number;
  activos17: Number;
  activos16: Number;
  pasivos17: Number;
  pasivos16: Number;
  patrimonio17: Number;
  patrimonio16: Number;
  utilidad17: Number;
  utilidad16: Number;
  controlNumero: Number;
  controlNumoeroPesonal: Number;

  checks2Descripcion: String;
  Chck4DescripRentada: String;
  check5Cuantas: String;
  checks8DescriMantenimiento: String;
  check11SiDescripcion: String;
  check12SiDescripcion: String;
  checks13SiDescripcion: String;
  checks14SiDescripcion: String;
  checks15SiDescripcion: String;
  checks16SiDescripcion: String;
  checks17SiDescripcionEmpr: String;
  checks18SiDescripcion: String;
  checks19DescripcionSi: String;
  checks20OtrosDescripcion: String;
  check21SiPorcentaje: String;
  checks22SiPaises: String;
  checks23SIDescripcion: String;
  checks24SiDescripcion: String;
  check25SiDescripcion: String;
  checks26SiDescripcion: String;
  checks27SiDescripcion: String;
  checks28SiDescripcion: String;
  checks29SiDescripcion: String;
  totalUnidadesUno: Number;
  totalUnidadesDos: Number;

  checks20Uno: String;
  checks20Dos: String;
  checks20Tres: String;
  checks20Cuatro: String;
  checks20Cinco: String;
  checks20Seis: String;
  checks2Uno: String;
  checks2Dos: String;
  checks2Tres: String;
  checks2Cuatro: String;
  checks27U:String;
estadoFormulario:string;
checks9SiDescripcion:string;



  // nuevos campos


  emailPattern = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  //textPattern= new RegExp(/^[a-zA-Z ]+$/);
  //new RegExp(/^[A-Z, a-z]{1}[a-zA-ZñÑáéíóúÁÉÍÓÚ\s/]+[^<>()[\]\\.,;:\s@\"\s]+$/);
  textPattern = new RegExp(/^[A-Z]{1}[a-zA-ZñÑáéíóúÁÉÍÓÚ&:;0-9.,-/\s]+$/);
  numberPattern =  new RegExp(/^[0-9/-]+$/);
  pageWebPattern = new RegExp(/^[w,a-z,A-Z/.-]+([\./]+[\w]+)+$/);

  constructor(public fb: FormBuilder, private proveedoresService: FormProveedoresServiceService, private uploadService: UploadService, private opcionesServices: OpcionesGeneralesService,private toastr: ToastrService,    
    private sanitizer: DomSanitizer,private opcionesServices3: OpcionesGenerales3Service,private route: ActivatedRoute,
    public dragdropService: DragdropService, private location: Location ) {
    this.formularioProveedor = this.fb.group({
      estadoFormulario:[''],
      avatar: [null],
      optionGeneralRequisito: ['', [Validators.required]],
      optionCartaRequisito: ['', [Validators.required]],
      optionRucRequisito: ['', [Validators.required]],
      optionCedulaRequisito: ['', [Validators.required]],
      optionCertificadoSriRequisito: ['', [Validators.required]],
      optionPlanillaAporteIessRequisito: ['', [Validators.required]],
      optionSuperintendenciaRequisito: ['', [Validators.required]],
      optionImpPredialRequisito: ['', [Validators.required]],
      optionPatenteRequisito: ['', [Validators.required]],
      optionFacClienteRequisito: ['', [Validators.required]],
      optionMarcasEspRequisito: ['', [Validators.required]],
      optionCertificadoSisRequisito: ['', [Validators.required]],
      optionCertificadoBancarioRequisito: ['', [Validators.required]],
      optionCertificadoComercialRequisito: ['', [Validators.required]],
      optionCertificadoProveedorRequisito: ['', [Validators.required]],
      optionCertificadoCentralRiesgosRequisito: ['', [Validators.required]],
      optionEstadoFinancieroRequisito: ['', [Validators.required]],
      razonSocial: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60)]],
      nombreComercial: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60)]],
      direccion: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(300)]],
      provincia: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30),Validators.pattern(this.textPattern)]],
      ruc: ['', [Validators.required, Validators.minLength(13), Validators.maxLength(13), Validators.pattern(this.numberPattern)]],
      ciudad: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      telefono: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(30)]],
      celular:['', [Validators.required, Validators.minLength(10), Validators.maxLength(30), ]],
      correo: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      paginaWeb: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60),Validators.pattern(this.pageWebPattern)]],
      referencia: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      cargo: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60)]],
      correoContacto: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      checks: ['', [Validators.required]],
      checks1: ['', [Validators.required]],
      descriptionTrue: ['', [Validators.required]],
      descriptionTrue1: ['', [Validators.required]],
      nombreDirectivo: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      cargoDirectivo: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60)]],
      nombreParticipacion: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      porcentajeParticipacion: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(30)]],
      nombreProveedor: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), ]],
      numeroProveedor: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(30), ]],
      nombreReferenciaComercial: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60)]],
      numeroReferenciaComercial: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(30)]],
      nombreDirectivo2: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      cargoDirectivo2: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), ]],
      nombreDirectivo3: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      cargoDirectivo3: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), ]],
      nombreDirectivo4: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      cargoDirectivo4: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), ]],
      nombreDirectivo5: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      cargoDirectivo5: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60) ]],
      nombreParticipacion2: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(this.textPattern)]],
      porcentajeParticipacion2: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(30)]],
      nombreParticipacion3: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      porcentajeParticipacion3: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(30)]],
      nombreParticipacion4: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      porcentajeParticipacion4: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(30)]],
      nombreParticipacion5: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(this.textPattern)]],
      porcentajeParticipacion5: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(30)]],
      nombreProveedor2: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60)]],
      numeroProveedor2: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(30)]],
      nombreReferenciaComercial2: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60)]],
      numeroReferenciaComercial2: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(30)]],

      checks2Descripcion: ['', [Validators.required]],
      Chck4DescripRentada: ['', [Validators.required]],
      check5Cuantas: ['', [Validators.required]],
      checks8DescriMantenimiento: ['', [Validators.required]],
      checks10SiDescripcion: ['', [Validators.required]],
      check11SiDescripcion: ['', [Validators.required]],
      check12SiDescripcion: ['', [Validators.required]],
      checks13SiDescripcion: ['', [Validators.required]],
      checks14SiDescripcion: ['', [Validators.required]],
      checks15SiDescripcion: ['', [Validators.required]],
      checks16SiDescripcion: ['', [Validators.required]],
      checks17SiDescripcionEmpr: ['', [Validators.required]],
      checks18SiDescripcion: ['', [Validators.required]],
      checks19DescripcionSi: ['', [Validators.required]],
      checks20OtrosDescripcion: ['', [Validators.required]],
      check21SiPorcentaje: ['', [Validators.required]],
      checks22SiPaises: ['', [Validators.required]],
      checks23SIDescripcion: ['', [Validators.required]],
      checks24SiDescripcion: ['', [Validators.required]],
      check25SiDescripcion: ['', [Validators.required]],
      checks26SiDescripcion: ['', [Validators.required]],
      checks27SiDescripcion: ['', [Validators.required]],
      checks28SiDescripcion: ['', [Validators.required]],
      checks29SiDescripcion: ['', [Validators.required]],
      totalUnidadesUno: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      totalUnidadesDos: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],

      checks2: ['', [Validators.required]],
      checks3: ['', [Validators.required]],
      checks4: ['', [Validators.required]],
      checks5: ['', [Validators.required]],
      checks6: ['', [Validators.required]],
      checks7: ['', [Validators.required]],
      checks8: ['', [Validators.required]],
      checks9: ['', [Validators.required]],
      checks10: ['', [Validators.required]],
      checks11: ['', [Validators.required]],
      checks12: ['', [Validators.required]],
      checks13: ['', [Validators.required]],
      checks14: ['', [Validators.required]],
      checks15: ['', [Validators.required]],
      checks16: ['', [Validators.required]],
      checks17: ['', [Validators.required]],
      checks18: ['', [Validators.required]],
      checks19: ['', [Validators.required]],
      checks20: ['', [Validators.required]],
      checks21: ['', [Validators.required]],
      checks22: ['', [Validators.required]],
      checks23: ['', [Validators.required]],
      checks24: ['', [Validators.required]],
      checks25: ['', [Validators.required]],
      checks26: ['', [Validators.required]],
      checks27: ['', [Validators.required]],
      checks28: ['', [Validators.required]],
      checks29: ['', [Validators.required]],
      ventas16: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10), Validators.pattern(this.numberPattern)]],
      ventas17: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10), Validators.pattern(this.numberPattern)]],
      activos16: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10), Validators.pattern(this.numberPattern)]],
      activos17: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10), Validators.pattern(this.numberPattern)]],
      pasivos16: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10), Validators.pattern(this.numberPattern)]],
      pasivos17: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10), Validators.pattern(this.numberPattern)]],
      patrimonio16: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10), Validators.pattern(this.numberPattern)]],
      patrimonio17: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10), Validators.pattern(this.numberPattern)]],
      utilidad16: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10), Validators.pattern(this.numberPattern)]],
      utilidad17: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10), Validators.pattern(this.numberPattern)]],
      controlNumero: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
      controlNumoeroPesonal: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
      checks20Uno: ['', [Validators.required]],
      checks20Dos: ['', [Validators.required]],
      checks20Tres: ['', [Validators.required]],
      checks20Cuatro: ['', [Validators.required]],
      checks20Cinco: ['', [Validators.required]],
      checks20Seis: ['', [Validators.required]],
      checks2Uno: ['', [Validators.required]],
      checks2Dos: ['', [Validators.required]],
      checks2Tres: ['', [Validators.required]],
      checks2Cuatro: ['', [Validators.required]],
      checks27U: ['', [Validators.required]],
      checks9SiDescripcion: ['', [Validators.required]],

    });
  }

  public beforeChange($event: NgbPanelChangeEvent) {
    /*if ($event.panelId === 'preventchange-2') {
      $event.preventDefault();
    }*/

    if ($event.panelId === 'preventchange-1' && $event.nextState === false) {
      $event.preventDefault();
    }



  }
  ngOnInit() {
    init_plugins();
    this.getOpciones1();
    this.getOpciones3();
    const id = this.route.snapshot.paramMap.get('id');
    this.proveedoresService.getProveedor(id)
      .subscribe(proveedor => this.proveedor = proveedor);
    
    this.toastr.success('es necesario subir un pdf con todos los documentos solicitados!', 'Hola!');
    this.iniciar()
  }

  getOpciones1() {
    return this.opcionesServices.getOpciones()
      .subscribe(
        opcionesGenerales => {
          this.toastr.success('Seleccione una opcion para validar si manda el documento solicitado o no!', 'Hola!');
          console.log(opcionesGenerales);
          this.opcionesGenerales = opcionesGenerales
        }
      );
  }



  saveData() {

  }



  onResetForm() {
    this.formularioProveedor.reset();

  }


  //funciones anandidas para mongoDB

  newProveedor(): void {
    this.submitted = false;
    this.proveedor = new FormProveedores();
  }

  addProveedor() {
    this.submitted = true;
    this.save();
    Swal.fire('Correcto', `La información fue guardada con éxito`, 'success');
    //this.onResetForm();
  }

  /*goBack(): void {
    this.location.back();
  }*/

  private save(): void {
    this.proveedor.urlPDF = this.urlPDF;
    this.proveedor.usuario = JSON.parse(localStorage.getItem('usuario')) as Usuario;
    console.log(this.proveedor);
    this.proveedoresService.addProveedor(this.proveedor)
      .subscribe();
  }


  //carga archivo
  onFileChange(element) {
    this.uploadedFiles = element.target.files;
    this.toastr.success('Es necesario subir un pdf con todos los documentos solicitados y renombrado con su nombre y ruc!', 'Gracias!');
    console.log("archivo"+this.uploadedFiles);
  }





  //sube archivo
  onUpload(){
    let formData = new FormData();

    for (var i = 0; i < this.uploadedFiles.length; i++) {
      formData.append("uploads[]", this.uploadedFiles[i], this.uploadedFiles[i].name);
      console.log(this.uploadedFiles[i]);
    }
    this.uploadService.uploadFile(formData).subscribe((res)=>{console.log('Response:',res);
  });
    Swal.fire('Correcto', `Archivo guardado con éxito`, 'success');
  }

  cerrarSesion(){
    this.proveedoresService.logOut()
  }
//nueva importacion de archivos drop
  upload(e) {
    const fileListAsArray = Array.from(e);
    fileListAsArray.forEach((item, i) => {
      const file = (e as HTMLInputElement);
      const url = URL.createObjectURL(file[i]);
      this.imgArr.push(url);
      this.fileArr.push({ item, url: url });
    })

    this.fileArr.forEach((item) => {
      this.fileObj.push(item.item)
    })

    // Set files form control
    this. formularioProveedor.patchValue({
      avatar: this.fileObj
    })

    this. formularioProveedor.get('avatar').updateValueAndValidity()

    // Upload to server
    this.dragdropService.addFiles(this. formularioProveedor.value.avatar)
      .subscribe((event: HttpEvent<any>) => {
     //   console.log(event.body.userCreate);
        switch (event.type) {
          case HttpEventType.Sent:
            console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:
            console.log('File uploaded successfully!', event.body);
             this.urlPDF= event.body.userCreated.avatar[0];
            setTimeout(() => {
              this.progress = 0;
              this.fileArr = [];
              this.fileObj = [];
              this.msg = "Archivo subido corretamente!"
              this.toastr.success('Es necesario subir un pdf con todos los documentos solicitados y renombrado con su nombre y ruc!', 'Gracias!');
            }, 3000);
        }
      })
  }

  // Clean Url for showing image preview
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  getOpciones3() {
    return this.opcionesServices3.getOpciones3()
      .subscribe(
        opcionesGenerales3 => {
          console.log(opcionesGenerales3);
          this.opcionesGenerales3 = opcionesGenerales3
        }
      );
  }

  atras(): void {
    this.location.back();
  }
  

  update(): void {
    this.submitted = true;
    this.proveedor.urlPDF = this.urlPDF;
    this.proveedoresService.updateProveedor(this.proveedor)
      .subscribe(result => this.message = "El proveedor fue actualizado");
    Swal.fire('Correcto', `La información fue actualizada con éxito`, 'success');
  }

  iniciar(){
    setTimeout(() => {
   this.cambioProvincia(this.proveedor.provincia)

    },3000);
   
   

  }
   
}
