import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InicioAdminComponent } from './pages/administrador/inicio-admin/inicio-admin.component';
import { NopagefoundComponent } from './nopagefound/nopagefound.component';
import { LoginAdminComponent } from './pages/administrador/login-admin/login-admin.component';
import { PagesComponent } from './pages/pages.component';
import { RegisterComponent } from './pages/administrador/register/register.component';
import { IdProveedoresComponent } from './pages/administrador/id-proveedores/id-proveedores.component';
import { CalificacionProveedoresComponent } from './pages/administrador/calificacion-proveedores/calificacion-proveedores.component';
import { AdminInformesComponent } from './pages/administrador/admin-informes/admin-informes.component';
import { DetalleCompletoComponent } from './pages/administrador/detalle-completo/detalle-completo.component';
import { DetalleExpressComponent } from './pages/administrador/detalle-express/detalle-express.component';

import { AdminProvComponent } from './pages/administrador/admin-prov/admin-prov.component';
import { LoginGuardGuard } from './services/login-guard.guard';
import { LoginProveedoresComponent } from './layout/calificacionProveedores/login-proveedores/login-proveedores.component';
import { LoginProveedorGuard } from './services/login-proveedor.guard';
import { SolicitudProvComponent } from './layout/calificacionProveedores/solicitud-prov/solicitud-prov.component';
import { DetalleSolicitudComponent } from './pages/administrador/detalle-solicitud/detalle-solicitud.component';
import { RequisitosProveedoresComponent } from './layout/calificacionProveedores/requisitos-proveedores/requisitos-proveedores.component';
import { InicioComercialesComponent } from './layout2/informesComerciales/inicio-comerciales/inicio-comerciales.component';
import { LoginComercialesComponent } from './layout2/informesComerciales/login-comerciales/login-comerciales.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';


import { PerfilProveedorComponent } from './layout/calificacionProveedores/perfil-proveedor/perfil-proveedor.component';
import { LayoutComponent } from './layout/layout.component';
import { AdminGuard } from './services/admin.guard';
import { Layout2Component } from './layout2/layout2.component';
import { PerfilComercialComponent } from './layout2/informesComerciales/perfil-comercial/perfil-comercial.component';

import { LoginComercialGuard } from './services/login-comercial.guard';
import { ListaArchivosComponent } from './pages/administrador/lista-archivos/lista-archivos.component';
import { IdInformesComponent } from './pages/administrador/id-informes/id-informes.component';
//import { ListainformesCompletosComponent } from './layout2/informesComerciales/listainformes-completos/listainformes-completos.component';
import { AdminTareasComponent } from './pages/administrador/admin-tareas/admin-tareas.component';
import { RequestResetComponent } from './recuperarContraseña/request-reset/request-reset.component';
import { ResponseResetComponent } from './recuperarContraseña/response-reset/response-reset.component';
import { FormularioExpressComponent } from './layout2/informesComerciales/formulario-express/formulario-express.component';
import { LoginClienteComponent } from './layout3/login-cliente/login-cliente.component';

import { Layout3Component } from './layout3/layout3.component';
import { PerfilClienteComponent } from './layout3/perfil-cliente/perfil-cliente.component';
import { LoginClienteGuard } from './services/login-cliente.guard';
import { Layout4Component } from './layout4/layout4.component';
import { InicioOperadorComponent } from './layout4/inicio-operador/inicio-operador.component';
import { LoginOperadorComponent } from './layout4/login-operador/login-operador.component';
import { PerfilOperadorComponent } from './layout4/perfil-operador/perfil-operador.component';
import { LoginOperadorGuard } from './services/login-operador.guard';
import { InformesRealizadosComponent } from './layout4/informes-realizados/informes-realizados.component';

import { AccoutSettingsComponent } from './accout-settings/accout-settings.component';
import { CalificacionProveedores2Component } from './layout3/calificacion-proveedores2/calificacion-proveedores2.component';

import { VerificaTokenGuard } from './services/verifica-token.guard';
import { InformesComercialesRealizadosComponent } from './layout2/informes-comerciales-realizados/informes-comerciales-realizados.component';
import { InformeCompletoComponent } from './layout2/informesComerciales/informe-completo/informe-completo.component';
import { InformeExpressComponent } from './layout2/informesComerciales/informe-express/informe-express.component';



import { SolicitudCompraComponent } from './pages/administrador/solicitud-compra/solicitud-compra.component';
import { DetalleCompraComponent } from './pages/administrador/solicitudCompra/detalle-compra/detalle-compra.component';
import { AdminInformesExpressComponent } from './pages/administrador/admin-informes-express/admin-informes-express.component';

import { LoginAnalistaComponent } from './layout6/login-analista/login-analista.component';
import { Layout6Component } from './layout6/layout6.component';
import { TareasAsignadasComponent } from './layout6/tareas-asignadas/tareas-asignadas.component';
import { PerfilAnalistaComponent } from './layout6/perfil-analista/perfil-analista.component';
import { CalificacionAnalistaComponent } from './layout6/calificacion-analista/calificacion-analista.component';
import { LoginAnalistaGuard } from './services/login-analista.guard';
import { ComprobantePagoComponent } from './layout/comprobante-pago/comprobante-pago.component';
import { ComprobantesComponent } from './pages/administrador/comprobantes/comprobantes.component';




import { CertificadosProveedorComponent } from './pages/certificados-proveedor/certificados-proveedor.component';
import { HistorialCalificacionComponent } from './layout/historial-calificacion/historial-calificacion.component';
import { AppmovilComponent } from './pages/appmovil/appmovil.component';

import { HistorialInformesComponent } from './layout2/historial-informes/historial-informes.component';
import { InformeExistenteComponent } from './layout2/informe-existente/informe-existente.component';
import { DesactivarComponent } from './pages/desactivar/desactivar.component';

import { ImportProveedoresComponent } from './layout3/import-proveedores/import-proveedores.component';
import { HistorialProveedoresComponent } from './layout3/historial-proveedores/historial-proveedores.component';
import { SolicitudCalificacionComponent } from './layout3/solicitud-calificacion/solicitud-calificacion.component';
import { CalificacionesRealizadasComponent } from './layout3/calificaciones-realizadas/calificaciones-realizadas.component';
import { EstadoProveedoresAdminComponent } from './pages/administrador/estado-proveedores-admin/estado-proveedores-admin.component';
import { DesactivarProveedoresComponent } from './pages/desactivar-proveedores/desactivar-proveedores.component';
import { BuscarInformeComponent } from './layout2/buscar-informe/buscar-informe.component';
import { RegistrarseComponent } from './layout2/registrarse/registrarse.component';
import { FormularioAdcicionalComponent } from './layout2/formulario-adcicional/formulario-adcicional.component';
import { FormularioAdicional2Component } from './layout2/formulario-adicional2/formulario-adicional2.component';
import { SolicitudPago2Component } from './layout2/solicitud-pago2/solicitud-pago2.component';
import { FormularioExpressCompletoComponent } from './layout2/formulario-express-completo/formulario-express-completo.component';
import { SolicitudPagoComponent } from './layout2/solicitud-pago/solicitud-pago.component';
import { HistorialSolicitudComponent } from './layout2/historial-solicitud/historial-solicitud.component';
import { DesactivarComprasComponent } from './pages/desactivar-compras/desactivar-compras.component';
import { TodoslosInformesComponent } from './pages/todoslos-informes/todoslos-informes.component';
import { InformecompletoEditableComponent } from './pages/informecompleto-editable/informecompleto-editable.component';
import { InformeexpressEditableComponent } from './pages/informeexpress-editable/informeexpress-editable.component';
import { DetalleExpress2Component } from './pages/todoslos-informes/detalle-express2/detalle-express2.component';
import { DetalleCompleto2Component } from './pages/todoslos-informes/detalle-completo2/detalle-completo2.component';



const routes: Routes = [

  { path:'loginAdmin', component: LoginAdminComponent},
  { path:'', redirectTo: 'loginAdmin', pathMatch: 'full'},
 
  {path: 'request-reset-password', component: RequestResetComponent},
  { path: 'response-reset-password/:token', component: ResponseResetComponent},
  
 //administrador 
  { path:'', component: PagesComponent , 
  children: [  
    { path:'Inicio', component: InicioAdminComponent, canActivate:[AdminGuard] },
    { path:'tema1', component: AccoutSettingsComponent,canActivate:[AdminGuard] },
  
    { path:'Registrar', component: RegisterComponent,canActivate:[AdminGuard]},
    { path:'idProveedores', component: IdProveedoresComponent ,canActivate:[AdminGuard]},
    { path: 'listaArchivos',component: ListaArchivosComponent}, 
    { path: 'completoeditable',component: InformecompletoEditableComponent}, 
    { path: 'expresseditable',component: InformeexpressEditableComponent},        
    { path: 'comprobantes', component: ComprobantesComponent },
    { path: 'todoslosinfo',component: TodoslosInformesComponent},
    { path: 'AdminProv', component: AdminProvComponent,canActivate:[AdminGuard] }, 
    { path: 'solicitudCompra', component: SolicitudCompraComponent,canActivate:[AdminGuard] },

 //informes comerciales
    { path: 'adminInforme', component: AdminInformesComponent},
    { path: 'adminInformeExpress', component: AdminInformesExpressComponent},
    { path: 'perfil',component: PerfilComponent, canActivate:[AdminGuard]},
    { path: 'usuarios',component: UsuariosComponent ,canActivate:[AdminGuard]},

 


    { path:'idInformes', component: IdInformesComponent, canActivate:[AdminGuard] },
    { path:'adminTareas', component: AdminTareasComponent,canActivate:[AdminGuard] },   
 

//proveedores
    { path: 'solicitud/:id', component: DetalleSolicitudComponent },
    { path: 'solicitud1/:id', component: DetalleCompraComponent },

    { path: 'listaprovAdmin',component:EstadoProveedoresAdminComponent},
    { path: 'certificadop', component: CertificadosProveedorComponent },
    { path: 'certificadop/:id', component: CertificadosProveedorComponent },
    { path: 'appmovil', component: AppmovilComponent },
    { path: 'desactivar', component: DesactivarComponent },
    { path: 'desactivarproveedor', component: DesactivarProveedoresComponent },    
    { path: 'desactivarCompras', component: DesactivarComprasComponent },
  ]
},
//todo lo relacionado con cliente proveedores
  { path:'', component: LayoutComponent , canActivate:[LoginProveedorGuard,VerificaTokenGuard],
  children: [
    { path:'perfilcliente', component: PerfilProveedorComponent, canActivate:[LoginProveedorGuard] },
    { path:'requisito', component: RequisitosProveedoresComponent, canActivate:[LoginProveedorGuard] },
    { path: 'solicitud', component: SolicitudProvComponent,canActivate:[LoginProveedorGuard] },
    { path: 'comprobante', component: ComprobantePagoComponent,canActivate:[LoginProveedorGuard] },
    { path: 'historial', component: HistorialCalificacionComponent,canActivate:[LoginProveedorGuard] },
   ]
},
//todo lo relacionado con informes comerciales
{ path:'', component: Layout2Component ,canActivate:[LoginComercialGuard,VerificaTokenGuard],                                          
children: [
  { path: 'buscarinforme',component: BuscarInformeComponent},
    
  { path:'inicioComerciales', component: InicioComercialesComponent,canActivate:[VerificaTokenGuard]},
  { path:'historialInformes', component:  HistorialInformesComponent},
  { path:'perfilinforme', component: PerfilComercialComponent,canActivate:[VerificaTokenGuard] },
  { path:'formularioExpress', component: FormularioExpressComponent,canActivate:[VerificaTokenGuard] },
  { path:'informeExistente/:id', component:  InformeExistenteComponent,canActivate:[VerificaTokenGuard] },  
  { path:'informeExistente', component:  InformeExistenteComponent,canActivate:[VerificaTokenGuard] },  
  { path:'formularioExpress/:id', component:  InformeExistenteComponent,canActivate:[VerificaTokenGuard] }, 
  { path:'FormulariosRealizados', component: InformesComercialesRealizadosComponent,canActivate:[VerificaTokenGuard] } ,
  { path: 'listaCompra',component: FormularioAdcicionalComponent},
  { path: 'listaCompra2',component: FormularioAdicional2Component},
  { path: 'solicitudPago2', component: SolicitudPago2Component,canActivate:[VerificaTokenGuard] },
  { path: 'listaCompra/:id',component: FormularioAdcicionalComponent},
  { path:'formularioExpressCompleto', component: FormularioExpressCompletoComponent }, 
  { path: 'solicitudPago', component: SolicitudPagoComponent,canActivate:[VerificaTokenGuard] },
  { path: 'solicitudPago/:id', component: SolicitudPagoComponent,canActivate:[VerificaTokenGuard] },
  { path: 'historialCompra',component:  HistorialSolicitudComponent}, 
 ]
},


//todo lo relacionado con cliente que soclicita calificacion de proveedores
{ path:'', component: Layout3Component , canActivate:[LoginClienteGuard],                                         
children: [
  { path: 'ListaProveedores',component: ImportProveedoresComponent,canActivate:[LoginClienteGuard]},
  { path: 'perfilcliente1', component: PerfilClienteComponent,canActivate:[LoginClienteGuard,VerificaTokenGuard]},
  { path: 'hitrorialProveedores',component: HistorialProveedoresComponent,canActivate:[LoginClienteGuard]},
  { path: 'solicitudCalificacion',component: SolicitudCalificacionComponent,canActivate:[LoginClienteGuard]}, 
  { path: 'calificacionesRealizadas',component: CalificacionesRealizadasComponent,canActivate:[LoginClienteGuard]}, 
]
},

//Todo lo relacionado con operador
{ path:'', component: Layout4Component ,canActivate:[LoginOperadorGuard],                                         
children: [

  { path:'perfilOperador', component: PerfilOperadorComponent,canActivate:[LoginOperadorGuard,VerificaTokenGuard]},  
  { path:'inicioOperador', component: InicioOperadorComponent,canActivate:[LoginOperadorGuard,VerificaTokenGuard]},
  { path:'informesRealizados', component: InformesRealizadosComponent,canActivate:[LoginOperadorGuard,VerificaTokenGuard]},
 ]
},
//compra de informes comerciales


//paginas de analista de proveedores

{
  path:'', component: Layout6Component ,  canActivate:[LoginAnalistaGuard],                                        
  children: [
    { path: 'tareas',component: TareasAsignadasComponent, canActivate:[LoginAnalistaGuard,VerificaTokenGuard]}, 
    { path:'perfilAnalista', component:  PerfilAnalistaComponent,canActivate:[LoginAnalistaGuard,VerificaTokenGuard]},
  ]
},



//todas las paginas que no tienen sideabar ni header 
//informes comerciales
{ path: 'completo/:id',component: DetalleCompletoComponent, canActivate:[VerificaTokenGuard]},
{ path: 'express/:id',component: DetalleExpressComponent, canActivate:[VerificaTokenGuard]},
{ path: 'completo',component: DetalleCompletoComponent, canActivate:[VerificaTokenGuard]},
{ path: 'express',component: DetalleExpressComponent, canActivate:[VerificaTokenGuard]}, 
//===============================PARA COMPRAS==========================================================//
{ path: 'completo2/:id',component: DetalleCompleto2Component, canActivate:[VerificaTokenGuard]},
{ path: 'express2/:id',component: DetalleExpress2Component, canActivate:[VerificaTokenGuard]},
{ path: 'completo2',component: DetalleCompleto2Component, canActivate:[VerificaTokenGuard]},
{ path: 'express2',component: DetalleExpress2Component, canActivate:[VerificaTokenGuard]}, 
//============================================================================================
//informes cliente 
{ path: 'completo1',component: InformeCompletoComponent, canActivate:[VerificaTokenGuard]},
{ path: 'express1',component: InformeExpressComponent, canActivate:[VerificaTokenGuard]},
{ path: 'completo1/:id',component: InformeCompletoComponent, canActivate:[VerificaTokenGuard]},
{ path: 'express1/:id',component: InformeExpressComponent, canActivate:[VerificaTokenGuard]},

//todos los logins
  { path:'loginProveedor', component: LoginProveedoresComponent},
  { path: 'loginComerciales',component: LoginComercialesComponent},
  { path: 'loginCliente',component: LoginClienteComponent},
   { path: 'loginOperador',component: LoginOperadorComponent},
   { path: 'loginAnalista',component: LoginAnalistaComponent},

   { path: 'registrarse',component: RegistrarseComponent},
 //fin logins  
 
 
  { path:'temas', component: AccoutSettingsComponent },
//proveedores
  { path: 'solicitud', component: SolicitudProvComponent,canActivate:[LoginProveedorGuard,VerificaTokenGuard] },
  { path:'requisito', component: RequisitosProveedoresComponent, canActivate:[LoginProveedorGuard,VerificaTokenGuard] },
  { path:'requisito/:id', component: RequisitosProveedoresComponent, canActivate:[LoginProveedorGuard,VerificaTokenGuard] },
  { path:'calificacion/:id', component: CalificacionProveedoresComponent, canActivate:[LoginGuardGuard,VerificaTokenGuard]},
  { path:'calificacion', component: CalificacionProveedoresComponent, canActivate:[LoginGuardGuard,VerificaTokenGuard]},
//fin proveedores

//cliente proveedores
  { path:'calificacion1/:id', component: CalificacionProveedores2Component,},
  { path:'calificacion1', component: CalificacionProveedores2Component, },


  
//analista proveedores
{ path:'calificacionAnalista/:id', component: CalificacionAnalistaComponent, canActivate:[LoginAnalistaGuard,VerificaTokenGuard]},
{ path:'calificacionAnalista', component: CalificacionAnalistaComponent, canActivate:[LoginAnalistaGuard,VerificaTokenGuard]},



//redirecion cuando hay ruta mal ubicada
  { path:'**', component: NopagefoundComponent},



 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
