import { Contact2 } from '../../models/contact2.models';
import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/models/usuario.model';
import { ExcelService } from 'src/app/services/excel.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import * as faker from 'faker';
import { ListaproveedorService } from 'src/app/services/listaproveedor.service';
import Swal from 'sweetalert2';

declare function init_plugins()


@Component({
  selector: 'app-import-proveedores',
  templateUrl: './import-proveedores.component.html',
  styleUrls: ['./import-proveedores.component.css']
})
export class ImportProveedoresComponent implements OnInit {
  usuario : Usuario;
  importContacts: Contact2[] = [];
  exportContacts: Contact2[] = [];
  //metodos para enviar la base
  lista = new Contact2();
  submitted = false;

  constructor(private excelSrv: ExcelService, private listainforme:ListaproveedorService,public _usuarioServices: UsuarioService) { 
  }

  ngOnInit() {
    this.usuario = this._usuarioServices.usuario;
    for (let index = 0; index < 10; index++) {
      const contact = new Contact2();
      contact.razonSocial = faker.name.findName();
      contact.ruc = faker.address.zipCode();
      contact.personaContacto = faker.name.findName();
      contact.telefono = faker.phone.phoneNumber();
      contact.email = faker.internet.email();
      contact.ciudad = faker.address.country();
      contact.nacionalInternacional=faker.name.findName();
      contact.observaciones = faker.name.findName();
    //  contact.ruc = faker.address.zipCode();
    //  contact.ciudad = faker.address.country();
     
    
    // 
    //  contact.ventasAnuales = faker.finance.mask();
    //  contact.plazoPagoCredito = faker.finance.mask();
    
      // contact.contacto = faker.phone.phoneNumber();
      // contact.fecha = faker.date.past();
     //  contact.tipocliente = 'CLIENTE SERVIRATING'
      //contact.nombreSolicitante = faker.name.findName();
      this.exportContacts.push(contact);
      console.log(contact);
      return (contact)
    }

  }
  onFileChange(evt: any) {
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const data = <any[]>this.excelSrv.importFromFile(bstr);
      const header: string[] = Object.getOwnPropertyNames(new Contact2());
      const importedData = data.slice(1);
      this.importContacts = importedData.map(arr => {
        var obj = {};
        for (let i = 0; i < header.length; i++) {
          const k = header[i];
          obj[k] = arr[i];
        }
        obj["usuario"] = this.usuario
        console.log(obj);
        this.listainforme.addCustomer(<Contact2>obj)
        .subscribe();
        return <Contact2>obj;        
      })
    };
    reader.readAsBinaryString(target.files[0]);
    Swal.fire('Correcto', `La información fue guardada con éxito`, 'success');
  }

 
}
